import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Authorization.ResetPassword.Request>(
  {
    email: {
      required: true,
      type: 'string',
      match: regexps.email,
      message: {
        required: 'Email is required',
        match: 'A valid email address is required'
      }
    },
    token: {
      required: true,
      message: {
        required: 'Token is required'
      }
    },
    password: {
      required: true,
      type: 'string',
      length: {
        min: 6
      },
      message: {
        required: 'Password must be at least 6 characters long',
        length: 'Password must be at least 6 characters long'
      }
    },
    passwordConfirmation: {
      required: true,
      type: 'string',
      use: { equals: (val, obj) => val === obj.password },
      message: {
        required: 'Confirm password is required',
        equals: 'Passwords must match'
      }
    }
  },
  { strip: false }
);
