import {
  Card,
  CardContent,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormButton, GenericLink } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { suppressFormSubmission } from 'src/components/FormDialog';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import SignInRequest from 'src/schemas/SignInRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import { Omit } from 'src/services/withDataFromApi';
import { RelmIcon } from 'src/theme/icons';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    authCard: {
      margin: `0 ${theme.spacing()}px`,
      width: `${theme.spacing(50)}px`,
      height: `${theme.spacing(45)}px`,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between'
    },
    toolbarBrand: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.primary.main,
      paddingTop: `${theme.spacing(1.5)}px`,
      paddingBottom: `${theme.spacing(1.5)}px`
    },
    toolbarBrandName: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      fontSize: `${theme.spacing(3.5)}px`
    },
    logo: {
      height: '46px',
      width: 'auto'
    },
    cardActionsRoot: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    loginButton: {
      marginBottom: `${theme.spacing()}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

// API.Authorization.RequestAccessToken.PasswordGrantRequest
interface State
  extends ValidationTraitState<
    Omit<
      API.Authorization.RequestAccessToken.PasswordGrantRequest,
      keyof API.Authorization.RequestAccessToken.Common.Request
    >
  > {}

// endregion

class SignIn extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _validator = new ValidationTrait(this, SignInRequest);

  readonly state: State = {
    inputValues: {
      username: '',
      password: ''
    },
    inputErrors: {
      username: '',
      password: ''
    }
  };

  async tryAuthenticate() {
    try {
      await RelmApi.authenticate(
        this.state.inputValues.username,
        this.state.inputValues.password
      );
    } catch (error) {
      if (error instanceof AxiosException) {
        // the message provided for oauth errors is very technical, so just check
        // it's there in case we've 500'd, but use a simpler message for users.
        const message = error.response?.data.message
          ? 'Invalid username or password'
          : 'Something went wrong trying to log in';

        toast(SnackbarVariant.ERROR, message);

        return;
      }

      this._validator.handlePossibleValidationError(error);
    }
  }

  // region autobound methods
  @autobind
  handleSignInButtonClick() {
    load(this.tryAuthenticate());
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <div className={this.props.classes.root}>
        <Card
          className={this.props.classes.authCard}
          component="form"
          onSubmit={suppressFormSubmission}
        >
          <CardContent className={this.props.classes.toolbarBrand}>
            <RelmIcon className={this.props.classes.logo} />
            <Typography
              variant="h4"
              className={this.props.classes.toolbarBrandName}
            >
              Login
            </Typography>
          </CardContent>
          <CardContent>
            {this._validator.buildTextFieldForProperty('username', 'Email', {
              required: true
            })}
            {this._validator.buildTextFieldForProperty('password', 'Password', {
              required: true,
              type: 'password'
            })}
          </CardContent>
          <CardContent className={this.props.classes.cardActionsRoot}>
            <FormButton
              className={this.props.classes.loginButton}
              variant="outlined"
              fullWidth
              onClick={this.handleSignInButtonClick}
            >
              Login
            </FormButton>
            <Typography align="center" variant="body2">
              <GenericLink to="/password/forgot">Forgot password?</GenericLink>
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }

  // endregion
}

export default withStyles(styles)(SignIn);
