import {
  Button,
  List,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import cx from 'classnames';
import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PersonaListItem, {
  PersonaListItemEvent
} from 'src/components/PersonaListItem';
import { API } from 'src/definitions';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: 'auto',
      paddingTop: 0,
      paddingBottom: 0
    },
    hiddenWarning: {
      margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    }
  });
// endregion

// region component props
interface ExternalProps {
  className?: string;
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  personas: API.Entities.Persona[];
  totalPersona: number;
  /**
   * Id of the `Persona` that is currently selected.
   */
  currentlySelectedPersonaId?: string;
  columnId: string;

  onPersonaArchived: (event: PersonaListItemEvent) => void;
  onPersonaClick: (event: PersonaListItemEvent) => void;
  onFilterClearClick: (event: React.MouseEvent<HTMLElement>) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class CurrentSellersPersonasList extends React.Component<Props, State> {
  static readonly defaultProps = {
    className: '',
    persona: []
  };

  readonly state: State = {};

  // region render & get-render-content methods
  render() {
    const hiddenPersonas = this.props.totalPersona - this.props.personas.length;

    return (
      <List className={cx(this.props.classes.root, this.props.className)}>
        <Droppable droppableId={this.props.columnId}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {this.props.personas.map((persona, index) => (
                <PersonaListItem
                  key={persona.id}
                  persona={persona}
                  index={index}
                  selected={
                    persona.id === this.props.currentlySelectedPersonaId
                  }
                  onPersonaArchived={this.props.onPersonaArchived}
                  onClick={this.props.onPersonaClick}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {this.props.totalPersona === 0 && (
          <Typography
            className={this.props.classes.hiddenWarning}
            align="center"
          >
            No Persona.
          </Typography>
        )}
        {this.props.totalPersona !== 0 && hiddenPersonas !== 0 && (
          <Button
            variant="outlined"
            size="small"
            onClick={this.props.onFilterClearClick}
            className={this.props.classes.hiddenWarning}
          >
            {this.props.totalPersona === hiddenPersonas &&
              'No Persona matches with your filter'}
            {this.props.totalPersona > hiddenPersonas &&
              `Show ${hiddenPersonas} hidden persona${
                hiddenPersonas !== 1 ? 's' : ''
              }`}
          </Button>
        )}
      </List>
    );
  }

  // endregion
}

export default withStyles(styles)(CurrentSellersPersonasList);
