// eslint-disable-next-line @typescript-eslint/no-var-requires
const Callsite = require('callsite');

/**
 * Base class for all generic exceptions and errors.
 *
 * If it's usually possible to recover from an error, then it's an Exception, otherwise;
 * If it's not usually possible to recover from an error, then it's an Error.
 *
 * This class is named as an Exception optimistically,
 * as it's favourable to recover whenever possible.
 */
class GenericException extends Error {
  protected readonly _timestamp = new Date();

  /**
   *
   * @param {string} message
   */
  constructor(message: string) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GenericException);
    }
  }

  // region getters & setters
  /**
   *
   * @return {string}
   * @instance
   */
  get name() {
    return Callsite()[0].getTypeName();
  }

  /**
   *
   * @return {Date}
   */
  get timestamp() {
    return this._timestamp;
  }

  // endregion
}

export default GenericException;
