import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Sellers.UpdateSellerStyles.Request>(
  {
    title: {
      required: true,
      type: 'string',
      message: {
        required: 'Title is required'
      }
    },
    subtitle: {
      required: false,
      type: 'string'
    },
    hostingUrl: {
      required: false,
      type: 'string',
      match: regexps.urlOrEmpty,
      message: {
        match: 'Must be a valid url'
      }
    },
    imageLogo: {
      required: false,
      type: 'string',
      match: regexps.urlOrEmpty,
      message: {
        match: 'Must be a valid url'
      }
    },
    favicon: {
      required: false,
      type: 'string',
      match: regexps.urlOrEmpty,
      message: {
        match: 'Must be a valid url'
      }
    },
    fontContent: {
      required: false,
      type: 'string',
      message: {
        font: 'Must be a valid font'
      }
    },
    fontTitle: {
      required: false,
      type: 'string',
      message: {
        font: 'Must be a valid font'
      }
    },
    colorContentPrimary: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    colorContentTonic: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    colorContentOver: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    colorBodyBackground: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    colorContentBackground: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    colorTopbarBackground: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    colorFooterBackground: {
      required: false,
      type: 'string',
      message: {
        color: 'Must be a valid color'
      }
    },
    piAld: {
      required: false,
      type: 'string'
    },
    piCld: {
      required: false,
      type: 'string'
    },
    signatureEnabled: {
      required: false,
      type: 'boolean'
    },
    signature: {
      required: false,
      type: 'string'
    }
  },
  { strip: false }
);
