import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import * as React from 'react';
import { Omit } from 'src/definitions';
import Toolbar, { ToolbarProps } from './components/Toolbar';
import createStore from './utils/createStore';

import './css/buttonStyles.css';
import './css/blockTypeSelectStyles.css';

const buttonStyles = {
  buttonWrapper: 'buttonWrapper',
  button: 'button',
  active: 'active',
  separator: 'separator'
};

const blockTypeSelectStyles = {
  blockType: 'blockType',
  spacer: 'spacer',
  popup: 'static'
};

export interface StaticToolbarStore {
  isVisible: boolean;
  editorState: Draft.EditorState;
  getEditorRef: () => React.Ref<unknown>;
  getEditorState: () => Draft.EditorState;
  setEditorState: (editorState: Draft.EditorState) => void;
}

export interface StaticToolbarConfig {
  theme?: DraftJsButtons.Theme;
}

const createStaticToolbarPlugin = (config: StaticToolbarConfig = {}) => {
  const defaultTheme = { buttonStyles, blockTypeSelectStyles };

  const store = createStore({
    isVisible: false,
    editorState: Draft.EditorState.createEmpty(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getEditorRef: ((() => {}) as unknown) as any,
    getEditorState: () => Draft.EditorState.createEmpty(),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setEditorState: (editorState: Draft.EditorState) => {}
  });

  const { theme = defaultTheme } = config;

  const toolbarProps = {
    store,
    theme
  };

  return {
    initialize: ({
      setEditorState,
      getEditorState,
      getEditorRef
    }: {
      getEditorRef: () => React.Ref<unknown>;
      getEditorState: () => Draft.EditorState;
      setEditorState: (editorState: Draft.EditorState) => void;
    }) => {
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
      store.updateItem('getEditorRef', getEditorRef);
    },
    // Re-Render the toolbar on every change
    onChange: (editorState: Draft.EditorState) => {
      store.updateItem('editorState', editorState);

      return editorState;
    },
    StaticToolbar: (props: Omit<ToolbarProps, keyof typeof toolbarProps>) => (
      <Toolbar {...props} {...toolbarProps} />
    )
  };
};

export default createStaticToolbarPlugin;
