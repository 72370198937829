import {
  Avatar,
  CardHeader,
  CardMedia,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Close, Home, Panorama, Videocam, VpnKey } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import * as React from 'react';
import { FormDialog } from 'src/components';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import EditorUtils from 'src/draft-plugins/draft-js-plugins-utils';
import {
  assertIsDefined,
  buildRelmHostingUrlForSeller,
  getYoutubeThumbUrl,
  getYoutubeVideoId
} from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    dialogHeader: {
      display: 'flex',
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`,
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      flex: '0 0 auto',
      boxShadow: '0px 4px 3px -2px rgba(0, 0, 0, 0.2)',
      zIndex: 2
    },
    dialogContent: {
      minWidth: '450px'
    },
    closeButton: {
      color: theme.palette.grey[500],
      marginTop: `${theme.spacing()}px`
    },
    listSubHeader: {
      backgroundColor: theme.palette.background.paper,
      borderTop: `1px solid ${theme.palette.grey.A200}`,
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      color: theme.palette.grey.A400,
      padding: `${theme.spacing()}px ${theme.spacing(3)}px`
    },
    listItemStories: {
      padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`
    },
    listItemStoriesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    listItemAvatar: {
      width: '40px',
      margin: '0 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    listItemIcon: {
      height: '50px',
      width: '40px',
      margin: '0 20px',
      padding: `${theme.spacing(1.5)}px 0px`
    },
    listItemPicture: {
      width: '80px',
      minWidth: '80px',
      height: '50px',
      margin: '0',
      borderRadius: `${theme.shape.borderRadius}px`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    imageWrapper: {
      position: 'relative',
      width: '80px',
      minWidth: '80px',
      margin: '0'
    },
    videoCover: {
      position: 'absolute',
      margin: '2px',
      padding: '2px',
      paddingBottom: 0,
      top: 0,
      right: 0,
      borderRadius: `${theme.shape.borderRadius}px`,
      backgroundColor: fade(theme.palette.text.disabled, 0.5)
    },
    videoCoverIcon: {
      color: '#fff',
      height: '0.75em',
      width: '0.75em'
    }
  });
// endregion

// region component props
interface ExternalProps {
  editorState: Draft.EditorState;
  selectedEngagement?: API.Nullable<API.Entities.EngagementWithConsumer>;
  stories: API.Entities.Story[];
  steps?: API.Entities.Step[];

  onChange: Draft.EditorProps['onChange'];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  DraftJsButtons.ButtonProps &
  WithStyles<typeof styles>;

interface State {
  isSelectLinkDialogOpen: boolean;
  selectedStoryId: API.Nullable<string>;
}

// endregion

/**
 *
 */
@withCurrentSeller<Props>()
class AddRelmLinkButton extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    selectedEngagement: null,
    onCurrentSellerChange: () => {},
    steps: undefined
  };

  private readonly _relmLink = 'relmlink';

  readonly state: State = {
    isSelectLinkDialogOpen: false,
    selectedStoryId: null
  };

  buildStoryLink() {
    assertIsDefined(this.props.currentSeller);

    if (this.state.selectedStoryId === this._relmLink) {
      return `{${this._relmLink}}`;
    }

    const hostingUrl = buildRelmHostingUrlForSeller(this.props.currentSeller);

    // can't use URL here as it'll encode {consumer_relm_key} :'(
    return `${hostingUrl}/rk/{consumer_relm_key}/${this.state.selectedStoryId}.html`;
  }

  // region component lifecycle methods
  // endregion
  // region autobound methods
  @autobind
  handleAddLinkClick() {
    this.setState({ isSelectLinkDialogOpen: true });
  }

  @autobind
  handleSelectLinkDialogClose() {
    this.setState({
      isSelectLinkDialogOpen: false,
      selectedStoryId: null
    });
  }

  async handleSelectStory(event: React.SyntheticEvent<unknown>, value: string) {
    const { getEditorState, setEditorState } = this.props;

    await this.setState({ selectedStoryId: value });

    setEditorState(
      EditorUtils.createLinkAtSelection(getEditorState(), this.buildStoryLink())
    );

    this.handleSelectLinkDialogClose();
  }

  // endregion
  // region render & get-render-content methods
  getIcon(story?: API.Entities.Story) {
    if (!story) {
      return (
        <Avatar>
          <Home className={this.props.classes.listItemIcon} />
        </Avatar>
      );
    }

    const youtubeId = getYoutubeVideoId(story.videoUrl ?? '');
    const videoUrl = youtubeId && getYoutubeThumbUrl(youtubeId);
    const thumbnailUrl = videoUrl ?? story.imageUrl;

    if (!thumbnailUrl) {
      return (
        <Avatar>
          <Panorama className={this.props.classes.listItemIcon} />
        </Avatar>
      );
    }

    return (
      <div className={this.props.classes.imageWrapper}>
        {videoUrl && (
          <div className={this.props.classes.videoCover}>
            <Videocam className={this.props.classes.videoCoverIcon} />
          </div>
        )}
        <CardMedia
          className={this.props.classes.listItemPicture}
          image={thumbnailUrl}
        />
      </div>
    );
  }

  getAvatar(story?: API.Entities.Story) {
    return (
      <ListItemAvatar className={this.props.classes.listItemAvatar}>
        {this.getIcon(story)}
      </ListItemAvatar>
    );
  }

  getStepHeader(currentStep?: API.Entities.Step) {
    if (!currentStep) {
      return;
    }

    return (
      <ListSubheader
        className={this.props.classes.listSubHeader}
        key={currentStep.position}
      >
        <Typography variant="caption">
          {`Step ${currentStep.position}: ${currentStep.name}`}
        </Typography>
      </ListSubheader>
    );
  }

  getHomepageRender() {
    return (
      <ListItem
        className={this.props.classes.listItemStories}
        key="homepage"
        divider={this.props.stories.length !== 0}
        button
        onClick={event => this.handleSelectStory(event, this._relmLink)}
      >
        <div className={this.props.classes.listItemStoriesContainer}>
          {this.getAvatar()}
          <ListItemText primary="Homepage" />
        </div>
      </ListItem>
    );
  }

  getStoriesRender() {
    if (!this.props.stories.length) {
      return null;
    }
    let lastStep: API.Nullable<string> = null;
    const consumerId: string | undefined = this.props.selectedEngagement
      ?.consumer.id;

    return this.props.stories
      .filter(
        story =>
          story.stepId !== null &&
          (story.consumerId === null || story.consumerId === consumerId)
      )
      .sort((a, b) => {
        return a.stepId !== null &&
          b.stepId !== null &&
          a.stepId + a.position > b.stepId + b.position
          ? 1
          : -1;
      })
      .map(story => {
        const currentStep = this.props.steps?.filter(
          step => step.id === story.stepId
        )[0];

        const showStep = this.props.steps && story.stepId !== lastStep;

        lastStep = story.stepId;

        const privatePostMessage = story.consumerId ? 'Private Story' : '';

        return (
          <>
            {showStep && this.getStepHeader(currentStep)}
            <ListItem
              className={this.props.classes.listItemStories}
              key={story.id}
              divider
              button
              onClick={event => this.handleSelectStory(event, story.id)}
            >
              <div className={this.props.classes.listItemStoriesContainer}>
                {this.getAvatar(story)}
                <ListItemText
                  primary={story.title}
                  secondary={privatePostMessage}
                />
              </div>
            </ListItem>
          </>
        );
      });
  }

  render() {
    return (
      <>
        <div className={this.props.theme.buttonWrapper}>
          <button
            className={this.props.theme.button}
            type="button"
            onClick={this.handleAddLinkClick}
          >
            <VpnKey />
          </button>
        </div>
        <FormDialog
          open={this.state.isSelectLinkDialogOpen}
          aria-labelledby={`${this.constructor.name}-dialog-title`}
          disableEnforceFocus
          onClose={this.handleSelectLinkDialogClose}
        >
          <CardHeader
            id={`${this.constructor.name}-dialog-title`}
            className={this.props.classes.dialogHeader}
            avatar={<VpnKey />}
            action={
              <IconButton
                aria-label="Close"
                className={this.props.classes.closeButton}
                onClick={this.handleSelectLinkDialogClose}
              >
                <Close />
              </IconButton>
            }
            title={<Typography variant="h6">Link your landing page</Typography>}
          />
          <DialogContent className={this.props.classes.dialogContent}>
            <List>
              {this.getHomepageRender()}
              {this.getStoriesRender()}
            </List>
          </DialogContent>
        </FormDialog>
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(AddRelmLinkButton);
