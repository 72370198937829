import {
  CardHeader,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { Close, Link } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import * as React from 'react';
import { FormDialog, Glue } from 'src/components';
import LoadingButton from 'src/components/LoadingButton';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import EditorUtils from 'src/draft-plugins/draft-js-plugins-utils';
import regexps from 'src/schemas/regexps';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    dialogHeader: {
      display: 'flex',
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`,
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      flex: '0 0 auto',
      boxShadow: '0px 4px 3px -2px rgba(0, 0, 0, 0.2)',
      zIndex: 2
    },
    dialogContent: {
      paddingTop: `${theme.spacing(3)}px`,
      minWidth: '450px'
    },
    closeButton: {
      color: theme.palette.grey[500],
      marginTop: `${theme.spacing()}px`
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    }
  });

// region component props
interface ExternalProps {
  editorState: Draft.EditorState;
  selectedEngagement?: API.Nullable<API.Entities.EngagementWithConsumer>;

  onChange: Draft.EditorProps['onChange'];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  DraftJsButtons.ButtonProps &
  WithStyles<typeof styles>;

interface State {
  isSelectLinkDialogOpen: boolean;
  genericUrlValue: string;
  genericUrlError: string;
}

// endregion

/**
 *
 */
@withCurrentSeller<Props>()
class AddLinkButton extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    selectedEngagement: null,

    onCurrentSellerChange: () => {}
  };

  private readonly _genericUrlInputRef: React.RefObject<
    HTMLInputElement
  > = React.createRef<HTMLInputElement>();

  readonly state: State = {
    isSelectLinkDialogOpen: false,
    genericUrlValue: '',
    genericUrlError: ''
  };

  validateGenericUrl(genericUrl: string): string {
    return genericUrl.match(regexps.url) ? '' : 'Must be a valid URL';
  }

  // region component lifecycle methods
  // endregion
  // region autobound methods
  @autobind
  handleAddLinkClick() {
    this.setState({ isSelectLinkDialogOpen: true });
  }

  @autobind
  handleSelectLinkDialogClose() {
    this.setState({
      genericUrlValue: '',
      genericUrlError: '',
      isSelectLinkDialogOpen: false
    });
  }

  @autobind
  handleGenericUrlInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const genericUrlValue = event.target.value;
    const genericUrlError = this.validateGenericUrl(genericUrlValue);

    this.setState({ genericUrlValue, genericUrlError });
  }

  @autobind
  handleGenericUrlInputClick() {
    this._genericUrlInputRef.current &&
      this._genericUrlInputRef.current.select();
  }

  @autobind
  handleInsertLink() {
    const { getEditorState, setEditorState } = this.props;

    setEditorState(
      EditorUtils.createLinkAtSelection(
        getEditorState(),
        this.state.genericUrlValue
      )
    );

    this.setState({
      genericUrlValue: '',
      genericUrlError: '',
      isSelectLinkDialogOpen: false
    });
  }

  @autobind
  isInsertLinkButtonDisabled(): boolean {
    return !(this.state.genericUrlValue && !this.state.genericUrlError);
  }

  // endregion
  // region render & get-render-content methods

  render() {
    const classes = this.props.classes;

    return (
      <>
        <div className={this.props.theme.buttonWrapper}>
          <button
            className={this.props.theme.button}
            type="button"
            onClick={this.handleAddLinkClick}
          >
            <Link />
          </button>
        </div>
        <FormDialog
          open={this.state.isSelectLinkDialogOpen}
          aria-labelledby={`${this.constructor.name}-dialog-title`}
          onClose={this.handleSelectLinkDialogClose}
        >
          <CardHeader
            id={`${this.constructor.name}-dialog-title`}
            className={classes.dialogHeader}
            avatar={<Link />}
            action={
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={this.handleSelectLinkDialogClose}
              >
                <Close />
              </IconButton>
            }
            title={<Typography variant="h6">External Link</Typography>}
          />
          <DialogContent className={classes.dialogContent}>
            <TextField
              name="genericUrl"
              error={!!this.state.genericUrlError}
              fullWidth
              helperText={this.state.genericUrlError}
              id="genericUrl"
              inputRef={this._genericUrlInputRef}
              label="URL"
              margin="dense"
              placeholder="http://"
              value={this.state.genericUrlValue || ''}
              onChange={this.handleGenericUrlInputChange}
              onClick={this.handleGenericUrlInputClick}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Glue />
            <LoadingButton
              color="primary"
              variant="outlined"
              autoFocus
              disabled={this.isInsertLinkButtonDisabled()}
              onClick={this.handleInsertLink}
            >
              Insert Link
            </LoadingButton>
          </DialogActions>
        </FormDialog>
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(AddLinkButton);
