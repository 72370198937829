import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import { BlockTypeSelectStyles, ButtonStyles } from 'draft-js-buttons';
import * as React from 'react';

export interface BlockTypeSelectTheme {
  buttonStyles: ButtonStyles;
  blockTypeSelectStyles: BlockTypeSelectStyles;
}

// region component props
interface ExternalProps {
  children: (externalProps: DraftJsButtons.ButtonProps) => React.ReactNode;

  theme: BlockTypeSelectTheme;
  getEditorState: () => Draft.EditorState;
  setEditorState: (editorState: Draft.EditorState) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  style: { [k: string]: string };
}

// endregion

/**
 *
 */
class BlockTypeSelect extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = BlockTypeSelect.name;

  readonly state: State = {
    style: {
      // transform: 'translate(-50%) scale(1)'
    }
  };

  @autobind
  handleMouseDown(clickEvent: React.SyntheticEvent<HTMLDivElement>) {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const { theme, getEditorState, setEditorState } = this.props;

    return (
      <div onMouseDown={this.handleMouseDown}>
        <div
          className={theme.blockTypeSelectStyles.popup}
          style={this.state.style}
        >
          {this.props.children({
            getEditorState,
            setEditorState,
            theme: theme.buttonStyles
          })}
        </div>
      </div>
    );
  }

  // endregion
}

export default BlockTypeSelect;
