import {
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, PersonOutline } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Papa from 'papaparse';
import * as React from 'react';
import { FormButton, FormDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import { API, Omit } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import ValidationException from 'src/exceptions/ValidationException';
import AddConsumerRequest from 'src/schemas/AddConsumerRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import { buildTrelloCardFromConsumer } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: `${theme.breakpoints.values.sm}px`
      }
    },
    typographyTitle: {
      textAlign: 'center'
    },
    cardHeader: {
      paddingTop: '10px',
      paddingBottom: '0'
    },
    cardContent: {
      paddingTop: '0',
      paddingBottom: '0'
    },
    cardActions: {
      paddingTop: '0',
      paddingBottom: '20px'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  /**
   * The default `Step` on the board.
   */
  defaultStep: API.Entities.Step;

  onConsumerAdded: (stepSlug: API.Entities.Step['id']) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentPersonaValue &
  ReactTrello.NewCardTemplateProps &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<
    Omit<API.Consumers.AddConsumer.Request, 'personaId'>
  > {
  addMode: ConsumerAddMode;
  csvConsumersValue: string;
  csvConsumersError: string;
}

// endregion

enum ConsumerAddMode {
  SINGLE = 'single',
  BULK = 'bulk'
}

/**
 *
 */
@withCurrentPersona<Props>()
class AddConsumerCardTemplate extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentPersona: null,
    laneId: '',
    onCurrentPersonaChange: () => {},
    onCancel: () => {},
    onAdd: () => {}
  };

  private readonly _validator = new ValidationTrait(this, AddConsumerRequest);

  readonly state: State = {
    addMode: ConsumerAddMode.SINGLE,
    csvConsumersValue: '',
    csvConsumersError: '',
    inputValues: {
      email: '',
      firstName: '',
      lastName: '',
      organisation: '',
      stepId: this.props.laneId
    },
    inputErrors: {
      email: '',
      firstName: '',
      lastName: '',
      organisation: '',
      stepId: ''
    }
  };

  async tryCreateNewConsumers() {
    // lane ids starting with `$` are invalid, and should be ignored ;)
    const stepId = this.props.laneId.startsWith('$')
      ? undefined
      : this.props.laneId;
    const personaId = stepId ? this.props.currentPersona!.id : undefined;

    const parseResults = Papa.parse<string[]>(this.state.csvConsumersValue, {
      delimiter: ',',
      skipEmptyLines: true
    });

    const consumers: API.Consumers.AddConsumerInBulk.Request['consumers'] = parseResults.data.map(
      (result: string[]) => ({
        organisation: (result.length === 4 && result.pop()) || '',
        email: result.pop() ?? '',
        lastName: result.pop() ?? '',
        firstName: result.pop() ?? '',
        stepId,
        personaId
      })
    );

    try {
      const newConsumers = (
        await RelmApi.bulkCreateConsumers(this.props.currentPersona!.sellerId, {
          consumers
        })
      ).data;

      toast(SnackbarVariant.SUCCESS, 'Successfully created consumer');

      newConsumers.forEach(newConsumer =>
        this.props.onAdd(buildTrelloCardFromConsumer(newConsumer, 'ignore'))
      );
      this.props.onConsumerAdded(stepId ?? '');
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to create consumer'
        );

        return;
      }

      ValidationException.throwIfNotOneOfUs<
        keyof API.Consumers.AddConsumerInBulk.Request
      >(error);

      this.setState({
        csvConsumersError: 'One or more consumers is invalid'
      });
    }
  }

  async tryCreateNewConsumer() {
    // lane ids starting with `$` are invalid, and should be ignored ;)
    const stepId = this.props.laneId.startsWith('$')
      ? undefined
      : this.props.laneId;
    const personaId = stepId ? this.props.currentPersona!.id : undefined;

    try {
      const newConsumer = (
        await RelmApi.createConsumer(this.props.currentPersona!.sellerId, {
          ...this.state.inputValues,
          stepId,
          personaId
        })
      ).data;

      toast(SnackbarVariant.SUCCESS, 'Successfully created consumer');

      this.props.onAdd(buildTrelloCardFromConsumer(newConsumer, 'ignore'));
      this.props.onConsumerAdded(stepId ?? '');
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to create consumer'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);
    }
  }

  // region autobound methods
  @autobind
  handleAddConsumerButtonClick() {
    load(
      this.state.addMode === ConsumerAddMode.SINGLE
        ? this.tryCreateNewConsumer()
        : this.tryCreateNewConsumers()
    );
  }

  @autobind
  handleCloseClick() {
    this.props.onCancel();
  }

  @autobind
  handleTabChange(event: any, value: ConsumerAddMode) {
    this.setState({ addMode: value });
  }

  /**
   * Handles when the value of an `input` element changes.
   *
   * @param {React.ChangeEvent} event
   */
  @autobind
  handleCsvValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ csvConsumersValue: event.target.value });
  }

  // endregion
  // region render & get-render-content methods
  getAddSingleConsumerTabContent() {
    return (
      <>
        {this._validator.buildTextFieldForProperty('firstName', 'First Name', {
          required: true,
          autoFocus: true
        })}
        {this._validator.buildTextFieldForProperty('lastName', 'Last Name', {
          required: true
        })}
        {this._validator.buildTextFieldForProperty('email', 'Email', {
          required: true
        })}
        {this._validator.buildTextFieldForProperty(
          'organisation',
          'Organisation'
        )}
      </>
    );
  }

  getAddBulkConsumerTabContent() {
    return (
      <>
        <TextField
          name="consumers-csv"
          label="CSV: firstName,lastName,email,organisation"
          value={this.state.csvConsumersValue}
          helperText={this.state.csvConsumersError}
          error={!!this.state.csvConsumersError}
          margin="dense"
          fullWidth
          required
          multiline
          rows={13}
          onChange={this.handleCsvValueChange}
        />
      </>
    );
  }

  render() {
    const steps = [this.props.defaultStep, ...this.props.currentPersona!.steps];

    return (
      <FormDialog classes={{ paper: this.props.classes.paper }} open>
        <CardHeader
          className={this.props.classes.cardHeader}
          avatar={<PersonOutline />}
          action={
            <IconButton onClick={this.handleCloseClick}>
              <Close />
            </IconButton>
          }
          titleTypographyProps={{
            className: this.props.classes.typographyTitle
          }}
          title="Add"
        />
        <CardContent className={this.props.classes.cardContent}>
          <Typography component="div">
            <Tabs value={this.state.addMode} onChange={this.handleTabChange}>
              <Tab label="Single" value={ConsumerAddMode.SINGLE} />
              <Tab label="Multiple" value={ConsumerAddMode.BULK} />
            </Tabs>
          </Typography>
          {this.state.addMode === ConsumerAddMode.SINGLE &&
            this.getAddSingleConsumerTabContent()}
          {this.state.addMode === ConsumerAddMode.BULK &&
            this.getAddBulkConsumerTabContent()}
          {this._validator.buildTextFieldForProperty('stepId', 'Step', {
            disabled: true, // for now, as `onAdd` can't change lanes
            required: true,
            select: true,
            children: steps.map(step => (
              <MenuItem key={step.id} value={step.id}>
                In "{step.name}"
              </MenuItem>
            ))
          })}
        </CardContent>
        <CardActions className={this.props.classes.cardActions}>
          <FormButton
            variant="outlined"
            fullWidth
            onClick={this.handleAddConsumerButtonClick}
          >
            Add Consumer
            {this.state.addMode === ConsumerAddMode.BULK ? 's' : ''}
          </FormButton>
        </CardActions>
      </FormDialog>
    );
  }

  // endregion
}

export default withStyles(styles)(AddConsumerCardTemplate);
