import {
  Grid,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import { DraftHandleValue, RichUtils } from 'draft-js';
import * as Draft from 'draft-js';
import createLinkPlugin from 'draft-js-anchor-plugin';
import {
  BoldButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton
} from 'draft-js-buttons';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import {
  Options as DraftJsExportHtmlOptions,
  stateToHTML
} from 'draft-js-export-html';
import createFocusPlugin from 'draft-js-focus-plugin';
import 'draft-js-focus-plugin/lib/plugin.css';
import createImagePlugin from 'draft-js-image-plugin';
import 'draft-js-image-plugin/lib/plugin.css';
import {
  Options as DraftJsImportHtmlOptions,
  stateFromHTML
} from 'draft-js-import-html';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import createVideoPlugin from 'draft-js-video-plugin';
import 'draft-js/dist/Draft.css';
import {
  CopySource,
  handleDraftEditorPastedText,
  registerCopySource
} from 'draftjs-conductor';
import jss from 'jss';
import * as React from 'react';
import AddImageButton from 'src/components/editors/AddImageButton';
import AddLinkButton from 'src/components/editors/AddLinkButton';
import AddVideoButton from 'src/components/editors/AddVideoButton';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import { API } from 'src/definitions';
import createAlignmentToolbarPlugin, {
  Alignment
} from 'src/draft-plugins/draft-js-alignment-plugin';
import createSideToolbarPlugin from 'src/draft-plugins/draft-js-side-toolbar-plugin';
import regexps from 'src/schemas/regexps';
import RelmApi from 'src/services/RelmApi';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';

const storyEditorImageCssClass = 'story-editor-image';
const draftEditorContainerClass = 'DraftEditor-editorContainer';
const draftEditorContentClass = 'public-DraftEditor-content';
const dataAlignmentAttrName = 'data-alignment';

const {
  types: { VIDEOTYPE }
} = createVideoPlugin();
const IMAGETYPE: Draft.DraftEntityType = 'IMAGE';

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

const BREAK = '<br />';
const SPECIAL = '\xA0';
const lgBreakpoint = 736;
const lgMainWidth = 700;
const lgMainWidthExtend = 1000;

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: '25vh',
      borderBottom: `1px solid rgba(${
        theme.palette.type === 'light' ? '0, 0, 0, 0.42' : '255, 255, 255, 0.7'
      })`,
      cursor: 'text',
      [`& .${draftEditorContainerClass}`]: {
        display: 'block'
      },
      [`& .${draftEditorContentClass} > div`]: {
        '& > *:first-child': {
          marginTop: '1em'
        },
        '& > *:last-child': {
          marginBottom: '1.5em'
        },
        '& > div, & > h1, & > h2, & > h3, & > h4, & > h5, & > h6, & > ul, & > ol': {
          width: '100%',
          maxWidth: `${lgMainWidth}px`,
          lineHeight: '28.8px',
          fontSize: '19.2px',
          marginTop: '19.2px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '28.8px',
          letterSpacing: 0
        },
        '& > h1': {
          fontSize: '34.56px',
          lineHeight: '34.56px',
          marginTop: '23.04px'
        },
        '& > h2': {
          fontSize: '24.96px',
          lineHeight: '24.96px',
          marginTop: '61.44px'
        },
        '& > h3': {
          fontSize: '22.2px',
          marginTop: '64.32px',
          lineHeight: '1em'
        },
        '& > h4': {
          fontSize: '19.2px',
          marginTop: '67.2px',
          lineHeight: '1em'
        },
        '& > h5': {
          fontSize: '16.8px',
          marginTop: '69.6px',
          lineHeight: '1em'
        },
        '& > h6': {
          fontSize: '14.4px',
          marginTop: '72px',
          lineHeight: '1em'
        },
        '& > ul, & > ol': {
          'marginTop': '19.2px',
          'marginBottom': '19.2px',
          'paddingLeft': '57.6px',
          '& li': {
            'marginTop': '9.6px',
            'marginLeft': 0,
            'lineHeight': '28.8px',
            '&:first-of-type': {
              marginTop: 0
            }
          }
        }
      },
      [`& figure`]: {
        width: 'auto',
        maxWidth: `${lgMainWidthExtend}px`,
        height: 'auto',
        position: 'relative',
        display: 'block',
        marginTop: '7.2px',
        marginBottom: '28.8px',
        marginLeft: `-${theme.spacing(2) + 3}px`,
        marginRight: `-${theme.spacing(2) + 3}px`,
        objectFit: 'cover',
        [`& .align-${Alignment.LEFT}`]: {
          float: 'left'
        },
        [`& .align-${Alignment.RIGHT}`]: {
          float: 'right'
        },
        [`& .align-${Alignment.LEFT}, & .align-${Alignment.RIGHT}`]: {
          display: 'block',
          position: 'relative',
          zIndex: 1,
          maxWidth: '350px',
          marginTop: '7.2px',
          marginBottom: '28.8px',
          marginLeft: '38.4px',
          marginRight: '38.4px',
          [theme.breakpoints.down(lgBreakpoint)]: {
            clear: 'both',
            float: 'none',
            width: 'auto',
            maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        },
        [`& .align-${Alignment.CENTER}`]: {
          [`&.${storyEditorImageCssClass}`]: {
            display: 'block',
            position: 'relative',
            width: 'auto',
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto'
          },
          [theme.breakpoints.down(lgBreakpoint)]: {
            width: '100%',
            maxWidth: '100%',
            marginLeft: 0,
            marginRight: 0
          }
        }
      },
      [`& .${VIDEOTYPE}`]: {
        '& iframe': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none'
        },
        [`& .align-${Alignment.LEFT}, & .align-${Alignment.RIGHT}`]: {
          height: '196.4px',
          width: '100%',
          marginTop: '7.2px',
          marginBottom: '28.8px',
          marginLeft: '38.4px',
          marginRight: '38.4px',
          [theme.breakpoints.down(lgBreakpoint)]: {
            paddingTop: `calc(56.25% - ${theme.spacing(4)}px)`,
            height: 0
          }
        },
        [`& .align-${Alignment.CENTER}`]: {
          paddingTop: '56.25%',
          width: '100%',
          height: '100%'
        }
      }
    },
    atomic: {
      clear: 'both'
    }
  });

// endregion
// region component props
interface ExternalProps {
  className?: string;
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  scrollOffsetRef?: React.RefObject<HTMLElement>;
  readOnly?: boolean;

  editorState: Draft.EditorState;
  onChange: Draft.EditorProps['onChange'];
  onCtrlEnter: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentPersonaValue &
  WithDataFromApiProps<
    'sellerStyles',
    API.Sellers.ShowSingleSellerStyles.Response
  > &
  WithStyles<typeof styles>;

interface State {}

// endregion

/**
 * Custom function for handling inline `HTML` elements when importing `HTML` into `Draft`.
 *
 * @param {Element} element
 * @param {(type: string, data?: Object) => Draft.Model.Entity.DraftEntityInstance} Entity
 *
 * @return {undefined | null | Style | Draft.Model.Entity.DraftEntityInstance}
 */
export const customInlineFn: DraftJsImportHtmlOptions['customInlineFn'] = (
  element,
  { Entity }
) => {
  if (element.tagName === 'IFRAME') {
    return Entity(VIDEOTYPE, {
      src: element.getAttribute('src'),
      alignment: element.getAttribute(dataAlignmentAttrName)
    });
  }

  if (element.tagName === 'IMG') {
    return Entity(IMAGETYPE, {
      src: element.getAttribute('src'),
      alignment: element.getAttribute(dataAlignmentAttrName)
    });
  }

  return null;
};

/**
 * Builds a youtube embed link for an `iframe`.
 *
 * @param {string} src
 *
 * @return {string}
 */
const buildYoutubeEmbedLink = (src: string): string => {
  const results = src.match(regexps.youtubeLink);

  if (!results) {
    throw new Error('not a youtube link!');
  }

  return `${YOUTUBE_PREFIX}${results[1]}`;
};

/**
 * Builds a vimeo embed link for an `iframe`.
 *
 * @param {string} src
 *
 * @return {string}
 */
const buildVimeoEmbedLink = (src: string): string => {
  const results = src.match(regexps.vimeoLink);

  if (!results) {
    throw new Error('not a vimeo link!');
  }

  return `${VIMEO_PREFIX}${results[1]}`;
};

/**
 * Builds a video embed link for an `iframe`.
 *
 * @param {string} src
 *
 * @return {string}
 */
const buildVideoEmbedLink = (src: string): string => {
  const results = src.match(regexps.videoProvider);

  if (!results) {
    throw new Error('not a video link!');
  }
  switch (results[1]) {
    case 'youtube':
    case 'youtu.be':
      return buildYoutubeEmbedLink(src);
    case 'vimeo':
      return buildVimeoEmbedLink(src);
    default:
      return '';
  }
};

/**
 * Creates a `blockRenderers` function for the given `contentState`.
 *
 * @param {Draft.Model.ImmutableData.ContentState} contentState
 *
 * @return {DraftJsExportHtmlOptions["blockRenderers"]}
 */
export const createBlockRenderers = (
  contentState: Draft.ContentState
): DraftJsExportHtmlOptions['blockRenderers'] => ({
  atomic: (block: Draft.ContentBlock) => {
    if (block.getText() === '') {
      return BREAK;
    } // prevent element collapse if completely empty

    return block
      .getCharacterList()
      .map(value => {
        if (value === undefined) {
          return;
        }

        const entity = contentState.getEntity(value.getEntity());

        const { src, alignment = Alignment.CENTER } = entity.getData();

        if (entity.getType() === VIDEOTYPE) {
          return `<iframe src="${buildVideoEmbedLink(
            src
          )}" ${dataAlignmentAttrName}="${alignment}" allowFullScreen="">${SPECIAL}</iframe>`;
        }

        if (entity.getType() === IMAGETYPE) {
          return `<img src="${src}" ${dataAlignmentAttrName}="${alignment}" alt=""/>`;
        }

        return undefined;
      })
      .map(contents => `<figure>${contents}</figure>`)
      .join('');
  }
});

export const contentStateFromHtml = (html: string): Draft.ContentState =>
  stateFromHTML(html, { customInlineFn });
export const contentStateToHtml = (contentState: Draft.ContentState): string =>
  stateToHTML(contentState, {
    blockRenderers: createBlockRenderers(contentState)
  });

/**
 *
 */
@withCurrentPersona<Props>()
@withDataFromApi<Props>(
  props => RelmApi.getSellerStyles(props.currentPersona.sellerId),
  'sellerStyles'
)
class StoryEditor extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentPersona: undefined,
    onCurrentPersonaChange: undefined,
    sellerStyles: undefined,
    landingPageStyles: undefined,

    onCurrentSellerChange: () => {},
    loadFromApi: undefined,

    readOnly: false,

    className: ''
  };

  /**
   *
   * @type {React.RefObject<Draft.Component.Base.DraftEditor>}
   * @private
   */
  private readonly _editorRef: React.RefObject<Editor> = React.createRef<
    Editor
  >();

  private _copySource?: CopySource;

  private readonly _focusPlugin = createFocusPlugin();
  private readonly _blockDndPlugin = createBlockDndPlugin();
  private readonly _alignmentPlugin = createAlignmentToolbarPlugin();

  private readonly _sideToolbarPlugin = createSideToolbarPlugin({
    position: 'left'
  });

  private readonly _blockSellerStyles = jss
    .createStyleSheet(
      {
        header: {
          'font-family': this.props.sellerStyles.fontTitle as string
        },
        paragraph: {
          'font-family': this.props.sellerStyles.fontContent as string
        },
        textColor: {
          color: this.props.sellerStyles.colorContentPrimary as string
        },
        linkColor: {
          'text-decoration': 'none',
          'color': this.props.sellerStyles.colorContentTonic as string
        }
      },
      { meta: 'StoryEditorSellerStyle' }
    )
    .attach();

  private readonly _imagePlugin = createImagePlugin({
    theme: { image: storyEditorImageCssClass },
    decorator: composeDecorators(
      this._focusPlugin.decorator,
      this._blockDndPlugin.decorator,
      this._alignmentPlugin.decorator
    )
  });

  private readonly _videoPlugin = createVideoPlugin({
    decorator: composeDecorators(
      this._focusPlugin.decorator,
      this._blockDndPlugin.decorator,
      this._alignmentPlugin.decorator
    )
  });

  private readonly _linkPlugin = createLinkPlugin({
    theme: { link: this._blockSellerStyles.classes.linkColor }
  });

  private readonly _draftPlugins = [
    this._focusPlugin,
    this._blockDndPlugin,
    this._alignmentPlugin,
    this._sideToolbarPlugin,
    this._imagePlugin,
    this._videoPlugin,
    this._linkPlugin
  ];

  readonly state: State = {
    editorState: Draft.EditorState.createEmpty()
  };

  componentDidMount() {
    if (this._editorRef.current) {
      this._copySource = registerCopySource(
        this._editorRef.current.getEditorRef()
      );
    }
  }

  componentWillUnmount() {
    if (this._copySource) {
      this._copySource.unregister();
    }
  }

  // region autobound methods
  /**
   * Handles when the `root` element of this component is clicked.
   */
  @autobind
  handleRootClick() {
    this._editorRef.current && this._editorRef.current.focus();
  }

  @autobind
  styleEditorBlocks(block: Draft.ContentBlock): string {
    const blockType: string = block.getType();

    switch (blockType) {
      case 'atomic':
        return cx(
          this.props.classes.atomic,
          block
            .getCharacterList()
            .map(
              value =>
                value &&
                this.props.editorState
                  .getCurrentContent()
                  .getEntity(value.getEntity())
                  .getType()
            )
            .toArray()
        );
      case 'header-one':
      case 'header-two':
      case 'header-three':
      case 'header-four':
      case 'header-five':
      case 'header-six':
        return cx(
          this._blockSellerStyles.classes.header,
          this._blockSellerStyles.classes.textColor
        );
      case 'unstyled':
      case 'paragraph':
      case 'unordered-list-item':
      case 'ordered-list-item':
      case 'blockquote':
      case 'code-block':
        return cx(
          this._blockSellerStyles.classes.paragraph,
          this._blockSellerStyles.classes.textColor
        );
      default:
        return '';
    }
  }

  @autobind
  handlePastedText(
    text: string,
    html?: string,
    editorState?: Draft.EditorState
  ) {
    const newState = handleDraftEditorPastedText(html, editorState);

    if (newState) {
      this.props.onChange(newState);

      return 'handled';
    }

    return 'not-handled';
  }

  @autobind
  handleReturn(event: React.KeyboardEvent): DraftHandleValue {
    if (event.shiftKey) {
      this.props.onChange(RichUtils.insertSoftNewline(this.props.editorState));

      return 'handled';
    }

    if (event.ctrlKey) {
      this.props.onCtrlEnter();

      return 'handled';
    }

    return 'not-handled';
  }

  // endregion
  // region render & get-render-content methods
  render() {
    if (!this.props.currentPersona) {
      throw new Error('currentPersona not loaded');
    } // shouldn't happen, but TypeScript doesn't realise that

    const { SideToolbar } = this._sideToolbarPlugin;
    const { AlignmentTool } = this._alignmentPlugin;

    return (
      <>
        <Typography
          className={cx(this.props.classes.root, this.props.className)}
          component="div"
          onClick={this.handleRootClick}
        >
          <Editor
            ref={this._editorRef}
            editorState={this.props.editorState}
            plugins={this._draftPlugins}
            spellCheck
            readOnly={this.props.readOnly}
            blockStyleFn={this.styleEditorBlocks}
            handlePastedText={this.handlePastedText}
            onChange={this.props.onChange}
            handleReturn={this.handleReturn}
          />
        </Typography>
        {!this.props.readOnly && <AlignmentTool />}
        {!this.props.readOnly && (
          <SideToolbar scrollOffsetRef={this.props.scrollOffsetRef}>
            {externalProps => (
              <Grid container>
                <Grid item md={6}>
                  <ItalicButton {...externalProps} />
                  <BoldButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <AddVideoButton
                    addVideo={this._videoPlugin.addVideo}
                    editorState={this.props.editorState}
                    onChange={this.props.onChange}
                    {...externalProps}
                  />
                  <AddLinkButton
                    editorState={this.props.editorState}
                    onChange={this.props.onChange}
                    {...externalProps}
                  />
                </Grid>
                <Grid item md={6}>
                  <HeadlineOneButton {...externalProps} />
                  <HeadlineTwoButton {...externalProps} />
                  <HeadlineThreeButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <AddImageButton
                    addImage={this._imagePlugin.addImage}
                    editorState={this.props.editorState}
                    onChange={this.props.onChange}
                    {...externalProps}
                  />
                </Grid>
              </Grid>
            )}
          </SideToolbar>
        )}
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(StoryEditor);
