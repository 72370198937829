const email = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
const name = /([^\s])/;
const url = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;
const number = /^[0-9]+$/;
const empty = /^$/;
const videoProvider = /(youtu[.]*be|vimeo)/;
const videoLink = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(?:https?:\/\/)?(?:www\.)?(?:player\.vimeo\.com\/video|vimeo\.com)\/(\d+)(?:|\/\?)$/;
const youtubeLink = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const vimeoLink = /^(?:https?:\/\/)?(?:www\.)?(?:player\.vimeo\.com\/video|vimeo\.com)\/(\d+)(?:|\/\?)$/;

const makeOrEmpty = (regexp: RegExp) =>
  new RegExp(`${regexp.source}|${empty.source}`);

const regexps = {
  name,
  nameOrEmpty: makeOrEmpty(name),
  email,
  emailOrEmpty: makeOrEmpty(email),
  url,
  urlOrEmpty: makeOrEmpty(url),
  number,
  numberOrEmpty: makeOrEmpty(number),
  videoProvider,
  videoProviderOrEmpty: makeOrEmpty(videoProvider),
  videoLink,
  videoLinkOrEmpty: makeOrEmpty(videoLink),
  youtubeLink,
  youtubeLinkOrEmpty: makeOrEmpty(youtubeLink),
  vimeoLink,
  vimeoLinkOrEmpty: makeOrEmpty(vimeoLink)
};

export default regexps;
