import {
  AppBar,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Typography,
  WithStyles,
  Zoom,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  ArrowRight,
  BarChart,
  Close,
  Delete,
  Edit,
  Email,
  Lock,
  OpenInNew,
  PersonOutline,
  Stars
} from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { AreYouSureDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import ConsumerAchievementsPanel from 'src/components/ConsumerAchievementsPanel';
import ConsumerEngagementsAccordion from 'src/components/ConsumerEngagementsAccordion';
import ConsumerStatisticsPanel from 'src/components/ConsumerStatisticsPanel';
import EditConsumerDialog from 'src/components/EditConsumerDialog';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import ValidationException from 'src/exceptions/ValidationException';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import { WithDataFromApiProps } from 'src/services/withDataFromApi';
import { CopyToClipboardIcon } from 'src/theme/icons';
import {
  buildRelmHostingUrlForSeller,
  getDefaultUrlForPersona,
  getFullName
} from 'src/utilities';
import PrivateStoriesBoard from 'src/views/Persona/PrivateStoriesBoard';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing()}px`
    },
    dialog: {
      width: '100%',
      height: '100%',
      overflowY: 'unset',
      [theme.breakpoints.up('lg')]: {
        maxWidth: '100%'
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: 0,
        margin: 0
      }
    },
    dialogContent: {
      paddingTop: 0,
      paddingBottom: 0
    },
    dialogActions: {
      paddingLeft: `${theme.spacing()}px`
    },
    personaSentence: {
      'paddingTop': `${theme.spacing()}px`,
      'paddingBottom': `${theme.spacing(2)}px`,
      'color': theme.palette.text.secondary,
      '& b': {
        color: theme.palette.text.primary
      }
    },
    moveButtonContainer: {
      paddingBottom: `${theme.spacing(2)}px`
    },
    linkable: {
      'textDecoration': 'underline',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none'
      }
    },
    tabHeader: {
      '& > header': {
        position: 'relative',
        zIndex: 'auto'
      }
    },
    tabHeaderLabel: {
      [theme.breakpoints.down('xs')]: {
        'minHeight': '48px',
        '& > span > span': {
          display: 'none'
        }
      }
    },
    tabContent: {
      width: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      minHeight: '230px',
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`
    },
    iconActions: {
      margin: `${theme.spacing()}px`
    },
    gridButtonsColumn: {
      textAlign: 'right'
    },
    buttonProfileAction: {
      marginBottom: `${theme.spacing(0.5)}px`,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start'
      }
    },
    buttonProfileActionLabel: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    iconProfileAction: {
      [theme.breakpoints.up('md')]: {
        paddingRight: `${theme.spacing(0.5)}px`
      }
    },
    buttonProfileActionProcessingLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    buttonProfileActionSecondary: {
      marginLeft: `${theme.spacing()}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`
    },
    iconProfileActionSecondary: {
      paddingRight: `${theme.spacing(0.5)}px`,
      [theme.breakpoints.up('sm')]: {
        paddingRight: 0
      }
    },
    dialogActionsUpdate: {
      marginRight: `${theme.spacing()}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`
    },
    dialogActionsProcess: {
      marginRight: `${theme.spacing()}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`,
      minWidth: 'auto'
    },
    dialogActionsSecondary: {
      marginLeft: `${theme.spacing()}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  open: boolean;
  consumer: API.Entities.Consumer;

  onCallToClose: () => void;
  onConsumerUpdated: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  CurrentPersonaValue &
  WithDataFromApiProps<
    'currentPersona',
    CurrentPersonaValue['currentPersona']
  > &
  WithStyles<typeof styles> &
  RouteComponentProps<{ personaId: string }>;

interface State {
  isArchiveConsumerDialogOpen: boolean;
  isUpdateModalOpen: boolean;
  activeTab: TabKey;
}

// endregion

enum TabKey {
  DEFAULT = 'outreach',
  OUTREACH = 'outreach',
  PRIVATE_POSTS = 'privatePosts',
  ACHIEVEMENTS = 'achievements',
  STATISTICS = 'statistics'
}

const storageKey = 'RelmCurrentConsumerProfileTab';

/**
 *
 */
@withCurrentSeller<Props>()
@withCurrentPersona<Props>(true)
class ConsumerProfileDialog extends React.Component<Props, State> {
  // region Tab management
  loadActiveTab(): TabKey {
    const savedActiveTabName = localStorage.getItem(storageKey);

    if (
      savedActiveTabName === TabKey.PRIVATE_POSTS &&
      this.props.consumer.personaId === null
    ) {
      return TabKey.DEFAULT;
    }

    return savedActiveTabName &&
      ConsumerProfileDialog.isValidTabName(savedActiveTabName)
      ? savedActiveTabName
      : TabKey.DEFAULT;
  }

  static isValidTabName = (tabKey: TabKey | string): tabKey is TabKey =>
    // @ts-expect-error
    Object.values(TabKey).includes(tabKey);

  static saveActiveTab = (tabKey: TabKey): TabKey => {
    const savedActiveTabName = ConsumerProfileDialog.isValidTabName(tabKey)
      ? tabKey
      : TabKey.DEFAULT;

    localStorage.setItem(storageKey, savedActiveTabName);

    return savedActiveTabName;
  };

  // endregion
  static readonly defaultProps = {
    currentSeller: undefined,
    onCurrentSellerChange: undefined,
    currentPersona: undefined,
    onCurrentPersonaChange: () => {},
    loadFromApi: () => Promise.resolve() as any
  };

  readonly state: State = {
    isArchiveConsumerDialogOpen: false,
    isUpdateModalOpen: false,
    activeTab: this.loadActiveTab()
  };

  /**
   * Tries to archive the `Consumer` with the given id.
   *
   * @param {string} consumerId
   */
  async tryArchiveConsumer(consumerId: string) {
    if (this.props.currentSeller === null) {
      throw new Error('no current seller');
    } // this'll never be null, but typescript don't know that

    try {
      await RelmApi.archiveConsumer(this.props.currentSeller.id, consumerId);
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong while trying to delete this consumer'
      );

      return;
    }

    this.props.onCallToClose();
    await this.props.onConsumerUpdated();

    toast(SnackbarVariant.SUCCESS, 'Successfully deleted consumer');
  }

  /**
   * Tries to move the `Consumer` to the step with the given `id`.
   *
   * @param {API.Nullable<string>} stepId
   *
   * @return {Promise<void>}
   */
  async tryMoveConsumer(stepId: API.Nullable<string>): Promise<void> {
    if (this.props.currentPersona === null) {
      throw new Error('no current persona');
    } // this'll never be null, but typescript don't know that

    try {
      await RelmApi.updateConsumer(
        this.props.currentPersona.sellerId,
        this.props.consumer.id,
        {
          stepId,
          personaId: this.props.currentPersona.id
        }
      );
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong while trying to move this consumer'
      );

      return;
    }

    await this.props.onConsumerUpdated();

    toast(SnackbarVariant.SUCCESS, 'Successfully moved consumer');
  }

  /**
   * Gets the id of the `Step` that comes after the `Step` with the given `id`.
   *
   * @param {string} stepId
   *
   * @return {string}
   */
  getNextStepInPersona(stepId: API.Nullable<string>): API.Nullable<string> {
    if (this.props.currentPersona === null) {
      throw new Error('missing current persona');
    }
    const steps = [...this.props.currentPersona.steps].sort(
      (a, b) => a.position - b.position
    );

    const currentStepIndex = steps.findIndex(step => step.id === stepId);

    const step = steps[Math.min(currentStepIndex + 1, steps.length - 1)];

    return step ? step.id : stepId;
  }

  findConsumerStepName() {
    const { currentPersona, consumer } = this.props;

    if (currentPersona === null) {
      throw new Error('missing current persona');
    }

    if (consumer.stepId === null) {
      return 'Available Consumers';
    }

    const consumerStep = currentPersona.steps.find(
      step => step.id === consumer.stepId
    );

    if (consumerStep === undefined) {
      throw new Error(`unable to find Step with slug of ${consumer.stepId}`);
    }

    return consumerStep.name;
  }

  buildRelmLinkForConsumer(consumer: API.Entities.Consumer): string {
    if (!this.props.currentSeller) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    const hostingUrl = buildRelmHostingUrlForSeller(this.props.currentSeller);

    return new URL(
      `/rk/${consumer.relmKey}.html?hidden`,
      hostingUrl
    ).toString();
  }

  // region autobound methods

  /**
   * Handles when the `'Edit Profile'` action button is clicked.
   */
  @autobind
  handleEditProfileButtonClick() {
    this.setState({ isUpdateModalOpen: true });
  }

  /**
   * Handles when the `EditConsumerModal` is called to close.
   *
   * @param {boolean} wasUpdated represents if the `Consumer` was updated or not.
   */
  @autobind
  handleEditConsumerModalClose(wasUpdated: boolean) {
    this.setState({ isUpdateModalOpen: false });

    if (wasUpdated) {
      this.props.onConsumerUpdated();
    }
  }

  /**
   * Handles when the `'Archive'` action button is clicked.
   */
  @autobind
  handleArchiveConsumerButtonClick() {
    this.setState({ isArchiveConsumerDialogOpen: true });
  }

  /**
   * Handles when the `'Move'` action button is clicked.
   */
  @autobind
  handleMoveConsumerButtonClick() {
    if (this.props.currentPersona === null) {
      throw new Error('no current persona');
    } // this'll never be null, but typescript don't know that

    const nextStepId = this.getNextStepInPersona(this.props.consumer.stepId);

    if (this.props.consumer.stepId === nextStepId) {
      return;
    } // don't do anything if the steps are the same

    load(this.tryMoveConsumer(nextStepId));
  }

  /**
   * Handles when the `'Cancel'` button on the `AreYouSureDialog` for archiving a consumer is clicked.
   */
  @autobind
  handleArchiveConsumerCancel() {
    this.setState({ isArchiveConsumerDialogOpen: false });
  }

  /**
   * Handles when the `'Action'` button on the `AreYouSureDialog` for archiving a consumer is clicked.
   */
  @autobind
  handleArchiveConsumerAction() {
    load(this.tryArchiveConsumer(this.props.consumer.id));
  }

  /**
   * Engage the current Consumer
   */
  @autobind
  handleEngagementClick() {
    if (this.props.consumer.hasOutstandingEngagement) {
      toast(
        SnackbarVariant.WARNING,
        `This consumers already have pending engagement`
      );
    }
  }

  @autobind
  handleGoToPersonaClick() {
    if (this.props.consumer.personaId === null) {
      throw new Error('no persona for this consumer');
    } // don't do anything if the steps are the same

    const personaUrl = getDefaultUrlForPersona(this.props.consumer.personaId);

    if (this.props.location.toString() !== personaUrl) {
      this.props.onCurrentSellerChange();
      this.props.history.push(personaUrl);
    }
  }

  @autobind
  handleCopyRelmLinkButtonClick() {
    if (!this.props.currentSeller) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    const hostingUrl = buildRelmHostingUrlForSeller(this.props.currentSeller);

    const url = new URL(
      `/rk/${this.props.consumer.relmKey}.html`,
      hostingUrl
    ).toString();

    copy(url);

    toast(SnackbarVariant.INFO, 'Link copied to clipboard');
  }

  /**
   * Handles when a `'Tabs'` is clicked.
   */
  @autobind
  handleChangeTab(event: React.SyntheticEvent<unknown>, value: TabKey) {
    this.setState({
      activeTab: ConsumerProfileDialog.saveActiveTab(value)
    });
  }

  /**
   * Render the current Tab
   */
  @autobind
  getActiveTabRenderContent() {
    switch (this.state.activeTab) {
      case TabKey.DEFAULT:
      case TabKey.OUTREACH:
        return <ConsumerEngagementsAccordion consumer={this.props.consumer} />;
      case TabKey.PRIVATE_POSTS:
        return <PrivateStoriesBoard consumer={this.props.consumer} />;
      case TabKey.ACHIEVEMENTS:
        return <ConsumerAchievementsPanel consumer={this.props.consumer} />;
      case TabKey.STATISTICS:
        return <ConsumerStatisticsPanel consumer={this.props.consumer} />;
      default:
        return <Typography>Content to update</Typography>;
    }
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const { classes } = this.props;
    const { pathname } = this.props.location;

    return (
      <>
        <Dialog
          open={this.props.open}
          classes={{ paper: classes.dialog }}
          maxWidth={false}
          TransitionComponent={Zoom}
          onClose={this.props.onCallToClose}
        >
          <CardHeader
            avatar={<PersonOutline />}
            title={getFullName(this.props.consumer)}
            subheader={this.props.consumer.organisation}
            titleTypographyProps={{ variant: 'h5' }}
            subheaderTypographyProps={{ variant: 'subtitle2' }}
            action={
              <IconButton onClick={this.props.onCallToClose}>
                <Close />
              </IconButton>
            }
          />
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="stretch"
            >
              <Grid
                item
                xs
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
              >
                <Grid item>
                  <Typography className={classes.personaSentence}>
                    {this.props.consumer.personaId !== null &&
                      this.props.currentPersona !== null && (
                        <>
                          In Persona:{' '}
                          <b
                            onClick={this.handleGoToPersonaClick}
                            className={cx({
                              [classes.linkable]:
                                pathname !==
                                getDefaultUrlForPersona(
                                  this.props.consumer.personaId
                                )
                            })}
                          >
                            {this.props.currentPersona.name}
                          </b>
                          <br />
                          On Step: <b>{this.findConsumerStepName()}</b>
                          <br />
                        </>
                      )}
                    {this.props.consumer.salesforceCampaignName && (
                      <>
                        Salesforce Campaign:{' '}
                        <b>{this.props.consumer.salesforceCampaignName}</b>
                      </>
                    )}
                    {this.props.consumer.personaId === null && (
                      <>Available Consumer</>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs
                container
                direction="row"
                justify="flex-end"
                alignContent="flex-end"
              >
                {this.props.consumer.personaId !== null &&
                  this.props.currentPersona !== null && (
                    <Grid item>
                      <div className={classes.moveButtonContainer}>
                        <Button
                          className={classes.buttonProfileActionSecondary}
                          variant="outlined"
                          size="small"
                          disabled={
                            this.props.consumer.stepId ===
                            this.getNextStepInPersona(
                              this.props.consumer.stepId
                            )
                          }
                          onClick={this.handleMoveConsumerButtonClick}
                        >
                          <span className={classes.buttonProfileActionLabel}>
                            Move forward
                          </span>
                          <ArrowRight
                            className={classes.iconProfileActionSecondary}
                          />
                        </Button>
                      </div>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            <Grid
              className={classes.tabHeader}
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <AppBar elevation={1} color="default">
                <Tabs
                  value={this.state.activeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  onChange={this.handleChangeTab}
                >
                  <Tab
                    className={classes.tabHeaderLabel}
                    label="Outreach"
                    icon={<Email />}
                    value={TabKey.OUTREACH}
                  />
                  <Tab
                    className={classes.tabHeaderLabel}
                    label="Private Stories"
                    icon={<Lock />}
                    value={TabKey.PRIVATE_POSTS}
                    disabled={this.props.consumer.personaId === null}
                  />
                  <Tab
                    className={classes.tabHeaderLabel}
                    label="Achievements"
                    icon={<Stars />}
                    value={TabKey.ACHIEVEMENTS}
                  />
                  <Tab
                    className={classes.tabHeaderLabel}
                    label="Statistics"
                    icon={<BarChart />}
                    value={TabKey.STATISTICS}
                  />
                </Tabs>
              </AppBar>
            </Grid>
            <Grid
              className={classes.tabContent}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <div className={classes.tabContent}>
                {this.getActiveTabRenderContent()}
              </div>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  className={classes.dialogActionsUpdate}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={this.handleArchiveConsumerButtonClick}
                >
                  <Delete className={classes.iconProfileAction} />
                  <span className={classes.buttonProfileActionLabel}>
                    Delete
                  </span>
                </Button>
                <Button
                  className={classes.dialogActionsUpdate}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={this.handleEditProfileButtonClick}
                >
                  <Edit className={classes.iconProfileAction} />
                  <span className={classes.buttonProfileActionLabel}>Edit</span>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.dialogActionsProcess}
                  variant="outlined"
                  color="primary"
                  aria-label="Copy Relm Link"
                  disabled={
                    this.props.consumer.stepId === null &&
                    this.props.consumer.personaId === null
                  }
                  onClick={this.handleCopyRelmLinkButtonClick}
                >
                  <CopyToClipboardIcon className={classes.iconProfileAction} />
                  <span className={classes.buttonProfileActionProcessingLabel}>
                    Copy Relm Link
                  </span>
                </Button>
                <Button
                  className={classes.dialogActionsProcess}
                  variant="outlined"
                  color="primary"
                  aria-label="Open landing page"
                  disabled={
                    this.props.consumer.stepId === null &&
                    this.props.consumer.personaId === null
                  }
                  href={this.buildRelmLinkForConsumer(this.props.consumer)}
                  target="_blank"
                >
                  <OpenInNew className={classes.iconProfileAction} />
                  <span className={classes.buttonProfileActionProcessingLabel}>
                    Open landing page
                  </span>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <EditConsumerDialog
          key={this.props.consumer.updatedAt ?? this.props.consumer.createdAt}
          consumer={this.props.consumer}
          open={this.state.isUpdateModalOpen}
          onClose={this.handleEditConsumerModalClose}
        />
        <AreYouSureDialog
          open={this.state.isArchiveConsumerDialogOpen}
          actionText="Delete"
          onCancel={this.handleArchiveConsumerCancel}
          onAction={this.handleArchiveConsumerAction}
        >
          Are you sure you want to delete this consumer?
          <br />
          <strong>This action cannot be undone.</strong>
        </AreYouSureDialog>
      </>
    );
  }

  // endregion
}

export default withRouter(withStyles(styles)(ConsumerProfileDialog));
