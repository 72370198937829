import {
  IconButton,
  Snackbar,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { amber, lightBlue, lightGreen } from '@material-ui/core/colors';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';

export enum SnackbarVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

export interface QueuedSnackbar {
  messages: string[];
  key: number;
}

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: lightGreen['600']
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: lightBlue['600']
    },
    warning: {
      backgroundColor: amber['700']
    },
    anchorOriginTopCenter: {
      top: theme.spacing(4)
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  messages: string[];
  variant: SnackbarVariant;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {
  messageInfo: QueuedSnackbar | null;
  open: boolean;
}

// endregion

/**
 *
 */
class AppSnackbar extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   * The messages queue.
   *
   * @type {Array<QueuedSnackbar>}
   * @private
   */
  private readonly _snackbarQueue: QueuedSnackbar[] = [];

  readonly state: State = {
    messageInfo: null,
    open: false
  };

  static getMessagesRenderContent(messages: string[]) {
    if (messages.length === 0) {
      return null;
    }

    if (messages.length > 1) {
      return (
        <ul>
          {messages.map(message => (
            <Typography key={btoa(message)} component="li">
              {message.trim()}
            </Typography>
          ))}
        </ul>
      );
    }

    return <Typography>{messages[0].trim()}</Typography>;
  }

  // region component lifecycle methods
  /**
   * @inheritDoc
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.messages !== prevProps.messages) {
      this.handleMessage(this.props.messages);
    }
  }

  // endregion
  // region autobound methods
  /**
   * Adds messages to snackbar queue, dismisses open snackbar and processes queue.
   *
   * @param {Array<string>} messages
   */
  @autobind
  handleMessage(messages: string[]) {
    if (messages.length === 0) {
      return;
    }

    this._snackbarQueue.push({
      key: new Date().getTime(),
      messages
    });

    this.state.open // immediately dismiss current message & start showing new one
      ? this.setState({ open: false })
      : this.processQueue();
  }

  /**
   * Displays the next queued snackbar.
   */
  @autobind
  processQueue() {
    if (this._snackbarQueue.length) {
      this.setState({
        messageInfo: this._snackbarQueue.shift() ?? null,
        open: true
      });
    }
  }

  /**
   * Requests the snackbar to close.
   *
   * @param {React.SyntheticEvent} event
   * @param {string} reason
   */
  @autobind
  handleClose(event: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  }

  /**
   * Processes queue on snackbar closing.
   */
  @autobind
  handleExited() {
    this.processQueue();
  }

  // endregion
  // region render & get-render-content methods
  render() {
    if (!this.state.messageInfo) {
      return null;
    }

    const formattedMessages = AppSnackbar.getMessagesRenderContent(
      this.state.messageInfo.messages
    );

    return (
      <Snackbar
        key={this.state.messageInfo.key}
        classes={{
          anchorOriginTopCenter: this.props.classes.anchorOriginTopCenter
        }}
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <Close />
          </IconButton>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={5000}
        ContentProps={{
          'aria-describedby': 'message-id',
          'classes': { root: this.props.classes[this.props.variant] }
        }}
        open={this.state.open}
        message={<span id="message-id">{formattedMessages}</span>}
        onClose={this.handleClose}
        onExited={this.handleExited}
      />
    );
  }

  // endregion
}

export default withStyles(styles)(AppSnackbar);
