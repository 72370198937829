import hoistNonReactStatics from 'hoist-non-react-statics';
import * as React from 'react';
import { API } from 'src/definitions';

export interface CurrentSellerValue {
  currentSeller: API.Nullable<API.Entities.CurrentSeller>;
  onCurrentSellerChange: () => void;
}

const CurrentSellerContext = React.createContext<CurrentSellerValue>({
  currentSeller: null,
  onCurrentSellerChange: () => {}
});

/**
 *
 * @param {boolean} [renderIfNull=false]
 */
export const withCurrentSeller = <P extends CurrentSellerValue>(
  renderIfNull = false
) => <C extends React.ComponentClass<P>>(Component: C): C => {
  class WithCurrentSeller extends React.Component<P & { innerRef?: any }> {
    render() {
      const TypedComponent = Component as React.ComponentClass<P>;

      return (
        <CurrentSellerContext.Consumer>
          {(contextValue: CurrentSellerValue) =>
            (contextValue.currentSeller || renderIfNull) && (
              <TypedComponent
                ref={this.props.innerRef}
                {...this.props}
                currentSeller={contextValue.currentSeller}
                onCurrentSellerChange={contextValue.onCurrentSellerChange}
              />
            )
          }
        </CurrentSellerContext.Consumer>
      );
    }
  }

  hoistNonReactStatics(WithCurrentSeller, Component);

  return (WithCurrentSeller as unknown) as C;
};

export default CurrentSellerContext;

// region code archive
/*
    Decorators + React + TypeScript are a touch weird, due to all parties having "issues".

    Below is a collection of code that is "working", just in case things blow up later down the line.

    The worst case is that decorators can be scrapped in favor fo just using HOC.
 */
// region working with statics
// export const withCurrentSeller = <C extends React.ComponentClass<any>>(Component: C): C => {
//     class WithCurrentSeller extends React.Component<{ innerRef?: any }> {
//         static readonly defaultProps = {};
//
//         render() {
//             const { innerRef, ...props } = this.props;
//
//             return (
//                 <CurrentSellerContext.Consumer>
//                     {
//                         // @ts-ignore
//                         (contextValue: CurrentSellerValue) => <Component
//                             ref={innerRef}
//
//                             currentSeller={contextValue.currentSeller}
//                             onCurrentSellerChange={contextValue.onCurrentSellerChange}
//
//                             {...props}
//                         />
//                     }
//                 </CurrentSellerContext.Consumer>
//             );
//         }
//     }
//
//     hoistNonReactStatics(WithCurrentSeller, Component);
//
//     return WithCurrentSeller as C;
// };
// endregion
// region working without statics
// export const withCurrentSeller = <C extends React.ComponentClass<any>>(Component: C): C => {
//     return ((props: CurrentSellerValue) => {
//             const { innerRef, ...adjustedProps } = props as { innerRef?: any };
//
//             return (
//                 <CurrentSellerContext.Consumer>
//                     {
//                         // @ts-ignore
//                         (contextValue: CurrentSellerValue) => <Component
//                             ref={innerRef}
//
//                             currentSeller={contextValue.currentSeller}
//                             onCurrentSellerChange={contextValue.onCurrentSellerChange}
//
//
//                             {...props}
//                         />
//                     }
//                 </CurrentSellerContext.Consumer>
//             );
//         }
//     ) as any as C;
// };
//
// export default CurrentSellerContext;
// endregion
// region original (working as HOC)
// export const withCurrentSeller = <P extends CurrentSellerValue>() => (Component: React.ComponentType<P & React.ClassAttributes<any>>) => {
//     class WithCurrentSeller extends React.Component<P> {
//         static readonly defaultProps = {};
//
//         render() { // generic spread bug: https://github.com/Microsoft/TypeScript/issues/10727
//             const { innerRef, ...props } = this.props as { innerRef?: any };
//
//             return (
//                 <CurrentSellerContext.Consumer>
//                     {
//                         (contextValue: CurrentSellerValue) => <Component
//                             ref={innerRef}
//
//                             currentSeller={contextValue.currentSeller}
//                             onCurrentSellerChange={contextValue.onCurrentSellerChange}
//
//                             {...props}
//                         />
//                     }
//                 </CurrentSellerContext.Consumer>
//             );
//         }
//     }
//
//     hoistNonReactStatics(WithCurrentSeller, Component);
//
//     return WithCurrentSeller;
// };
// endregion
// endregion
