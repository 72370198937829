import {
  Button,
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Delete, OpenInNew, Save, Send } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import * as React from 'react';
import { AreYouSureDialog, ConsumersListCard, Glue } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { ConsumerListItemEvent } from 'src/components/ConsumerListItem';
import OutboxEditor from 'src/components/editors/OutboxEditor';
import {
  contentStateFromHtml,
  contentStateToHtml
} from 'src/components/editors/StoryEditor';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import ValidationException from 'src/exceptions/ValidationException';
import UpdateEngagementRequest from 'src/schemas/UpdateEngagementRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import withUrlParametersAsProps from 'src/services/withUrlParametersAsProps';
import { assertIsDefined, buildRelmHostingUrlForSeller } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.spacing(9)}px)`
    },
    spacing: {
      padding: `${theme.spacing(2)}px`
    },
    buttonDelete: {
      marginTop: `${theme.spacing()}px`
    },
    iconDelete: {
      [theme.breakpoints.up('md')]: {
        paddingRight: `${theme.spacing(0.5)}px`
      }
    },
    iconDeleteLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    displayBlock: {
      display: 'block'
    },
    buttonSave: {
      marginTop: `${theme.spacing()}px`,
      marginRight: `${theme.spacing()}px`
    },
    iconSave: {
      [theme.breakpoints.up('md')]: {
        paddingRight: `${theme.spacing(0.5)}px`
      }
    },
    iconSaveLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    buttonEngage: {
      marginTop: `${theme.spacing()}px`
    },
    iconEngage: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: `${theme.spacing(0.5)}px`
      }
    },
    iconEngageLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    buttonPreview: {
      marginTop: `${theme.spacing()}px`
    },
    iconPreview: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: `${theme.spacing(0.5)}px`
      }
    },
    iconPreviewLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    iframeLandingPagePreview: {
      width: '100%',
      height: '75%'
    },
    editorContainer: {
      flex: 1,
      padding: `${theme.spacing(2)}px`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: `${theme.spacing(0.5)}px`,
      border: `1px solid rgba(${
        theme.palette.type === 'light' ? '0, 0, 0, 0.25' : '255, 255, 255, 0.25'
      })`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
  personaId: string;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  WithDataFromApiProps<
    'engagements',
    API.Sellers.ListEngagementsOfSeller.Response
  > &
  WithDataFromApiProps<'stories', API.Sellers.ListStoriesOfSeller.Response> &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<API.Engagements.UpdateEngagement.Request> {
  selectedEngagement: API.Nullable<API.Entities.EngagementWithConsumer>;
  editorState: Draft.EditorState;
  actionToConfirm: 'clear' | 'send' | null;
}

// endregion

@withUrlParametersAsProps<Props>()
@withCurrentSeller<Props>()
@withDataFromApi<Props>(
  props => RelmApi.listSellerStories(props.currentSeller.id),
  'stories'
)
@withDataFromApi<Props>(
  props =>
    RelmApi.listSellerEngagements(props.currentSeller.id) // only show engagements that are not deleted
      .then(({ data }) => ({
        data: data.filter(engagement => engagement.deletedAt === null)
      })),
  'engagements'
)
class Outbox extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _validator = new ValidationTrait(
    this,
    UpdateEngagementRequest
  );

  readonly state: State = {
    selectedEngagement: null,
    editorState: Draft.EditorState.createEmpty(),
    inputValues: {
      title: '',
      body: ''
    },
    // eslint-disable-next-line react/no-unused-state
    inputErrors: {
      title: '',
      body: ''
    },
    actionToConfirm: null
  };

  /**
   * Tries to delete the currently selected `Engagement`.
   *
   * @return {Promise<void>}
   */
  async tryDeleteSelectedEngagement(): Promise<void> {
    if (!this.state.selectedEngagement) {
      return;
    } // don't do anything

    try {
      await RelmApi.deleteEngagement(
        this.state.selectedEngagement.personaId,
        this.state.selectedEngagement.id
      );
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to delete this engagement'
      );

      return;
    }

    this.setState({
      selectedEngagement: null,
      editorState: Draft.EditorState.createEmpty(),
      inputValues: {}
    });
    this.props.loadFromApi();
  }

  /**
   * Tries to update the currently selected `Engagement`.
   *
   * @return {Promise<API.Nullable<API.Entities.Engagement>>}
   */
  async tryUpdateSelectedEngagement(): Promise<
    API.Nullable<API.Entities.Engagement>
  > {
    if (!this.state.selectedEngagement) {
      return null;
    } // don't do anything

    let updatedEngagement = null;

    try {
      updatedEngagement = (
        await RelmApi.updateEngagement(
          this.state.selectedEngagement.personaId,
          this.state.selectedEngagement.id,
          this.state.inputValues
        )
      ).data;
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to update this engagement'
        );

        return null;
      }

      this._validator.handlePossibleValidationError(error);
    }

    return updatedEngagement;
  }

  /**
   * Tries to engage the currently selected `Engagement`.
   *
   * @return {Promise<void>}
   */
  async tryEngageSelectedEngagement(): Promise<void> {
    if (
      !this.state.selectedEngagement ||
      !(await this.tryUpdateSelectedEngagement())
    ) {
      return;
    } // don't do anything

    try {
      await RelmApi.sendEngagement(
        this.state.selectedEngagement.personaId,
        this.state.selectedEngagement.id
      );
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to engage this engagements'
      );

      return;
    }

    toast(SnackbarVariant.SUCCESS, 'Engagement sent');

    this.setState({
      selectedEngagement: null,
      inputValues: {
        title: '',
        body: ''
      },
      editorState: Draft.EditorState.createEmpty()
    });
    this.props.loadFromApi();
  }

  buildRelmLinkForConsumer(consumer: API.Entities.Consumer): string {
    if (!this.props.currentSeller) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    const hostingUrl = buildRelmHostingUrlForSeller(this.props.currentSeller);

    return new URL(
      `/rk/${consumer.relmKey}.html?hidden`,
      hostingUrl
    ).toString();
  }

  getFilteredStories() {
    return this.props.stories.filter(story => {
      assertIsDefined(this.state.selectedEngagement);

      return (
        story.consumerId === this.state.selectedEngagement.consumer.id ||
        story.personaId === this.state.selectedEngagement.consumer.personaId
      );
    });
  }

  // region autobound methods
  @autobind
  handleDeleteFromOutboxClick() {
    load(this.tryDeleteSelectedEngagement());
  }

  @autobind
  handleSaveClick() {
    load(this.tryUpdateSelectedEngagement()).then(() => {
      this.props.loadFromApi(false);
      toast(SnackbarVariant.SUCCESS, 'Engagement saved');
    });
  }

  @autobind
  handleEngageClick() {
    load(this.tryEngageSelectedEngagement());
  }

  @autobind
  handleBodyEditorChange(editorState: Draft.EditorState) {
    this.setState(prevState => ({
      editorState,
      inputValues: {
        ...prevState.inputValues,
        body: contentStateToHtml(editorState.getCurrentContent())
      }
    }));
  }

  @autobind
  handleConsumerClick(event: ConsumerListItemEvent) {
    const selectedEngagement = this.props.engagements.find(
      engagement => engagement.consumer.id === event.consumer.id
    );

    if (selectedEngagement) {
      this.setState({
        selectedEngagement,
        inputValues: { ...selectedEngagement },
        editorState: Draft.EditorState.createWithContent(
          contentStateFromHtml(selectedEngagement.body)
        )
      });
    }
  }

  @autobind
  handleClearOutboxClick() {
    this.setState({ actionToConfirm: 'clear' });
  }

  @autobind
  handleSendAllClick() {
    this.setState({ actionToConfirm: 'send' });
  }

  @autobind
  handleConfirmAction() {
    if (!this.state.actionToConfirm) {
      return;
    }

    assertIsDefined(this.props.currentSeller);

    const sellerId = this.props.currentSeller.id;
    const engagementIds = this.props.engagements.map(
      engagement => engagement.id
    );

    const promise =
      this.state.actionToConfirm === 'clear'
        ? RelmApi.bulkDeleteEngagement(sellerId, engagementIds)
        : RelmApi.bulkSendEngagement(sellerId, engagementIds);

    load(
      promise
        .then(() => this.props.loadFromApi())
        .then(() =>
          this.setState({
            selectedEngagement: null,
            actionToConfirm: null
          })
        )
    );
  }

  @autobind
  handleCancelAction() {
    this.setState({ actionToConfirm: null });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const { classes } = this.props;

    return (
      <>
        <Grid className={classes.root} container>
          <Grid className={classes.spacing} item xs={1} />
          <Grid className={classes.spacing} item xs={2}>
            <ConsumersListCard
              title="Outbox"
              consumers={this.props.engagements.map(
                engagement => engagement.consumer
              )}
              idOfSelectedConsumer={
                this.state.selectedEngagement
                  ? this.state.selectedEngagement.consumer.id
                  : undefined
              }
              onConsumerClick={this.handleConsumerClick}
            />
            <Button
              className={classes.displayBlock}
              disabled={this.props.engagements.length === 0}
              onClick={this.handleClearOutboxClick}
            >
              Clear Outbox
            </Button>
            <Button
              className={classes.displayBlock}
              disabled={this.props.engagements.length === 0}
              onClick={this.handleSendAllClick}
            >
              Send All
            </Button>
          </Grid>
          <Grid
            className={classes.spacing}
            container
            direction="column"
            item
            xs={5}
          >
            {this.state.selectedEngagement && (
              <>
                {this._validator.buildTextFieldForProperty('title', 'Subject')}
                <OutboxEditor
                  className={classes.editorContainer}
                  editorState={this.state.editorState}
                  selectedEngagement={this.state.selectedEngagement}
                  stories={this.getFilteredStories()}
                  onChange={this.handleBodyEditorChange}
                />
                <Grid container>
                  <Button
                    className={classes.buttonDelete}
                    variant="outlined"
                    color="primary"
                    size="small"
                    aria-label="Delete from outbox"
                    onClick={this.handleDeleteFromOutboxClick}
                  >
                    <Delete className={classes.iconDelete} />
                    <span className={classes.iconDeleteLabel}>
                      Delete from outbox
                    </span>
                  </Button>
                  <Glue />
                  <Button
                    className={classes.buttonSave}
                    variant="outlined"
                    color="primary"
                    aria-label="Save"
                    onClick={this.handleSaveClick}
                  >
                    <Save className={classes.iconSave} />
                    <span className={classes.iconSaveLabel}>Save</span>
                  </Button>
                  <Button
                    className={classes.buttonEngage}
                    variant="contained"
                    color="secondary"
                    aria-label="Engage"
                    onClick={this.handleEngageClick}
                  >
                    <span className={classes.iconEngageLabel}>Send</span>
                    <Send className={classes.iconEngage} />
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <Grid className={classes.spacing} item xs={4}>
            {this.state.selectedEngagement && (
              <>
                <iframe
                  title="Landing Page Preview"
                  className={classes.iframeLandingPagePreview}
                  src={this.buildRelmLinkForConsumer(
                    this.state.selectedEngagement.consumer
                  )}
                />
                <Button
                  className={classes.buttonPreview}
                  variant="text"
                  color="primary"
                  href={this.buildRelmLinkForConsumer(
                    this.state.selectedEngagement.consumer
                  )}
                  target="_blank"
                >
                  <span className={classes.iconPreviewLabel}>
                    Open landing page
                  </span>
                  <OpenInNew className={classes.iconPreview} />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        {this.state.actionToConfirm && (
          <AreYouSureDialog
            open
            onCancel={this.handleCancelAction}
            onAction={this.handleConfirmAction}
            actionText="Confirm Action"
          >
            Are you sure you want to {this.state.actionToConfirm} all
            engagements?
            <br />
            <strong>This action cannot be undone.</strong>
          </AreYouSureDialog>
        )}
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(Outbox);
