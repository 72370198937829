import Axios from 'axios';
import debug from 'debug';
import { API } from 'src/definitions';
import AuthTokenFailureException from 'src/exceptions/AuthTokenFailureException';
import AxiosException from 'src/exceptions/AxiosException';
import ValidationException from 'src/exceptions/ValidationException';
import { UpdateConsumerInBulkRequest } from 'src/schemas';
import AddConsumerInBulkRequest from 'src/schemas/AddConsumerInBulkRequest';
import AddConsumerRequest from 'src/schemas/AddConsumerRequest';
import AddEngagementInBulkRequest from 'src/schemas/AddEngagementInBulkRequest';
import AddEngagementRequest from 'src/schemas/AddEngagementRequest';
import AddPersonaRequest from 'src/schemas/AddPersonaRequest';
import AddStepRequest from 'src/schemas/AddStepRequest';
import AddStoryRequest from 'src/schemas/AddStoryRequest';
import ArchiveConsumersInBulk from 'src/schemas/ArchiveConsumersInBulkRequest';
import DeleteEngagementInBulkRequest from 'src/schemas/DeleteEngagementInBulkRequest';
import ForgotPasswordRequest from 'src/schemas/ForgotPasswordRequest';
import ResetPasswordRequest from 'src/schemas/ResetPasswordRequest';
import SendEngagementInBulkRequest from 'src/schemas/SendEngagementInBulkRequest';
import SignInRequest from 'src/schemas/SignInRequest';
import SignUpRequest from 'src/schemas/SignUpRequest';
import UpdateConsumerRequest from 'src/schemas/UpdateConsumerRequest';
import UpdateEngagementRequest from 'src/schemas/UpdateEngagementRequest';
import UpdatePersonaRequest from 'src/schemas/UpdatePersonaRequest';
import UpdateSellerRequest from 'src/schemas/UpdateSellerRequest';
import UpdateSellerStylesRequest from 'src/schemas/UpdateSellerStylesRequest';
import UpdateStepRequest from 'src/schemas/UpdateStepRequest';
import UpdateStoryRequest from 'src/schemas/UpdateStoryRequest';
import AuthTokenManager, {
  StoredAuthToken
} from 'src/services/AuthTokenManager';

class RelmApi {
  /**
   * @type {debug.IDebugger}
   * @private
   */
  private readonly _logger: debug.IDebugger = debug(this.constructor.name);

  /**
   *
   * @type {!string}
   * @private
   */
  private _apiUrl: string;

  /**
   *
   * @type {!string}
   * @private
   */
  private readonly _authUrl: string;

  /**
   * Manager that takes care of storing & supplying the `OAuth2` token.
   *
   * @type {AuthTokenManager}
   * @private
   */
  private _authManager: AuthTokenManager;

  /**
   *
   * @type {AxiosInstance}
   * @private
   */
  private readonly _apiRequest = Axios.create({
    baseURL: this.apiUrl,
    headers: {
      'X-Clacks-Overhead': 'GNU Terry Pratchett',
      'Accept': 'application/json'
    }
  });

  /**
   * The latest Promise for a new access token.
   *
   * Used to allow inf. calls to refresh the access token without
   * actually making multiple calls with the same refresh token.
   *
   * @type {?Promise}
   */
  private _promiseRefreshedToken: Promise<null> | null = null;

  /**
   * Instantiates a new `FormData` instance, appending the properties in the given `object`.
   *
   * If the `object` passed is an instance of `FormData`, then it's returned without change.
   *
   * @param {object} object
   *
   * @return {FormData}
   * @static
   */
  static formDataFromObject(
    object: Record<any, string | File> | FormData
  ): FormData {
    if (object instanceof FormData) {
      return object;
    }

    const formData = new FormData();

    Object.keys(object).forEach(key => formData.append(key, object[key]));

    return formData;
  }

  /**
   *
   * @param {!string} apiUrl
   * @param {!string} authUrl
   * @param {AuthTokenManager} authManager
   */
  constructor(apiUrl: string, authUrl: string, authManager: AuthTokenManager) {
    this._apiUrl = apiUrl;
    this._authUrl = authUrl;
    this._authManager = authManager;

    // just to be safe, this time around
    this._apiRequest.defaults.baseURL = this._apiUrl;
    // region interceptor: strip undefined properties from data
    this._apiRequest.interceptors.request.use(config => {
      if (config.params && config.params.noStrip) {
        delete config.params.noStrip;

        return config;
      }

      if (config.data instanceof FormData) {
        return config;
      }

      const data = { ...config.data };

      Object.keys(data)
        .filter(key => typeof data[key] === 'undefined')
        .forEach(key => delete data[key]);

      return {
        ...config,
        data
      };
    });
    // endregion
    // region interceptor: authentication token refreshing
    this._apiRequest.interceptors.request.use(async config => {
      if (config.params && config.params.noAuth) {
        delete config.params.noAuth;

        return config;
      } // unauthenticated calls use this to skip this check

      if (!this.isAuthenticated()) {
        return config;
      } // only when authenticated...

      const authToken = await this.promiseFreshAuthToken();

      if (!authToken) {
        throw new AuthTokenFailureException('auth token failure');
      } // this shouldn't really happen by the time we get here...

      config.headers.Authorization = `Bearer ${authToken.access_token}`;

      return config;
    });
    // endregion
    this._apiRequest.interceptors.response.use(
      undefined,
      AxiosException.interceptorWrapper
    );
  }

  // region getters & setters
  // region apiUrl (get & set)
  /**
   *
   * @return {!string}
   */
  get apiUrl(): string {
    return this._apiUrl;
  }

  /**
   *
   * @param {!string} apiUrl
   */
  set apiUrl(apiUrl: string) {
    this._apiUrl = apiUrl;

    this._apiRequest.defaults.baseURL = this._apiUrl;
  }

  // endregion
  // region authManager (get & set)
  /**
   *
   * @return {AuthTokenManager}
   */
  get authManager() {
    return this._authManager;
  }

  /**
   *
   * @param {AuthTokenManager} authManager
   */
  set authManager(authManager: AuthTokenManager) {
    this._authManager = authManager;
  }

  // endregion
  // endregion
  // region Private API Endpoints
  // region Authorization
  // region Password Resets
  // region ForgotPassword
  /**
   * Sends a `POST` request to the `ForgotPassword` endpoint.
   *
   * This will result in sending an email to reset the password for an account.
   *
   * @param {API.Authorization.ForgotPassword.Parameters} endpointParameters
   * @param {API.Authorization.ForgotPassword.Request} endpointRequest
   *
   * @return {Promise<API.Authorization.ForgotPassword.Response>}
   * @private
   */
  async _forgotPassword(
    endpointParameters: API.Authorization.ForgotPassword.Parameters,
    endpointRequest: API.Authorization.ForgotPassword.Request
  ): Promise<API.Authorization.ForgotPassword.Response> {
    ValidationException.assert(ForgotPasswordRequest.validate(endpointRequest));

    return this._apiRequest
      .post('/password/email', endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region ResetPassword
  /**
   * Sends a `POST` request to the `ResetPassword` endpoint.
   *
   * This will result in the changing of the password of the `User`s whose
   * account is tied to the `email` address provided in the request.
   *
   * @param {API.Authorization.ResetPassword.Parameters} endpointParameters
   * @param {API.Authorization.ResetPassword.Request} endpointRequest
   *
   * @return {Promise<API.Authorization.ResetPassword.Response>}
   * @private
   */
  async _resetPassword(
    endpointParameters: API.Authorization.ResetPassword.Parameters,
    endpointRequest: API.Authorization.ResetPassword.Request
  ): Promise<API.Authorization.ResetPassword.Response> {
    ValidationException.assert(ResetPasswordRequest.validate(endpointRequest));

    return this._apiRequest
      .post('/password/reset', endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region RequestAccessToken
  /**
   * Sends a `POST` request to the `RequestAccessToken` endpoint.
   *
   * This will result in a new `OAuth2` access token being granted.
   *
   * @param {API.Authorization.RequestAccessToken.Parameters} endpointParameters
   * @param {API.Authorization.RequestAccessToken.Request} endpointRequest
   *
   * @return {Promise<API.Authorization.RequestAccessToken.Response>}
   * @private
   */
  _requestAccessToken(
    endpointParameters: API.Authorization.RequestAccessToken.Parameters,
    endpointRequest: API.Authorization.RequestAccessToken.Request
  ): Promise<API.Authorization.RequestAccessToken.Response> {
    return this._apiRequest
      .post('oauth/token', endpointRequest, {
        params: { noAuth: true },
        baseURL: this._authUrl || this._apiUrl
      })
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Sellers
  // region GetCurrentSeller
  /**
   * Sends a `GET` request to the `GetCurrentSeller` endpoint.
   *
   * @param {API.Sellers.GetCurrentSeller.Parameters} [endpointParameters={}]
   * @param {API.Sellers.GetCurrentSeller.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Sellers.GetCurrentSeller.Response>}
   * @private
   */
  private _getCurrentSeller(
    endpointParameters: API.Sellers.GetCurrentSeller.Parameters = {},
    endpointRequest: API.Sellers.GetCurrentSeller.Request = {}
  ): Promise<API.Sellers.GetCurrentSeller.Response> {
    return this._apiRequest.get('/seller').then(response => response.data);
  }

  // endregion
  // region SignUpSeller
  /**
   * Sends a `POST` request to the `SignUpSeller` endpoint.
   *
   * @param {API.Sellers.SignUpSeller.Parameters} endpointParameters
   * @param {API.Sellers.SignUpSeller.Request} endpointRequest
   *
   * @return {Promise<API.Sellers.SignUpSeller.Response>}
   * @private
   *
   * @throws {ValidationException<API.Sellers.SignUpSeller.KeyOfRequest>} when the request fails validation
   */
  private async _signUpSeller(
    endpointParameters: API.Sellers.SignUpSeller.Parameters,
    endpointRequest: API.Sellers.SignUpSeller.Request
  ): Promise<API.Sellers.SignUpSeller.Response> {
    ValidationException.assert(SignUpRequest.validate(endpointRequest));

    return this._apiRequest
      .post(`/sellers`, endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region ListStoriesOfSeller
  /**
   * Sends a `GET` request to the `ListStoriesOfSeller` endpoint.
   *
   * @param {API.Sellers.ListStoriesOfSeller.Parameters} endpointParameters
   * @param {API.Sellers.ListStoriesOfSeller.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Sellers.ListStoriesOfSeller.Response>}
   * @private
   */
  private _listStoriesOfSeller(
    endpointParameters: API.Sellers.ListStoriesOfSeller.Parameters,
    endpointRequest: API.Sellers.ListStoriesOfSeller.Request = {}
  ): Promise<API.Sellers.ListStoriesOfSeller.Response> {
    return this._apiRequest
      .get(`/sellers/${endpointParameters.sellerId}/stories`)
      .then(response => response.data);
  }

  // endregion
  // region ListEngagementsOfSeller
  /**
   * Sends a `GET` request to the `ListEngagementsOfSeller` endpoint.
   *
   * @param {API.Sellers.ListEngagementsOfSeller.Parameters} endpointParameters
   * @param {API.Sellers.ListEngagementsOfSeller.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Sellers.ListEngagementsOfSeller.Response>}
   * @private
   */
  private _listEngagementsOfSeller(
    endpointParameters: API.Sellers.ListEngagementsOfSeller.Parameters,
    endpointRequest: API.Sellers.ListEngagementsOfSeller.Request = {}
  ): Promise<API.Sellers.ListEngagementsOfSeller.Response> {
    return this._apiRequest
      .get(`/sellers/${endpointParameters.sellerId}/engagements`)
      .then(response => response.data);
  }

  // endregion
  // region ShowSingleSeller
  /**
   * Sends a `GET` request to the `ShowSingleSeller` endpoint.
   *
   * @param {API.Sellers.ShowSingleSeller.Parameters} endpointParameters
   * @param {API.Sellers.ShowSingleSeller.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Sellers.ShowSingleSeller.Response>}
   * @private
   */
  private _showSingleSeller(
    endpointParameters: API.Sellers.ShowSingleSeller.Parameters,
    endpointRequest: API.Sellers.ShowSingleSeller.Request = {}
  ): Promise<API.Sellers.ShowSingleSeller.Response> {
    return this._apiRequest
      .get(`/sellers/${endpointParameters.sellerId}`)
      .then(response => response.data);
  }

  // endregion
  // region ShowSingleSellerStyles
  /**
   * Sends a `GET` request to the `ShowSingleSellerStyles` endpoint.
   *
   * @param {API.Sellers.ShowSingleSellerStyles.Parameters} endpointParameters
   * @param {API.Sellers.ShowSingleSellerStyles.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Sellers.ShowSingleSellerStyles.Response>}
   * @private
   */
  private _showSingleSellerStyles(
    endpointParameters: API.Sellers.ShowSingleSellerStyles.Parameters,
    endpointRequest: API.Sellers.ShowSingleSellerStyles.Request = {}
  ): Promise<API.Sellers.ShowSingleSellerStyles.Response> {
    return this._apiRequest
      .get(`/sellers/${endpointParameters.sellerId}/styles`)
      .then(response => response.data);
  }

  // endregion
  // region UpdateSeller
  /**
   * Sends a `PATCH` request to the `UpdateSeller` endpoint.
   *
   * @param {API.Sellers.UpdateSeller.Parameters} endpointParameters
   * @param {API.Sellers.UpdateSeller.Request} endpointRequest
   *
   * @return {Promise<API.Sellers.UpdateSeller.Response>}
   * @private
   *
   * @throws {ValidationException<API.Sellers.UpdateSeller.KeyOfRequest>} when the request fails validation
   */
  private async _updateSeller(
    endpointParameters: API.Sellers.UpdateSeller.Parameters,
    endpointRequest: API.Sellers.UpdateSeller.Request
  ): Promise<API.Sellers.UpdateSeller.Response> {
    ValidationException.assert(UpdateSellerRequest.validate(endpointRequest));

    return this._apiRequest
      .patch(`/sellers/${endpointParameters.sellerId}`, endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region UpdateSellerStyles
  /**
   * Sends a `PATCH` request to the `UpdateSellerStyles` endpoint.
   *
   * @param {API.Sellers.UpdateSellerStyles.Parameters} endpointParameters
   * @param {API.Sellers.UpdateSellerStyles.Request} endpointRequest
   *
   * @return {Promise<API.Sellers.UpdateSellerStyles.Response>}
   * @private
   *
   * @throws {ValidationException<API.Sellers.UpdateSellerStyles.KeyOfRequest>} when the request fails validation
   */
  private async _updateSellerStyles(
    endpointParameters: API.Sellers.UpdateSellerStyles.Parameters,
    endpointRequest: API.Sellers.UpdateSellerStyles.Request
  ): Promise<API.Sellers.UpdateSellerStyles.Response> {
    ValidationException.assert(
      UpdateSellerStylesRequest.validate(endpointRequest)
    );

    return this._apiRequest
      .patch(`/sellers/${endpointParameters.sellerId}/styles`, endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region RemoveSeller
  /**
   * Sends a `DELETE` request to the `RemoveSeller` endpoint.
   *
   * @param {API.Sellers.RemoveSeller.Parameters} endpointParameters
   * @param {API.Sellers.RemoveSeller.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Sellers.RemoveSeller.Response>}
   * @private
   */
  private _removeSeller(
    endpointParameters: API.Sellers.RemoveSeller.Parameters,
    endpointRequest: API.Sellers.RemoveSeller.Request = {}
  ): Promise<API.Sellers.RemoveSeller.Response> {
    return this._apiRequest
      .delete(`/sellers/${endpointParameters.sellerId}`)
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Personas
  // region ListAllPersonas
  /**
   * Sends a `GET` request to the `ListPersonas` endpoint.
   *
   * @param {API.Personas.ListPersonas.Parameters} [endpointParameters={}]
   * @param {API.Personas.ListPersonas.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Personas.ListPersonas.Response>}
   * @private
   */
  private _listPersonas(
    endpointParameters: API.Personas.ListPersonas.Parameters = {},
    endpointRequest: API.Personas.ListPersonas.Request = {}
  ): Promise<API.Personas.ListPersonas.Response> {
    return this._apiRequest.get(`/personas`).then(response => response.data);
  }

  // endregion
  // region ShowSinglePersona
  /**
   * Sends a `GET` request to the `ShowSinglePersona` endpoint.
   *
   * @param {API.Personas.ShowSinglePersona.Parameters} endpointParameters
   * @param {API.Personas.ShowSinglePersona.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Personas.ShowSinglePersona.Response>}
   * @private
   */
  private _showSinglePersona(
    endpointParameters: API.Personas.ShowSinglePersona.Parameters,
    endpointRequest: API.Personas.ShowSinglePersona.Request = {}
  ): Promise<API.Personas.ShowSinglePersona.Response> {
    return this._apiRequest
      .get(`/personas/${endpointParameters.personaId}`)
      .then(response => response.data);
  }

  // endregion
  // region AddPersona
  /**
   * Sends a `POST` request to the `AddPersona` endpoint.
   *
   * @param {API.Personas.AddPersona.Parameters} endpointParameters
   * @param {API.Personas.AddPersona.Request} endpointRequest
   *
   * @return {Promise<API.Personas.AddPersona.Response>}
   * @private
   *
   * @throws {ValidationException<API.Personas.AddPersona.KeyOfRequest>} when the request fails validation
   */
  private async _addPersona(
    endpointParameters: API.Personas.AddPersona.Parameters,
    endpointRequest: API.Personas.AddPersona.Request
  ): Promise<API.Personas.AddPersona.Response> {
    ValidationException.assert(AddPersonaRequest.validate(endpointRequest));

    return this._apiRequest
      .post(`/personas`, endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region UpdatePersona
  /**
   * Sends a `PATCH` request to the `UpdatePersona` endpoint.
   *
   * @param {API.Personas.UpdatePersona.Parameters} endpointParameters
   * @param {API.Personas.UpdatePersona.Request} endpointRequest
   *
   * @return {Promise<API.Personas.UpdatePersona.Response>}
   * @private
   *
   * @throws {ValidationException<API.Personas.UpdatePersona.KeyOfRequest>} when the request fails validation
   */
  private async _updatePersona(
    endpointParameters: API.Personas.UpdatePersona.Parameters,
    endpointRequest: API.Personas.UpdatePersona.Request
  ): Promise<API.Personas.UpdatePersona.Response> {
    ValidationException.assert(UpdatePersonaRequest.validate(endpointRequest));

    return this._apiRequest
      .patch(`/personas/${endpointParameters.personaId}`, endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region RemovePersona
  /**
   * Sends a `DELETE` request to the `RemovePersona` endpoint.
   *
   * @param {API.Personas.RemovePersona.Parameters} endpointParameters
   * @param {API.Personas.RemovePersona.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Personas.RemovePersona.Response>}
   * @private
   */
  private _removePersona(
    endpointParameters: API.Personas.RemovePersona.Parameters,
    endpointRequest: API.Personas.RemovePersona.Request = {}
  ): Promise<API.Personas.RemovePersona.Response> {
    return this._apiRequest
      .delete(`/personas/${endpointParameters.personaId}`)
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Stories
  // region ListStoriesOfPersona
  /**
   * Sends a `GET` request to the `ListStoriesOfPersona` endpoint.
   *
   * @param {API.Stories.ListStoriesOfPersona.Parameters} endpointParameters
   * @param {API.Stories.ListStoriesOfPersona.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Stories.ListStoriesOfPersona.Response>}
   * @private
   */
  private _listStoriesOfPersona(
    endpointParameters: API.Stories.ListStoriesOfPersona.Parameters,
    endpointRequest: API.Stories.ListStoriesOfPersona.Request = {}
  ): Promise<API.Stories.ListStoriesOfPersona.Response> {
    return this._apiRequest
      .get(`/personas/${endpointParameters.personaId}/stories`)
      .then(response => response.data);
  }

  // endregion
  // region ShowSingleStory
  /**
   * Sends a `GET` request to the `ShowSingleStory` endpoint.
   *
   * @param {API.Stories.ShowSingleStory.Parameters} endpointParameters
   * @param {API.Stories.ShowSingleStory.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Stories.ShowSingleStory.Response>}
   * @private
   */
  private _showSingleStory(
    endpointParameters: API.Stories.ShowSingleStory.Parameters,
    endpointRequest: API.Stories.ShowSingleStory.Request = {}
  ): Promise<API.Stories.ShowSingleStory.Response> {
    return this._apiRequest
      .get(
        `/personas/${endpointParameters.personaId}/stories/${endpointParameters.storyId}`
      )
      .then(response => response.data);
  }

  // endregion
  // region AddPersona
  /**
   * Sends a `POST` request to the `AddStory` endpoint.
   *
   * @param {API.Stories.AddStory.Parameters} endpointParameters
   * @param {API.Stories.AddStory.Request} endpointRequest
   *
   * @return {Promise<API.Stories.AddStory.Response>}
   * @private
   *
   * @throws {ValidationException<API.Stories.AddStory.KeyOfRequest>} when the request fails validation
   */
  private async _addStory(
    endpointParameters: API.Stories.AddStory.Parameters,
    endpointRequest: API.Stories.AddStory.Request
  ): Promise<API.Stories.AddStory.Response> {
    ValidationException.assert(AddStoryRequest.validate(endpointRequest));

    return this._apiRequest
      .post(
        `/personas/${endpointParameters.personaId}/stories`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region UpdatePersona
  /**
   * Sends a `PATCH` request to the `UpdateStory` endpoint.
   *
   * @param {API.Stories.UpdateStory.Parameters} endpointParameters
   * @param {API.Stories.UpdateStory.Request} endpointRequest
   *
   * @return {Promise<API.Stories.UpdateStory.Response>}
   * @private
   *
   * @throws {ValidationException<API.Stories.UpdateStory.KeyOfRequest>} when the request fails validation
   */
  private async _updateStory(
    endpointParameters: API.Stories.UpdateStory.Parameters,
    endpointRequest: API.Stories.UpdateStory.Request
  ): Promise<API.Stories.UpdateStory.Response> {
    ValidationException.assert(UpdateStoryRequest.validate(endpointRequest));

    return this._apiRequest
      .patch(
        `/personas/${endpointParameters.personaId}/stories/${endpointParameters.storyId}`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region RemovePersona
  /**
   * Sends a `DELETE` request to the `RemoveStory` endpoint.
   *
   * @param {API.Stories.RemoveStory.Parameters} endpointParameters
   * @param {API.Stories.RemoveStory.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Stories.RemoveStory.Response>}
   * @private
   */
  private _removeStory(
    endpointParameters: API.Stories.RemoveStory.Parameters,
    endpointRequest: API.Stories.RemoveStory.Request = {}
  ): Promise<API.Stories.RemoveStory.Response> {
    return this._apiRequest
      .delete(
        `/personas/${endpointParameters.personaId}/stories/${endpointParameters.storyId}`
      )
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Consumers
  // region ListConsumersOfSeller
  /**
   * Sends a `GET` request to the `ListConsumersOfSeller` endpoint.
   *
   * @param {API.Consumers.ListConsumersOfSeller.Parameters} endpointParameters
   * @param {API.Consumers.ListConsumersOfSeller.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Consumers.ListConsumersOfSeller.Response>}
   * @private
   */
  private _listConsumersOfPersona(
    endpointParameters: API.Consumers.ListConsumersOfSeller.Parameters,
    endpointRequest: API.Consumers.ListConsumersOfSeller.Request = {}
  ): Promise<API.Consumers.ListConsumersOfSeller.Response> {
    return this._apiRequest
      .get(`/sellers/${endpointParameters.sellerId}/consumers`)
      .then(response => response.data);
  }

  // endregion
  // region ShowSingleConsumer
  /**
   * Sends a `GET` request to the `ShowSingleConsumer` endpoint.
   *
   * @param {API.Consumers.ShowSingleConsumer.Parameters} endpointParameters
   * @param {API.Consumers.ShowSingleConsumer.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Consumers.ShowSingleConsumer.Response>}
   * @private
   */
  private _showSingleConsumer(
    endpointParameters: API.Consumers.ShowSingleConsumer.Parameters,
    endpointRequest: API.Consumers.ShowSingleConsumer.Request = {}
  ): Promise<API.Consumers.ShowSingleConsumer.Response> {
    return this._apiRequest
      .get(
        `/sellers/${endpointParameters.sellerId}/consumers/${endpointParameters.consumerId}`
      )
      .then(response => response.data);
  }

  // endregion
  // region ListStoriesOfConsumer
  /**
   * Sends a `GET` request to the `ListStoriesOfConsumer` endpoint.
   *
   * @param {API.Consumers.ListStoriesOfConsumer.Parameters} endpointParameters
   * @param {API.Consumers.ListStoriesOfConsumer.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Consumers.ListStoriesOfConsumer.Response>}
   * @private
   */
  private _listStoriesOfConsumer(
    endpointParameters: API.Consumers.ListStoriesOfConsumer.Parameters,
    endpointRequest: API.Consumers.ListStoriesOfConsumer.Request = {}
  ): Promise<API.Consumers.ListStoriesOfConsumer.Response> {
    return this._apiRequest
      .get(
        `/sellers/${endpointParameters.sellerId}/consumers/${endpointParameters.consumerId}/stories`
      )
      .then(response => response.data);
  }

  // endregion
  // region ListEngagementsOfConsumer
  /**
   * Sends a `GET` request to the `ListEngagementsOfConsumer` endpoint.
   *
   * @param {API.Consumers.ListEngagementsOfConsumer.Parameters} endpointParameters
   * @param {API.Consumers.ListEngagementsOfConsumer.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Consumers.ListEngagementsOfConsumer.Response>}
   * @private
   */
  private _listEngagementsOfConsumer(
    endpointParameters: API.Consumers.ListEngagementsOfConsumer.Parameters,
    endpointRequest: API.Consumers.ListEngagementsOfConsumer.Request = {}
  ): Promise<API.Consumers.ListEngagementsOfConsumer.Response> {
    return this._apiRequest
      .get(
        `/sellers/${endpointParameters.sellerId}/consumers/${endpointParameters.consumerId}/engagements`
      )
      .then(response => response.data);
  }

  // endregion
  // region AddConsumer
  /**
   * Sends a `POST` request to the `AddConsumer` endpoint.
   *
   * @param {API.Consumers.AddConsumer.Parameters} endpointParameters
   * @param {API.Consumers.AddConsumer.Request} endpointRequest
   *
   * @return {Promise<API.Consumers.AddConsumer.Response>}
   * @private
   *
   * @throws {ValidationException<API.Consumers.AddConsumer.KeyOfRequest>} when the request fails validation
   */
  private async _addConsumer(
    endpointParameters: API.Consumers.AddConsumer.Parameters,
    endpointRequest: API.Consumers.AddConsumer.Request
  ): Promise<API.Consumers.AddConsumer.Response> {
    ValidationException.assert(AddConsumerRequest.validate(endpointRequest));

    return this._apiRequest
      .post(
        `/sellers/${endpointParameters.sellerId}/consumers`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region AddConsumerInBulk
  /**
   * Sends a `POST` request to the `AddConsumerInBulk` endpoint.
   *
   * @param {API.Consumers.AddConsumerInBulk.Parameters} endpointParameters
   * @param {API.Consumers.AddConsumerInBulk.Request} endpointRequest
   *
   * @return {Promise<API.Consumers.AddConsumerInBulk.Response>}
   * @private
   *
   * @throws {ValidationException<API.Consumers.AddConsumerInBulk.KeyOfRequest>} when the request fails validation
   */
  private async _addConsumerInBulk(
    endpointParameters: API.Consumers.AddConsumerInBulk.Parameters,
    endpointRequest: API.Consumers.AddConsumerInBulk.Request
  ): Promise<API.Consumers.AddConsumerInBulk.Response> {
    ValidationException.assert(
      AddConsumerInBulkRequest.validate(endpointRequest)
    );

    return this._apiRequest
      .post(
        `/sellers/${endpointParameters.sellerId}/consumers/bulk`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region UpdateConsumer
  /**
   * Sends a `PATCH` request to the `UpdateConsumer` endpoint.
   *
   * @param {API.Consumers.UpdateConsumer.Parameters} endpointParameters
   * @param {API.Consumers.UpdateConsumer.Request} endpointRequest
   *
   * @return {Promise<API.Consumers.UpdateConsumer.Response>}
   * @private
   *
   * @throws {ValidationException<API.Consumers.UpdateConsumer.KeyOfRequest>} when the request fails validation
   */
  private async _updateConsumer(
    endpointParameters: API.Consumers.UpdateConsumer.Parameters,
    endpointRequest: API.Consumers.UpdateConsumer.Request
  ): Promise<API.Consumers.UpdateConsumer.Response> {
    ValidationException.assert(UpdateConsumerRequest.validate(endpointRequest));

    return this._apiRequest
      .patch(
        `/sellers/${endpointParameters.sellerId}/consumers/${endpointParameters.consumerId}`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region UpdateConsumerInBulk
  /**
   * Sends a `POST` request to the `UpdateConsumerInBulk` endpoint.
   *
   * @param {API.Consumers.UpdateConsumerInBulk.Parameters} endpointParameters
   * @param {API.Consumers.UpdateConsumerInBulk.Request} endpointRequest
   *
   * @return {Promise<API.Consumers.UpdateConsumerInBulk.Response>}
   * @private
   *
   * @throws {ValidationException<API.Consumers.UpdateConsumerInBulk.KeyOfRequest>} when the request fails validation
   */
  private async _updateConsumerInBulk(
    endpointParameters: API.Consumers.UpdateConsumerInBulk.Parameters,
    endpointRequest: API.Consumers.UpdateConsumerInBulk.Request
  ): Promise<API.Consumers.AddConsumerInBulk.Response> {
    ValidationException.assert(
      UpdateConsumerInBulkRequest.validate(endpointRequest)
    );

    return (
      await this._apiRequest.patch(
        `/sellers/${endpointParameters.sellerId}/consumers/bulk`,
        endpointRequest
      )
    ).data;
  }

  // endregion
  // region ArchiveConsumer
  /**
   * Sends a `DELETE` request to the `ArchiveConsumer` endpoint.
   *
   * @param {API.Consumers.ArchiveConsumer.Parameters} endpointParameters
   * @param {API.Consumers.ArchiveConsumer.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Consumers.ArchiveConsumer.Response>}
   * @private
   */
  private _archiveConsumer(
    endpointParameters: API.Consumers.ArchiveConsumer.Parameters,
    endpointRequest: API.Consumers.ArchiveConsumer.Request = {}
  ): Promise<API.Consumers.ArchiveConsumer.Response> {
    return this._apiRequest
      .delete(
        `/sellers/${endpointParameters.sellerId}/consumers/${endpointParameters.consumerId}`
      )
      .then(response => response.data);
  }

  // endregion
  // region ArchiveConsumerInBulk
  /**
   * Sends a `POST` request to the `ArchiveConsumersInBulk` endpoint.
   *
   * @param {API.Consumers.ArchiveConsumersInBulk.Parameters} endpointParameters
   * @param {API.Consumers.ArchiveConsumersInBulk.Request} endpointRequest
   *
   * @return {Promise<API.Consumers.ArchiveConsumersInBulk.Response>}
   * @private
   *
   * @throws {ValidationException<API.Consumers.ArchiveConsumersInBulk.KeyOfRequest>} when the request fails validation
   */
  private async _archiveConsumersInBulk(
    endpointParameters: API.Consumers.ArchiveConsumersInBulk.Parameters,
    endpointRequest: API.Consumers.ArchiveConsumersInBulk.Request
  ): Promise<API.Consumers.ArchiveConsumersInBulk.Response> {
    ValidationException.assert(
      ArchiveConsumersInBulk.validate(endpointRequest)
    );

    return this._apiRequest
      .post(
        `/sellers/${endpointParameters.sellerId}/consumers/bulk/delete`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Engagements
  // region ListEngagementsOfPersona
  /**
   * Sends a `GET` request to the `ListEngagementsOfPersona` endpoint.
   *
   * @param {API.Engagements.ListEngagementsOfPersona.Parameters} endpointParameters
   * @param {API.Engagements.ListEngagementsOfPersona.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Engagements.ListEngagementsOfPersona.Response>}
   * @private
   */
  private _listEngagementsOfPersona(
    endpointParameters: API.Engagements.ListEngagementsOfPersona.Parameters,
    endpointRequest: API.Engagements.ListEngagementsOfPersona.Request = {}
  ): Promise<API.Engagements.ListEngagementsOfPersona.Response> {
    return this._apiRequest
      .get(`/personas/${endpointParameters.personaId}/engagements`)
      .then(response => response.data);
  }

  // endregion
  // region ShowSingleEngagement
  /**
   * Sends a `GET` request to the `ShowSingleEngagement` endpoint.
   *
   * @param {API.Engagements.ShowSingleEngagement.Parameters} endpointParameters
   * @param {API.Engagements.ShowSingleEngagement.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Engagements.ShowSingleEngagement.Response>}
   * @private
   */
  private _showSingleEngagement(
    endpointParameters: API.Engagements.ShowSingleEngagement.Parameters,
    endpointRequest: API.Engagements.ShowSingleEngagement.Request = {}
  ): Promise<API.Engagements.ShowSingleEngagement.Response> {
    return this._apiRequest
      .get(
        `/personas/${endpointParameters.personaId}/engagements/${endpointParameters.engagementId}`
      )
      .then(response => response.data);
  }

  // endregion
  // region AddEngagement
  /**
   * Sends a `POST` request to the `AddEngagement` endpoint.
   *
   * @param {API.Engagements.AddEngagement.Parameters} endpointParameters
   * @param {API.Engagements.AddEngagement.Request} endpointRequest
   *
   * @return {Promise<API.Engagements.AddEngagement.Response>}
   * @private
   *
   * @throws {ValidationException<API.Engagements.AddEngagement.KeyOfRequest>} when the request fails validation
   */
  private async _addEngagement(
    endpointParameters: API.Engagements.AddEngagement.Parameters,
    endpointRequest: API.Engagements.AddEngagement.Request
  ): Promise<API.Engagements.AddEngagement.Response> {
    ValidationException.assert(AddEngagementRequest.validate(endpointRequest));

    return this._apiRequest
      .post(
        `/personas/${endpointParameters.personaId}/engagements`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region AddEngagementInBulk
  /**
   * Sends a `POST` request to the `AddEngagementInBulk` endpoint.
   *
   * @param {API.Engagements.AddEngagementInBulk.Parameters} endpointParameters
   * @param {API.Engagements.AddEngagementInBulk.Request} endpointRequest
   *
   * @return {Promise<API.Engagements.AddEngagementInBulk.Response>}
   * @private
   *
   * @throws {ValidationException<API.Engagements.AddEngagementInBulk.KeyOfRequest>} when the request fails validation
   */
  private async _addEngagementInBulk(
    endpointParameters: API.Engagements.AddEngagementInBulk.Parameters,
    endpointRequest: API.Engagements.AddEngagementInBulk.Request
  ): Promise<API.Engagements.AddEngagementInBulk.Response> {
    ValidationException.assert(
      AddEngagementInBulkRequest.validate(endpointRequest)
    );

    return this._apiRequest
      .post(
        `/personas/${endpointParameters.personaId}/engagements/bulk`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region UpdateEngagement
  /**
   * Sends a `PATCH` request to the `UpdateEngagement` endpoint.
   *
   * @param {API.Engagements.UpdateEngagement.Parameters} endpointParameters
   * @param {API.Engagements.UpdateEngagement.Request} endpointRequest
   *
   * @return {Promise<API.Engagements.UpdateEngagement.Response>}
   * @private
   *
   * @throws {ValidationException<API.Engagements.UpdateEngagement.KeyOfRequest>} when the request fails validation
   */
  private async _updateEngagement(
    endpointParameters: API.Engagements.UpdateEngagement.Parameters,
    endpointRequest: API.Engagements.UpdateEngagement.Request
  ): Promise<API.Engagements.UpdateEngagement.Response> {
    ValidationException.assert(
      UpdateEngagementRequest.validate(endpointRequest)
    );

    return this._apiRequest
      .patch(
        `/personas/${endpointParameters.personaId}/engagements/${endpointParameters.engagementId}`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region DeleteEngagement
  /**
   * Sends a `DELETE` request to the `DeleteEngagement` endpoint.
   *
   * @param {API.Engagements.DeleteEngagement.Parameters} endpointParameters
   * @param {API.Engagements.DeleteEngagement.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Engagements.DeleteEngagement.Response>}
   * @private
   */
  private _deleteEngagement(
    endpointParameters: API.Engagements.DeleteEngagement.Parameters,
    endpointRequest: API.Engagements.DeleteEngagement.Request = {}
  ): Promise<API.Engagements.DeleteEngagement.Response> {
    return this._apiRequest
      .delete(
        `/personas/${endpointParameters.personaId}/engagements/${endpointParameters.engagementId}`
      )
      .then(response => response.data);
  }

  // endregion
  // region DeleteEngagementInBulk
  /**
   * Sends a `POST` request to the `DeleteEngagementInBulk` endpoint.
   *
   * @param {API.Engagements.DeleteEngagementInBulk.Parameters} endpointParameters
   * @param {API.Engagements.DeleteEngagementInBulk.Request} endpointRequest
   *
   * @return {Promise<API.Engagements.DeleteEngagementInBulk.Response>}
   * @private
   *
   * @throws {ValidationException<API.Engagements.DeleteEngagementInBulk.KeyOfRequest>} when the request fails validation
   */
  private async _deleteEngagementInBulk(
    endpointParameters: API.Engagements.DeleteEngagementInBulk.Parameters,
    endpointRequest: API.Engagements.DeleteEngagementInBulk.Request
  ): Promise<API.Engagements.DeleteEngagementInBulk.Response> {
    ValidationException.assert(
      DeleteEngagementInBulkRequest.validate(endpointRequest)
    );

    return this._apiRequest
      .post(
        `/sellers/${endpointParameters.sellerId}/engagements/bulk/delete`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region SendEngagement
  /**
   * Sends a `POST` request to the `SendEngagement` endpoint.
   *
   * @param {API.Engagements.SendEngagement.Parameters} endpointParameters
   * @param {API.Engagements.SendEngagement.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Engagements.SendEngagement.Response>}
   * @private
   */
  private _sendEngagement(
    endpointParameters: API.Engagements.SendEngagement.Parameters,
    endpointRequest: API.Engagements.SendEngagement.Request = {}
  ): Promise<API.Engagements.SendEngagement.Response> {
    return this._apiRequest
      .post(
        `/personas/${endpointParameters.personaId}/engagements/${endpointParameters.engagementId}/send`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region SendEngagementInBulk
  /**
   * Sends a `POST` request to the `SendEngagementInBulk` endpoint.
   *
   * @param {API.Engagements.SendEngagementInBulk.Parameters} endpointParameters
   * @param {API.Engagements.SendEngagementInBulk.Request} endpointRequest
   *
   * @return {Promise<API.Engagements.SendEngagementInBulk.Response>}
   * @private
   *
   * @throws {ValidationException<API.Engagements.SendEngagementInBulk.KeyOfRequest>} when the request fails validation
   */
  private async _sendEngagementInBulk(
    endpointParameters: API.Engagements.SendEngagementInBulk.Parameters,
    endpointRequest: API.Engagements.SendEngagementInBulk.Request
  ): Promise<API.Engagements.SendEngagementInBulk.Response> {
    ValidationException.assert(
      SendEngagementInBulkRequest.validate(endpointRequest)
    );

    return this._apiRequest
      .post(
        `/sellers/${endpointParameters.sellerId}/engagements/bulk/send`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Steps
  // region ListStepsOfPersona
  /**
   * Sends a `GET` request to the `ListStepsOfPersona` endpoint.
   *
   * @param {API.Steps.ListStepsOfPersona.Parameters} endpointParameters
   * @param {API.Steps.ListStepsOfPersona.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Steps.ListStepsOfPersona.Response>}
   * @private
   */
  private _listStepsOfPersona(
    endpointParameters: API.Steps.ListStepsOfPersona.Parameters,
    endpointRequest: API.Steps.ListStepsOfPersona.Request = {}
  ): Promise<API.Steps.ListStepsOfPersona.Response> {
    return this._apiRequest
      .get(`/personas/${endpointParameters.personaId}/steps`)
      .then(response => response.data);
  }

  // endregion
  // region ShowSingleStep
  /**
   * Sends a `GET` request to the `ShowSingleStep` endpoint.
   *
   * @param {API.Steps.ShowSingleStep.Parameters} endpointParameters
   * @param {API.Steps.ShowSingleStep.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Steps.ShowSingleStep.Response>}
   * @private
   */
  private _showSingleStep(
    endpointParameters: API.Steps.ShowSingleStep.Parameters,
    endpointRequest: API.Steps.ShowSingleStep.Request = {}
  ): Promise<API.Steps.ShowSingleStep.Response> {
    return this._apiRequest
      .get(
        `/personas/${endpointParameters.personaId}/steps/${endpointParameters.stepId}`
      )
      .then(response => response.data);
  }

  // endregion
  // region AddStep
  /**
   * Sends a `POST` request to the `AddStep` endpoint.
   *
   * @param {API.Steps.AddStep.Parameters} endpointParameters
   * @param {API.Steps.AddStep.Request} endpointRequest
   *
   * @return {Promise<API.Steps.AddStep.Response>}
   * @private
   *
   * @throws {ValidationException<API.Steps.AddStep.KeyOfRequest>} when the request fails validation
   */
  private async _addStep(
    endpointParameters: API.Steps.AddStep.Parameters,
    endpointRequest: API.Steps.AddStep.Request
  ): Promise<API.Steps.AddStep.Response> {
    ValidationException.assert(AddStepRequest.validate(endpointRequest));

    return this._apiRequest
      .post(`/personas/${endpointParameters.personaId}/steps`, endpointRequest)
      .then(response => response.data);
  }

  // endregion
  // region UpdateStep
  /**
   * Sends a `PATCH` request to the `UpdateStep` endpoint.
   *
   * @param {API.Steps.UpdateStep.Parameters} endpointParameters
   * @param {API.Steps.UpdateStep.Request} endpointRequest
   *
   * @return {Promise<API.Steps.UpdateStep.Response>}
   * @private
   *
   * @throws {ValidationException<API.Steps.UpdateStep.KeyOfRequest>} when the request fails validation
   */
  private async _updateStep(
    endpointParameters: API.Steps.UpdateStep.Parameters,
    endpointRequest: API.Steps.UpdateStep.Request
  ): Promise<API.Steps.UpdateStep.Response> {
    ValidationException.assert(UpdateStepRequest.validate(endpointRequest));

    return this._apiRequest
      .patch(
        `/personas/${endpointParameters.personaId}/steps/${endpointParameters.stepId}`,
        endpointRequest
      )
      .then(response => response.data);
  }

  // endregion
  // region DeleteStep
  /**
   * Sends a `DELETE` request to the `DeleteStep` endpoint.
   *
   * @param {API.Steps.DeleteStep.Parameters} endpointParameters
   * @param {API.Steps.DeleteStep.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Steps.DeleteStep.Response>}
   * @private
   */
  private _deleteStep(
    endpointParameters: API.Steps.DeleteStep.Parameters,
    endpointRequest: API.Steps.DeleteStep.Request = {}
  ): Promise<API.Steps.DeleteStep.Response> {
    return this._apiRequest
      .delete(
        `/personas/${endpointParameters.personaId}/steps/${endpointParameters.stepId}`
      )
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Files
  // region UploadFile
  /**
   * Sends a `POST` request to the `UploadFile` endpoint.
   *
   * @param {API.Files.UploadFile.Parameters} endpointParameters
   * @param {API.Files.UploadFile.Request} endpointRequest
   *
   * @return {Promise<API.Files.UploadFile.Response>}
   * @private
   */
  _uploadFile(
    endpointParameters: API.Files.UploadFile.Parameters,
    endpointRequest: API.Files.UploadFile.Request
  ): Promise<API.Files.UploadFile.Response> {
    // ts is silly, hence spread
    const formData = RelmApi.formDataFromObject({ ...endpointRequest });

    return this._apiRequest
      .post(`/files`, formData, { params: { noStrip: true } })
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Teams
  // region ShowTeam
  /**
   * Sends a `GET` request to the `ShowSingleTeam` endpoint.
   *
   * @param {API.Teams.ShowSingleTeam.Parameters} endpointParameters
   * @param {API.Teams.ShowSingleTeam.Request} [endpointRequest={}]
   *
   * @return {Promise<API.Teams.ShowSingleTeam.Response>}
   * @private
   */
  private _showTeam(
    endpointParameters: API.Teams.ShowSingleTeam.Parameters,
    endpointRequest: API.Teams.ShowSingleTeam.Request = {}
  ): Promise<API.Teams.ShowSingleTeam.Response> {
    return this._apiRequest
      .get(`/teams/${endpointParameters.teamId}`)
      .then(response => response.data);
  }

  // endregion
  // endregion
  // region Public Accessor methods
  /**
   * Sends a request for an email to reset the password for an account.
   *
   * @param {string} email The email address of the account whose password has been forgotten.
   *
   * @return {Promise<API.Authorization.ForgotPassword.Response>}
   */
  sendForgotPasswordEmail(
    email: string
  ): Promise<API.Authorization.ForgotPassword.Response> {
    return this._forgotPassword({}, { email });
  }

  /**
   * Change the password of the `User` whose account is tied to the given `email` to the given `password`,
   * using a `resetToken` received by an email sent as a result to a call to the `ForgotPassword` endpoint.
   *
   * @param {string} email The email address of the account whose password has been forgotten.
   * @param {string} password
   * @param {string} passwordConfirmation
   * @param {string} resetToken token received via email to authenticate the reset request.
   *
   * @return {Promise<API.Authorization.ResetPassword.Response>}
   */
  updateUserPassword(
    email: string,
    password: string,
    passwordConfirmation: string,
    resetToken: string
  ): Promise<API.Authorization.ResetPassword.Response> {
    return this._resetPassword(
      {},
      {
        email,
        password,
        passwordConfirmation,
        token: resetToken
      }
    );
  }

  // region client authentication management
  /**
   * Checks if this api client is authenticated or not.
   *
   * @return {boolean}
   */
  isAuthenticated() {
    return !!this.authManager.load();
  }

  /**
   * Checks if the access token being used by this api client has expired or not.
   *
   * @return {boolean}
   */
  isAuthenticatedExpired() {
    return this.authManager.isExpired();
  }

  /**
   * Attempts to authenticate using the given `username` & `password` to request an access token.
   *
   * If authentication is a success, the granted access token will be passed to the `AuthTokenManager`,
   * where they'll be stored for use with any future requests.
   *
   * Otherwise, if authentication fails, todo: something else will happen...? (return vs throw)
   *
   * @param {string} username
   * @param {string} password
   *
   * @return {Promise}
   */
  async authenticate(username: string, password: string): Promise<any> {
    ValidationException.assert(SignInRequest.validate({ username, password }));

    const token = await this._requestAccessToken(
      {},
      {
        grant_type: 'password',
        client_id: 1,
        username,
        password,
        scope: '*'
      }
    );

    this.authManager.save(token);
  }

  /**
   * Promises a fresh auth token, refreshing it if needed.
   *
   * @return {Promise<?StoredAuthToken>}
   */
  async promiseFreshAuthToken(): Promise<StoredAuthToken | null> {
    if (!this.isAuthenticatedExpired()) {
      return this.authManager.load();
    } // if the token hasn't expired, no need to refresh

    if (!this._promiseRefreshedToken) {
      this._promiseRefreshedToken = this.refreshAuthToken().then(
        () => (this._promiseRefreshedToken = null)
      );
    } // only actually make a refresh request if we've not already promised one...

    this._logger('awaiting refresh token');

    try {
      await this._promiseRefreshedToken;
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      this._logger(this.promiseFreshAuthToken.name, error);

      this.unauthenticate();
    }

    return this.authManager.load();
  }

  /**
   * Refreshes the authentication token being managed by the `AuthTokenManager`.
   *
   * Once saved by the `AuthTokenManager`, the new token is returned.
   *
   * @return {Promise<?StoredAuthToken>}
   */
  async refreshAuthToken(): Promise<StoredAuthToken | null> {
    this._logger('refreshAuthToken: refreshing auth token');

    const storedToken = this.authManager.load();

    if (!storedToken) {
      this._logger('refreshAuthToken: no refresh token on hand');

      return null;
    }

    const token = await this._requestAccessToken(
      {},
      {
        grant_type: 'refresh_token',
        client_id: 1,
        scope: '*',
        refresh_token: storedToken.refresh_token
      }
    );

    return this.authManager.save(token);
  }

  /**
   * Attempts to unauthenticate by clearing the access token managed by the `AuthTokenManager`.
   */
  unauthenticate(): void {
    this.authManager.clear();
  }

  // endregion
  // region sellers
  /**
   *
   * Gets the current `Seller` that's authenticated.
   *
   * @return {Promise<API.Sellers.GetCurrentSeller.Response>}
   */
  public getCurrentSeller(): Promise<API.Sellers.GetCurrentSeller.Response> {
    return this._getCurrentSeller();
  }

  /**
   * Creates a new `Seller`, signing them up with the api.
   *
   * @param {API.Sellers.SignUpSeller.Request} newSeller
   *
   * @return {Promise<API.Sellers.SignUpSeller.Response>}
   */
  public createSeller(
    newSeller: API.Sellers.SignUpSeller.Request
  ): Promise<API.Sellers.SignUpSeller.Response> {
    // todo: validation!
    return this._signUpSeller({}, newSeller);
  }

  /**
   * Lists all the `Story`s belonging to the `Seller` with the given id, using the `ListStoriesOfSeller` api endpoint.
   *
   * @param {string} sellerId
   *
   * @return {Promise<API.Sellers.ListStoriesOfSeller.Response>}
   */
  public listSellerStories(
    sellerId: string
  ): Promise<API.Sellers.ListStoriesOfSeller.Response> {
    return this._listStoriesOfSeller({ sellerId });
  }

  /**
   * Lists all the `Engagement`s belonging to the `Seller` with the given id, using the `ListEngagementsOfSeller` api endpoint.
   *
   * @param {string} sellerId
   *
   * @return {Promise<API.Sellers.ListEngagementsOfSeller.Response>}
   */
  public listSellerEngagements(
    sellerId: string
  ): Promise<API.Sellers.ListEngagementsOfSeller.Response> {
    return this._listEngagementsOfSeller({ sellerId });
  }

  /**
   * Gets the information on a single `Seller`, using the `ShowSingleSeller` api endpoint.
   *
   * @param {string} sellerId
   *
   * @return {Promise<API.Sellers.ShowSingleSeller.Response>}
   */
  public getSeller(
    sellerId: string
  ): Promise<API.Sellers.ShowSingleSeller.Response> {
    return this._showSingleSeller({ sellerId });
  }

  /**
   * Gets the styles for a single `Seller`, using the `ShowSingleSellerStyles` api endpoint.
   *
   * @param {string} sellerId
   *
   * @return {Promise<API.Sellers.ShowSingleSellerStyles.Response>}
   */
  public getSellerStyles(
    sellerId: string
  ): Promise<API.Sellers.ShowSingleSellerStyles.Response> {
    return this._showSingleSellerStyles({ sellerId });
  }

  /**
   * Updates the profile of a `Seller`, patching them up with the api.
   *
   * @param {string} sellerId
   * @param {API.Sellers.UpdateSeller.Request} updatedProperties
   *
   * @return {Promise<API.Sellers.UpdateSeller.Response>}
   *
   * @throws {ValidationException<API.Sellers.UpdateSeller.KeyOfRequest>} when the request fails validation
   */
  public updateSeller(
    sellerId: string,
    updatedProperties: API.Sellers.UpdateSeller.Request
  ): Promise<API.Sellers.UpdateSeller.Response> {
    return this._updateSeller({ sellerId }, updatedProperties);
  }

  /**
   * Updates the styles of a `Seller`, patching them up with the api.
   *
   * @param {string} sellerId
   * @param {API.Sellers.UpdateSellerStyles.Request} updatedStyles
   *
   * @return {Promise<API.Sellers.UpdateSellerStyles.Response>}
   *
   * @throws {ValidationException<API.Sellers.UpdateSellerStyles.KeyOfRequest>} when the request fails validation
   */
  public updateSellerStyles(
    sellerId: string,
    updatedStyles: API.Sellers.UpdateSellerStyles.Request
  ): Promise<API.Sellers.UpdateSellerStyles.Response> {
    return this._updateSellerStyles({ sellerId }, updatedStyles);
  }

  /**
   * Removes a seller account
   *
   * @param {string} sellerId
   *
   * @return {Promise<API.Sellers.RemoveSeller.Response>}
   */
  public removeSeller(
    sellerId: string
  ): Promise<API.Sellers.RemoveSeller.Response> {
    return this._removeSeller({ sellerId });
  }

  // endregion
  // region personas
  /**
   * Lists all the `Personas`, using the `ListPersonas` api endpoint.
   *
   * @return {Promise<API.Personas.ListPersonas.Response>}
   */
  listPersonas(): Promise<API.Personas.ListPersonas.Response> {
    return this._listPersonas();
  }

  /**
   * Gets the information on a single `Persona`, using the `ShowSinglePersona` api endpoint.
   *
   * @param {string} personaId
   *
   * @return {Promise<API.Personas.ShowSinglePersona.Response>}
   */
  getPersona(
    personaId: string
  ): Promise<API.Personas.ShowSinglePersona.Response> {
    return this._showSinglePersona({ personaId });
  }

  /**
   * Creates a new `Persona`, using the `AddPersona` api endpoint.
   *
   * @param {API.Personas.AddPersona.Request} newPersona
   *
   * @return {Promise<API.Personas.AddPersona.Response>}
   *
   * @throws {ValidationException<API.Personas.AddPersona.KeyOfRequest>} when the request fails validation
   */
  createPersona(
    newPersona: API.Personas.AddPersona.Request
  ): Promise<API.Personas.AddPersona.Response> {
    return this._addPersona({}, newPersona);
  }

  /**
   * Updated `Persona` details, using the `UpdatePersona` api endpoint.
   *
   * @param {string} personaId
   * @param {API.Personas.UpdatePersona.Request} updatedProperties
   *
   * @return {Promise<API.Personas.UpdatePersona.Response>}
   *
   * @throws {ValidationException<API.Personas.UpdatePersona.KeyOfRequest>} when the request fails validation
   */
  updatePersona(
    personaId: string,
    updatedProperties: API.Personas.UpdatePersona.Request
  ): Promise<API.Personas.UpdatePersona.Response> {
    return this._updatePersona({ personaId }, updatedProperties);
  }

  /**
   * Removes a `Persona`, using the `RemovePersona` api endpoint.
   *
   * @param {string} personaId
   *
   * @return {Promise<API.Personas.RemovePersona.Response>}
   */
  removePersona(
    personaId: string
  ): Promise<API.Personas.RemovePersona.Response> {
    return this._removePersona({ personaId });
  }

  // endregion
  // region stories
  /**
   * Lists all the `Stories`, using the `ListStoriesOfPersona` api endpoint.
   *
   * @param {string} personaId
   *
   * @return {Promise<API.Stories.ListStoriesOfPersona.Response>}
   */
  listStories(
    personaId: string
  ): Promise<API.Stories.ListStoriesOfPersona.Response> {
    return this._listStoriesOfPersona({ personaId });
  }

  /**
   * Gets the information on a single `Story`, using the `ShowSingleStory` api endpoint.
   *
   * @param {string} personaId
   * @param {string} storyId
   *
   * @return {Promise<API.Stories.ShowSingleStory.Response>}
   */
  getStory(
    personaId: string,
    storyId: string
  ): Promise<API.Stories.ShowSingleStory.Response> {
    return this._showSingleStory({ personaId, storyId });
  }

  /**
   * Creates a new `Story`, using the `AddStory` api endpoint.
   *
   * @param {string} personaId
   * @param {API.Stories.AddStory.Request} newStory
   *
   * @return {Promise<API.Stories.AddStory.Response>}
   *
   * @throws {ValidationException<API.Stories.AddStory.KeyOfRequest>} when the request fails validation
   */
  createStory(
    personaId: string,
    newStory: API.Stories.AddStory.Request
  ): Promise<API.Stories.AddStory.Response> {
    return this._addStory({ personaId }, newStory);
  }

  /**
   * Updated the details of a `Story`.
   *
   * @param {string} personaId
   * @param {string} storyId
   * @param {API.Stories.UpdateStory.Request} updatedProperties
   *
   * @return {Promise<API.Stories.UpdateStory.Response>}
   *
   * @throws {ValidationException<API.Stories.UpdateStory.KeyOfRequest>} when the request fails validation
   */
  updateStory(
    personaId: string,
    storyId: string,
    updatedProperties: API.Stories.UpdateStory.Request
  ): Promise<API.Stories.UpdateStory.Response> {
    return this._updateStory({ personaId, storyId }, updatedProperties);
  }

  /**
   * Removes a `Story`, using the `RemoveStory` api endpoint.
   *
   * @param {string} personaId
   * @param {string} storyId
   *
   * @return {Promise<API.Stories.RemoveStory.Response>}
   */
  removeStory(
    personaId: string,
    storyId: string
  ): Promise<API.Stories.RemoveStory.Response> {
    return this._removeStory({ personaId, storyId });
  }

  // endregion
  // region consumers
  /**
   * Lists all the `Consumers`, using the `ListConsumersOfPersona` api endpoint.
   *
   * @param {string} sellerId
   *
   * @return {Promise<API.Consumers.ListConsumersOfSeller.Response>}
   */
  listConsumers(
    sellerId: string
  ): Promise<API.Consumers.ListConsumersOfSeller.Response> {
    return this._listConsumersOfPersona({ sellerId });
  }

  /**
   * Gets the information on a single `Consumer`, using the `ShowSingleConsumer` api endpoint.
   *
   * @param {string} sellerId
   * @param {string} consumerId
   *
   * @return {Promise<API.Consumers.ShowSingleConsumer.Response>}
   */
  getConsumer(
    sellerId: string,
    consumerId: string
  ): Promise<API.Consumers.ShowSingleConsumer.Response> {
    return this._showSingleConsumer({ sellerId, consumerId });
  }

  /**
   * Lists all the `Story`s belonging to the `Consumer` with the given id, using the `ListStoriesOfConsumer` api endpoint.
   *
   * @param {string} sellerId
   * @param {string} consumerId
   *
   * @return {Promise<API.Consumers.ListStoriesOfConsumer.Response>}
   */
  listConsumerStories(
    sellerId: string,
    consumerId: string
  ): Promise<API.Consumers.ListStoriesOfConsumer.Response> {
    return this._listStoriesOfConsumer({ sellerId, consumerId });
  }

  /**
   * Lists all the `Engagement`s belonging to the `Consumer` with the given id, using the `ListEngagementsOfConsumer` api endpoint.
   *
   * @param {string} sellerId
   * @param {string} consumerId
   *
   * @return {Promise<API.Consumers.ListEngagementsOfConsumer.Response>}
   */
  listConsumerEngagements(
    sellerId: string,
    consumerId: string
  ): Promise<API.Consumers.ListEngagementsOfConsumer.Response> {
    return this._listEngagementsOfConsumer({ sellerId, consumerId });
  }

  /**
   * Creates a new `Consumer`, using the `AddConsumer` api endpoint.
   *
   * @param {string} sellerId
   * @param {API.Consumers.AddConsumer.Request} newConsumer
   *
   * @return {Promise<API.Consumers.AddConsumer.Response>}
   *
   * @throws {ValidationException<API.Consumers.AddConsumer.KeyOfRequest>} when the request fails validation
   */
  createConsumer(
    sellerId: string,
    newConsumer: API.Consumers.AddConsumer.Request
  ): Promise<API.Consumers.AddConsumer.Response> {
    return this._addConsumer({ sellerId }, newConsumer);
  }

  /**
   * Creates new `Consumer`s, using the `AddConsumerInBulk` api endpoint.
   *
   * @param {string} sellerId
   * @param {API.Consumers.AddConsumerInBulk.Request} newConsumers
   *
   * @return {Promise<API.Consumers.AddConsumerInBulk.Response>}
   *
   * @throws {ValidationException<API.Consumers.AddConsumerInBulk.KeyOfRequest>} when the request fails validation
   */
  bulkCreateConsumers(
    sellerId: string,
    newConsumers: API.Consumers.AddConsumerInBulk.Request
  ): Promise<API.Consumers.AddConsumerInBulk.Response> {
    return this._addConsumerInBulk({ sellerId }, newConsumers);
  }

  /**
   * Updated the details of a `Consumer`.
   *
   * @param {string} sellerId
   * @param {string} consumerId
   * @param {API.Consumers.UpdateConsumer.Request} updatedProperties
   *
   * @return {Promise<API.Consumers.UpdateConsumer.Response>}
   *
   * @throws {ValidationException<API.Consumers.UpdateConsumer.KeyOfRequest>} when the request fails validation
   */
  updateConsumer(
    sellerId: string,
    consumerId: string,
    updatedProperties: API.Consumers.UpdateConsumer.Request
  ): Promise<API.Consumers.UpdateConsumer.Response> {
    return this._updateConsumer({ sellerId, consumerId }, updatedProperties);
  }

  /**
   * Updates existing `Consumer`s, using the `UpdateConsumerInBulk` api endpoint.
   *
   * @param {string} sellerId
   * @param {API.Consumers.UpdateConsumerInBulk.Request['consumers']} consumers
   *
   * @return {Promise<API.Consumers.UpdateConsumerInBulk.Response>}
   *
   * @throws {ValidationException<API.Consumers.UpdateConsumerInBulk.KeyOfRequest>} when the request fails validation
   */
  bulkUpdateConsumers(
    sellerId: string,
    consumers: API.Consumers.UpdateConsumerInBulk.Request['consumers']
  ): Promise<API.Consumers.UpdateConsumerInBulk.Response> {
    return this._updateConsumerInBulk({ sellerId }, { consumers });
  }

  /**
   * Archives a `Consumer`, using the `ArchiveConsumer` api endpoint.
   *
   * @param {string} sellerId
   * @param {string} consumerId
   *
   * @return {Promise<API.Consumers.ArchiveConsumer.Response>}
   */
  archiveConsumer(
    sellerId: string,
    consumerId: string
  ): Promise<API.Consumers.ArchiveConsumer.Response> {
    return this._archiveConsumer({ sellerId, consumerId });
  }

  /**
   * Archives `Consumer`s, using the `ArchiveConsumersInBulk` api endpoint.
   *
   * @param {string} sellerId
   * @param {API.Consumers.ArchiveConsumersInBulk.Request} consumers
   *
   * @return {Promise<API.Consumers.ArchiveConsumersInBulk.Response>}
   *
   * @throws {ValidationException<API.Consumers.ArchiveConsumersInBulk.KeyOfRequest>} when the request fails validation
   */
  bulkArchiveConsumers(
    sellerId: string,
    consumers: string[]
  ): Promise<API.Consumers.ArchiveConsumersInBulk.Response> {
    return this._archiveConsumersInBulk({ sellerId }, { consumers });
  }

  // endregion
  // region engagements
  /**
   * Lists all the `Engagements`, using the `ListEngagementsOfPersona` api endpoint.
   *
   * @param {string} personaId
   *
   * @return {Promise<API.Engagements.ListEngagementsOfPersona.Response>}
   */
  listEngagements(
    personaId: string
  ): Promise<API.Engagements.ListEngagementsOfPersona.Response> {
    return this._listEngagementsOfPersona({ personaId });
  }

  /**
   * Gets the information on a single `Engagement`, using the `ShowSingleEngagement` api endpoint.
   *
   * @param {string} personaId
   * @param {string} engagementId
   *
   * @return {Promise<API.Engagements.ShowSingleEngagement.Response>}
   */
  getEngagement(
    personaId: string,
    engagementId: string
  ): Promise<API.Engagements.ShowSingleEngagement.Response> {
    return this._showSingleEngagement({ personaId, engagementId });
  }

  /**
   * Creates a new `Engagement`, using the `AddEngagement` api endpoint.
   *
   * @param {string} personaId
   * @param {API.Engagements.AddEngagement.Request} newEngagement
   *
   * @return {Promise<API.Engagements.AddEngagement.Response>}
   *
   * @throws {ValidationException<API.Engagements.AddEngagement.KeyOfRequest>} when the request fails validation
   */
  createEngagement(
    personaId: string,
    newEngagement: API.Engagements.AddEngagement.Request
  ): Promise<API.Engagements.AddEngagement.Response> {
    return this._addEngagement({ personaId }, newEngagement);
  }

  /**
   * Creates a new `Engagement`, using the `AddEngagementInBulk` api endpoint.
   *
   * @param {string} personaId
   * @param {API.Engagements.AddEngagementInBulk.Request} newEngagement
   *
   * @return {Promise<API.Engagements.AddEngagementInBulk.Response>}
   *
   * @throws {ValidationException<API.Engagements.AddEngagementInBulk.KeyOfRequest>} when the request fails validation
   */
  bulkCreateEngagement(
    personaId: string,
    newEngagement: API.Engagements.AddEngagementInBulk.Request
  ): Promise<API.Engagements.AddEngagementInBulk.Response> {
    return this._addEngagementInBulk({ personaId }, newEngagement);
  }

  /**
   * Updated the details of a `Engagement`.
   *
   * @param {string} personaId
   * @param {string} engagementId
   * @param {API.Engagements.UpdateEngagement.Request} updatedProperties
   *
   * @return {Promise<API.Engagements.UpdateEngagement.Response>}
   *
   * @throws {ValidationException<API.Engagements.UpdateEngagement.KeyOfRequest>} when the request fails validation
   */
  updateEngagement(
    personaId: string,
    engagementId: string,
    updatedProperties: API.Engagements.UpdateEngagement.Request
  ): Promise<API.Engagements.UpdateEngagement.Response> {
    return this._updateEngagement(
      { personaId, engagementId },
      updatedProperties
    );
  }

  /**
   * Deletes a `Engagement`, using the `DeleteEngagement` api endpoint.
   *
   * @param {string} personaId
   * @param {string} engagementId
   *
   * @return {Promise<API.Engagements.DeleteEngagement.Response>}
   */
  deleteEngagement(
    personaId: string,
    engagementId: string
  ): Promise<API.Engagements.DeleteEngagement.Response> {
    return this._deleteEngagement({ personaId, engagementId });
  }

  /**
   * Deletes a bunch of `Engagement`s, using the `DeleteEngagementInBulk` api endpoint.
   *
   * @param {string} sellerId
   * @param {API.Engagements.DeleteEngagementInBulk.Request['engagementIds']} engagementIds
   *
   * @return {Promise<API.Engagements.DeleteEngagementInBulk.Response>}
   *
   * @throws {ValidationException<API.Engagements.DeleteEngagementInBulk.KeyOfRequest>} when the request fails validation
   */
  bulkDeleteEngagement(
    sellerId: string,
    engagementIds: API.Engagements.DeleteEngagementInBulk.Request['engagementIds']
  ): Promise<API.Engagements.DeleteEngagementInBulk.Response> {
    return this._deleteEngagementInBulk({ sellerId }, { engagementIds });
  }

  /**
   * Sends a `Engagement`, using the `SendEngagement` api endpoint.
   *
   * @param {string} personaId
   * @param {string} engagementId
   *
   * @return {Promise<API.Engagements.SendEngagement.Response>}
   */
  sendEngagement(
    personaId: string,
    engagementId: string
  ): Promise<API.Engagements.SendEngagement.Response> {
    return this._sendEngagement({ personaId, engagementId });
  }

  /**
   * Sends a bunch of `Engagement`s, using the `SendEngagementInBulk` api endpoint.
   *
   * @param {string} sellerId
   * @param {API.Engagements.SendEngagementInBulk.Request['engagementIds']} engagementIds
   *
   * @return {Promise<API.Engagements.SendEngagement.Response>}
   */
  bulkSendEngagement(
    sellerId: string,
    engagementIds: API.Engagements.SendEngagementInBulk.Request['engagementIds']
  ): Promise<API.Engagements.SendEngagementInBulk.Response> {
    return this._sendEngagementInBulk({ sellerId }, { engagementIds });
  }

  // endregion
  // region steps
  /**
   * Lists all the `Steps`, using the `ListStepsOfPersona` api endpoint.
   *
   * @param {string} personaId
   *
   * @return {Promise<API.Steps.ListStepsOfPersona.Response>}
   */
  listSteps(personaId: string): Promise<API.Steps.ListStepsOfPersona.Response> {
    return this._listStepsOfPersona({ personaId });
  }

  /**
   * Gets the information on a single `Step`, using the `ShowSingleStep` api endpoint.
   *
   * @param {string} personaId
   * @param {string} stepId
   *
   * @return {Promise<API.Steps.ShowSingleStep.Response>}
   */
  getStep(
    personaId: string,
    stepId: string
  ): Promise<API.Steps.ShowSingleStep.Response> {
    return this._showSingleStep({ personaId, stepId });
  }

  /**
   * Creates a new `Step`, using the `AddStep` api endpoint.
   *
   * @param {string} personaId
   * @param {API.Steps.AddStep.Request} newStep
   *
   * @return {Promise<API.Steps.AddStep.Response>}
   *
   * @throws {ValidationException<API.Steps.AddStep.KeyOfRequest>} when the request fails validation
   */
  createStep(
    personaId: string,
    newStep: API.Steps.AddStep.Request
  ): Promise<API.Steps.AddStep.Response> {
    return this._addStep({ personaId }, newStep);
  }

  /**
   * Updated the details of a `Step`.
   *
   * @param {string} personaId
   * @param {string} stepId
   * @param {API.Steps.UpdateStep.Request} updatedProperties
   *
   * @return {Promise<API.Steps.UpdateStep.Response>}
   *
   * @throws {ValidationException<API.Steps.UpdateStep.KeyOfRequest>} when the request fails validation
   */
  updateStep(
    personaId: string,
    stepId: string,
    updatedProperties: API.Steps.UpdateStep.Request
  ): Promise<API.Steps.UpdateStep.Response> {
    return this._updateStep({ personaId, stepId }, updatedProperties);
  }

  /**
   * Deletes a `Step`, using the `DeleteStep` api endpoint.
   *
   * @param {string} personaId
   * @param {string} stepId
   *
   * @return {Promise<API.Steps.DeleteStep.Response>}
   */
  deleteStep(
    personaId: string,
    stepId: string
  ): Promise<API.Steps.DeleteStep.Response> {
    return this._deleteStep({ personaId, stepId });
  }

  // endregion
  // region files
  /**
   * Creates a new `File`, using the `UploadFile` api endpoint.
   *
   * @param {File} file
   *
   * @return {Promise<API.Files.UploadFile.Response>}
   */
  uploadFile(file: File): Promise<API.Files.UploadFile.Response> {
    return this._uploadFile({}, { file });
  }

  // endregion
  // region teams
  /**
   * Gets the information on a single `Team`, using the `ShowSingleTeam` api endpoint.
   *
   * @param {string} teamId
   *
   * @return {Promise<API.Teams.ShowSingleTeam.Response>}
   */
  getTeam(teamId: string): Promise<API.Teams.ShowSingleTeam.Response> {
    return this._showTeam({ teamId });
  }

  // endregion
  // endregion

  /**
   * Makes a `GET` request to the given `url`,
   * returning the returned content as a `Blob`.
   *
   * @param {string} url
   *
   * @return {Promise<Blob>}
   */
  getUrlContentsAsBlob(url: string): Promise<Blob> {
    return this._apiRequest
      .get(url, { responseType: 'blob' })
      .then(response => response.data);
  }
}

export default new RelmApi(
  String(process.env.API_URL),
  String(process.env.AUTH_URL),
  new AuthTokenManager(String(process.env.AUTH_TOKEN_KEY))
);
