import { API } from 'src/definitions';
import Schema from 'validate';

export default new Schema<API.Engagements.SendEngagementInBulk.Request>(
  {
    engagementIds: {
      required: true,
      type: 'array',
      message: {
        required: 'An array of engagement ids is required'
      }
    }
  },
  { strip: false }
);
