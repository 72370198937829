import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ExpandMore } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import { format } from 'date-fns';
import * as Draft from 'draft-js';
import * as React from 'react';
import { Glue } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import OutboxEditor from 'src/components/editors/OutboxEditor';
import { contentStateFromHtml } from 'src/components/editors/StoryEditor';
import { API } from 'src/definitions';
import { toast } from 'src/services/Toaster';

const storyEditorImageCssClass = 'story-editor-image';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    panelDetails: {
      'margin': [
        `0px`,
        `${theme.spacing(3)}px`,
        `${theme.spacing(2)}px`,
        `${theme.spacing(3)}px`
      ].join(' '),
      'padding': `${theme.spacing(2)}px`,
      'border': '1px solid rgba(0, 0, 0, 0.25)',
      'cursor': 'not-allowed',
      [`& *`]: {
        cursor: 'not-allowed'
      },
      '& figure': {
        marginRight: 0,
        marginLeft: 0
      },
      [`& .${storyEditorImageCssClass}`]: {
        cursor: 'not-allowed',
        boxShadow: 'none !important',
        margin: 0
      }
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  engagement: API.Entities.Engagement;
  expanded: boolean;
  disabled?: boolean;

  onChange: (event: EngagementExpansionPanelEvent) => void;
}

export interface EngagementExpansionPanelEvent {
  engagement: API.Entities.Engagement;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {
  editorState: Draft.EditorState;
}

// endregion

/**
 *
 */
class EngagementExpansionPanel extends React.Component<Props, State> {
  static readonly defaultProps = {
    disabled: false
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = EngagementExpansionPanel.name;

  readonly state: State = {
    editorState: Draft.EditorState.createWithContent(
      contentStateFromHtml(this.props.engagement.body)
    )
  };

  // region autobound methods
  @autobind
  handleExpansionChange() {
    this.props.onChange({ engagement: this.props.engagement });
  }

  @autobind
  handleBodyEditorChange(editorState: Draft.EditorState) {
    this.setState({ editorState });
  }

  @autobind
  handleExpansionPanelDetailsClick(event: React.SyntheticEvent<HTMLElement>) {
    event.preventDefault();
    event.stopPropagation();
    toast(
      SnackbarVariant.INFO,
      `Clicking is forbidden to avoid false positive in the statistics`
    );
  }

  // endregion
  // region render & get-render-content methods
  render() {
    console.log(this.props.engagement.deletedAt);

    return (
      <ExpansionPanel
        expanded={this.props.expanded}
        disabled={this.props.disabled}
        onChange={this.handleExpansionChange}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle2">
            {this.props.engagement.title}
          </Typography>
          <Glue />
          <Typography>
            {this.props.engagement.deletedAt === null
              ? 'Pending'
              : format(
                  new Date(`${this.props.engagement.deletedAt}.0Z`),
                  "EEEE, do MMMM, yyyy 'at' pp"
                )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={this.props.classes.panelDetails}
          onClick={this.handleExpansionPanelDetailsClick}
        >
          <OutboxEditor
            editorState={this.state.editorState}
            readOnly
            onChange={this.handleBodyEditorChange}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  // endregion
}

export default withStyles(styles, { name: EngagementExpansionPanel.jssName })(
  EngagementExpansionPanel
);
