import { ListItem, ListItemText } from '@material-ui/core';
import autobind from 'autobind-decorator';
import * as React from 'react';

// region component props
interface ExternalProps<ClickData extends object> {
  label: string;

  disabled?: boolean;
  divider?: boolean;

  clickData: ClickData;

  onClick: (event: ClickData) => void;
}

type InternalProps<ClickData extends object> = Required<
  ExternalProps<ClickData>
>;

type Props<ClickData extends object> = InternalProps<ClickData>;

interface State {}

// endregion

/**
 *
 */
class ListItemButton<TClickData extends object> extends React.Component<
  Props<TClickData>,
  State
> {
  static readonly defaultProps = {
    disabled: false,
    divider: false
  };

  readonly state: State = {};

  // region autobound methods
  @autobind
  handleListItemClick() {
    this.props.onClick(this.props.clickData);
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <ListItem
        button
        disabled={this.props.disabled}
        divider={this.props.divider}
      >
        <ListItemText onClick={this.handleListItemClick}>
          {this.props.label}
        </ListItemText>
      </ListItem>
    );
  }

  // endregion
}

export default ListItemButton;
