import GenericException from 'src/exceptions/GenericException';

export interface ValidationResult {
  message: string;
  path: string;
}

export type ValidationResultsMap<TKeys extends PropertyKey = string> = {
  [K in TKeys]: string;
};

/**
 * Exception that represents when the validation of an Object against
 * a Schema results in errors being thrown.
 *
 * This Exception maps the errors to a Map, so that they can be
 * accessed & retrieved at ease.
 *
 * This is an Exception, as it's expected to be caught & used to inform
 * the user to adjust the values they're giving.
 *
 * @template PropertyKeys
 */
class ValidationException<
  TPropertyKeys extends PropertyKey = string
> extends GenericException {
  /**
   * @type {ValidationResultsMap}
   * @private
   */
  private readonly _validationResultsMap: ValidationResultsMap<TPropertyKeys>;

  /**
   * Assets if there are any validation errors, by throwing a `ValidationException`.
   *
   * @param {Array<Error & ValidationResult>} validationErrors
   *
   * @throws {ValidationException} when there are validation errors.
   */
  static assert(validationErrors: ValidationResult[]) {
    if (!validationErrors.length) {
      return;
    }

    const validationResultsMap: ValidationResultsMap = {};

    validationErrors.forEach(
      error => (validationResultsMap[error.path] = error.message)
    );

    throw new ValidationException<keyof ValidationResultsMap>(
      validationResultsMap
    );
  }

  /**
   * Assets that the given exception is an instance of `ValidationException`.
   *
   * If it's not, it's re-thrown.
   *
   * @param {Error} possibleValidationException
   *
   * @return {ValidationException}
   * @static
   */
  static throwIfNotOneOfUs<TPropertyKeys extends PropertyKey = string>(
    possibleValidationException: Error
  ): ValidationException<TPropertyKeys> {
    if (!(possibleValidationException instanceof ValidationException)) {
      throw possibleValidationException;
    }

    return possibleValidationException;
  }

  /**
   *
   * @param {ValidationResultsMap} validationResultsMap
   */
  constructor(validationResultsMap: ValidationResultsMap<TPropertyKeys>) {
    super(`failed validation`); // message isn't expected to be used

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationException);
    }

    this._validationResultsMap = Object.assign({}, validationResultsMap);
  }

  // region getters & setters
  /**
   *
   * @return {ValidationResultsMap}
   */
  get validationResultsMap() {
    return this._validationResultsMap;
  }

  // endregion

  /**
   * Gets the validation message that was provided for the given property path.
   *
   * If the property didn't fail validation, then no message would be present.
   *
   * @param {PropertyKeys|string} propertyPath
   *
   * @return {string}
   */
  messageFor(propertyPath: TPropertyKeys): string {
    return this.validationResultsMap[propertyPath];
  }
}

export default ValidationException;
