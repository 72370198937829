import { API } from 'src/definitions';
import Schema from 'validate';

export default new Schema<API.Engagements.AddEngagement.Request>(
  {
    title: {
      required: true,
      type: 'string',
      message: {
        required: 'A title is required'
      }
    },
    body: {
      required: true,
      type: 'string',
      message: {
        required: 'A body is required'
      }
    },
    consumerId: {
      required: true,
      type: 'string',
      message: {
        required: 'A consumer id is required'
      }
    }
  },
  { strip: false }
);
