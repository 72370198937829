import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

/**
 * Builds a custom icon, with the given `content` wrapped in an `SvgIcon`.
 *
 * @param {React.ReactElement<any>} content
 * @param {string} [viewBox]
 *
 * @return {React.ComponentType<SvgIconProps>}
 */
const buildCustomIcon = (
  content: React.ReactElement,
  viewBox?: string
): React.ComponentType<SvgIconProps> => {
  return class CustomIcon extends React.Component<SvgIconProps> {
    static readonly defaultProps = {};

    // region render & get-render-content methods
    render() {
      return (
        <SvgIcon viewBox={viewBox} {...this.props}>
          {content}
        </SvgIcon>
      );
    }

    // endregion
  };
};

export const LightbulbOutlineIcon = buildCustomIcon(
  <path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z" />
);

export const LightbulbFullIcon = buildCustomIcon(
  <path d="m9,21c0,0.55 0.45,1 1,1l4,0c0.55,0 1,-0.45 1,-1l0,-1l-6,0l0,1zm3,-19c-3.86,0 -7,3.14 -7,7c0,2.38 1.19,4.47 3,5.74l0,2.26c0,0.55 0.45,1 1,1l6,0c0.55,0 1,-0.45 1,-1l0,-2.26c1.81,-1.27 3,-3.36 3,-5.74c0,-3.86 -3.14,-7 -7,-7z" />
);

export const CopyToClipboardIcon = buildCustomIcon(
  <path d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z" />,
  '0 0 448 512'
);

export const RelmIcon = buildCustomIcon(
  <>
    <defs>
      <linearGradient
        id="a"
        x1="129.777"
        y1="130.86"
        x2="27.897"
        y2="280.998"
        gradientTransform="rotate(6.84 -345.37 1870.578)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#eeefef" />
        <stop offset="1" stopColor="#9c9ea1" />
      </linearGradient>
    </defs>
    <g data-name="Layer 2">
      <path
        d="M149.66 308.107c2.64.315 78.609 10.766 78.609 10.766l181.95-82.215-156.303-70.136z"
        fill="url(#a)"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fill="#fff"
        d="M300.423 63.645L150.213 0 .123 63.795 0 63.742v177.936l150.164 64.797 150.177-64.891V63.678l.082-.033z"
      />
      <path
        d="M150.316 128.262l66.539-28.71c21.508-9.28 36.385-8.57 45.832-.429 8.242 6.625 12.465 18.55 12.465 36.115v.51c0 27.236-11.46 50.254-28.95 67.217l33.573 47.627-45.031 19.426-25.24-43.028-3.506 1.467-13.58 5.906v53.965l-42.102 18.117zm64.728 57.6c13.268-5.723 20.909-17.167 20.909-30.147v-.508c0-14.002-8.043-17.66-21.11-12.023l-22.425 9.722v42.764zM15.24 203.854l8.936 3.988a12.168 12.168 0 0 1 7.372 11.344v.068c0 3.94-1.704 5.652-4.189 5.693l4.774 10.844-5.026-2.244-4.187-9.676-.056-.025-3.323-1.483v7.807l-4.3-1.92zm8.656 15.714c2.095.934 3.295.077 3.295-1.98v-.068a5.315 5.315 0 0 0-3.378-4.995l-4.272-1.908v7.006zM38.303 214.15l14.742 6.582v4.776l-10.47-4.676v4.95l9.214 4.113v4.775l-9.215-4.115v5.125l10.611 4.736v4.775l-14.882-6.646zM60.048 223.86l4.3 1.92v19.515l9.745 4.352v4.878l-14.045-6.271zM79.783 232.67l4.636 2.07 5.138 12.61 5.137-8.024 4.635 2.07v24.397l-4.272-1.908v-15.926l-5.5 7.965-.113-.05L84 243.126v15.822l-4.217-1.883z"
        fill="#4253af"
      />
    </g>
  </>,
  '0 0 410.218 318.873'
);
