import * as H from 'history';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as React from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';

export type WithUrlParametersAsPropsProps<
  TParams extends { [K in keyof TParams]?: string } = {},
  C extends StaticContext = StaticContext,
  S = H.LocationState
> = RouteComponentProps<TParams, C, S> &
  {
    [K in keyof TParams]: string;
  };

/**
 * Decorator that passes parameters from `match.params` as props.
 */
const withUrlParametersAsProps = <P extends {}>() => <
  C extends React.ComponentClass<P>
>(
  Component: C
): C => {
  class WithUrlParameters extends React.Component<RouteComponentProps<P>> {
    render() {
      const TypedComponent = Component as React.ComponentClass<P>;

      return <TypedComponent {...this.props} {...this.props.match.params} />;
    }
  }

  hoistNonReactStatics(WithUrlParameters, Component);

  return (WithUrlParameters as unknown) as C;
};

export default withUrlParametersAsProps;
