import {
  Button,
  LinearProgress,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import cx from 'classnames';
import * as React from 'react';

// region component styles
const styles = createStyles({
  root: {
    position: 'relative'
  },
  progress: {
    width: '75%',
    position: 'absolute'
  },
  hidden: {
    opacity: 0
  },
  visible: {}
});

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  loading?: boolean;
  LinearProgressProps?: LinearProgressProps;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & ButtonProps & WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class LoadingButton extends React.Component<Props, State> {
  static readonly defaultProps = {
    loading: false,
    LinearProgressProps: {}
  };

  readonly state: State = {};

  // region render & get-render-content methods
  render() {
    const {
      classes,
      disabled,
      children,
      loading,
      LinearProgressProps,
      ...rest
    } = this.props;

    return (
      <div className={classes.root}>
        <Button disabled={loading || disabled} {...rest}>
          {loading && (
            <LinearProgress
              className={classes.progress}
              {...LinearProgressProps}
            />
          )}
          <div className={cx(loading && classes.hidden)}>{children}</div>
        </Button>
      </div>
    );
  }

  // endregion
}

export default withStyles(styles, { name: LoadingButton.name })(LoadingButton);
