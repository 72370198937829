import {
  AppBar,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ChevronLeft, SortByAlpha } from '@material-ui/icons';
import * as React from 'react';
import { Glue } from 'src/components';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    toolbar: {
      marginLeft: `${theme.spacing(3)}px`,
      marginRight: `${theme.spacing(3)}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  onClose?: () => void;
  onFilterOptionsClick?: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class PersonasDrawerTopBar extends React.Component<Props, State> {
  static readonly defaultProps = {
    onClose: undefined,
    onFilterOptionsClick: undefined
  };

  readonly state: State = {};

  // region render & get-render-content methods
  render() {
    return (
      <AppBar position="static" className={this.props.classes.root}>
        <Toolbar disableGutters className={this.props.classes.toolbar}>
          <IconButton
            aria-label="Close Persona List"
            color="inherit"
            onClick={this.props.onClose}
          >
            <ChevronLeft />
          </IconButton>
          <Glue />
          <Typography color="inherit">Personas List</Typography>
          <Glue />
          <IconButton
            aria-label="Clear filter"
            color="inherit"
            onClick={this.props.onFilterOptionsClick}
          >
            <SortByAlpha />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }

  // endregion
}

export default withStyles(styles)(PersonasDrawerTopBar);
