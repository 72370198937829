import { WithStyles, createStyles, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import CurrentPersonaProvider from 'src/components/CurrentPersonaProvider';
import PersonaTopbar from 'src/components/PersonaTopbar';
import withUrlParametersAsProps, {
  WithUrlParametersAsPropsProps
} from 'src/services/withUrlParametersAsProps';
import ConsumersBoard from 'src/views/Persona/ConsumersBoard';
import Outbox from 'src/views/Persona/Outbox';
import StoriesBoard from 'src/views/Persona/StoriesBoard';

// region component styles
const styles = createStyles({
  root: {
    margin: '10px',
    textAlign: 'center'
  }
});

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  WithStyles<typeof styles> &
  WithUrlParametersAsPropsProps<{ personaId: string }>;

interface State {}

// endregion

@withUrlParametersAsProps<Props>()
class Persona extends React.Component<Props, State> {
  static readonly defaultProps = {};

  readonly state: State = {};

  render() {
    const { pathname } = this.props.location;

    return (
      <CurrentPersonaProvider personaId={this.props.match.params.personaId}>
        {['/engage', '/outbox'].reduce(
          (acc: boolean, str) => (pathname.endsWith(str) ? true : acc),
          false
        ) || <PersonaTopbar />}
        <Switch>
          <Route
            path={`${this.props.match.path}/consumers`}
            component={ConsumersBoard}
          />
          <Route
            path={`${this.props.match.path}/stories`}
            component={StoriesBoard}
          />
          <Route path={`${this.props.match.path}/outbox`} component={Outbox} />

          <Redirect to={`${this.props.match.path}/consumers`} />
        </Switch>
      </CurrentPersonaProvider>
    );
  }
}

export default withStyles(styles)(Persona);
