import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import cx from 'classnames';
import * as React from 'react';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'absolute',
      zIndex: 200,
      opacity: 0,
      transition: 'all 400ms',
      backgroundColor: theme.palette.background.paper,
      left: 0,
      right: 0,
      top: 0,
      bottom: `${theme.spacing()}px`,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      textAlign: 'center',
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    icon: {
      '& svg': {
        fontSize: theme.typography.fontSize * 5,
        marginTop: `${theme.spacing(2)}px`,
        opacity: 0.5,
        color: theme.palette.grey.A200
      }
    },
    show: {
      opacity: 1,
      display: 'flex'
    }
  }),
  { name: 'StatisticsNoDataWarning' }
);

interface Props {
  warningMessage: string;
  show: boolean;
  icon?: React.ReactElement;
}

export const StatisticsNoDataWarning = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Paper
      elevation={0}
      className={cx(classes.root, props.show ? classes.show : '')}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="subtitle2">{props.warningMessage}</Typography>
        {props.icon && <div className={classes.icon}>{props.icon}</div>}
      </Grid>
    </Paper>
  );
};
