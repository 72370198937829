import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputAdornment,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Add, Clear } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import * as React from 'react';
import { ChromePicker, ColorState } from 'react-color';
import Dropzone from 'react-dropzone';
import stylesHelp from 'src/assets/images/styles-help.png';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import SellerStylesDrawerTopBar from 'src/components/SellerStylesDrawerTopBar';
import SignatureEditor from 'src/components/editors/SignatureEditor';
import {
  contentStateFromHtml,
  contentStateToHtml
} from 'src/components/editors/StoryEditor';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import UpdateSellerStylesRequest from 'src/schemas/UpdateSellerStylesRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import { assertIsDefined, fonts } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: '20%'
    },
    innerRoot: {
      padding: `${theme.spacing(2)}px`
    },
    divider: {
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`
    },
    styleFieldRow: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: `${theme.spacing()}px 0`
    },
    styleFieldLabel: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 0 60%',
      justifyContent: 'space-between',
      paddingRight: `${theme.spacing()}px`
    },
    styleFieldInput: {
      flex: '1 0 40%'
    },
    signatureEditorContainer: {
      padding: `${theme.spacing(2)}px`,
      clear: 'both',
      overflowX: 'hidden',
      overflowY: 'auto',
      boxShadow: '0px 0px 1px inset black'
    },
    swatch: {
      display: 'block',
      width: `${theme.spacing(4)}px`
    },
    swatchInner: {
      boxShadow: '0px 0px 1px inset black',
      display: 'block',
      paddingTop: '100%',
      width: '100%'
    },
    swatchLabel: {
      position: 'absolute',
      textIndent: '-9999px'
    },
    imageLabel: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 30%',
      paddingRight: `${theme.spacing()}px`
    },
    imageWrapper: {
      flex: '0 1 auto',
      position: 'relative'
    },
    imagePreview: {
      display: 'block',
      height: 'auto',
      maxHeight: `${theme.spacing(32)}px`,
      maxWidth: '100%'
    },
    imageClearButton: {
      position: 'absolute',
      bottom: `-${theme.spacing()}px`,
      right: `-${theme.spacing()}px`,
      transition: 'opacity .25s ease'
    },
    imageUpload: {
      flex: '0 0 70%'
    },
    imageHelperText: {
      flex: '1 0 100%'
    },
    buttonUpdateAnalytics: {
      marginLeft: '5px'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  open: boolean;

  onClose?: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  WithDataFromApiProps<
    'sellerStyles',
    API.Sellers.ShowSingleSellerStyles.Response
  > &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<API.Sellers.UpdateSellerStyles.Request> {
  editorState: Draft.EditorState;
  selectedHostingMethod: HostingMethod;
  pickerAnchorEl: API.Nullable<HTMLElement>;
  imageLogoFile: API.Nullable<File>;
  faviconFile: API.Nullable<File>;
  stylesHelpOpen: boolean;
  analyticsSettingsOpen: boolean;
}

interface StyleFields {
  name: keyof State['inputValues'];
  label: string;
}

// endregion

enum HostingMethod {
  RELM = 'RELM',
  SELF = 'SELF'
}

/**
 *
 */
@withCurrentSeller<Props>()
@withDataFromApi<Props>(
  props => RelmApi.getSellerStyles(props.currentSeller.id),
  'sellerStyles'
)
class SellerStylesDrawer extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    sellerStyles: undefined,
    stories: undefined,

    onCurrentSellerChange: () => {},
    loadFromApi: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = SellerStylesDrawer.name;

  private readonly _validator = new ValidationTrait(
    this,
    UpdateSellerStylesRequest,
    () => ({
      hostingUrl:
        this.state.selectedHostingMethod === HostingMethod.RELM
          ? null
          : this.state.inputValues.hostingUrl
    })
  );

  readonly state: State = {
    pickerAnchorEl: null,
    selectedHostingMethod: this.props.sellerStyles.hostingUrl
      ? HostingMethod.SELF
      : HostingMethod.RELM,
    editorState: Draft.EditorState.createEmpty(),
    inputValues: {
      title: this.props.sellerStyles.title ?? '',
      subtitle: this.props.sellerStyles.subtitle ?? '',
      hostingUrl: this.props.sellerStyles.hostingUrl ?? '',
      imageLogo: this.props.sellerStyles.imageLogo ?? '',
      favicon: this.props.sellerStyles.favicon ?? '',
      fontContent: this.props.sellerStyles.fontContent ?? '',
      fontTitle: this.props.sellerStyles.fontTitle ?? '',
      colorContentPrimary: this.props.sellerStyles.colorContentPrimary ?? '',
      colorContentTonic: this.props.sellerStyles.colorContentTonic ?? '',
      colorContentOver: this.props.sellerStyles.colorContentOver ?? '',
      colorBodyBackground: this.props.sellerStyles.colorBodyBackground ?? '',
      colorContentBackground:
        this.props.sellerStyles.colorContentBackground ?? '',
      colorTopbarBackground:
        this.props.sellerStyles.colorTopbarBackground ?? '',
      colorFooterBackground:
        this.props.sellerStyles.colorFooterBackground ?? '',
      piAld: this.props.sellerStyles.piAld ?? '',
      piCld: this.props.sellerStyles.piCld ?? '',
      signatureEnabled: this.props.sellerStyles.signatureEnabled ?? false,
      signature: this.props.sellerStyles.signature ?? ''
    },
    inputErrors: {
      title: '',
      subtitle: '',
      hostingUrl: '',
      imageLogo: '',
      favicon: '',
      fontContent: '',
      fontTitle: '',
      colorContentPrimary: '',
      colorContentTonic: '',
      colorContentOver: '',
      colorBodyBackground: '',
      colorContentBackground: '',
      colorTopbarBackground: '',
      colorFooterBackground: '',
      piAld: '',
      piCld: '',
      signatureEnabled: '',
      signature: ''
    },
    imageLogoFile: null,
    faviconFile: null,
    stylesHelpOpen: false,
    analyticsSettingsOpen: false
  };

  /**
   * Attempts to upload a logo image file from the state.
   *
   * If the `imageLogoFile` is `null`, or an error occurs while uploading the file,
   * `null` is returned. Otherwise, the `url` of the file is returned.
   *
   * @return {Promise<API.Nullable<API.Entities.File['url']>>}
   */
  async tryUploadImageLogoFromState(): Promise<
    API.Nullable<API.Entities.File['url']>
  > {
    if (!this.state.imageLogoFile) {
      return null;
    }

    try {
      return (await RelmApi.uploadFile(this.state.imageLogoFile)).data.url;
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to upload your logo.'
      );

      return null;
    }
  }

  /**
   * Attempts to upload a favicon image file from the state.
   *
   * If the `faviconFile` is `null`, or an error occurs while uploading the file,
   * `null` is returned. Otherwise, the `url` of the file is returned.
   *
   * @return {Promise<API.Nullable<API.Entities.File['url']>>}
   */
  async tryUploadFaviconFromState(): Promise<
    API.Nullable<API.Entities.File['url']>
  > {
    if (!this.state.faviconFile) {
      return null;
    }

    try {
      return (await RelmApi.uploadFile(this.state.faviconFile)).data.url;
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to upload your favicon.'
      );

      return null;
    }
  }

  async tryUpdateSellerStyles() {
    assertIsDefined(this.props.currentSeller);
    const imageLogo =
      (await this.tryUploadImageLogoFromState()) ??
      this.state.inputValues.imageLogo;
    const favicon =
      (await this.tryUploadFaviconFromState()) ??
      this.state.inputValues.favicon;

    try {
      await RelmApi.updateSellerStyles(this.props.currentSeller.id, {
        ...this.state.inputValues,
        imageLogo,
        favicon
      });
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to update styles'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);

      return;
    }

    toast(SnackbarVariant.SUCCESS, 'Successfully updated styles');

    this.props.onCurrentSellerChange();
  }

  // region autobound methods
  @autobind
  handleHostingChange(
    event: React.ChangeEvent<{}>,
    value: HostingMethod | string
  ) {
    this.setState({ selectedHostingMethod: value as HostingMethod }, () =>
      this._validator.tryValidatePropertyAgainstValue(
        'hostingUrl',
        this.state.inputValues.hostingUrl
      )
    );
  }

  @autobind
  handleInsertSignatureChange(event: React.ChangeEvent<{}>, value: boolean) {
    this.setState(
      prevState => ({
        inputValues: {
          ...prevState.inputValues,
          signatureEnabled: value
        }
      }),
      () =>
        this._validator.tryValidatePropertyAgainstValue(
          'signatureEnabled',
          value
        )
    );
  }

  @autobind
  handleColorChangeComplete(color: ColorState) {
    const { stateKey } = this.state.pickerAnchorEl?.dataset ?? {};

    if (stateKey) {
      this.setState(prevState => ({
        inputValues: {
          ...prevState.inputValues,
          [`${stateKey}`]: color.hex
        }
      }));
    }
  }

  /**
   * Opens the color picker.
   *
   * @param {React.SyntheticEvent<HTMLElement>} event
   */
  @autobind
  handleSwatchClick(event: React.SyntheticEvent<HTMLElement>) {
    this.setState({ pickerAnchorEl: event.currentTarget });
  }

  /**
   * Closes the color picker.
   */
  @autobind
  handlePickerClose() {
    this.setState({ pickerAnchorEl: null });
  }

  /**
   *
   * @param {React.ChangeEvent<{ name?: string; value: unknown }>} event
   */
  @autobind
  handleFontChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const { name, value } = event.target;

    assertIsDefined(name);

    this.setState(prevState => ({
      inputValues: { ...prevState.inputValues, [`${name}`]: value }
    }));
  }

  /**
   * Clear the imageLogo
   */
  @autobind
  handleClearImageLogoClick() {
    this.setState(prevState => ({
      inputValues: {
        ...prevState.inputValues,
        imageLogo: null
      },
      imageLogoFile: null
    }));
  }

  /**
   * Clear the favicon
   */
  @autobind
  handleClearFaviconClick() {
    this.setState(prevState => ({
      inputValues: {
        ...prevState.inputValues,
        favicon: null
      },
      faviconFile: null
    }));
  }

  /**
   * Handles when a file is dropped onto the `imageLogo` dropzone.
   *
   * @param {Array<File>} accepted
   */
  @autobind
  handleImageLogoFileDrop(accepted: File[]) {
    this.setState({ imageLogoFile: accepted[0] });
  }

  /**
   * Handles when a file is dropped onto the `favicon` dropzone.
   *
   * @param {Array<File>} accepted
   */
  @autobind
  handleFaviconFileDrop(accepted: File[]) {
    this.setState({ faviconFile: accepted[0] });
  }

  @autobind
  handleUpdateStylesButtonClick() {
    load(this.tryUpdateSellerStyles());
  }

  @autobind
  handleOpenStylesHelp() {
    this.setState({ stylesHelpOpen: true });
  }

  @autobind
  handleCloseStylesHelp() {
    this.setState({ stylesHelpOpen: false });
  }

  @autobind
  handleOpenAnalyticsSettingsButtonClick() {
    this.setState({ analyticsSettingsOpen: true });
  }

  @autobind
  handleCloseAnalyticsSettings() {
    this.setState({ analyticsSettingsOpen: false });
  }

  @autobind
  handleSignatureEditorChange(editorState: Draft.EditorState) {
    this.setState(prevState => ({
      editorState,
      inputValues: {
        ...prevState.inputValues,
        signature: contentStateToHtml(editorState.getCurrentContent())
      }
    }));
  }

  // endregion
  // region render & get-render-content methods
  getPageStyleFieldRenderContent(field: StyleFields) {
    const classes = this.props.classes;
    const swatchColor =
      (this.state.inputValues[field.name] as string) ?? '#ffffff';
    const pickerAnchorElId = this.state.pickerAnchorEl
      ? this.state.pickerAnchorEl.id
      : '';

    return (
      <div className={classes.styleFieldRow} key={`${field.name}`}>
        <div className={classes.styleFieldLabel}>
          <Typography variant="body1">
            <label htmlFor={field.name}>{field.label}</label>
          </Typography>
          <span
            className={classes.swatch}
            data-state-key={field.name}
            id={`swatch${field.name}`}
            style={{ backgroundColor: swatchColor }}
            onClick={this.handleSwatchClick}
          >
            <span className={classes.swatchInner}>
              <span className={classes.swatchLabel}>{swatchColor}</span>
            </span>
          </span>
        </div>
        <div className={classes.styleFieldInput}>
          <Input
            fullWidth
            id={field.name}
            value={swatchColor}
            inputProps={{
              'data-state-key': field.name
              // onFocus: this.handleSwatchClick
            }}
          />
        </div>
        <Popover
          anchorEl={this.state.pickerAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={
            pickerAnchorElId === `swatch${field.name}` ||
            pickerAnchorElId === field.name
          }
          onClose={this.handlePickerClose}
        >
          <div>
            <ChromePicker
              color={swatchColor}
              disableAlpha
              onChangeComplete={this.handleColorChangeComplete}
            />
          </div>
        </Popover>
      </div>
    );
  }

  getFontStyleRenderContent() {
    const styleFields: StyleFields[] = [
      {
        name: 'fontContent',
        label: 'Content font'
      },
      {
        name: 'fontTitle',
        label: 'Title font'
      }
    ];

    return styleFields.map(field => {
      return (
        <div className={this.props.classes.styleFieldRow} key={`${field.name}`}>
          <div className={this.props.classes.styleFieldLabel}>
            <Typography variant="body1">
              <label htmlFor={field.name}>{field.label}</label>
            </Typography>
          </div>
          <div className={this.props.classes.styleFieldInput}>
            <Select
              fullWidth
              inputProps={{
                id: field.name,
                name: field.name
              }}
              value={this.state.inputValues[field.name] ?? fonts[0]}
              onChange={this.handleFontChange}
            >
              {fonts.map((font: string) => (
                <MenuItem key={font} value={font}>
                  {font}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      );
    });
  }

  getPageStyleRenderContent() {
    const styleFields: StyleFields[] = [
      {
        name: 'colorContentPrimary',
        label: 'Font colour'
      },
      {
        name: 'colorContentTonic',
        label: 'Tonic colour'
      },
      {
        name: 'colorContentOver',
        label: 'Over colour'
      },
      {
        name: 'colorBodyBackground',
        label: 'Body background colour'
      },
      {
        name: 'colorContentBackground',
        label: 'Content background colour'
      },
      {
        name: 'colorTopbarBackground',
        label: 'Topbar background colour'
      },
      {
        name: 'colorFooterBackground',
        label: 'Footer background colour'
      }
    ];

    return styleFields.map(field => this.getPageStyleFieldRenderContent(field));
  }

  /**
   * Gets the render content for the `ImageLogoUrl` section of this component.
   *
   * @return {any}
   */
  getImageLogoRenderContent() {
    const classes = this.props.classes;
    const url =
      this.state.inputValues.imageLogo ||
      (this.state.imageLogoFile &&
        URL.createObjectURL(this.state.imageLogoFile)) ||
      undefined;

    if (url) {
      return (
        <div className={classes.imageWrapper}>
          <img className={classes.imagePreview} alt="Preview" src={url} />
          <Fab
            aria-label="Remove logo"
            className={classes.imageClearButton}
            color="primary"
            size="medium"
            onClick={this.handleClearImageLogoClick}
          >
            <Clear />
          </Fab>
        </div>
      );
    }

    return (
      <Dropzone
        accept="image/gif, image/jpeg, image/png"
        onDrop={this.handleImageLogoFileDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={classes.imageUpload} {...getRootProps()}>
            <input {...getInputProps()} />
            <Input
              fullWidth
              value={
                this.state.imageLogoFile
                  ? this.state.imageLogoFile.name
                  : 'Drag or click here to upload a logo image'
              }
              endAdornment={
                <InputAdornment position="end">
                  <Add />
                </InputAdornment>
              }
            />
          </div>
        )}
      </Dropzone>
    );
  }

  /**
   * Gets the render content for the `favicon` section of this component.
   *
   * @return {any}
   */
  getFaviconRenderContent() {
    const classes = this.props.classes;
    const url =
      this.state.inputValues.favicon ||
      (this.state.faviconFile && URL.createObjectURL(this.state.faviconFile)) ||
      undefined;

    if (url) {
      return (
        <div className={classes.imageWrapper}>
          <img className={classes.imagePreview} alt="Preview" src={url} />
          <Fab
            aria-label="Remove favicon"
            className={classes.imageClearButton}
            color="primary"
            size="medium"
            onClick={this.handleClearFaviconClick}
          >
            <Clear />
          </Fab>
        </div>
      );
    }

    return (
      <Dropzone
        accept="image/jpeg, image/png"
        onDrop={this.handleFaviconFileDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={classes.imageUpload} {...getRootProps()}>
            <input {...getInputProps()} />
            <Input
              fullWidth
              value={
                this.state.faviconFile
                  ? this.state.faviconFile.name
                  : 'Drag or click here to upload a favicon'
              }
              endAdornment={
                <InputAdornment position="end">
                  <Add />
                </InputAdornment>
              }
            />
          </div>
        )}
      </Dropzone>
    );
  }

  componentDidMount() {
    this.setState(prevState => ({
      editorState: Draft.EditorState.createWithContent(
        contentStateFromHtml(prevState.inputValues.signature as API.HTML)
      )
    }));
  }

  render() {
    assertIsDefined(this.props.currentSeller);
    const classes = this.props.classes;

    return (
      <Drawer
        classes={{ paper: classes.root }}
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <SellerStylesDrawerTopBar
          onClose={this.props.onClose}
          onOpenStylesHelp={this.handleOpenStylesHelp}
        />
        <div className={classes.innerRoot}>
          <Typography variant="subtitle2">Hosting</Typography>
          <RadioGroup
            value={this.state.selectedHostingMethod}
            onChange={this.handleHostingChange}
          >
            <FormControlLabel
              value={HostingMethod.RELM}
              control={<Radio />}
              label={`${this.props.currentSeller.id}.relm.online`}
            />
            <FormControlLabel
              value={HostingMethod.SELF}
              control={<Radio />}
              label="self hosted on"
            />
          </RadioGroup>
          {this._validator.buildTextFieldForProperty(
            'hostingUrl',
            'self-hosting url',
            {
              disabled: this.state.selectedHostingMethod === HostingMethod.RELM,
              required: this.state.selectedHostingMethod === HostingMethod.SELF
            }
          )}
          <Divider className={classes.divider} />
          <Typography variant="subtitle2">Titles</Typography>
          {this._validator.buildTextFieldForProperty('title', 'Title')}
          {this._validator.buildTextFieldForProperty('subtitle', 'Subtitle')}
          <Divider className={classes.divider} />
          <Typography variant="subtitle2">Font styling</Typography>
          {this.getFontStyleRenderContent()}
          <Divider className={classes.divider} />
          <Typography variant="subtitle2">Page styling</Typography>
          {this.getPageStyleRenderContent()}
          <Divider className={classes.divider} />
          <Typography variant="subtitle2">Images</Typography>
          <FormControl
            className={classes.styleFieldRow}
            error={!!this.state.inputErrors.imageLogo}
            fullWidth
          >
            <Typography className={classes.imageLabel} variant="body1">
              Logo
            </Typography>
            {this.getImageLogoRenderContent()}
            <FormHelperText className={classes.imageHelperText}>
              {this.state.inputErrors.imageLogo
                ? `${this.state.inputErrors.imageLogo}. Try re-adding your logo.`
                : ''}
            </FormHelperText>
          </FormControl>
          <FormControl
            className={classes.styleFieldRow}
            error={!!this.state.inputErrors.favicon}
            fullWidth
          >
            <Typography className={classes.imageLabel} variant="body1">
              Favicon
            </Typography>
            {this.getFaviconRenderContent()}
            <FormHelperText className={classes.imageHelperText}>
              {this.state.inputErrors.favicon
                ? `${this.state.inputErrors.favicon}. Try re-adding your favicon.`
                : ''}
            </FormHelperText>
          </FormControl>
          <Divider className={classes.divider} />
          <Typography variant="subtitle2">Signature</Typography>
          <FormControlLabel
            checked={this.state.inputValues.signatureEnabled}
            value="true"
            onChange={this.handleInsertSignatureChange}
            control={<Checkbox />}
            label="Enable outreach signature"
          />
          <SignatureEditor
            className={classes.signatureEditorContainer}
            editorState={this.state.editorState}
            onChange={this.handleSignatureEditorChange}
            onCtrlEnter={this.handleUpdateStylesButtonClick}
          />
          <Divider className={classes.divider} />
          <Button
            variant="outlined"
            onClick={this.handleUpdateStylesButtonClick}
          >
            Update styles
          </Button>
          <Button
            className={classes.buttonUpdateAnalytics}
            variant="outlined"
            onClick={this.handleOpenAnalyticsSettingsButtonClick}
          >
            Update Analytics
          </Button>
        </div>
        <Dialog
          aria-labelledby="styles-analytics-title"
          open={this.state.analyticsSettingsOpen}
          onClose={this.handleCloseAnalyticsSettings}
        >
          <DialogTitle id="styles-analytics-title">Analytics</DialogTitle>
          <DialogContent>
            {this._validator.buildTextFieldForProperty('piAld', 'piAld')}
            {this._validator.buildTextFieldForProperty('piCld', 'piCld')}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={this.handleCloseAnalyticsSettings}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="styles-help-title"
          open={this.state.stylesHelpOpen}
          onClose={this.handleCloseStylesHelp}
        >
          <DialogTitle id="styles-help-title">Styles help</DialogTitle>
          <DialogContent>
            <img alt="Styles Help" src={stylesHelp} />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.handleCloseStylesHelp}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    );
  }

  // endregion
}

export default withStyles(styles, { name: SellerStylesDrawer.jssName })(
  SellerStylesDrawer
);
