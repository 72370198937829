import {
  CardHeader,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  TextField,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormButton, FormDialog } from 'src/components';
import { API } from 'src/definitions';
import { assertIsString } from 'src/utilities';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: `${theme.breakpoints.values.sm / 2}px`
      }
    },
    typographyTitle: {
      textAlign: 'center'
    },
    dialogHeader: {
      paddingTop: '10px',
      paddingBottom: '0'
    },
    dialogContent: {
      padding: `${theme.spacing(2)}px`
    }
  });

interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  steps: API.Entities.Step[];

  /**
   * Controls the open state of the `Dialog`.
   */
  open: boolean;

  /**
   * Called when the `Dialog` is closed.
   *
   * @param {string?} [stepId] The id of the step to move to, if any.
   */
  onClose: (stepId?: string) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {
  selectedStepId: string;
}

/**
 *
 */
class SelectStepDialog extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = SelectStepDialog.name;

  readonly state: State = {
    selectedStepId: ''
  };

  @autobind
  handleMoveToStepClick() {
    this.props.onClose(this.state.selectedStepId || undefined);
  }

  @autobind
  handleCloseClick() {
    this.props.onClose();
  }

  @autobind
  handleStepChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    assertIsString(event.target.value);

    this.setState({ selectedStepId: event.target.value });
  }

  render() {
    return (
      <FormDialog
        classes={{ paper: this.props.classes.paper }}
        open={this.props.open}
        onClose={this.handleCloseClick}
      >
        <CardHeader
          className={this.props.classes.dialogHeader}
          action={
            <IconButton onClick={this.handleCloseClick}>
              <Close />
            </IconButton>
          }
          titleTypographyProps={{
            className: this.props.classes.typographyTitle
          }}
          title="Select Step"
        />
        <DialogContent className={this.props.classes.dialogContent}>
          <TextField
            margin="dense"
            value={this.state.selectedStepId}
            fullWidth
            select
            onChange={this.handleStepChange}
          >
            {this.props.steps.map(step => (
              <MenuItem key={step.id} value={step.id}>
                {step.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <FormButton
            variant="outlined"
            fullWidth
            onClick={this.handleMoveToStepClick}
          >
            Move Consumers
          </FormButton>
        </DialogActions>
      </FormDialog>
    );
  }
}

export default withStyles(styles, { name: SelectStepDialog.jssName })(
  SelectStepDialog
);
