import color from 'color';
import { API } from 'src/definitions';
import { assertIsDefined } from 'src/utilities';
import * as WebFont from 'webfontloader';

// todo: clean up types to be more accurate, so we can drop the asserts
export const createLandingPageStyles = ({
  fontTitle,
  fontContent,
  colorBodyBackground,
  colorContentPrimary,
  colorContentBackground
}: Pick<
  API.Entities.Seller.Styles,
  | 'fontTitle'
  | 'fontContent'
  | 'colorBodyBackground'
  | 'colorContentPrimary'
  | 'colorContentBackground'
>) => {
  assertIsDefined(fontTitle);
  assertIsDefined(fontContent);

  WebFont.load({
    google: {
      families: [fontTitle, fontContent]
    }
  });

  assertIsDefined(colorBodyBackground);
  assertIsDefined(colorContentPrimary);
  assertIsDefined(colorContentBackground);

  return {
    storyHeader: {
      boxShadow: `0 5px 10px -5px rgba(${color(
        colorContentPrimary
      ).rgb()},.15)`,
      zIndex: 1
    },
    storyBg: {
      background: `linear-gradient(to bottom, ${colorBodyBackground}, ${color(
        colorBodyBackground
      ).darken(0.3)})`
    },
    storyContainer: {
      backgroundColor: colorContentBackground,
      boxShadow: `0 5px 25px rgba(${color(
        colorContentPrimary
      ).rgb()}, .1), 0 1px 3px rgba(${color(colorContentPrimary).rgb()}, .3)`
    },
    storyTitle: {
      color: colorContentPrimary,
      fontFamily: fontTitle
    },
    storySummary: {
      color: colorContentPrimary,
      fontFamily: fontContent
    }
  };
};
