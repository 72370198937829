import autobind from 'autobind-decorator';
import debug from 'debug';
import * as React from 'react';
import AppSnackbar, { SnackbarVariant } from 'src/components/AppSnackbar';
import Toaster, { ToastShowEvent, ToasterEvent } from 'src/services/Toaster';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  variant: SnackbarVariant;
  messages: string[];
}

// endregion

/**
 *
 */
class Toastbar extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   *
   * @type {debug.IDebugger}
   * @private
   */
  private readonly _logger = debug(this.constructor.name);

  readonly state: State = {
    variant: SnackbarVariant.INFO,
    messages: []
  };

  // region component lifecycle methods
  /**
   * @inheritDoc
   */
  componentDidMount() {
    Toaster.on(ToasterEvent.E_TOAST_SHOW, this.handleToastShow);
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    Toaster.removeListener(ToasterEvent.E_TOAST_SHOW, this.handleToastShow);
  }

  // endregion
  // region autobound methods
  /**
   * Handles when a new toast is shown.
   *
   * @param {Toaster#event:E_TOAST_SHOW} event
   */
  @autobind
  handleToastShow(event: ToastShowEvent) {
    this._logger('toast happened', event);

    this.setState({
      variant: event.variant,
      messages: event.messages
    });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <AppSnackbar
        variant={this.state.variant}
        messages={this.state.messages}
      />
    );
  }

  // endregion
}

export default Toastbar;
