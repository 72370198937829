import { API } from 'src/definitions';
import { groupObjectsByStringProperty } from 'src/utilities';

/**
 * Groups the given `Consumer`s based on their `stepId`,
 * using `defaultStepId` if they don't belong to a step.
 *
 * @param consumers
 * @param {string} defaultStepId
 *
 * @return {Record<string, Array<API.Entities.Consumer>>}
 */
export const groupConsumersByStep = (
  consumers: API.Entities.Consumer[],
  defaultStepId: string
) => groupObjectsByStringProperty(consumers, 'stepId', defaultStepId);
