import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useTheme
} from '@material-ui/core';
import * as React from 'react';
import LoadingButton from 'src/components/LoadingButton';

const useStyles = makeStyles(
  theme => ({
    root: {},
    dialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    }
  }),
  { name: 'AreYouSureDialog' }
);

interface Props {
  className?: string;

  open: boolean;
  loading?: boolean;

  actionText: string;

  onCancel: () => void;
  onAction: () => void;
}

export const AreYouSureDialog = (props: React.PropsWithChildren<Props>) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Dialog
      className={props.className}
      open={props.open}
      aria-labelledby={`${AreYouSureDialog.name}-title`}
      aria-describedby={`${AreYouSureDialog.name}-description`}
      onClose={props.loading ? undefined : props.onCancel}
    >
      <DialogTitle id={`${AreYouSureDialog.name}-title`}>
        Confirm Action
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`${AreYouSureDialog.name}-description`}>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button disabled={props.loading} onClick={props.onCancel}>
          Cancel
        </Button>
        <LoadingButton
          loading={props.loading}
          color="secondary"
          variant="outlined"
          autoFocus
          onClick={props.onAction}
        >
          {props.actionText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
