import { EventEmitter } from 'events';
// eslint-disable-next-line import/order
import { SnackbarVariant } from 'src/components/AppSnackbar';

/** @type {debug.IDebugger} */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logger = require('debug')('toaster');

export enum ToasterEvent {
  /**
   * Event that represents when a new toast is to be shown.
   *
   * @type {'e:toast.show'}
   */
  E_TOAST_SHOW = 'e:toast.show'
}

export interface ToastShowEvent {
  variant: SnackbarVariant;
  messages: string[];
}

export declare interface Toaster extends EventEmitter {
  on(
    event: ToasterEvent.E_TOAST_SHOW,
    listener: (event: ToastShowEvent) => void
  ): this;

  once(
    event: ToasterEvent.E_TOAST_SHOW,
    listener: (event: ToastShowEvent) => void
  ): this;

  prependListener(
    event: ToasterEvent.E_TOAST_SHOW,
    listener: (event: ToastShowEvent) => void
  ): this;

  prependOnceListener(
    event: ToasterEvent.E_TOAST_SHOW,
    listener: (event: ToastShowEvent) => void
  ): this;

  addListener(
    event: ToasterEvent.E_TOAST_SHOW,
    listener: (event: ToastShowEvent) => void
  ): this;

  removeListener(
    event: ToasterEvent.E_TOAST_SHOW,
    listener: (event: ToastShowEvent) => void
  ): this;

  removeAllListeners(event: ToasterEvent): this;

  emit(event: ToasterEvent.E_TOAST_SHOW, eventObj: ToastShowEvent): boolean;
}

/**
 *
 * @type {module:events.internal.EventEmitter}
 */
export const Toaster: Toaster = new EventEmitter();
// region event emitting
/**
 * Emits an event signaling that a new toast is to be shown.
 *
 * This event includes the variant of the toast, and it's message.
 *
 * @param {SnackbarVariant} variant
 * @param {Array<string>} messages
 *
 * @fires Toaster#E_TOAST_SHOW
 * @private
 */
const _emitToast = (variant: SnackbarVariant, messages: string[]): void => {
  logger(`${_emitToast.name}: emitting "${ToasterEvent.E_TOAST_SHOW}" event`, {
    variant,
    messages
  });
  /**
   * @event Toaster#E_TOAST_SHOW
   * @type {Object}
   *
   * @property {SnackbarVariant} E_TOAST_SHOW:variant
   * @property {Array<string>} E_TOAST_SHOW:messages
   */
  Toaster.emit(ToasterEvent.E_TOAST_SHOW, { variant, messages });
};

// endregion

/**
 * Displays a toast.
 *
 * @param {SnackbarVariant} variant
 * @param {...string} messages
 */
export const toast = (variant: SnackbarVariant, ...messages: string[]) =>
  _emitToast(variant, messages);

export default Toaster;
