import hoistNonReactStatics from 'hoist-non-react-statics';
import * as React from 'react';
import { API } from 'src/definitions';

export interface CurrentPersonaValue {
  currentPersona: API.Nullable<API.Entities.Persona>;
  onCurrentPersonaChange: () => void;
}

export const withCurrentPersonaDefault: CurrentPersonaValue = {
  currentPersona: null,
  onCurrentPersonaChange: () => {}
};

const CurrentPersonaContext = React.createContext<CurrentPersonaValue>(
  withCurrentPersonaDefault
);

/**
 *
 * @param {boolean} [renderIfNull=false]
 */
export const withCurrentPersona = <P extends CurrentPersonaValue>(
  renderIfNull = false
) => <C extends React.ComponentClass<P>>(Component: C): C => {
  class WithCurrentPersona extends React.Component<P & { innerRef?: any }> {
    render() {
      const TypedComponent = Component as React.ComponentClass<P>;

      return (
        <CurrentPersonaContext.Consumer>
          {(contextValue: CurrentPersonaValue) =>
            (contextValue.currentPersona || renderIfNull) && (
              <TypedComponent
                ref={this.props.innerRef}
                {...this.props}
                currentPersona={contextValue.currentPersona}
                onCurrentPersonaChange={contextValue.onCurrentPersonaChange}
              />
            )
          }
        </CurrentPersonaContext.Consumer>
      );
    }
  }

  hoistNonReactStatics(WithCurrentPersona, Component);

  return (WithCurrentPersona as unknown) as C;
};

export default CurrentPersonaContext;

// region code archive
/*
    Decorators + React + TypeScript are a touch weird, due to all parties having "issues".

    Below is a collection of code that is "working", just in case things blow up later down the line.

    The worst case is that decorators can be scrapped in favor fo just using HOC.
 */
// region working with statics
// export const withCurrentSeller = <C extends React.ComponentClass<any>>(Component: C): C => {
//     class WithCurrentSeller extends React.Component<{ innerRef?: any }> {
//         static readonly defaultProps = {};
//
//         render() {
//             const { innerRef, ...props } = this.props;
//
//             return (
//                 <CurrentSellerContext.Consumer>
//                     {
//                         // @ts-ignore
//                         (contextValue: CurrentSellerValue) => <Component
//                             ref={innerRef}
//
//                             currentSeller={contextValue.currentSeller}
//                             onCurrentSellerChange={contextValue.onCurrentSellerChange}
//
//                             {...props}
//                         />
//                     }
//                 </CurrentSellerContext.Consumer>
//             );
//         }
//     }
//
//     hoistNonReactStatics(WithCurrentSeller, Component);
//
//     return WithCurrentSeller as C;
// };
// endregion
// region working without statics
// export const withCurrentSeller = <C extends React.ComponentClass<any>>(Component: C): C => {
//     return ((props: CurrentSellerValue) => {
//             const { innerRef, ...adjustedProps } = props as { innerRef?: any };
//
//             return (
//                 <CurrentSellerContext.Consumer>
//                     {
//                         // @ts-ignore
//                         (contextValue: CurrentSellerValue) => <Component
//                             ref={innerRef}
//
//                             currentSeller={contextValue.currentSeller}
//                             onCurrentSellerChange={contextValue.onCurrentSellerChange}
//
//
//                             {...props}
//                         />
//                     }
//                 </CurrentSellerContext.Consumer>
//             );
//         }
//     ) as any as C;
// };
//
// export default CurrentSellerContext;
// endregion
// region original (working as HOC)
// export const withCurrentSeller = <P extends CurrentSellerValue>() => (Component: React.ComponentType<P & React.ClassAttributes<any>>) => {
//     class WithCurrentSeller extends React.Component<P> {
//         static readonly defaultProps = {};
//
//         render() { // generic spread bug: https://github.com/Microsoft/TypeScript/issues/10727
//             const { innerRef, ...props } = this.props as { innerRef?: any };
//
//             return (
//                 <CurrentSellerContext.Consumer>
//                     {
//                         (contextValue: CurrentSellerValue) => <Component
//                             ref={innerRef}
//
//                             currentSeller={contextValue.currentSeller}
//                             onCurrentSellerChange={contextValue.onCurrentSellerChange}
//
//                             {...props}
//                         />
//                     }
//                 </CurrentSellerContext.Consumer>
//             );
//         }
//     }
//
//     hoistNonReactStatics(WithCurrentSeller, Component);
//
//     return WithCurrentSeller;
// };
// endregion
// endregion
