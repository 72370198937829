import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { VideoLibrary } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import { AddVideo } from 'draft-js-video-plugin';
import * as React from 'react';
import regexps from 'src/schemas/regexps';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      minWidth: '400px'
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  addVideo: AddVideo;
  editorState: Draft.EditorState;
  onChange: Draft.EditorProps['onChange'];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  DraftJsButtons.ButtonProps &
  WithStyles<typeof styles>;

interface State {
  videoLink: string;
  videoError: string;
  isEmbedVideoDialogOpen: boolean;
}

// endregion

/**
 *
 */
class AddVideoButton extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _videoUrlInputRef: React.RefObject<
    HTMLInputElement
  > = React.createRef<HTMLInputElement>();

  readonly state: State = {
    videoLink: '',
    videoError: '',
    isEmbedVideoDialogOpen: false
  };

  validateVideoLink(videoLink: string): string {
    return videoLink.match(regexps.videoLink)
      ? ''
      : 'Must be a valid youtube or vimeo link';
  }

  // region autobound methods
  @autobind
  handleEmbedVideoClick() {
    this.setState({ isEmbedVideoDialogOpen: true });
  }

  @autobind
  handleEmbedVideoDialogClose() {
    this.setState({ isEmbedVideoDialogOpen: false });
  }

  @autobind
  handleEmbedVideoLinkClick() {
    const videoError = this.validateVideoLink(this.state.videoLink);

    if (this.state.videoLink && videoError === '') {
      this.props.onChange(
        this.props.addVideo(this.props.getEditorState(), {
          src: this.state.videoLink
        })
      );

      this.setState({
        isEmbedVideoDialogOpen: false,
        videoError: '',
        videoLink: ''
      });
    }
  }

  @autobind
  handleVideoLinkChange(event: React.ChangeEvent<HTMLInputElement>) {
    const videoLink = event.target.value;
    const videoError = this.validateVideoLink(videoLink);

    this.setState({ videoLink, videoError });
  }

  /**
   * Handles when the `Input` element is clicked.
   *
   * For some weird reason, we need to manually grab focus
   * when the input is clicked?
   */
  @autobind
  handleVideoLinkInputClick() {
    this._videoUrlInputRef.current && this._videoUrlInputRef.current.focus();
  }

  // endregion
  // region render & get-render-content methods
  render() {
    // console.log(this.props);
    return (
      <>
        <div className={this.props.theme.buttonWrapper}>
          <button
            className={this.props.theme.button}
            type="button"
            onClick={this.handleEmbedVideoClick}
          >
            <VideoLibrary />
          </button>
        </div>
        <Dialog
          open={this.state.isEmbedVideoDialogOpen}
          aria-labelledby={`${this.constructor.name}-dialog-title`}
          onClose={this.handleEmbedVideoDialogClose}
        >
          <DialogTitle id={`${this.constructor.name}-dialog-title`}>
            Embed YouTube or Vimeo Video
          </DialogTitle>
          <DialogContent className={this.props.classes.dialogContent}>
            <TextField
              inputRef={this._videoUrlInputRef}
              label="Video link"
              value={this.state.videoLink}
              helperText={this.state.videoError}
              error={this.state.videoError !== ''}
              margin="dense"
              fullWidth
              required
              onChange={this.handleVideoLinkChange}
              onClick={this.handleVideoLinkInputClick}
            />
          </DialogContent>
          <DialogActions className={this.props.classes.dialogActions}>
            <Button onClick={this.handleEmbedVideoDialogClose}>Cancel</Button>
            <Button
              color="primary"
              variant="outlined"
              autoFocus
              disabled={
                this.state.videoError !== '' || this.state.videoLink === ''
              }
              onClick={this.handleEmbedVideoLinkClick}
            >
              Embed
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(AddVideoButton);

/*
var YOUTUBEMATCH_URL = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
var VIMEOMATCH_URL = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/; // eslint-disable-line no-useless-escape
 */
