import {
  Card,
  Grid,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
  withWidth
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import { HotTub } from '@material-ui/icons';
import * as React from 'react';
import { StatisticsNoDataWarning, StatisticsSimpleCard } from 'src/components';
import StatisticsFeedback from 'src/components/StatisticsFeedback';
import StatisticsHistory from 'src/components/StatisticsHistory';
import StatisticsStoriesTimeSpent from 'src/components/StatisticsStoriesTimeSpent';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import { fromTimeDisplay } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    card: {
      position: 'relative',
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%',
      minHeight: '450px'
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    },
    historyPan: {
      paddingTop: `${theme.spacing()}px`,
      paddingBottom: `${theme.spacing()}px`,
      height: 'auto'
    },
    historyPanTable: {
      flexGrow: 1,
      flex: '1 0 0',
      height: 'auto',
      [theme.breakpoints.up('sm')]: {
        overflow: 'auto',
        minHeight: '450px'
      }
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
  consumer: API.Entities.Consumer;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentStatisticsValue &
  WithWidth &
  WithStyles<typeof styles>;

interface State {
  consumerStats: API.Nullable<API.Entities.Statistics.ConsumerWithStats>;
  numberOfStoriesRead: number;
  storyTimeSpentBySession: API.Entities.Statistics.StoryTimeSpentBySession[];
  sessionIdList: API.Entities.Statistics.ConsumerSession[];
  history: API.Entities.Statistics.ConsumerEvent[];
}

// endregion

@withCurrentStatistics<Props>(true)
class ConsumerStatisticsPanel extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {}
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = ConsumerStatisticsPanel.name;

  readonly state: State = {
    consumerStats: null,
    numberOfStoriesRead: 0,
    storyTimeSpentBySession: [],
    sessionIdList: [],
    history: []
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.currentStatistics !== this.props.currentStatistics) {
      this.sync();
    }
  }

  componentDidMount() {
    this.sync();
  }

  sync(): void {
    if (
      !this.props.currentStatistics?.consumersById ||
      !(this.props.consumer.id in this.props.currentStatistics.consumersById)
    ) {
      return;
    }

    const [consumerStats] = this.props.currentStatistics.consumersById[
      this.props.consumer.id
    ];

    this.setState({
      consumerStats,
      numberOfStoriesRead: StatsApi.consumerPanelNumberOfStoriesRead(
        consumerStats
      ),
      storyTimeSpentBySession: StatsApi.consumerTimeSpentBySessions(
        consumerStats
      ),
      sessionIdList: StatsApi.getSessionIdList(consumerStats),
      history: StatsApi.getChronologicalConsumerHistory(consumerStats)
    });
  }

  render() {
    if (!this.state.consumerStats?.firstSeen) {
      return (
        <div className={this.props.classes.root}>
          <StatisticsNoDataWarning
            show
            warningMessage="This consumer has not visited the website."
            icon={<HotTub />}
          />
        </div>
      );
    }

    return (
      <div className={this.props.classes.root}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h5">Activity</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatisticsSimpleCard
                  value="First Seen"
                  description={fromTimeDisplay(
                    this.state.consumerStats.firstSeen
                  )}
                />
              </Grid>
              {this.state.consumerStats.lastSeen && (
                <Grid item xs={12} sm={6}>
                  <StatisticsSimpleCard
                    value="Last Seen"
                    description={fromTimeDisplay(
                      this.state.consumerStats.lastSeen
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <StatisticsSimpleCard
                  value={this.state.consumerStats.visits}
                  description={
                    this.state.consumerStats.visits > 1 ? 'Visits' : 'Visit'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatisticsSimpleCard
                  value={this.state.consumerStats.timeSpentDisplay}
                  description="Spent on the website"
                />
              </Grid>
              <Grid item xs={12}>
                <StatisticsSimpleCard
                  value={this.state.numberOfStoriesRead}
                  description={
                    this.state.numberOfStoriesRead > 1
                      ? 'Stories read'
                      : 'Story read'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} lg={8}>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h5">
                  {isWidthDown('sm', this.props.width)
                    ? 'Time spent on stories'
                    : 'Time spent on each story by visits'}
                </Typography>
              </Grid>
              <Grid item>
                <Card className={this.props.classes.root}>
                  <StatisticsStoriesTimeSpent
                    sessionIdList={this.state.sessionIdList}
                    storiesWithSessions={this.state.storyTimeSpentBySession}
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          spacing={2}
          className={this.props.classes.historyPan}
        >
          <Grid item>
            <Typography variant="h5">History</Typography>
          </Grid>
          <Grid item className={this.props.classes.historyPanTable}>
            <StatisticsHistory history={this.state.history} />
          </Grid>
        </Grid>
        <StatisticsFeedback />
      </div>
    );
  }
}

export default withWidth()(
  withStyles(styles, { name: ConsumerStatisticsPanel.jssName })(
    ConsumerStatisticsPanel
  )
);
