import {
  CssBaseline,
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CurrentThemeProvider from 'src/components/CurrentThemeProvider';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import StatsApi from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';
import App from 'src/views/App';

const generateClassName = createGenerateClassName({ productionPrefix: 'relm' });

ReactDOM.render(
  <StylesProvider generateClassName={generateClassName}>
    <CurrentThemeProvider>
      <CssBaseline />
      <App />
    </CurrentThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);

declare global {
  interface Window {
    api: typeof RelmApi;
    toast: typeof toast;
    load: typeof load;
    statsApi: typeof StatsApi;
  }
}

window.api = RelmApi;
window.toast = toast;
window.load = load;
window.statsApi = StatsApi;
