import { API } from 'src/definitions';
import Schema from 'validate';

export default new Schema<API.Consumers.ArchiveConsumersInBulk.Request>(
  {
    consumers: {
      required: true,
      type: 'array',
      message: {
        required: 'A consumer id is required'
      }
    }
  },
  { strip: false }
);
