import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4LangEn from '@amcharts/amcharts4/lang/en';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap, WithStyles } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { StatisticsNoDataWarning } from 'src/components';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import { secondsToHms } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'relative',
      [`& .amcharts-XYSeries-group.amcharts-ColumnSeries-group,
        & .amcharts-amexport-menu,
        & .amcharts-ZoomOutButton-group`]: {
        cursor: 'default'
      }
    },
    header: {
      textAlign: 'center'
    },
    chart: {
      width: '100%',
      minHeight: '300px',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      margin: '0 0 1em 0',
      overflowX: 'visible',
      overflowY: 'visible'
    },
    wrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    graphWrapper: {
      flexGrow: 1,
      width: '100%',
      minHeight: '200px',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'visible'
    },
    legendWrapper: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      width: '100%',
      height: 'auto',
      maxHeight: '75px',
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    },
    table: {
      display: 'none',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'table'
      }
    },
    tdTitle: {
      padding: `${theme.spacing()}px`,
      width: '80%'
    },
    tdTimeSpent: {
      padding: `${theme.spacing()}px`,
      width: '20%',
      textAlign: 'center'
    },
    title: {
      fontSize: '1rem'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  sessionIdList: API.Entities.Statistics.ConsumerSession[];
  storiesWithSessions: API.Entities.Statistics.StoryTimeSpentBySession[];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {
  showNoDataWarning: boolean;
  consumerTimeSpentByStoryAndSession: API.Entities.Statistics.ConsumerTimeSpentByStoryAndSession[];
}

// region Am4core
am4core.useTheme(am4ThemesAnimated);
am4core.options.autoSetClassName = true;
am4core.options.minPolylineStep = 5;
// endregion Am4core

// endregion

/**
 * Graph view about time spent by a consumer on each stories by session
 */
class StatisticsStoriesTimeSpent extends React.Component<Props, State> {
  static readonly defaultProps = {};
  static readonly jssName: string = StatisticsStoriesTimeSpent.name;

  private _amCharts: API.Nullable<am4charts.XYChart> = null;
  private _amChartsLegendContainer: API.Nullable<am4core.Container> = null;
  private readonly _chartId = 'amCharts-stories-time-spent';
  private readonly _chartLegendId = 'amCharts-stories-time-spent-legend';
  private readonly _chartStyle: API.Entities.Statistics.ChartStyle = {
    tensionX: 0.77,
    tensionY: 1,
    strokeWidth: 1,
    fillOpacity: 0.6,
    sequencedInterpolation: true
  };

  readonly state: State = {
    showNoDataWarning: true,
    consumerTimeSpentByStoryAndSession: []
  };

  private static _setMarker(
    markerTemplate: am4core.Container,
    isBig: boolean
  ): am4core.Container {
    if (isBig) {
      markerTemplate.width = 25;
      markerTemplate.height = 15;

      return markerTemplate;
    }
    markerTemplate.width = 15;
    markerTemplate.height = 10;
    markerTemplate.fillOpacity = 0.8;

    return markerTemplate;
  }

  private static _createScrollbar(): am4core.Scrollbar {
    const scrollbar = new am4core.Scrollbar();

    scrollbar.showOnInit = false;
    scrollbar.hideGrips = true;
    scrollbar.startGrip.disabled = true;
    scrollbar.endGrip.disabled = true;
    scrollbar.showSystemTooltip = false;
    scrollbar.hide();

    return scrollbar;
  }

  /**
   * Draw the column's gradient
   *
   * @returns {}
   * @private
   */
  private static _fillModifier() {
    const fillModifier = new am4core.LinearGradientModifier();

    fillModifier.opacities = [1, 0.7];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 180;

    return fillModifier;
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.storiesWithSessions !== this.props.storiesWithSessions) {
      this.updateStats();
    }
  }

  componentDidMount() {
    this.initGraph();
  }

  componentWillUnmount() {
    this._amCharts && this._amCharts.dispose();
    this._amChartsLegendContainer && this._amChartsLegendContainer.dispose();
  }

  private _createCursor(axis: am4charts.Axis): am4charts.XYCursor {
    const cursor = new am4charts.XYCursor();

    cursor.yAxis = axis;

    cursor.fullWidthLineY = true;
    cursor.lineY.strokeWidth = this._chartStyle.strokeWidth;
    cursor.lineY.strokeDasharray = '';
    cursor.lineY.stroke = am4core.color('#777777');
    cursor.lineY.fill = am4core.color('#cccccc');
    cursor.lineY.fillOpacity = 0.1;

    cursor.lineX.strokeWidth = this._chartStyle.strokeWidth;
    cursor.lineX.strokeDasharray = '';
    cursor.lineX.stroke = am4core.color('#333333');

    return cursor;
  }

  // Create series
  private _createSeries(field: string, name: string, color: am4core.Color) {
    if (this._amCharts === null) {
      return;
    }
    // Series
    const series = this._amCharts.series.push(new am4charts.ColumnSeries());

    series.dataFields.categoryY = 'story';
    series.dataFields.valueX = field;
    series.sequencedInterpolation = true;
    series.name = name;
    series.stacked = true;
    series.tooltipPosition = 'pointer';
    if (series.tooltip) {
      series.tooltip.getStrokeFromObject = true;
      series.tooltip.background.strokeWidth = 2;
      series.tooltip.getFillFromObject = false;
      series.tooltip.pointerOrientation = 'down';
      series.tooltipY = 0;
      series.tooltip.dy = -10;
    }

    const seriesTemplate = series.columns.template;

    seriesTemplate.fill = color;
    seriesTemplate.stroke = color;
    seriesTemplate.fillOpacity = 1;
    seriesTemplate.strokeWidth = this._chartStyle.strokeWidth;
    seriesTemplate.fillModifier = StatisticsStoriesTimeSpent._fillModifier();
    seriesTemplate.tooltipText = '{name}';
    seriesTemplate.adapter.add('tooltipText', (label, target) =>
      target.dataItem && target.dataItem.values
        ? `[font-size:14px][#000]{name}[/]`
        : ''
    );
    const labelBullet = series.bullets.push(new am4charts.LabelBullet());

    labelBullet.label.text = '{valueX}';
    labelBullet.label.fill = am4core.color('#fff');
    labelBullet.locationX = 0.5;
    labelBullet.valign = 'middle';
    labelBullet.label.adapter.add('text', (label, target) =>
      target.dataItem && target.dataItem.values
        ? `[font-size:14px #000]${secondsToHms(
            target.dataItem.values.valueX.workingValue
          )}[/]`
        : ''
    );
  }

  private _createSeriesTotals() {
    if (this._amCharts === null) {
      return;
    }
    const totalSeries = this._amCharts.series.push(
      new am4charts.ColumnSeries()
    );

    totalSeries.dataFields.valueX = 'none';
    totalSeries.dataFields.categoryY = 'story';
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    const totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());

    totalBullet.dx = 10;
    totalBullet.width = 60;
    totalBullet.label.text = '{total.valueX}';
    totalBullet.label.hideOversized = false;
    totalBullet.label.truncate = false;
    totalBullet.label.horizontalCenter = 'left';
    totalBullet.label.adapter.add('text', (label, target) =>
      target.dataItem && target.dataItem.values
        ? `[font-size:14px #000]Total: [bold #000]${secondsToHms(
            target.dataItem.values.valueX.total
          )}[/]`
        : ''
    );

    return totalSeries;
  }

  private _scrollBarAutoToggle(chart: am4charts.XYChart) {
    chart.zoomOutButton.events.on('visibilitychanged', event => {
      chart.scrollbarX[event.visible ? 'show' : 'hide']();
      if (chart.scrollbarY) {
        chart.scrollbarY[event.visible ? 'show' : 'hide']();
      }
    });
  }

  @autobind
  initGraph() {
    this.setState({
      showNoDataWarning: this.props.storiesWithSessions.length === 0,
      consumerTimeSpentByStoryAndSession: StatsApi.consumerPanelTimeSpentChart(
        this.props.storiesWithSessions
      )
    });
    if (this._amCharts !== null) {
      return;
    }
    this._amCharts = am4core.create(this._chartId, am4charts.XYChart);
    this._amCharts.language.locale = am4LangEn;
    this._amCharts.numberFormatter.numberFormat = '#.';
    // this._amCharts.responsive.enabled = true;

    // X Axis
    const timeSpentAxis = this._amCharts.xAxes.push(new am4charts.ValueAxis());

    timeSpentAxis.min = 0;
    timeSpentAxis.paddingRight = 50;
    timeSpentAxis.calculateTotals = true;
    timeSpentAxis.renderer.grid.template.opacity = 0.8;
    timeSpentAxis.renderer.grid.template.location = 0.5;
    timeSpentAxis.renderer.ticks.template.setPropertyValue('length', 10);
    timeSpentAxis.renderer.labels.template.location = 0.5;
    timeSpentAxis.renderer.labels.template.verticalCenter = 'middle';
    timeSpentAxis.renderer.labels.template.horizontalCenter = 'middle';
    timeSpentAxis.renderer.labels.template.adapter.add('text', text =>
      text ? secondsToHms(parseInt(text, 10)) : ''
    );
    timeSpentAxis.cursorTooltipEnabled = true;
    timeSpentAxis.adapter.add('getTooltipText', text =>
      text ? secondsToHms(parseInt(text, 10)) : ''
    );

    // Y Axis
    const storiesAxis = this._amCharts.yAxes.push(new am4charts.CategoryAxis());

    storiesAxis.dataFields.category = 'story';
    storiesAxis.renderer.grid.template.opacity = 0;
    storiesAxis.renderer.cellStartLocation = 0.01;
    storiesAxis.renderer.cellEndLocation = 0.99;
    storiesAxis.renderer.minGridDistance = 30;
    storiesAxis.cursorTooltipEnabled = false;

    // Y Axis Label
    const storiesAxisLabel = storiesAxis.renderer.labels.template;

    storiesAxisLabel.dx = -10;
    storiesAxisLabel.hideOversized = true;
    storiesAxisLabel.maxWidth = 300;
    // storiesAxisLabel.wrap = true;
    storiesAxisLabel.truncate = true;
    storiesAxisLabel.horizontalCenter = 'right';

    // Add Scrollbars
    this._amCharts.scrollbarX = StatisticsStoriesTimeSpent._createScrollbar();
    this._amCharts.scrollbarY = StatisticsStoriesTimeSpent._createScrollbar();
    this._scrollBarAutoToggle(this._amCharts);

    // Add Cursor
    this._amCharts.cursor = this._createCursor(storiesAxis);
    this._amCharts.cursor.behavior = 'zoomXY';

    // Add Legend container
    this._amChartsLegendContainer = am4core.create(
      this._chartLegendId,
      am4core.Container
    );
    const legendContainer = this._amChartsLegendContainer;

    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);

    // Add Legend
    this._amCharts.legend = new am4charts.Legend();
    const legend = this._amCharts.legend;

    legend.parent = legendContainer;
    legend.position = 'bottom';

    // this._amCharts.legend.markers.template.disabled = true;
    StatisticsStoriesTimeSpent._setMarker(legend.markers.template, false);

    const resizeLegend = () => {
      const chartLegendContainer = document.getElementById(this._chartLegendId);

      if (chartLegendContainer === null) {
        return;
      }
      setTimeout(
        () => (chartLegendContainer.style.height = `${legend.contentHeight}px`),
        100
      );
    };

    // Charts Events
    this._amCharts.events.on('datavalidated', resizeLegend);
    this._amCharts.events.on('maxsizechanged', resizeLegend);
    this._amCharts.events.on('ready', this.updateStats);
  }

  @autobind
  updateStats() {
    const consumerTimeSpentByStoryAndSession = StatsApi.consumerPanelTimeSpentChart(
      this.props.storiesWithSessions
    );

    this.setState({
      showNoDataWarning: this.props.storiesWithSessions.length === 0,
      consumerTimeSpentByStoryAndSession
    });
    if (!this._amCharts?.isReady()) {
      return;
    }

    const chartContainer = document.getElementById(this._chartId);

    if (chartContainer !== null) {
      chartContainer.style.height = `${
        consumerTimeSpentByStoryAndSession.length * 50 + 30
      }px`;
    }

    this._amCharts.data = consumerTimeSpentByStoryAndSession.map(story => {
      const updatedStory: API.Entities.Statistics.AmChartFormatConsumerTimeSpentByStoryAndSession = {
        story: story.story,
        total: story.total,
        none: 0
      };

      story.sessions
        .filter(session => session.timeSpent !== 0)
        .forEach(session => (updatedStory[session.id] = session.timeSpent));

      return updatedStory;
    });

    this._amCharts.series.clear();
    const colorSet = new am4core.ColorSet();

    this.props.sessionIdList.forEach(session =>
      this._createSeries(session.id, session.label, colorSet.next())
    );
    this._createSeriesTotals();
  }

  mobileRender() {
    const { classes } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tdTitle}>Stories</TableCell>
            <TableCell className={classes.tdTimeSpent}>Time Spent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...this.state.consumerTimeSpentByStoryAndSession]
            .reverse()
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tdTitle}>
                  <Typography variant="subtitle2" className={classes.title}>
                    {row.story}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tdTimeSpent}>
                  <Typography>{row.total}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }

  // region render & get-render-content methods
  render() {
    const { classes } = this.props;

    return (
      <CardContent className={this.props.classes.root}>
        <StatisticsNoDataWarning
          show={this.state.showNoDataWarning}
          warningMessage="No stories have been viewed."
        />
        <div className={classes.wrapper}>
          <div className={classes.graphWrapper}>
            <div
              id={this._chartId}
              className={classes.chart}
              hidden={this.props.storiesWithSessions.length === 0}
            />
          </div>
          <div className={classes.legendWrapper}>
            <div id={this._chartLegendId} />
          </div>
        </div>
        {this.mobileRender()}
      </CardContent>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StatisticsStoriesTimeSpent.jssName })(
  StatisticsStoriesTimeSpent
);
