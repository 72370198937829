import { Avatar, Chip, makeStyles, useTheme } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import cx from 'classnames';
import * as React from 'react';

const useStyles = makeStyles(
  theme => ({
    root: {},
    chip: {
      marginBottom: `${theme.spacing()}px`,
      marginRight: `${theme.spacing()}px`
    },
    achievementDone: {
      backgroundColor: 'green',
      color: 'white'
    },
    achievementMiss: {
      backgroundColor: 'transparent'
    }
  }),
  { name: 'AchievementChip' }
);

interface Props {
  label: string;
  isDone: boolean;
}

export const AchievementChip = ({ label, isDone }: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const reachAchievementClassname = isDone ? classes.achievementDone : '';

  return (
    <Chip
      className={cx(classes.chip, reachAchievementClassname)}
      avatar={
        <Avatar className={reachAchievementClassname}>
          {isDone ? <Done /> : '?'}
        </Avatar>
      }
      label={label}
      variant={isDone ? 'default' : 'outlined'}
    />
  );
};
