import {
  Button,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Delete, Panorama, Videocam } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { API } from 'src/definitions';
import { getYoutubeVideoId } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    mediaWrap: {
      position: 'relative',
      margin: `0 auto`,
      width: '100%',
      maxWidth: '1000px',
      transition: theme.transitions.create('padding')
    },
    mediaWrapImage: {
      paddingTop: '384px'
    },
    mediaWrapVideo: {
      paddingTop: '56.25%'
    },
    mediaWrapSelection: {
      paddingTop: '200px'
    },

    mediaInner: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      maxWidth: '1000px'
    },
    mediaButtons: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.text.disabled}`
    },
    mediaButton: {
      flex: '0 0 auto',
      transition: theme.transitions.create('width'),
      width: '50%',
      borderRadius: 0,
      borderRight: 'none'
    },
    mediaButtonImage: {
      borderLeft: `1px solid ${theme.palette.text.disabled}`
    },
    mediaButtonVideo: {},
    mediaButtonOpen: {
      width: '10%'
    },
    mediaButtonOpenImage: {
      borderLeft: `1px solid ${theme.palette.text.disabled}`
    },
    mediaButtonOpenVideo: {
      borderRight: `1px solid ${theme.palette.text.disabled}`
    },
    mediaContainer: {
      display: 'flex',
      flex: '1 0 auto'
    },
    mediaVideoContainer: {
      alignItems: 'center',
      position: 'relative',
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
      flexFlow: 'column'
    },
    mediaVideoShow: {
      margin: 0,
      padding: 0
    },
    mediaVideoIframe: {
      height: '100%',
      width: '100%',
      border: 'none'
    },
    mediaVideoIcon: {
      color: theme.palette.grey.A100,
      fontSize: `${theme.spacing(5)}px`
    },
    mediaImageDropper: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'space-between',
      margin: 0,
      padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
      flexFlow: 'column'
    },
    mediaImageDropperIcon: {
      color: theme.palette.grey.A100,
      fontSize: `${theme.spacing(5)}px`
    },
    mediaImageDropperActive: {
      backgroundColor: fade(theme.palette.text.disabled, 0.15),
      borderRadius: 0
    },
    mediaImage: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      flex: '1 0 auto',
      position: 'relative'
      // borderRadius: `${theme.shape.borderRadius}px`
    },
    mediaClearButton: {
      position: 'absolute',
      backgroundColor: `${theme.palette.background.paper} !important`,
      bottom: `-${theme.spacing()}px`,
      left: `-${theme.spacing()}px`,
      margin: `${theme.spacing(2)}px`,
      transition: 'opacity .25s ease'
    },
    mediaClearButtonLabel: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    mediaClearButtonIcon: {
      [theme.breakpoints.up('md')]: {
        paddingRight: `${theme.spacing(0.5)}px`
      }
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  imageFile?: API.Nullable<File>;
  imageUrl: API.Nullable<string>;
  videoUrl: API.Nullable<string>;
  videoError: API.Nullable<string>;
  readOnly?: boolean;

  onFileDropped: (accepted: File[]) => void;
  onClearMediaClick: (mediaType: MediaType) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {
  currentMediaType: MediaKey;
  fileDOMString: API.Nullable<string>;
}

enum MediaKey {
  NONE = 'none',
  IMAGE = 'image',
  VIDEO = 'video'
}

export enum MediaType {
  IMAGE = 'imageUrl',
  VIDEO = 'videoUrl'
}

// endregion

/**
 *
 */
class StoryMedia extends React.Component<Props, State> {
  static readonly defaultProps = {
    imageFile: null,
    imageUrl: '',
    videoUrl: '',
    videoError: '',
    readOnly: false
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = StoryMedia.name;

  readonly state: State = {
    currentMediaType: MediaKey.NONE,
    fileDOMString: null
  };

  private _fileDOMString: API.Nullable<string> = null;

  // region component lifecycle methods
  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    // only do something if the image to upload has changed
    if (this.props.imageFile !== prevProps.imageFile) {
      if (this._fileDOMString) {
        URL.revokeObjectURL(this._fileDOMString);
        this._fileDOMString = null;
      }

      if (this.props.imageFile) {
        this._fileDOMString = URL.createObjectURL(this.props.imageFile);
      }

      this.setState({ fileDOMString: this._fileDOMString });
    }
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    if (this._fileDOMString) {
      URL.revokeObjectURL(this._fileDOMString);
    }
  }

  // endregion
  // region autobound methods
  @autobind
  handleImageButtonClick() {
    this.setState({ currentMediaType: MediaKey.IMAGE });
  }

  @autobind
  handleVideoButtonClick() {
    this.setState({ currentMediaType: MediaKey.VIDEO });
  }

  @autobind
  handleClearImageClick() {
    this.props.onClearMediaClick(MediaType.IMAGE);
  }

  @autobind
  handleClearVideoClick() {
    this.props.onClearMediaClick(MediaType.VIDEO);
  }

  // endregion
  // region render & get-render-content methods
  /**
   * Gets the render content for the `ImageUrl` section of this component.
   *
   * @return {any}
   */
  getImageUrlRenderContent() {
    const classes = this.props.classes;
    const url = this.state.fileDOMString ?? this.props.imageUrl ?? undefined;

    if (url) {
      return (
        <div className={classes.mediaContainer}>
          <div
            className={classes.mediaImage}
            style={{ backgroundImage: `url(${url})` }}
          >
            {!this.props.readOnly && (
              <Button
                aria-label="Remove the cover"
                className={classes.mediaClearButton}
                variant="outlined"
                size="small"
                color="primary"
                onClick={this.handleClearImageClick}
              >
                <Delete className={classes.mediaClearButtonIcon} />
                <span className={classes.mediaClearButtonLabel}>
                  Remove the cover
                </span>
              </Button>
            )}
          </div>
        </div>
      );
    }

    return this.getImageUploadUI();
  }

  /**
   * Get Image upload UI
   *
   * @return {any}
   */
  getImageUploadUI() {
    const classes = this.props.classes;

    return (
      <div className={classes.mediaContainer}>
        <Dropzone
          accept="image/gif, image/jpeg, image/png"
          onDrop={this.props.onFileDropped}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className={cx(
                classes.mediaImageDropper,
                isDragActive && classes.mediaImageDropperActive
              )}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Typography variant="subtitle1">
                Drag or click here to upload an image as a cover
              </Typography>
              <Typography variant="caption" align="center">
                Image Size: 1000x384px or more,
                <br />
                Maximum File Size: 2MB
              </Typography>
              <Panorama className={classes.mediaImageDropperIcon} />
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  /**
   * Gets the render content for the `VideoUrl` section of this component.
   *
   * @return {any}
   */
  getVideoUrlRenderContent() {
    const classes = this.props.classes;

    if (this.props.videoUrl && !this.props.videoError) {
      const videoId = getYoutubeVideoId(this.props.videoUrl);

      if (videoId) {
        return (
          <div
            className={cx(
              classes.mediaContainer,
              classes.mediaVideoContainer,
              classes.mediaVideoShow
            )}
          >
            <iframe
              title="Video Frame"
              className={this.props.classes.mediaVideoIframe}
              src={`https://www.youtube.com/embed/${videoId}?controls=0&modestbranding=1`}
            />
            {!this.props.readOnly && (
              <Button
                aria-label="Remove the cover"
                className={classes.mediaClearButton}
                variant="outlined"
                size="small"
                color="primary"
                onClick={this.handleClearVideoClick}
              >
                <Delete className={classes.mediaClearButtonIcon} />
                <span className={classes.mediaClearButtonLabel}>
                  Remove the cover
                </span>
              </Button>
            )}
          </div>
        );
      }
    }

    return this.getVideoURLUI();
  }

  /**
   * Get Video URL UI
   *
   * @return {any}
   */
  getVideoURLUI() {
    const classes = this.props.classes;

    return (
      <div className={cx(classes.mediaContainer, classes.mediaVideoContainer)}>
        <Typography variant="subtitle1">
          Use a Youtube video as a cover
        </Typography>
        <Typography variant="caption" align="center">
          Video Ratio: 16/9
        </Typography>
        {this.props.children}
        <Videocam className={classes.mediaVideoIcon} />
      </div>
    );
  }

  render() {
    const classes = this.props.classes;

    if (
      this.props.readOnly &&
      this.state.currentMediaType !== MediaKey.VIDEO &&
      this.state.currentMediaType !== MediaKey.IMAGE &&
      !this.props.imageFile &&
      !this.props.imageUrl &&
      !this.props.videoUrl
    ) {
      return null;
    }

    const isCurrentMediaTypeNone =
      this.state.currentMediaType === MediaKey.NONE;

    const showImage = !!(this.state.fileDOMString ?? this.props.imageUrl);

    const showVideo =
      !!getYoutubeVideoId(this.props.videoUrl ?? '') &&
      this.state.currentMediaType !== MediaKey.IMAGE;

    const hideMedia = !(showImage || showVideo);

    const showImageUploadUi =
      !this.props.readOnly &&
      !showImage &&
      this.state.currentMediaType === MediaKey.IMAGE;

    const showVideoUploadUi =
      !this.props.readOnly &&
      !showVideo &&
      this.state.currentMediaType === MediaKey.VIDEO;

    const switchToImageButton =
      !this.props.readOnly &&
      (isCurrentMediaTypeNone || showVideoUploadUi) &&
      hideMedia;

    const switchToVideoButton =
      !this.props.readOnly &&
      (isCurrentMediaTypeNone || showImageUploadUi) &&
      hideMedia;

    return (
      <>
        <div
          className={cx({
            [classes.mediaWrapImage]: showImage,
            [classes.mediaWrapVideo]: showVideo,
            [classes.mediaWrap]: true,
            [classes.mediaWrapSelection]: hideMedia
          })}
        >
          <div className={classes.mediaInner}>
            <div className={classes.mediaButtons}>
              {switchToVideoButton && (
                <Button
                  className={cx(
                    classes.mediaButton,
                    classes.mediaButtonVideo,
                    switchToImageButton || [
                      classes.mediaButtonOpenVideo,
                      classes.mediaButtonOpen
                    ]
                  )}
                  onClick={this.handleVideoButtonClick}
                >
                  <Videocam fontSize="large" />
                </Button>
              )}
              {(showVideo || showVideoUploadUi) &&
                this.getVideoUrlRenderContent()}
              {(showImage || showImageUploadUi) &&
                this.getImageUrlRenderContent()}
              {switchToImageButton && (
                <Button
                  className={cx(
                    classes.mediaButton,
                    classes.mediaButtonImage,
                    switchToVideoButton || [
                      classes.mediaButtonOpenImage,
                      classes.mediaButtonOpen
                    ]
                  )}
                  onClick={this.handleImageButtonClick}
                >
                  <Panorama fontSize="large" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StoryMedia.jssName })(StoryMedia);
