import {
  Checkbox,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Warning } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as React from 'react';
import { API } from 'src/definitions';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0
    },
    consumerCard: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(5),
      boxShadow: 'none'
    },
    consumerContent: {
      paddingRight: 0
    },
    consumerSecondaryAction: {
      right: 0
    },
    consumerCheckbox: {
      padding: theme.spacing()
    },
    duplicateWarningIcon: {
      verticalAlign: 'middle',
      padding: '1px'
    }
  });

// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  /**
   * `className` for the root of this component.
   *
   * Since `react-trello` passes all the lane props,
   * we can't use `className` as it'll be overridden.
   */
  rootClassName?: string;
  selectedConsumerIds: Array<API.Entities.Consumer['id']>;

  onCardClick: (event: ConsumerCardEvent) => void;
  onCheckClick: (event: ConsumerCardEvent) => void;
}

export interface ConsumerCardEvent {
  consumer: API.Entities.Consumer;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  ReactTrello.CardTemplateProps<API.Entities.Consumer> &
  WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class ConsumerTrelloCard extends React.Component<Props, State> {
  static readonly defaultProps = {
    rootClassName: '',

    id: '',
    laneId: '',
    lineId: '',
    index: -1,
    title: '',
    editable: false,
    customCardLayout: false,
    hideCardDeleteIcon: false,
    tagStyle: {},
    cardStyle: {},
    dragStyle: {},
    metadata: {} as API.Entities.Consumer,
    onClick: () => {},
    onDelete: () => {},
    removeCard: () => {}
  };

  readonly state: State = {};

  // region autobound methods
  @autobind
  handleListItemClick() {
    this.props.onCardClick({ consumer: this.props.metadata! });
  }

  @autobind
  handleCheckboxClick() {
    this.props.onCheckClick({ consumer: this.props.metadata! });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    if (!this.props.metadata) {
      throw new Error('missing metadata');
    }

    return (
      <ListItem
        className={cx({
          [this.props.rootClassName]: true,
          [this.props.classes.consumerCard]: true
        })}
        ContainerComponent="div"
        button
        dense
        onClick={this.handleListItemClick}
      >
        <ListItemText
          className={this.props.classes.consumerContent}
          primary={
            <>
              {this.props.title}
              {this.props.metadata.isEmailDuplicatedWithinSeller && (
                <Warning className={this.props.classes.duplicateWarningIcon} />
              )}
            </>
          }
          secondary={
            <>
              {this.props.metadata.organisation}
              {this.props.metadata.salesforceCampaignName && (
                <>
                  <br />
                  Salesforce Campaign:{' '}
                  {this.props.metadata.salesforceCampaignName}
                </>
              )}
            </>
          }
        />
        <ListItemSecondaryAction
          className={this.props.classes.consumerSecondaryAction}
        >
          <Checkbox
            className={this.props.classes.consumerCheckbox}
            checked={this.props.selectedConsumerIds.includes(
              this.props.metadata.id
            )}
            onClick={this.handleCheckboxClick}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  // endregion
}

export default withStyles(styles)(ConsumerTrelloCard);
