import {
  ListItem,
  ListItemText,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { API } from 'src/definitions';
import { getFullName } from 'src/utilities';

// region component styles
const styles = createStyles({
  root: {}
});

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  consumer: API.Entities.Consumer;
  selected?: boolean;

  onClick?: (event: ConsumerListItemEvent) => void;
}

export interface ConsumerListItemEvent {
  consumer: API.Entities.Consumer;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class ConsumerListItem extends React.Component<Props, State> {
  static readonly defaultProps = {
    selected: false,
    onClick: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = ConsumerListItem.name;

  readonly state: State = {};

  // region autobound methods
  @autobind
  handleListItemClick() {
    this.props.onClick && this.props.onClick({ consumer: this.props.consumer });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <ListItem
        selected={this.props.selected}
        dense
        divider
        button={!!this.props.onClick}
        onClick={this.handleListItemClick}
      >
        <ListItemText
          primary={getFullName(this.props.consumer)}
          secondary={this.props.consumer.organisation}
        />
      </ListItem>
    );
  }

  // endregion
}

export default withStyles(styles, { name: ConsumerListItem.jssName })(
  ConsumerListItem
);
