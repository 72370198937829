import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  BarChart,
  Menu as MenuIcon,
  People,
  Person,
  PresentToAll,
  RecentActors
} from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { MenuLink } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import ChangePasswordDialog from 'src/components/ChangePasswordDialog';
import HideUnlessAuthed from 'src/components/HideUnlessAuthed';
import { PersonaListItemEvent } from 'src/components/PersonaListItem';
import PersonasDrawer from 'src/components/PersonasDrawer';
import SellerStylesDrawer from 'src/components/SellerStylesDrawer';
import TeamDrawer from 'src/components/TeamDrawer';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import {
  CurrentThemeValue,
  withCurrentTheme
} from 'src/contexts/CurrentThemeContext';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import {
  LightbulbFullIcon,
  LightbulbOutlineIcon,
  RelmIcon
} from 'src/theme/icons';
import { getOppositeThemeName, getThemePaletteType } from 'src/theme/theme';
import { getDefaultUrlForPersona } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexDirection: 'row'
    },
    teamMenuButton: {
      borderRadius: `${theme.spacing(3)}px`,
      minWidth: 'auto',
      padding: `${theme.spacing(0.25)}px`,
      [theme.breakpoints.up('md')]: {
        padding: [0.25, 2, 0.25, 0.25]
          .map(n => `${theme.spacing(n)}px`)
          .join(' ')
      }
    },
    teamMenuButtonLabel: {
      marginLeft: `${theme.spacing()}px`,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    paletteToggle: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    toolbarNavigation: {
      justifyContent: 'flex-start',
      flex: '1 1 33%'
    },
    toolbarBrand: {
      justifyContent: 'center',
      flex: '1 1 33%',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    toolbarSeller: {
      justifyContent: 'flex-end',
      flex: '1 1 33%'
    },
    logo: {
      height: '46px',
      width: 'auto'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  CurrentThemeValue &
  WithStyles<typeof styles> &
  RouteComponentProps<{ personaId: string }>;

interface State {
  anchorElement: HTMLElement | null;
  isPersonasDrawerOpen: boolean;
  isSellerStylesDrawerOpen: boolean;
  isChangePasswordDialogOpen: boolean;
  isTeamDrawerOpen: boolean;
}

// endregion

@withCurrentSeller<Props>()
@withCurrentTheme<Props>()
class AppTopBar extends React.Component<Props, State> {
  static readonly defaultProps = {};

  readonly state: State = {
    anchorElement: null,
    isPersonasDrawerOpen: false,
    isSellerStylesDrawerOpen: false,
    isChangePasswordDialogOpen: false,
    isTeamDrawerOpen: false
  };

  // region autobound methods
  @autobind
  handleSellerMenuIconClick(event: React.SyntheticEvent<HTMLElement>) {
    this.setState({ anchorElement: event.currentTarget });
  }

  @autobind
  handlePersonasMenuClick(event: React.SyntheticEvent<HTMLElement>) {
    this.setState({
      isPersonasDrawerOpen: !this.state.isPersonasDrawerOpen
    });
  }

  @autobind
  handlePersonasDrawerClose() {
    this.setState({ isPersonasDrawerOpen: false });
  }

  @autobind
  handleSellerStylesMenuClick() {
    this.setState({ isSellerStylesDrawerOpen: true });
  }

  @autobind
  handleSellerStylesDrawerClose() {
    this.setState({
      anchorElement: null,
      isSellerStylesDrawerOpen: false
    });
  }

  @autobind
  handleTeamMenuClick() {
    this.setState({ isTeamDrawerOpen: true });
  }

  @autobind
  handleTeamDrawerClose() {
    this.setState({ isTeamDrawerOpen: false });
  }

  @autobind
  handlePersonaClick(event: PersonaListItemEvent) {
    const currentPersonaId = this.props.match
      ? this.props.match.params.personaId
      : '';

    const newUrl = currentPersonaId
      ? this.props.location.pathname.replace(currentPersonaId, event.persona.id)
      : getDefaultUrlForPersona(event.persona.id);

    this.props.history.push(newUrl);

    this.setState({ isPersonasDrawerOpen: false });
  }

  @autobind
  handlePersonaAdd(event: PersonaListItemEvent) {
    this.props.history.push(getDefaultUrlForPersona(event.persona.id));

    this.setState({ isPersonasDrawerOpen: false });
  }

  @autobind
  handlePersonaArchived(event: PersonaListItemEvent) {
    const currentPersonaId = this.props.match.params.personaId;

    if (currentPersonaId === event.persona.id) {
      this.props.history.push('/');
    }
  }

  @autobind
  handleSellerMenuClose() {
    this.setState({ anchorElement: null });
  }

  @autobind
  handleLogOutClick() {
    RelmApi.unauthenticate();

    this.setState({ anchorElement: null });

    toast(SnackbarVariant.SUCCESS, 'Successfully logged out');
  }

  @autobind
  handleShowChangePasswordDialog() {
    this.setState({ isChangePasswordDialogOpen: true });
  }

  @autobind
  handleChangePasswordDialogClose() {
    this.setState({ isChangePasswordDialogOpen: false });
  }

  @autobind
  handleTogglePaletteTypeClick() {
    this.props.changeCurrentTheme(
      getOppositeThemeName(this.props.currentThemeName)
    );
  }

  // endregion
  getSellerMenuRenderContent() {
    const sellerMenuOpen = !!this.state.anchorElement;
    const sellerAvatar = this.props.currentSeller!.photoUrl ?? '';
    const sellerLabel =
      this.props.currentSeller!.firstName ||
      this.props.currentSeller!.email ||
      'Team menu';

    return (
      <HideUnlessAuthed>
        <MenuLink to="/home" icon={BarChart} text="Dashboard" />
        <Button
          aria-owns={sellerMenuOpen ? 'seller-menu' : ''}
          aria-haspopup="true"
          className={this.props.classes.teamMenuButton}
          color="inherit"
          onClick={this.handleSellerMenuIconClick}
        >
          <Avatar src={sellerAvatar}>{!sellerAvatar && <Person />}</Avatar>
          <span className={this.props.classes.teamMenuButtonLabel}>
            {sellerLabel}
          </span>
        </Button>
        <Menu
          id="seller-menu"
          anchorEl={this.state.anchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={sellerMenuOpen}
          onClose={this.handleSellerMenuClose}
        >
          <MenuItem onClick={this.handleTeamMenuClick}>Team</MenuItem>
          <MenuItem onClick={this.handleSellerStylesMenuClick}>
            Seller Styles
          </MenuItem>
          <MenuItem onClick={this.handleShowChangePasswordDialog}>
            Change password
          </MenuItem>
          <MenuItem onClick={this.handleLogOutClick}>Log out</MenuItem>
        </Menu>
      </HideUnlessAuthed>
    );
  }

  render() {
    return (
      <>
        <AppBar className={this.props.classes.root} position="static">
          <Toolbar className={this.props.classes.toolbarNavigation}>
            <HideUnlessAuthed>
              <IconButton
                color="inherit"
                onClick={this.handlePersonasMenuClick}
              >
                <MenuIcon />
              </IconButton>
              {this.props.match && (
                <>
                  <MenuLink
                    to={`${this.props.match.url}/consumers`}
                    icon={People}
                    text="Consumers"
                  />
                  <MenuLink
                    to={`${this.props.match.url}/stories`}
                    icon={RecentActors}
                    text="Stories"
                  />
                  <MenuLink
                    to={`${this.props.match.url}/outbox`}
                    icon={PresentToAll}
                    text="Outbox"
                  />
                </>
              )}
            </HideUnlessAuthed>
          </Toolbar>
          <Toolbar className={this.props.classes.toolbarBrand}>
            <RelmIcon className={this.props.classes.logo} />
          </Toolbar>
          <Toolbar className={this.props.classes.toolbarSeller}>
            {this.getSellerMenuRenderContent()}
            {process.env.NODE_ENV === 'development' && (
              <IconButton
                className={this.props.classes.paletteToggle}
                color="inherit"
                onClick={this.handleTogglePaletteTypeClick}
              >
                {getThemePaletteType(this.props.currentThemeName) ===
                'light' ? (
                  <LightbulbOutlineIcon />
                ) : (
                  <LightbulbFullIcon />
                )}
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <HideUnlessAuthed>
          <ChangePasswordDialog
            open={this.state.isChangePasswordDialogOpen}
            onClose={this.handleChangePasswordDialogClose}
          />
          <PersonasDrawer
            open={this.state.isPersonasDrawerOpen}
            currentPersonaId={
              this.props.match ? this.props.match.params.personaId : ''
            }
            onClose={this.handlePersonasDrawerClose}
            onPersonaAdd={this.handlePersonaAdd}
            onPersonaArchived={this.handlePersonaArchived}
            onPersonaClick={this.handlePersonaClick}
          />
          <SellerStylesDrawer
            open={this.state.isSellerStylesDrawerOpen}
            onClose={this.handleSellerStylesDrawerClose}
          />
          <TeamDrawer
            open={this.state.isTeamDrawerOpen}
            onClose={this.handleTeamDrawerClose}
          />
        </HideUnlessAuthed>
      </>
    );
  }
}

export default withRouter(withStyles(styles)(AppTopBar));
