import { MuiThemeProvider, Theme } from '@material-ui/core';
import * as React from 'react';
import CurrentThemeContext, {
  CurrentThemeValue
} from 'src/contexts/CurrentThemeContext';
import { ThemeName, buildTheme, isValidThemeName } from 'src/theme/theme';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  currentThemeName: ThemeName;
}

// endregion

const storageKey = 'current-theme';

const loadCurrentTheme = (): ThemeName => {
  const savedCurrentThemeName = localStorage.getItem(storageKey);

  return savedCurrentThemeName && isValidThemeName(savedCurrentThemeName)
    ? savedCurrentThemeName
    : ThemeName.DEFAULT;
};

const saveCurrentTheme = (themeName: ThemeName) =>
  localStorage.setItem(storageKey, themeName);

class CurrentThemeProvider extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private _currentThemeValue: CurrentThemeValue = {
    currentThemeName: loadCurrentTheme(),
    changeCurrentTheme: themeName =>
      this.setState({ currentThemeName: themeName })
  };

  private _currentTheme: Theme = buildTheme(
    this._currentThemeValue.currentThemeName
  );

  readonly state: State = {
    currentThemeName: this._currentThemeValue.currentThemeName
  };

  render() {
    if (
      this._currentThemeValue.currentThemeName !== this.state.currentThemeName
    ) {
      this._currentTheme = buildTheme(this.state.currentThemeName);

      saveCurrentTheme(this.state.currentThemeName);

      this._currentThemeValue = {
        ...this._currentThemeValue,
        currentThemeName: this.state.currentThemeName
      }; // save re-renders by not passing a new object every render.
    }

    return (
      <MuiThemeProvider theme={this._currentTheme}>
        <CurrentThemeContext.Provider value={this._currentThemeValue}>
          {this.props.children}
        </CurrentThemeContext.Provider>
      </MuiThemeProvider>
    );
  }
}

export default CurrentThemeProvider;
