import { Button, makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(
  theme => ({
    root: {},
    buttonAdd: {
      'position': 'absolute',
      'padding': `${theme.spacing(0.5)}px ${theme.spacing()}px`,
      'bottom': theme.spacing(1.5),
      '& svg': {
        marginRight: theme.spacing(),
        fontSize: theme.spacing(2)
      }
    }
  }),
  { name: 'AddCardLink' }
);

interface Props {
  text: string;
}

export const AddCardLink = (props: React.PropsWithChildren<Props>) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Button
      className={classes.buttonAdd}
      variant="outlined"
      size="small"
      color="primary"
    >
      {props.children}
      <span>{props.text}</span>
    </Button>
  );
};
