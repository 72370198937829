import { PaletteType } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export enum ThemeName {
  DEFAULT = 'light',
  LIGHT = 'light',
  DARK = 'dark'
}

const common: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: deepPurple,
    secondary: deepPurple
  }
};

/**
 * Checks that the given `themeName` is a valid `ThemeName`.
 *
 * @param {ThemeName | string} themeName
 *
 * @return {themeName is ThemeName}
 */
export const isValidThemeName = (
  themeName: ThemeName | string
): themeName is ThemeName => ThemeName.hasOwnProperty(themeName);

/**
 * Gets the theme options for the for with the given `name`.
 *
 * @param {ThemeName} themeName
 *
 * @return {ThemeOptions}
 */
const getThemeOptions = (themeName: ThemeName): ThemeOptions => {
  switch (themeName) {
    case ThemeName.DARK:
      return {
        palette: {
          ...common,
          type: 'dark'
        }
      };
    case ThemeName.LIGHT:
      return {
        palette: {
          ...common,
          type: 'light'
        }
      };

    case ThemeName.DEFAULT:
    default:
      return common;
  }
};

/**
 * Gets the value of the `palette.type` property
 * for the theme of the given name.
 *
 * @param {ThemeName} themeName
 *
 * @return {PaletteType}
 */
export const getThemePaletteType = (themeName: ThemeName): PaletteType =>
  (getThemeOptions(themeName).palette ?? { type: 'light' }).type ?? 'light';

/**
 * Gets the name of the theme that's 'opposite' the given theme.
 *
 * @param {ThemeName} themeName
 *
 * @return {ThemeName}
 */
export const getOppositeThemeName = (themeName: ThemeName): ThemeName => {
  switch (themeName) {
    case ThemeName.DARK:
      return ThemeName.LIGHT;
    case ThemeName.LIGHT:
      return ThemeName.DARK;

    case ThemeName.DEFAULT:
    default:
      return ThemeName.DEFAULT;
  }
};

export const buildTheme = (themeName: ThemeName) =>
  createMuiTheme({ ...common, ...getThemeOptions(themeName) });

export default buildTheme(ThemeName.DEFAULT);
