import { API } from 'src/definitions';
import { Omit } from 'src/services/withDataFromApi';
import Schema from 'validate';

export default new Schema<
  Omit<
    API.Authorization.RequestAccessToken.PasswordGrantRequest,
    keyof API.Authorization.RequestAccessToken.Common.Request
  >
>(
  {
    username: {
      required: true,
      type: 'string',
      message: {
        required: 'Email is required'
      }
    },
    password: {
      required: true,
      type: 'string',
      message: {
        required: 'Password is required'
      }
    }
  },
  { strip: false }
);
