import hoistNonReactStatics from 'hoist-non-react-statics';
import * as React from 'react';
import { API } from 'src/definitions';

export interface CurrentStatisticsValue {
  currentStatistics: API.Nullable<API.Entities.Statistics.Statistics>;
  onCurrentStatisticsChange: () => void;
}

const CurrentStatisticsContext = React.createContext<CurrentStatisticsValue>({
  currentStatistics: null,
  onCurrentStatisticsChange: () => {}
});

/**
 *
 * @param {boolean} [renderIfNull=false]
 */
export const withCurrentStatistics = <P extends CurrentStatisticsValue>(
  renderIfNull = false
) => <C extends React.ComponentClass<P>>(Component: C): C => {
  class WithCurrentStatistics extends React.Component<P & { innerRef?: any }> {
    render() {
      const TypedComponent = Component as React.ComponentClass<P>;

      return (
        <CurrentStatisticsContext.Consumer>
          {(contextValue: CurrentStatisticsValue) =>
            (contextValue.currentStatistics || renderIfNull) && (
              <TypedComponent
                ref={this.props.innerRef}
                {...this.props}
                currentStatistics={contextValue.currentStatistics}
                onCurrentStatisticsChange={
                  contextValue.onCurrentStatisticsChange
                }
              />
            )
          }
        </CurrentStatisticsContext.Consumer>
      );
    }
  }

  hoistNonReactStatics(WithCurrentStatistics, Component);

  return (WithCurrentStatistics as unknown) as C;
};

export default CurrentStatisticsContext;
