import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthTokenFailureException from 'src/exceptions/AuthTokenFailureException';

class AxiosException implements AxiosError {
  public code?: string;
  public config: AxiosRequestConfig;
  public message: string;
  public name: string;
  public request: any;
  public response?: AxiosResponse;
  public stack?: string;
  public isAxiosError: boolean;

  static interceptorWrapper(error: AxiosError | AuthTokenFailureException) {
    if (error instanceof AuthTokenFailureException) {
      throw error;
    }

    throw new AxiosException(error);
  }

  /**
   * Assets that the given exception is an instance of `AxiosException`.
   *
   * If it's not, it's re-thrown.
   *
   * @param {Error} possibleAxiosError
   *
   * @return {AxiosException}
   * @static
   */
  static throwIfNotOneOfUs(possibleAxiosError: Error) {
    if (!(possibleAxiosError instanceof AxiosException)) {
      throw possibleAxiosError;
    }

    return possibleAxiosError;
  }

  constructor(error: AxiosError) {
    this.code = error.code;
    this.config = error.config;
    this.message = error.message;
    this.name = error.name;
    this.request = error.request;
    this.response = error.response;
    this.stack = error.stack;
    this.isAxiosError = error.isAxiosError;

    Object.assign(this, error);
  }

  toJSON() {
    return {
      code: this.code,
      config: this.config,
      message: this.message,
      name: this.name,
      request: this.request,
      response: this.response,
      stack: this.stack,
      isAxiosError: this.isAxiosError
    };
  }
}

export default AxiosException;
