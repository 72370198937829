import * as am4core from '@amcharts/amcharts4/core';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
  withWidth
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import cx from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { API } from 'src/definitions';
import { fromTimeDisplay } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'unset'
      }
    },
    table: {
      width: '100%'
    },
    tdDates: {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing()}px`,
        width: 'auto'
      }
    },
    tdTitle: {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing()}px`,
        width: '200px'
      }
    },
    tdTimeSpent: {
      width: '10%',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing()}px`,
        width: 'auto',
        textAlign: 'center'
      }
    },
    tdViewed: {
      width: '10%',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing()}px`,
        width: 'auto',
        textAlign: 'center'
      }
    },
    tdCities: {
      width: '10%'
    },
    tdDevices: {
      width: '10%',
      textTransform: 'capitalize'
    },
    date: {
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]: {
        fontSize: '.75rem'
      }
    },
    dateFrom: {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('xs')]: {
        fontSize: '.75rem'
      }
    },
    title: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(0.5)}px`,
        fontSize: '1rem'
      }
    },
    titleHome: {
      fontStyle: 'italic',
      color: theme.palette.text.secondary
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  history: API.Entities.Statistics.ConsumerEvent[];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithWidth & WithStyles<typeof styles>;

// region Am4core
am4core.useTheme(am4ThemesAnimated);
am4core.options.autoSetClassName = true;
am4core.options.minPolylineStep = 5;
// endregion Am4core

// endregion

/**
 * Expansion Panel view of consumer sessions
 */
class StatisticsHistory extends React.Component<Props> {
  static readonly defaultProps = {};
  static readonly jssName: string = StatisticsHistory.name;

  geoDisplay(geo: API.Entities.Statistics.Geo): string {
    if (geo.city && geo.country_code) {
      return `${geo.city} (${geo.country_code})`;
    }
    if (geo.country) {
      return geo.country;
    }

    return 'Unknown';
  }

  desktopRender() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tdDates}>Dates</TableCell>
              <TableCell className={classes.tdTitle}>Stories</TableCell>
              <TableCell className={classes.tdTimeSpent}>
                Time&nbsp;Spent
              </TableCell>
              <TableCell className={classes.tdViewed}>Viewed</TableCell>
              <TableCell className={classes.tdCities}>Location</TableCell>
              <TableCell className={classes.tdDevices}>Devices</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.history.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  className={classes.tdDates}
                  component="th"
                  scope="row"
                >
                  <Typography variant="subtitle2" className={classes.dateFrom}>
                    {fromTimeDisplay(row.consuming.start)}
                  </Typography>
                  <Typography className={classes.date}>
                    {format(
                      new Date(row.consuming.start),
                      `EEEE, do MMMM, yyyy 'at' pp`
                    )}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tdTitle}>
                  <Typography
                    variant="subtitle2"
                    className={cx(classes.title, {
                      [classes.titleHome]: row.consuming.pageType === 'homepage'
                    })}
                  >
                    {row.consuming.title}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tdTimeSpent}>
                  <Typography>{row.consuming.timeSpentDisplay}</Typography>
                </TableCell>
                <TableCell className={classes.tdViewed}>
                  <Typography>
                    {row.context?.consumed ? `${row.context.consumed}%` : '--'}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tdCities}>
                  <Typography>{this.geoDisplay(row.geo ?? {})}</Typography>
                </TableCell>
                <TableCell className={classes.tdDevices}>
                  <Typography>{row.tech.device}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  mobileRender() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tdDates}>Dates / Stories</TableCell>
              <TableCell className={classes.tdTimeSpent}>Time Spent</TableCell>
              <TableCell className={classes.tdViewed}>Viewed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.history.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  className={classes.tdDates}
                  component="th"
                  scope="row"
                >
                  <Typography
                    variant="subtitle2"
                    className={cx(classes.title, {
                      [classes.titleHome]: row.consuming.pageType === 'homepage'
                    })}
                  >
                    {row.consuming.title}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.dateFrom}>
                    {fromTimeDisplay(row.consuming.start)}
                  </Typography>
                  <Typography className={classes.date}>
                    {format(
                      new Date(row.consuming.start),
                      `EEE, dd MMM yyyy, pp`
                    )}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tdTimeSpent}>
                  <Typography>{row.consuming.timeSpentDisplay}</Typography>
                </TableCell>
                <TableCell className={classes.tdViewed}>
                  <Typography>
                    {row.context?.consumed ? `${row.context.consumed}%` : '--'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  // region render & get-render-content methods
  render() {
    if (isWidthDown('sm', this.props.width)) {
      return this.mobileRender();
    }

    return this.desktopRender();
  }

  // endregion
}

export default withWidth()(
  withStyles(styles, { name: StatisticsHistory.jssName })(StatisticsHistory)
);
