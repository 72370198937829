import {
  Button,
  Drawer,
  ExpansionPanel,
  ExpansionPanelDetails,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import AddPersonaDialog, {
  AddPersonaDialogCloseEvent
} from 'src/components/AddPersonaDialog';
import CurrentSellersPersonasList from 'src/components/CurrentSellersPersonasList';
import { PersonaListItemEvent } from 'src/components/PersonaListItem';
import PersonasDrawerAppBar from 'src/components/PersonasDrawerTopBar';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import RelmApi from 'src/services/RelmApi';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import { assertIsDefined } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: '500px',
      maxWidth: theme.breakpoints.values.sm,
      width: '25%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        maxWidth: 'auto',
        width: '100%'
      }
    },
    filterPanel: {
      boxShadow: '0px 4px 3px -2px rgba(0, 0, 0, 0.2)'
    },
    drawerOpen: {
      transform: 'none !important'
    },
    glue: {
      flexGrow: 1
    },
    filterInput: {
      marginTop: 0,
      marginBottom: 0
    },
    sortButton: {
      'marginRight': `${theme.spacing(2)}px`,
      '&:last-of-type': {
        marginRight: 0
      }
    },
    sortButtonIcon: {
      fontSize: '20px'
    }
  });
// endregion

interface SortBy {
  label: string;
  value: SortField;
  isAscending: boolean;
}
interface SortSettings {
  filterValue: string;
  sortBy: API.Nullable<SortBy>;
  sellerId: string;
  personaOrder: string[];
}

// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  open: boolean;
  /**
   * Id of the currently selected `Persona`..
   *
   * We pass this as a prop to save on an API call.
   * We just need to know the id for `selected`.
   */
  currentPersonaId: string;

  onClose?: () => void;
  onPersonaAdd: (event: PersonaListItemEvent) => void;
  onPersonaArchived: (event: PersonaListItemEvent) => void;
  onPersonaClick: (event: PersonaListItemEvent) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  WithStyles<typeof styles> &
  WithDataFromApiProps<'personas', API.Personas.ListPersonas.Response>;

enum SortField {
  CREATED_AT = 'createdAt',
  NAME = 'name'
}

const storageKey = 'RelmPersonaSettings';
const columnId = 'drawerPersonaList';
const personaSortByDefault = {
  label: 'Chronological',
  value: SortField.CREATED_AT,
  isAscending: true
};
const personaSortBy: SortBy[] = [
  personaSortByDefault,
  {
    label: 'Alphabetical',
    value: SortField.NAME,
    isAscending: true
  }
];

interface State {
  isAddPersonaDialogOpen: boolean;
  isFilterOpen: boolean;
  filterValue: string;
  sortBy: API.Nullable<SortBy>;
  personas: API.Entities.Persona[];
  personaOrder: string[];
}

// endregion

/**
 *
 */
@withCurrentSeller<Props>()
@withDataFromApi<Props>(() => RelmApi.listPersonas(), 'personas')
class PersonasDrawer extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    onCurrentSellerChange: () => {},
    personas: [],
    loadFromApi: () => Promise.resolve() as any
  };

  readonly state: State = {
    isAddPersonaDialogOpen: false,
    isFilterOpen: false,
    filterValue: '',
    sortBy: personaSortByDefault,
    personas: this.props.personas,
    personaOrder: []
  };

  componentDidMount() {
    this.loadSettings();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    assertIsDefined(this.props.currentSeller);
    if (
      prevState.personas !== this.state.personas ||
      prevState.sortBy !== this.state.sortBy ||
      prevState.filterValue !== this.state.filterValue
    ) {
      this.saveSettings({
        filterValue: this.state.filterValue,
        sortBy: this.state.sortBy,
        sellerId: this.props.currentSeller.id,
        personaOrder: this.state.personas.map(persona => persona.id)
      });
    }
  }

  getPersonasSortedNavigation(
    sortBy: API.Nullable<SortBy>,
    filterValue: string,
    sellerList = this.state.personaOrder || []
  ): API.Entities.Persona[] {
    // Filtering by Name
    const newNavigation = this.props.personas.filter(persona =>
      persona.name.toLowerCase().includes(filterValue.toLowerCase())
    );

    // Automatic sort
    if (sortBy !== null) {
      return newNavigation.sort((a, b) =>
        sortBy.isAscending
          ? a[sortBy.value].localeCompare(b[sortBy.value])
          : b[sortBy.value].localeCompare(a[sortBy.value])
      );
    }

    // Custom Sort (Drag'n'drop)
    return [...newNavigation].sort((a, b) =>
      sellerList.indexOf(a.id) < sellerList.indexOf(b.id) ? -1 : 1
    );
  }

  // region save settings
  /**
   * Save the settings
   */
  saveSettings(personaSortSettings: SortSettings) {
    localStorage.setItem(storageKey, JSON.stringify(personaSortSettings));
  }

  /**
   * Load keen records from the local storage of the seller
   */
  loadSettings() {
    assertIsDefined(this.props.currentSeller);
    const loadedSettings = localStorage.getItem(storageKey);

    if (loadedSettings === null) {
      return;
    }
    const settings: SortSettings = JSON.parse(loadedSettings);

    if (settings.sellerId !== this.props.currentSeller.id) {
      // Partial reset of the recorded setting
      settings.sortBy = settings.sortBy ?? personaSortByDefault;
      settings.personaOrder = [];
    }
    this.setState({
      filterValue: settings.filterValue,
      sortBy: settings.sortBy,
      personas: this.getPersonasSortedNavigation(
        settings.sortBy,
        settings.filterValue,
        settings.personaOrder
      )
    });
  }
  // endregion

  // region autobound methods
  /**
   * Handles when the value of the "search" `Input` element changes.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  @autobind
  handleFilterInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const filterValue = event.target.value;

    this.setState(prevState => ({
      filterValue,
      personas: this.getPersonasSortedNavigation(prevState.sortBy, filterValue)
    }));
  }

  /**
   * Handles when the clear filter button is clicked.
   */
  @autobind
  handleFilterClearClick() {
    const filterValue = '';

    this.setState(prevState => ({
      filterValue,
      personas: this.getPersonasSortedNavigation(prevState.sortBy, filterValue)
    }));
  }

  /**
   * Handles when the 'create new persona' `Button` is clicked.
   */
  @autobind
  handleNewPersonaClick() {
    this.setState({ isAddPersonaDialogOpen: true });
  }

  /**
   * Handles when the `AddPersonaDialog` is closed.
   *
   * @param {AddPersonaDialogCloseEvent} event
   */
  @autobind
  handleAddPersonaDialogClose(event: AddPersonaDialogCloseEvent) {
    this.setState({ isAddPersonaDialogOpen: false });

    if (event.addedPersona) {
      this.props.onPersonaAdd({ persona: event.addedPersona });
      this.props.loadFromApi();
    }
  }

  /**
   * Handles when a persona is archived
   *
   * @param {PersonaListItemEvent} event
   */
  @autobind
  handlePersonaArchived(event: PersonaListItemEvent) {
    if (event.persona) {
      this.props.onPersonaArchived({ persona: event.persona });
      this.props.loadFromApi();
    }
  }

  /**
   * Sort order selection
   */
  @autobind
  handleOrderSelectionClick(
    event: React.MouseEvent<HTMLElement>,
    sort: SortBy
  ) {
    this.setState(prevState => {
      const sortBy = {
        ...sort,
        isAscending:
          prevState.sortBy === null ||
          prevState.sortBy.value !== sort.value ||
          !prevState.sortBy.isAscending
      };

      return {
        sortBy,
        personas: this.getPersonasSortedNavigation(
          sortBy,
          prevState.filterValue
        )
      };
    });
  }

  /**
   * Filter panel switch
   */
  @autobind
  handlePersonaOptionsClick() {
    this.setState(prevState => ({ isFilterOpen: !prevState.isFilterOpen }));
  }

  /**
   * When Drawer closing, close filter as well
   */
  @autobind
  handleDrawerClose() {
    this.setState(prevState => ({
      isFilterOpen: false,
      personas:
        this.props.personas === prevState.personas
          ? prevState.personas
          : this.getPersonasSortedNavigation(
              prevState.sortBy,
              prevState.filterValue
            )
    }));
    this.props.onClose();
  }

  /**
   * Call when the persona drags end
   */
  @autobind
  handlePersonaDragEnd(result: DropResult) {
    const { destination, source } = result;

    if (!destination || destination === source) {
      return;
    }

    this.setState(prevState => {
      const newOrderedNavigation = this.getPersonasSortedNavigation(
        prevState.sortBy,
        prevState.filterValue
      );
      const movingPersona = newOrderedNavigation[source.index];

      newOrderedNavigation.splice(source.index, 1);
      newOrderedNavigation.splice(destination.index, 0, movingPersona);

      return {
        sortBy: null,
        personaOrder: newOrderedNavigation.map(persona => persona.id),
        personas: newOrderedNavigation
      };
    });
  }

  // endregion
  // region render & get-render-content methods

  /**
   * Sort Button Arrow Icon Render
   * @returns {any}
   */
  getKeyboardArrow() {
    const iconClass = this.props.classes.sortButtonIcon;

    if (this.state.sortBy?.isAscending) {
      return <KeyboardArrowDown className={iconClass} />;
    }

    return <KeyboardArrowUp className={iconClass} />;
  }

  /**
   * Sort Button
   *
   * @param {SortBy} sortBy
   * @returns {any}
   */
  @autobind
  getSortButtonRenderContent(sortBy: SortBy) {
    const isCurrentlySelectedSortBy = sortBy.value === this.state.sortBy?.value;

    return (
      <Button
        key={sortBy.value}
        variant="outlined"
        color={isCurrentlySelectedSortBy ? 'primary' : 'default'}
        onClick={event => this.handleOrderSelectionClick(event, sortBy)}
        className={this.props.classes.sortButton}
      >
        {sortBy.label}
        {isCurrentlySelectedSortBy && this.getKeyboardArrow()}
      </Button>
    );
  }

  /**
   * Content of the Filter and Order pan
   *
   * @returns {any}
   */
  getFilterAndOrderRenderContent() {
    return (
      <ExpansionPanel
        expanded={this.state.isFilterOpen}
        elevation={0}
        className={this.props.classes.filterPanel}
      >
        <div />
        <ExpansionPanelDetails>
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item>
              <Typography>Filter by:</Typography>
              <TextField
                className={this.props.classes.filterInput}
                placeholder="Persona name"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.filterValue}
                onChange={this.handleFilterInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Clear filter"
                        onClick={this.handleFilterClearClick}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <Typography>Sort order:</Typography>
              {personaSortBy.map(this.getSortButtonRenderContent)}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  render() {
    return (
      <>
        <Drawer
          classes={{
            paper: cx(this.props.classes.root, {
              [this.props.classes.drawerOpen]: this.props.open
            })
          }}
          open={this.props.open}
          onClose={this.handleDrawerClose}
          SlideProps={{ unmountOnExit: true }}
          ModalProps={{ keepMounted: true }}
        >
          <PersonasDrawerAppBar
            onFilterOptionsClick={this.handlePersonaOptionsClick}
            onClose={this.handleDrawerClose}
          />
          {this.getFilterAndOrderRenderContent()}
          <DragDropContext onDragEnd={this.handlePersonaDragEnd}>
            <CurrentSellersPersonasList
              className={this.props.classes.glue}
              currentlySelectedPersonaId={this.props.currentPersonaId}
              columnId={columnId}
              personas={this.state.personas}
              totalPersona={this.props.personas.length}
              onPersonaArchived={this.handlePersonaArchived}
              onPersonaClick={this.props.onPersonaClick}
              onFilterClearClick={this.handleFilterClearClick}
            />
          </DragDropContext>
          <Button onClick={this.handleNewPersonaClick}>
            Create new persona
          </Button>
        </Drawer>
        <AddPersonaDialog
          open={this.state.isAddPersonaDialogOpen}
          onClose={this.handleAddPersonaDialogClose}
        />
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(PersonasDrawer);
