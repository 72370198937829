import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  CenteredCircularProgress,
  StatisticsNoDataWarning
} from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import ConsumerProfileDialog from 'src/components/ConsumerProfileDialog';
import CurrentPersonaProvider from 'src/components/CurrentPersonaProvider';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%',
      position: 'relative',
      minHeight: '450px'
    },
    chart: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      overflowY: 'auto',
      overflowX: 'hidden',
      margin: 0,
      padding: 0,
      height: '450px'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.grey['400']}`,
      paddingTop: `${theme.spacing(2)}px`,
      paddingBottom: `${theme.spacing(2)}px`
    },
    consumerHeader: {
      fontWeight: 'bold',
      flexGrow: 3
    },
    timeSpentHeader: {
      fontWeight: 'bold',
      textAlign: 'right',
      flexGrow: 1
    },
    consumer: {
      flexGrow: 3,
      maxWidth: '80%',
      minWidth: 'auto'
    },
    timeSpend: {
      textAlign: 'right',
      flexGrow: 1,
      maxWidth: '20%',
      paddingRight: 0,
      paddingLeft: 0
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  id: string;
  days: number;
  value: API.Nullable<API.Entities.Statistics.ConsumerWithStats[]>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

interface State {
  showNoDataWarning: boolean;
  consumersList: API.Entities.Statistics.TopConsumer[];
  consumer: API.Nullable<API.Entities.Statistics.ConsumerWithStats>;
}

// endregion

/**
 * Build to display statistics
 */
@withCurrentStatistics<Props>()
class StatisticsTopConsumers extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {},
    id: 'amCharts-lp-visit',
    value: null,
    days: 7
  };

  static readonly jssName: string = StatisticsTopConsumers.name;

  readonly state: State = {
    showNoDataWarning: false,
    consumersList: [],
    consumer: null
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.days !== this.props.days
    ) {
      this.updateStats();
    }
  }

  componentDidMount() {
    this.updateStats();
  }

  /**
   * Handles when the `ConsumerProfile` is called to close.
   */
  @autobind
  handleConsumerProfileCallToClose() {
    this.setState({
      consumer: null
    });
  }

  /**
   * Handles when a `Consumer` has been updated.
   */
  @autobind
  handleConsumerProfileUpdated() {
    this.setState({
      consumer: null
    });
  }

  @autobind
  handleOpenConsumerProfile(relmId: string): void {
    if (
      !this.props.currentStatistics ||
      !this.props.currentStatistics.consumersById
    ) {
      return;
    }

    if (!(relmId in this.props.currentStatistics.consumersById)) {
      this.setState({
        consumer: null
      });
      toast(SnackbarVariant.WARNING, `This consumer doesn't exist anymore`);

      return;
    }
    this.setState({
      consumer: this.props.currentStatistics.consumersById[relmId][0]
    });
  }

  @autobind
  updateStats() {
    if (this.props.value === null) {
      return;
    }
    const topConsumers = StatsApi.topConsumers(
      this.props.value,
      this.props.days
    ).filter(topConsumer => topConsumer.timeSpent > 0);

    this.setState({ showNoDataWarning: topConsumers.length === 0 });
    this.setState({ consumersList: topConsumers });
  }

  // region render & get-render-content methods
  render() {
    if (!this.props.currentStatistics) {
      throw new Error('currentStatistics not loaded');
    }

    return (
      <CardContent className={this.props.classes.root}>
        {this.props.value === null && (
          <div className={this.props.classes.progress}>
            <CenteredCircularProgress disableOutline />
          </div>
        )}
        <StatisticsNoDataWarning
          show={this.state.showNoDataWarning}
          warningMessage="There have been no visits during this timeframe."
        />
        <List id={this.props.id} className={this.props.classes.chart}>
          {this.props.currentStatistics.events.length > 0 && (
            <ListSubheader className={this.props.classes.header}>
              <Typography className={this.props.classes.consumerHeader}>
                Consumers
              </Typography>
              <Typography className={this.props.classes.timeSpentHeader}>
                Time Spent
              </Typography>
            </ListSubheader>
          )}
          {this.state.consumersList.map(consumer => (
            <ListItem
              button
              key={consumer.consumer.id}
              divider
              onClick={event =>
                this.handleOpenConsumerProfile(consumer.consumer.id)
              }
            >
              <ListItemText
                className={this.props.classes.consumer}
                primary={consumer.consumer.fullName}
                secondary={consumer.consumer.organisation ?? ''}
              />
              <ListItemText
                className={this.props.classes.timeSpend}
                primary={consumer.timeSpentDisplay}
              />
            </ListItem>
          ))}
        </List>
        {this.state.consumer !== null && (
          <>
            {this.state.consumer.personaId === null && (
              <ConsumerProfileDialog
                open
                consumer={this.state.consumer}
                onCallToClose={this.handleConsumerProfileCallToClose}
                onConsumerUpdated={this.handleConsumerProfileUpdated}
              />
            )}
            {this.state.consumer.personaId !== null && (
              <CurrentPersonaProvider personaId={this.state.consumer.personaId}>
                <ConsumerProfileDialog
                  open
                  consumer={this.state.consumer}
                  onCallToClose={this.handleConsumerProfileCallToClose}
                  onConsumerUpdated={this.handleConsumerProfileUpdated}
                />
              </CurrentPersonaProvider>
            )}
          </>
        )}
      </CardContent>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StatisticsTopConsumers.jssName })(
  StatisticsTopConsumers
);
