import { AppBar, Toolbar, Typography } from '@material-ui/core';
import * as React from 'react';

export const TeamDrawerTopBar = () => (
  <AppBar position="static">
    <Toolbar>
      <Typography color="inherit">Team</Typography>
    </Toolbar>
  </AppBar>
);
