import {
  CardHeader,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, Redo } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormButton, FormDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import UpdateStepRequest from 'src/schemas/UpdateStepRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import { Omit } from 'src/services/withDataFromApi';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: `${theme.breakpoints.values.sm}px`
      }
    },
    typographyTitle: {
      textAlign: 'center'
    },
    dialogHeader: {
      paddingTop: '10px',
      paddingBottom: '0'
    },
    dialogContent: {
      padding: `0 ${theme.spacing(2)}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  /**
   * The `Step` that is being edited.
   */
  step: API.Entities.Step;
  /**
   * Controls the open state of the `Dialog`.
   */
  open: boolean;

  /**
   * Called when the `Dialog` is closed.
   *
   * @param {boolean} wasUpdated `true` if the `Step` was updated, otherwise `false`.
   */
  onClose: (wasUpdated: boolean) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<
    Omit<API.Steps.UpdateStep.Request, 'position'>
  > {}

// endregion

/**
 *
 */
class EditStepDialog extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _validator = new ValidationTrait(this, UpdateStepRequest);

  readonly state: State = {
    inputValues: { ...this.props.step },
    inputErrors: {
      name: ''
    }
  };

  async tryUpdateStep() {
    try {
      await RelmApi.updateStep(
        this.props.step.personaId,
        this.props.step.id,
        this.state.inputValues
      );
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to rename step'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);

      return;
    }

    toast(SnackbarVariant.SUCCESS, 'Successfully renamed step');

    this.props.onClose(true);
  }

  // region autobound methods
  /**
   * Handles when the `save` button is clicked.
   */
  @autobind
  handleUpdateButtonClick() {
    load(this.tryUpdateStep());
  }

  @autobind
  handleCloseClick() {
    this.props.onClose(false);
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <FormDialog
        classes={{ paper: this.props.classes.paper }}
        open={this.props.open}
        onClose={this.handleCloseClick}
      >
        <CardHeader
          className={this.props.classes.dialogHeader}
          avatar={<Redo />}
          action={
            <IconButton onClick={this.handleCloseClick}>
              <Close />
            </IconButton>
          }
          titleTypographyProps={{
            className: this.props.classes.typographyTitle
          }}
          title="Rename Step"
        />
        <DialogContent className={this.props.classes.dialogContent}>
          {this._validator.buildTextFieldForProperty('name', 'Name', {
            required: true
          })}
        </DialogContent>
        <DialogActions>
          <FormButton
            variant="outlined"
            fullWidth
            onClick={this.handleUpdateButtonClick}
          >
            Update
          </FormButton>
        </DialogActions>
      </FormDialog>
    );
  }

  // endregion
}

export default withStyles(styles)(EditStepDialog);
