import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4LangEn from '@amcharts/amcharts4/lang/en';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  CardContent,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  CenteredCircularProgress,
  StatisticsNoDataWarning
} from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import ConsumerProfileDialog from 'src/components/ConsumerProfileDialog';
import CurrentPersonaProvider from 'src/components/CurrentPersonaProvider';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';
import { secondsToHms } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'relative',
      minHeight: '450px',
      [`& .amcharts-XYSeries-group.amcharts-ColumnSeries-group,
        & .amcharts-amexport-menu,
        & .amcharts-ZoomOutButton-group`]: {
        cursor: 'pointer'
      }
    },
    header: {
      textAlign: 'center'
    },
    chart: {
      width: '100%',
      height: '450px',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      margin: '1em 0',
      overflowX: 'visible',
      overflowY: 'visible'
    },
    legendWrapper: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      width: '100%',
      height: '105px',
      maxHeight: '150px',
      overflowX: 'hidden',
      overflowY: 'auto'
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  id: string;
  days: number;
  value: API.Nullable<API.Entities.Statistics.ConsumerWithStats[]>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

interface State {
  showNoDataWarning: boolean;
  consumer: API.Nullable<API.Entities.Statistics.ConsumerWithStats>;
}

// region Am4core
am4core.useTheme(am4ThemesAnimated);
am4core.options.autoSetClassName = true;
am4core.options.minPolylineStep = 5;
// endregion Am4core

// endregion

/**
 * Build to display statistics
 */
@withCurrentStatistics<Props>()
class StatisticsVisitsChart extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {},
    id: 'amCharts-lp-visit',
    value: null,
    days: 7
  };

  static readonly jssName: string = StatisticsVisitsChart.name;

  private _amChartsLpVisit: API.Nullable<am4charts.XYChart> = null;
  private _amChartsLegendContainer: API.Nullable<am4core.Container> = null;
  private readonly _chartId: string = this.props.id;
  private readonly _chartLegendId = `${this.props.id}-legend`;
  private readonly _chartStyle: API.Entities.Statistics.ChartStyle = {
    tensionX: 0.77,
    tensionY: 1,
    strokeWidth: 1,
    fillOpacity: 0.6,
    sequencedInterpolation: true
  };

  readonly state: State = {
    showNoDataWarning: false,
    consumer: null
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.days !== this.props.days
    ) {
      this.updateStats();
    }
  }

  componentDidMount() {
    this.initGraph();
    this.updateStats();
  }

  componentWillUnmount() {
    this._amChartsLpVisit && this._amChartsLpVisit.dispose();
    this._amChartsLegendContainer && this._amChartsLegendContainer.dispose();
  }

  /**
   * Handles when the `ConsumerProfile` is called to close.
   */
  @autobind
  handleConsumerProfileCallToClose() {
    this.setState({
      consumer: null
    });
  }

  /**
   * Handles when a `Consumer` has been updated.
   */
  @autobind
  handleConsumerProfileUpdated() {
    this.setState({
      consumer: null
    });
  }

  @autobind
  handleOpenConsumerProfile(relmId: string): void {
    if (
      !this.props.currentStatistics ||
      !this.props.currentStatistics.consumersById
    ) {
      return;
    }

    if (!(relmId in this.props.currentStatistics.consumersById)) {
      this.setState({
        consumer: null
      });
      toast(SnackbarVariant.WARNING, `This consumer doesn't exist anymore`);

      return;
    }
    this.setState({
      consumer: this.props.currentStatistics.consumersById[relmId][0]
    });
  }

  private static _setTooltip(series: am4charts.XYSeries, field?: string): void {
    if (field !== undefined) {
      series.tooltipText = `[#000]${field}: [bold #000]{${field}}`;
    }
    if (series.tooltip) {
      series.tooltip.getStrokeFromObject = true;
      series.tooltip.background.strokeWidth = 2;
      series.tooltip.getFillFromObject = false;
    }
  }

  private static _setMarker(
    markerTemplate: am4core.Container,
    isBig: boolean
  ): am4core.Container {
    if (isBig) {
      markerTemplate.width = 25;
      markerTemplate.height = 15;

      return markerTemplate;
    }
    markerTemplate.width = 15;
    markerTemplate.height = 10;
    markerTemplate.fillOpacity = 0.8;

    return markerTemplate;
  }

  private static _createLegend(): am4charts.Legend {
    const legend = new am4charts.Legend();

    legend.position = 'bottom';

    return legend;
  }

  private _createCursor(dateAxis: am4charts.DateAxis): am4charts.XYCursor {
    const cursor = new am4charts.XYCursor();

    cursor.xAxis = dateAxis;
    cursor.fullWidthLineX = true;
    cursor.lineX.strokeWidth = this._chartStyle.strokeWidth;
    cursor.lineX.strokeDasharray = '';
    cursor.tooltipText = '{valueY}';
    cursor.adapter.add('tooltipText', label => label);
    cursor.lineX.fill = am4core.color('#cccccc');
    cursor.lineX.fillOpacity = 0.1;
    cursor.lineX.stroke = am4core.color('#777777');
    cursor.lineY.disabled = true;

    return cursor;
  }

  private static _createScrollbar(): am4core.Scrollbar {
    const scrollbar = new am4core.Scrollbar();

    scrollbar.showOnInit = false;
    scrollbar.hideGrips = true;
    scrollbar.startGrip.disabled = true;
    scrollbar.endGrip.disabled = true;
    scrollbar.showSystemTooltip = false;
    scrollbar.hide();

    return scrollbar;
  }

  /**
   * Draw the column's gradient
   *
   * @returns {LinearGradientModifier}
   * @private
   */
  private static _fillModifier() {
    const fillModifier = new am4core.LinearGradientModifier();

    fillModifier.opacities = [1, 0.7];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;

    return fillModifier;
  }

  /**
   * Create series
   *
   * @param {API.Nullable<string>} field
   * @param {string} name
   * @param {Color} color
   *
   * @return {ColumnSeries}
   * @private
   */
  private _createSeries(
    field: API.Nullable<string>,
    name: string,
    color: am4core.Color
  ) {
    if (this._amChartsLpVisit === null) {
      return;
    }
    if (field === null) {
      return;
    }
    // Series
    const series = this._amChartsLpVisit.series.push(
      new am4charts.ColumnSeries()
    );

    series.dataFields.dateX = 'date';
    series.dataFields.valueY = field;
    series.sequencedInterpolation = false;
    series.name = name;
    series.stacked = true;
    series.columns.template.fill = color;
    series.columns.template.stroke = color;
    series.columns.template.fillOpacity = 1;
    series.columns.template.strokeWidth = this._chartStyle.strokeWidth;
    series.tooltipPosition = 'pointer';
    series.columns.template.fillModifier = StatisticsVisitsChart._fillModifier();

    StatisticsVisitsChart._setTooltip(series);
    series.columns.template.adapter.add('tooltipText', (label, target) => {
      if (target.dataItem?.values) {
        return `[#000]{name}: [bold #000]${secondsToHms(
          target.dataItem.values.valueY.workingValue
        )}`;
      }

      return '';
    });
    series.columns.template.events.on(
      'hit',
      event => {
        if (
          event.target.dataItem === undefined ||
          event.target.dataItem.component === undefined
        ) {
          return;
        }
        const relmId = JSON.stringify(
          event.target.dataItem.component.dataFields
        );

        return this.handleOpenConsumerProfile(
          JSON.parse(relmId).valueY.toString()
        );
      },
      this._amChartsLpVisit
    );

    return series;
  }

  /**
   * Auto toggle scrollBar visibility on Zoom change
   *
   * @param {am4charts.XYChart} chart
   */
  private _scrollBarAutoToggle(chart: am4charts.XYChart) {
    chart.zoomOutButton.events.on('visibilitychanged', event => {
      chart.scrollbarX[event.visible ? 'show' : 'hide']();
      if (chart.scrollbarY) {
        chart.scrollbarY[event.visible ? 'show' : 'hide']();
      }
    });
  }

  @autobind
  initGraph() {
    // am4core.useTheme(am4themes_material);
    this._amChartsLpVisit = am4core.create(this._chartId, am4charts.XYChart);
    this._amChartsLpVisit.language.locale = am4LangEn;
    this._amChartsLpVisit.numberFormatter.numberFormat = '#.';

    // X Axis
    const dateAxis = this._amChartsLpVisit.xAxes.push(new am4charts.DateAxis());

    dateAxis.dateFormatter.inputDateFormat = 'i';
    dateAxis.renderer.cellStartLocation = 0.01;
    dateAxis.renderer.cellEndLocation = 0.99;
    dateAxis.renderer.grid.template.opacity = 0;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.renderer.labels.template.verticalCenter = 'middle';
    dateAxis.renderer.labels.template.horizontalCenter = 'middle';

    // Y Axis
    const durationAxis = this._amChartsLpVisit.yAxes.push(
      new am4charts.ValueAxis()
    );

    durationAxis.min = 0;
    durationAxis.renderer.grid.template.opacity = 0.8;
    durationAxis.renderer.ticks.template.length = 10;
    durationAxis.title.text = 'Time Spent on Landing Page';
    durationAxis.renderer.baseGrid.disabled = true;
    durationAxis.renderer.labels.template.adapter.add('text', label => {
      if (label) {
        return secondsToHms(parseInt(label, 10), false);
      }

      return '';
    });
    durationAxis.cursorTooltipEnabled = false;

    this._amChartsLpVisit.scrollbarX = StatisticsVisitsChart._createScrollbar();
    this._amChartsLpVisit.scrollbarY = StatisticsVisitsChart._createScrollbar();
    this._scrollBarAutoToggle(this._amChartsLpVisit);
    this._amChartsLpVisit.cursor = this._createCursor(dateAxis);
    this._amChartsLpVisit.cursor.behavior = 'zoomXY';

    // Add legend
    this._amChartsLegendContainer = am4core.create(
      this._chartLegendId,
      am4core.Container
    );
    this._amChartsLegendContainer.width = am4core.percent(100);
    this._amChartsLegendContainer.height = am4core.percent(100);

    // eslint-disable-next-line no-multi-assign
    const legend = (this._amChartsLpVisit.legend = StatisticsVisitsChart._createLegend());

    legend.parent = this._amChartsLegendContainer;
    StatisticsVisitsChart._setMarker(legend.markers.template, false);

    const resizeLegend = () => {
      const cont = document.getElementById(this._chartLegendId);

      if (cont === null) {
        return;
      }
      const t = setTimeout(() => {
        cont.style.height = `${legend.contentHeight}px`;
        clearTimeout(t);
      }, 100);
    };

    this._amChartsLpVisit.events.on('datavalidated', resizeLegend);
    this._amChartsLpVisit.events.on('maxsizechanged', resizeLegend);
    this._amChartsLpVisit.events.on('ready', this.updateStats);
  }

  @autobind
  updateStats() {
    if (this.props.value === null) {
      return;
    }
    if (!this.props.currentStatistics) {
      throw new Error('currentStatistics not loaded');
    }
    const consumerTimeSpentByDays = StatsApi.dailyVisitsAmChartsFormated(
      this.props.value,
      this.props.days
    );

    const consumerIdList: string[] = [];

    consumerTimeSpentByDays.forEach(day => {
      Object.keys(day)
        // todo: the type for "day" only has "date", so it must be incorrect
        .filter(key => key !== 'date')
        .forEach(consumerId => {
          if (!consumerIdList.includes(consumerId)) {
            consumerIdList.push(consumerId);
          }
        });
    });

    this.setState({ showNoDataWarning: consumerIdList.length === 0 });

    if (this._amChartsLpVisit === null || !this._amChartsLpVisit.isReady()) {
      return;
    }
    this._amChartsLpVisit.series.clear();
    this._amChartsLpVisit.data = consumerTimeSpentByDays;
    const colorSet = new am4core.ColorSet();

    this.props.currentStatistics.consumers
      .filter(consumer => consumerIdList.includes(consumer.id))
      .sort((a, b) =>
        a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1
      )
      .forEach(consumer =>
        this._createSeries(consumer.id, consumer.fullName, colorSet.next())
      );
  }

  // region render & get-render-content methods
  render() {
    if (!this.props.currentStatistics) {
      throw new Error('currentStatistics not loaded');
    }

    return (
      <CardContent className={this.props.classes.root}>
        {this.props.value === null && (
          <div className={this.props.classes.progress}>
            <CenteredCircularProgress disableOutline />
          </div>
        )}
        <StatisticsNoDataWarning
          show={this.state.showNoDataWarning}
          warningMessage="There have been no visits during this timeframe."
        />
        <div
          id={this._chartId}
          className={this.props.classes.chart}
          hidden={!this.props.currentStatistics.events.length}
        />
        <div className={this.props.classes.legendWrapper}>
          <div id={this._chartLegendId} />
        </div>
        {this.state.consumer !== null && (
          <>
            {this.state.consumer.personaId === null && (
              <ConsumerProfileDialog
                open
                consumer={this.state.consumer}
                onCallToClose={this.handleConsumerProfileCallToClose}
                onConsumerUpdated={this.handleConsumerProfileUpdated}
              />
            )}
            {this.state.consumer.personaId !== null && (
              <CurrentPersonaProvider personaId={this.state.consumer.personaId}>
                <ConsumerProfileDialog
                  open
                  consumer={this.state.consumer}
                  onCallToClose={this.handleConsumerProfileCallToClose}
                  onConsumerUpdated={this.handleConsumerProfileUpdated}
                />
              </CurrentPersonaProvider>
            )}
          </>
        )}
      </CardContent>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StatisticsVisitsChart.jssName })(
  StatisticsVisitsChart
);
