import {
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Modal,
  RootRef,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ModalProps } from '@material-ui/core/Modal';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { MoreHoriz } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { API } from 'src/definitions';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden'
    },
    actionsModal: {
      position: 'absolute'
    },
    typography: {
      textAlign: 'left',
      whiteSpace: 'normal',
      fontSize: Math.round(theme.spacing(2.1)),
      lineHeight: `${Math.round(theme.spacing(3))}px`,
      flexGrow: 1,
      maxWidth: 230
    },
    icon: {
      padding: 0,
      alignSelf: 'flex-start'
    },
    popper: {
      zIndex: 50000
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  idOfLaneWithMenuOpen?: API.Nullable<string>;
  idOfLaneBeingLoadedFor?: API.Nullable<string>;

  actionsModalBuilder?: (props: Props) => React.ReactElement<ModalProps>;

  onMenuIconClick?: (event: TrelloLaneHeaderEvent) => void;
  onMenuClickAway?: (event: TrelloLaneHeaderEvent) => void;
}

export interface TrelloLaneHeaderEvent {
  laneId: string;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  Pick<ReactTrello.LaneHeaderProps, 'title' | 'id' | 'cards'> &
  WithStyles<typeof styles>;

export type TrelloLaneHeaderProps = Props;

interface State {}

// endregion

/**
 *
 */
class TrelloLaneHeader extends React.Component<Props, State> {
  static readonly defaultProps = {
    id: '',
    title: '',
    cards: [],
    idOfLaneWithMenuOpen: undefined,
    idOfLaneBeingLoadedFor: undefined,
    actionsModalBuilder: undefined,
    onMenuIconClick: () => {},
    onMenuClickAway: () => {}
  };

  readonly state: State = {};

  private readonly _menuAnchorRef: React.RefObject<
    HTMLElement
  > = React.createRef();

  // region autobound methods
  /**
   * Handles when the menu `IconButton` is clicked.
   */
  @autobind
  handleMenuIconClick() {
    this.props.onMenuIconClick({ laneId: this.props.id });
  }

  /**
   * Handles when the menu is clicked away from.
   *
   * @param {React.ChangeEvent<{}>} event
   */
  @autobind
  handleMenuClickAway(event: React.ChangeEvent<{}>) {
    if (
      this._menuAnchorRef.current &&
      this._menuAnchorRef.current.contains(event.target as Element)
    ) {
      return;
    }

    this.props.onMenuClickAway({ laneId: this.props.id });
  }

  // endregion
  // region render & get-render-content methods
  /**
   * Gets the content to render for the header `Menu`.
   *
   * @return {any}
   */
  getMenuRenderContent() {
    if (!this.props.actionsModalBuilder) {
      return null;
    }

    return (
      <>
        <RootRef rootRef={this._menuAnchorRef}>
          <IconButton
            className={this.props.classes.icon}
            onClick={this.handleMenuIconClick}
          >
            {this.getProgressRenderContent(24) ?? <MoreHoriz />}
          </IconButton>
        </RootRef>
        <Modal
          className={this.props.classes.actionsModal}
          open={this.props.id === this.props.idOfLaneWithMenuOpen}
          style={{ position: 'absolute' }}
          disablePortal
          hideBackdrop
        >
          <div>
            <ClickAwayListener onClickAway={this.handleMenuClickAway}>
              {this.props.actionsModalBuilder(this.props)}
            </ClickAwayListener>
          </div>
        </Modal>
      </>
    );
  }

  getProgressRenderContent(size: number) {
    if (this.props.id !== this.props.idOfLaneBeingLoadedFor) {
      return null;
    }

    return (
      <CircularProgress
        thickness={7}
        style={{
          width: `${size}px`,
          height: `${size}px`
        }}
      />
    );
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Typography variant="h6" className={this.props.classes.typography}>
          {this.props.title}
        </Typography>
        {this.getMenuRenderContent() ?? this.getProgressRenderContent(20)}
      </div>
    );
  }

  // endregion
}

export default withStyles(styles)(TrelloLaneHeader);
