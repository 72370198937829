import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  TextField,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { PlaylistAdd } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactTrello from 'react-trello';
import { AddCardLink, AreYouSureDialog } from 'src/components';
import AddStepLaneTemplate from 'src/components/AddStepLaneTemplate';
import AddStoryCardTemplate from 'src/components/AddStoryCardTemplate';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import EditStepDialog from 'src/components/EditStepDialog';
import EditStoryDialog from 'src/components/EditStoryDialog';
import ListItemButton from 'src/components/ListItemButton';
import ListItemCollapse, {
  ListItemCollapseEvent
} from 'src/components/ListItemCollapse';
import StoryTrelloCard, {
  StoryCardEvent
} from 'src/components/StoryTrelloCard';
import TrelloLaneHeader, {
  TrelloLaneHeaderEvent,
  TrelloLaneHeaderProps
} from 'src/components/TrelloLaneHeader';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import { API, ObjectMap } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import ValidationException from 'src/exceptions/ValidationException';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import {
  buildTrelloCardFromStory,
  buildTrelloLaneFromStep,
  calculateObjectPositions,
  groupObjectsByStringProperty,
  trelloClasses
} from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0
    },
    ...trelloClasses(theme),
    actionsCard: {
      maxHeight: `calc(100vh - ${theme.spacing(24)}px)`,
      overflowY: 'auto'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentPersonaValue &
  WithDataFromApiProps<'stories', API.Stories.ListStoriesOfPersona.Response> &
  WithStyles<typeof styles>;

interface State {
  boardData: ReactTrello.BoardData<API.Entities.Story>;
  idOfStoryBeingEdited: API.Nullable<API.Entities.Story['id']>;
  idOfLaneWithMenuOpen: API.Nullable<ReactTrello.Lane['id']>;
  idOfLaneLoadingFor: API.Nullable<ReactTrello.Lane['id']>;
  idOfStepBeingEdited: API.Nullable<API.Entities.Step['id']>;
  idOfStepToDelete: API.Nullable<API.Entities.Step['id']>;
  isDeletingStep: boolean;
  expandedList: ExpandKey;
  titleFilterKeys: ObjectMap<string>;
}

// endregion

enum ExpandKey {
  NONE = 'none',
  SORT = 'sort',
  FILTER = 'filter'
}

const defaultStep: API.Entities.Step = {
  personaId: 'ignore',
  id: '$available-stories',
  name: 'Available Stories',
  position: 0
};

@withCurrentPersona<Props>()
@withDataFromApi<Props>(
  props =>
    RelmApi.listSellerStories(props.currentPersona.sellerId).then(
      ({ data }) => ({
        data: data
          .filter(story => story.consumerId === null)
          .map(story => ({
            ...story,
            stepId:
              story.personaId === props.currentPersona.id ? story.stepId : null
          }))
      })
    ),
  'stories'
)
class StoriesBoard extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = StoriesBoard.name;

  readonly state: State = {
    boardData: { lanes: this.buildLanes() },
    idOfStoryBeingEdited: null,
    idOfLaneWithMenuOpen: null,
    idOfLaneLoadingFor: null,
    idOfStepBeingEdited: null,
    idOfStepToDelete: null,
    isDeletingStep: false,
    expandedList: ExpandKey.NONE,
    titleFilterKeys: {}
  };

  /**
   * @inheritDoc
   *
   * @param {Readonly<Props>} prevProps
   * @param {Readonly<State>} prevState
   * @param [snapshot]
   */
  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.classes !== this.props.classes) {
      this.setState({ boardData: { lanes: this.buildLanes() } });
    } // rebuild the board whenever classes change, b/c new class names

    if (
      prevState.titleFilterKeys !== this.state.titleFilterKeys &&
      this.state.idOfLaneWithMenuOpen
    ) {
      this.filterStories();
    }
  }

  /**
   * Filter the stories in the lane the user
   * is currently filtering.
   */
  filterStories() {
    // Keep typescript happy, prevent potentially null value for stepId
    if (!this.state.idOfLaneWithMenuOpen) {
      return;
    }

    const stepId = this.state.idOfLaneWithMenuOpen;
    const step = [defaultStep, ...this.props.currentPersona!.steps].find(
      step => step.id === stepId
    );
    const filter = this.state.titleFilterKeys[stepId].toLowerCase();
    const groupedStories = groupObjectsByStringProperty(
      this.props.stories,
      'stepId',
      defaultStep.id
    );

    // if we don't have a step or the step has no stories, we might as well stop here
    if (!step || !groupedStories.hasOwnProperty(stepId)) {
      return;
    }

    const filteredStories = groupedStories[stepId].filter(story =>
      story.title.toLowerCase().includes(filter)
    );
    const lanePosition = this.state.boardData.lanes.findIndex(
      lane => lane.id === stepId
    );
    const lanes = this.state.boardData.lanes.filter(lane => lane.id !== stepId);

    lanes.splice(
      lanePosition,
      0,
      buildTrelloLaneFromStep(
        step,
        this.props.classes.trelloLane,
        filteredStories
          .sort((a, b) => a.position - b.position)
          .map(card =>
            buildTrelloCardFromStory(card, this.props.classes.trelloCard)
          )
      )
    );

    this.setState({ boardData: { lanes } });
  }

  /**
   * Finds the `Story` that has the given `id`.
   *
   * `null` is returned when no `Story` exists
   * with the given `id`.
   *
   * @param {API.Entities.Story["id"]} storyId
   *
   * @return {API.Nullable<API.Entities.Story>}
   */
  findStoryById(
    storyId: API.Entities.Story['id']
  ): API.Nullable<API.Entities.Story> {
    return this.props.stories.find(story => story.id === storyId) ?? null;
  }

  /**
   * Load data from the api, marking the lane with the given id as being 'in progress'.
   *
   * @param {string} forLane
   *
   * @return {Promise<void>}
   */
  async loadFromApi(forLane: API.Nullable<string> = null) {
    if (forLane) {
      this.setState({ idOfLaneLoadingFor: forLane });
    }

    await this.props.loadFromApi();

    this.setState({ idOfLaneLoadingFor: null });
  }

  /**
   * Tries to move the `Step` with the given `id` to the given `position`.
   *
   * @param {string} stepId
   * @param {number} position
   */
  async tryMoveStep(stepId: string, position: number) {
    try {
      await RelmApi.updateStep(this.props.currentPersona!.id, stepId, {
        position
      });
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to update step'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    this.props.onCurrentPersonaChange();
  }

  /**
   * Tries to move the `Story` with the given `id`.
   *
   * @param {string} storyId
   * @param {API.Nullable<string>} stepId
   * @param {number?} [position]
   */
  async tryMoveStory(
    storyId: string,
    stepId: API.Nullable<string>,
    position?: number
  ) {
    if (stepId) {
      this.setState({ idOfLaneLoadingFor: stepId });
    }

    try {
      await RelmApi.updateStory(this.props.currentPersona!.id, storyId, {
        stepId: stepId,
        position
      });
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to updating story'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    await this.loadFromApi(stepId ?? defaultStep.id);
  }

  /** d
   * Tries to clone a given `Story`.
   *
   * @param {API.Entities.Story} story
   * @param {API.Nullable<string>} stepId
   * @param {number?} [position]
   *
   * @return {Promise<API.Nullable<API.Entities.Story>>}
   */
  async tryCloneStory(
    story: API.Entities.Story,
    stepId: API.Nullable<string>,
    position?: number
  ): Promise<API.Nullable<API.Entities.Story>> {
    let clonedStory = null;

    try {
      clonedStory = (
        await RelmApi.createStory(this.props.currentPersona!.id, {
          ...story,
          stepId,
          position
        })
      ).data;

      toast(SnackbarVariant.SUCCESS, 'Story copied successfully');
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to clone story'
      );
    }

    return clonedStory;
  }

  /**
   * Tries to delete the `Step` with the given `id`.
   *
   * @param {string} stepId
   *
   * @return {Promise<void>}
   */
  async tryDeleteStep(stepId: string) {
    try {
      await RelmApi.deleteStep(this.props.currentPersona!.id, stepId);
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong while trying to delete step'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    toast(SnackbarVariant.SUCCESS, 'Successfully deleted step');

    await Promise.all([
      this.props.onCurrentPersonaChange(),
      this.props.loadFromApi()
    ]);
  }

  // region autobound methods
  @autobind
  handleLaneDragEnd(
    oldPosition: number,
    newPosition: number,
    lane: ReactTrello.Lane
  ) {
    if (lane.id.startsWith('$') || newPosition === 0) {
      this.forceUpdate();

      return;
    } // force an update to restore lane positions

    const positionedLanes = this.state.boardData.lanes.map(
      (lane, position) => ({ lane, position })
    );

    this.setState({
      boardData: {
        lanes: calculateObjectPositions(
          positionedLanes,
          positionedLanes[oldPosition],
          newPosition + 1
        ).map(({ lane }) => lane)
      }
    });

    load(this.tryMoveStep(lane.id, newPosition));
  }

  @autobind
  async handleStoryCardDragEnd(
    cardId: string,
    sourceLaneId: string,
    targetLaneId: string,
    position: number,
    cardDetails: ReactTrello.Card<API.Entities.Story>
  ) {
    if (
      sourceLaneId === '$available-stories' &&
      sourceLaneId === targetLaneId
    ) {
      return;
    } // Do nothing in available-stories

    if (!cardDetails.metadata) {
      throw new Error('A card without metadata was dragged');
    }

    const currentPosition = cardDetails.metadata.position;
    // api positions are 1-indexed
    const comparePosition = currentPosition === 1 ? position : position + 1;

    if (sourceLaneId === targetLaneId && currentPosition === comparePosition) {
      return;
    } // nothings changed, so ignore...

    const stepId = targetLaneId.startsWith('$') ? null : targetLaneId;
    const finalPosition =
      currentPosition === comparePosition ? undefined : comparePosition;

    if (cardDetails.metadata.personaId === this.props.currentPersona!.id) {
      await this.tryMoveStory(cardDetails.metadata.id, stepId, finalPosition);
      this.setState({ boardData: { lanes: this.buildLanes() } });

      return;
    } // stories owned by the current persona don't need to be cloned

    this.setState({ boardData: { lanes: this.buildLanes() } });
    const clonedStory = await load(
      this.tryCloneStory(cardDetails.metadata, stepId, finalPosition)
    );

    if (clonedStory) {
      await load(this.props.loadFromApi()).then(() =>
        this.setState({ boardData: { lanes: this.buildLanes() } })
      );
    }
  }

  /**
   * Handles when a card for a `Story` is clicked.
   *
   * @param {StoryCardEvent} event
   */
  @autobind
  async handleStoryCardClick(event: StoryCardEvent) {
    this.setState({ idOfStoryBeingEdited: event.story.id });
  }

  @autobind
  handleStoryEditDialogClose(wasUpdated: boolean) {
    this.setState({ idOfStoryBeingEdited: null });

    if (wasUpdated) {
      load(this.props.loadFromApi()).then(() =>
        this.setState({ boardData: { lanes: this.buildLanes() } })
      );
    }
  }

  @autobind
  async handleStepEditDialogClose(wasUpdated: boolean) {
    if (wasUpdated) {
      await load(this.props.onCurrentPersonaChange());

      this.setState({ boardData: { lanes: this.buildLanes() } });
    }

    this.setState({ idOfStepBeingEdited: null });
  }

  @autobind
  handleCancelDeleteStep() {
    this.setState({ idOfStepToDelete: null });
  }

  @autobind
  async handleActionDeleteStep() {
    if (this.state.idOfStepToDelete) {
      this.setState({ isDeletingStep: true });

      await load(this.tryDeleteStep(this.state.idOfStepToDelete));

      this.setState({
        boardData: { lanes: this.buildLanes() },
        idOfStepToDelete: null,
        isDeletingStep: false
      });
    }
  }

  /**
   * Handles when the menu icon of a `TrelloLaneHeader` is clicked.
   *
   * @param {TrelloLaneHeaderEvent} event
   */
  @autobind
  handleLaneHeaderMenuIconClick(event: TrelloLaneHeaderEvent) {
    const idOfLaneWithMenuOpen =
      this.state.idOfLaneWithMenuOpen === event.laneId ? null : event.laneId;

    this.setState({ idOfLaneWithMenuOpen });
  }

  /**
   * Handles when the menu of a `TrelloLaneHeader` is closed.
   */
  @autobind
  handleLaneHeaderMenuClickAway() {
    this.setState({
      idOfLaneWithMenuOpen: null,
      expandedList: ExpandKey.NONE
    });
  }

  @autobind
  handleDeleteStepClick(event: { laneId: string }) {
    const targetLaneId = event.laneId;

    if (targetLaneId === defaultStep.id) {
      console.warn('ignoring request to delete ghost step!');

      return;
    } // just in case...

    this.setState({
      idOfLaneWithMenuOpen: null,
      idOfStepToDelete: targetLaneId
    });
  }

  @autobind
  handleRenameStepClick(event: { laneId: string }) {
    const targetLaneId = event.laneId;

    if (targetLaneId === defaultStep.id) {
      console.warn('ignoring request to rename ghost step!');

      return;
    } // just in case...

    this.setState({
      idOfLaneWithMenuOpen: null,
      idOfStepBeingEdited: targetLaneId
    });
  }

  @autobind
  handleBoardDataChange(newData: ReactTrello.BoardData<API.Entities.Story>) {
    // console.log(newData);

    this.setState({ boardData: newData });
  }

  @autobind
  handleStoryAdded(stepId: API.Entities.Step['id']) {
    load(this.loadFromApi(stepId));
  }

  @autobind
  async handleStepAdded() {
    await load(this.props.onCurrentPersonaChange());

    this.setState({ boardData: { lanes: this.buildLanes() } });
  }

  /**
   *
   * @param {ListItemCollapseEvent} event
   */
  @autobind
  handleExpandListClick(event: ListItemCollapseEvent) {
    const expandedList = event.expandKey as ExpandKey;

    this.setState({ expandedList });
  }

  @autobind
  handleTitleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;

    this.setState({
      titleFilterKeys: { ...this.state.titleFilterKeys, [id]: value }
    });
  }

  // endregion
  // region render & get-render-content methods
  /**
   *
   * @return {Array<ReactTrello.Lane>}
   */
  buildLanes(): Array<ReactTrello.Lane<API.Entities.Story>> {
    const classes = this.props.classes;

    const groupedStories = groupObjectsByStringProperty(
      this.props.stories,
      'stepId',
      defaultStep.id
    );
    const sortedSteps = [defaultStep, ...this.props.currentPersona!.steps].sort(
      (a, b) => a.position - b.position
    );

    return sortedSteps.map(step =>
      buildTrelloLaneFromStep(
        step,
        classes.trelloLane,
        (groupedStories[step.id] || [])
          .sort((a, b) => a.position - b.position)
          .map(card => buildTrelloCardFromStory(card, classes.trelloCard))
      )
    );
  }

  getActionsModalBuilderRenderContent(props: TrelloLaneHeaderProps) {
    return (
      <Card className={this.props.classes.actionsCard} tabIndex={-1}>
        <CardContent>
          <Typography variant="subtitle2">Actions</Typography>
        </CardContent>
        <Divider />
        <List dense disablePadding>
          {props.id !== defaultStep.id && (
            <>
              <ListItemButton<{ laneId: string }>
                label="Rename step"
                clickData={{ laneId: props.id }}
                onClick={this.handleRenameStepClick}
              />
              <ListItemButton<{ laneId: string }>
                label="Delete step"
                divider
                clickData={{ laneId: props.id }}
                onClick={this.handleDeleteStepClick}
              />
            </>
          )}
          <ListItemCollapse
            in={this.state.expandedList === ExpandKey.FILTER}
            expandKey={ExpandKey.FILTER}
            header="Filter by..."
            onClick={this.handleExpandListClick}
          >
            <List dense disablePadding>
              <ListItem>
                <TextField
                  id={props.id}
                  fullWidth
                  label="Title"
                  margin="dense"
                  value={this.state.titleFilterKeys[props.id] || ''}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={this.handleTitleFilterChange}
                />
              </ListItem>
            </List>
          </ListItemCollapse>
        </List>
      </Card>
    );
  }

  render() {
    if (!this.props.currentPersona) {
      throw new Error('currentPersona not loaded');
    } // shouldn't happen, but TypeScript doesn't realise that

    return (
      <>
        <div className={this.props.classes.root}>
          <ReactTrello<API.Entities.Story>
            className={this.props.classes.trelloBoard}
            draggable
            editable
            hideCardDeleteIcon
            customCardLayout
            addLaneTitle="Add a step..."
            addCardLink={
              <AddCardLink text="Add a story">
                <PlaylistAdd />
              </AddCardLink>
            }
            newLaneTemplate={
              <AddStepLaneTemplate onStepAdded={this.handleStepAdded} />
            }
            newCardTemplate={
              <AddStoryCardTemplate onStoryAdded={this.handleStoryAdded} />
            }
            customLaneHeader={
              <TrelloLaneHeader
                idOfLaneWithMenuOpen={this.state.idOfLaneWithMenuOpen}
                idOfLaneBeingLoadedFor={this.state.idOfLaneLoadingFor}
                actionsModalBuilder={props =>
                  this.getActionsModalBuilderRenderContent(props)
                }
                onMenuIconClick={this.handleLaneHeaderMenuIconClick}
                onMenuClickAway={this.handleLaneHeaderMenuClickAway}
              />
            }
            data={this.state.boardData}
            handleLaneDragEnd={this.handleLaneDragEnd}
            handleDragEnd={this.handleStoryCardDragEnd}
            onDataChange={this.handleBoardDataChange}
          >
            <StoryTrelloCard
              rootClassName={this.props.classes.trelloCardInner}
              currentPersonaId={this.props.currentPersona.id}
              onCardClick={this.handleStoryCardClick}
            />
          </ReactTrello>
        </div>
        {this.state.idOfStoryBeingEdited !== null && (
          <EditStoryDialog
            open
            story={this.findStoryById(this.state.idOfStoryBeingEdited)!}
            onClose={this.handleStoryEditDialogClose}
          />
        )}
        {this.state.idOfStepBeingEdited !== null && (
          <EditStepDialog
            open
            step={
              this.props.currentPersona.steps.find(
                step => step.id === this.state.idOfStepBeingEdited
              )!
            }
            onClose={this.handleStepEditDialogClose}
          />
        )}
        {this.state.idOfStepToDelete !== null && (
          <AreYouSureDialog
            open
            loading={this.state.isDeletingStep}
            onCancel={this.handleCancelDeleteStep}
            onAction={this.handleActionDeleteStep}
            actionText="Delete step"
          >
            Are you sure you want to delete this step?
            <br />
            All stories & consumers currently in this step will be moved back to
            'available'.
            <br />
            <br />
            <strong>This action cannot be undone.</strong>
          </AreYouSureDialog>
        )}
      </>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StoriesBoard.jssName })(StoriesBoard);
