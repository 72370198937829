import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Authorization.ForgotPassword.Request>(
  {
    email: {
      required: true,
      type: 'string',
      match: regexps.email,
      message: {
        required: 'Email is required',
        match: 'A valid email address is required'
      }
    }
  },
  { strip: false }
);
