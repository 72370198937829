import {
  Grid,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { LocalCafe } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import { format } from 'date-fns';
import * as React from 'react';
import { StatisticsNoDataWarning } from 'src/components';
import EngagementExpansionPanel, {
  EngagementExpansionPanelEvent
} from 'src/components/EngagementExpansionPanel';
import { API } from 'src/definitions';
import RelmApi from 'src/services/RelmApi';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';

// region component styles
const styles = createStyles({
  root: {}
});

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  consumer: API.Entities.Consumer;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  WithDataFromApiProps<
    'engagements',
    API.Consumers.ListEngagementsOfConsumer.Response
  > &
  WithStyles<typeof styles>;

interface State {
  idOfExpandedEngagement: API.Nullable<API.Entities.Engagement['id']>;
}

// endregion

/**
 *
 */
@withDataFromApi<Props>(
  props =>
    RelmApi.listConsumerEngagements(props.consumer.sellerId, props.consumer.id),
  'engagements',
  false
)
class ConsumerEngagementsAccordion extends React.Component<Props, State> {
  static readonly defaultProps = {
    engagements: undefined,
    loadFromApi: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = ConsumerEngagementsAccordion.name;

  readonly state: State = {
    idOfExpandedEngagement: null
  };

  // region autobound methods
  @autobind
  handleExpansionChange(event: EngagementExpansionPanelEvent) {
    const engagementId = event.engagement.id;

    this.setState(prevState => ({
      idOfExpandedEngagement:
        engagementId === prevState.idOfExpandedEngagement ? null : engagementId
    }));
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <>
        {this.props.engagements.map(engagement => (
          <EngagementExpansionPanel
            key={engagement.id}
            engagement={engagement}
            expanded={this.state.idOfExpandedEngagement === engagement.id}
            onChange={this.handleExpansionChange}
          />
        ))}
        {this.props.engagements.length === 0 && (
          <div className={this.props.classes.root}>
            <StatisticsNoDataWarning
              show
              warningMessage="This consumer has not been engaged."
              icon={<LocalCafe />}
            />
          </div>
        )}
        {this.props.engagements.length > 0 && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="caption">
                Local time: {format(new Date(), `O`)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  // endregion
}

export default withStyles(styles, {
  name: ConsumerEngagementsAccordion.jssName
})(ConsumerEngagementsAccordion);
