import { API } from 'src/definitions';

/**
 * Extracts all the `salesforceCampaignName`s from the given `Consumer`s,
 * filtering out both `null`s and duplicates.
 *
 * @param {Array<API.Entities.Consumer>} consumers
 *
 * @return {Array<string>}
 */
export const extractCampaignsFromConsumers = (
  consumers: API.Entities.Consumer[]
): string[] =>
  Array.from(
    new Set(consumers.map(consumer => consumer.salesforceCampaignName))
  ).filter((name): name is string => name !== null);
