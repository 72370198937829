import { API } from 'src/definitions';
import AddConsumerRequest from 'src/schemas/AddConsumerRequest';
import Schema from 'validate';

export default new Schema<API.Consumers.AddConsumerInBulk.Request>(
  {
    consumers: {
      required: true,
      type: 'array',
      each: AddConsumerRequest.props
    }
  },
  { strip: false }
);
