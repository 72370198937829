import {
  Grid,
  RootRef,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as Draft from 'draft-js';
import { DraftHandleValue, RichUtils } from 'draft-js';
import createLinkPlugin from 'draft-js-anchor-plugin';
import {
  BoldButton,
  HeadlineOneButton,
  HeadlineThreeButton,
  HeadlineTwoButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton
} from 'draft-js-buttons';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import 'draft-js-focus-plugin/lib/plugin.css';
import createImagePlugin from 'draft-js-image-plugin';
import 'draft-js-image-plugin/lib/plugin.css';
import createMentionPlugin, {
  MentionSearchChangeEvent,
  MentionSuggestion,
  defaultSuggestionsFilter
} from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import 'draft-js/dist/Draft.css';
import * as React from 'react';
import AddImageButton from 'src/components/editors/AddImageButton';
import AddLinkButton from 'src/components/editors/AddLinkButton';
import AddRelmLinkButton from 'src/components/editors/AddRelmLinkButton';
import { contentStateFromHtml } from 'src/components/editors/StoryEditor';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import { API } from 'src/definitions';
import createSideToolbarPlugin from 'src/draft-plugins/draft-js-side-toolbar-plugin';

const storyEditorImageCssClass = 'story-editor-image';
const draftEditorDefaultClass = 'public-DraftStyleDefault';

// Regex pattern for finding relm links that will not work
const brokenRelmLinkPattern = /<a .*? (?:href="(.*?%7Brelmlink%7D.*?)".*?title="{relmlink}"|title="{relmlink}".*?href="(.*?%7Brelmlink%7D.*?)") .*?>/g;
const brokenConsumerKeyPattern = /(%7Bconsumer_relm_key%7D)/g;
const maxImageWidth = 600;

export type ShortcodeSuggestion = MentionSuggestion;

// region component styles
const styles = () =>
  createStyles({
    root: {
      'overflowY': 'auto',
      'overflowX': 'hidden',
      'width': '100%',
      'minHeight': '25vh',
      'cursor': 'text',
      'fontSize': 13,
      'lineHeight': `1.5em`,
      'color': '#222222',
      [`& .${draftEditorDefaultClass}-block`]: {
        marginTop: 0,
        marginRight: 0,
        marginBottom: '1em',
        marginLeft: 0
      },
      [`& .${draftEditorDefaultClass}-ul, & .${draftEditorDefaultClass}-ol`]: {
        marginTop: '1em',
        marginRight: 0,
        marginBottom: '1em',
        marginLeft: 0,
        paddingLeft: 40
      },
      [`& .${draftEditorDefaultClass}-unorderedListItem, & .${draftEditorDefaultClass}-orderedListItem`]: {
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: '1.154em'
      },
      [`& .${draftEditorDefaultClass}-unorderedListItem > .${draftEditorDefaultClass}-block, & .${draftEditorDefaultClass}-orderedListItem > .${draftEditorDefaultClass}-block`]: {
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0
      },
      [`& .${storyEditorImageCssClass}`]: {
        maxWidth: '100%'
      },
      '& h1': {
        fontSize: 19,
        color: '#202020',
        marginTop: 0,
        marginRight: 0,
        marginBottom: '0.67em',
        marginLeft: 0
      },
      '& h2': {
        fontSize: 16,
        color: '#202020',
        marginTop: 0,
        marginRight: 0,
        marginBottom: '0.83em',
        marginLeft: 0
      },
      '& h3': {
        fontSize: 14,
        color: '#202020',
        marginTop: 0,
        marginRight: 0,
        marginBottom: '1em',
        marginLeft: 0
      },
      '& a': {
        color: '#1155cc'
      },
      '& figure': {
        marginTop: 13,
        marginRight: 3,
        marginBottom: 13,
        marginLeft: 3,
        maxWidth: maxImageWidth
      }
    }
  });

// endregion
// region component props
interface ExternalProps {
  className?: string;
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  shortcodes?: ShortcodeSuggestion[];
  stories?: API.Entities.Story[];
  editorState: Draft.EditorState;

  onChange: Draft.EditorProps['onChange'];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentPersonaValue & WithStyles<typeof styles>;

interface State {
  shortcodeSuggestions: ShortcodeSuggestion[];
}

// endregion

/**
 *
 */
@withCurrentPersona<Props>()
class EngageEditor extends React.Component<Props, State> {
  static readonly defaultProps = {
    className: '',
    shortcodes: [],
    stories: [],
    currentPersona: null,
    onCurrentPersonaChange: () => {}
  };

  /**
   *
   * @type {React.RefObject<Draft.Component.Base.DraftEditor>}
   * @private
   */
  private readonly _editorRef: React.RefObject<Editor> = React.createRef<
    Editor
  >();

  /**
   *
   * @type {React.RefObject<HTMLElement>}
   * @private
   */
  private readonly _rootRef: React.RefObject<HTMLElement> = React.createRef<
    HTMLElement
  >();

  private readonly _focusPlugin = createFocusPlugin();
  private readonly _blockDndPlugin = createBlockDndPlugin();

  private readonly _sideToolbarPlugin = createSideToolbarPlugin({
    position: 'left'
  });

  private readonly _imagePlugin = createImagePlugin({
    theme: { image: storyEditorImageCssClass },
    decorator: composeDecorators(
      this._focusPlugin.decorator,
      this._blockDndPlugin.decorator
    )
  });

  private readonly _mentionPlugin = createMentionPlugin({
    mentionTrigger: '{'
  });

  private readonly _linkPlugin = createLinkPlugin();

  private readonly _draftPlugins = [
    this._focusPlugin,
    this._blockDndPlugin,
    this._sideToolbarPlugin,
    this._mentionPlugin,
    this._imagePlugin,
    this._linkPlugin
  ];

  readonly state: State = {
    shortcodeSuggestions: []
  };

  // region autobound methods
  /**
   * Handles when the `root` element of this component is clicked.
   */
  @autobind
  handleRootClick() {
    this._editorRef.current && this._editorRef.current.focus();
  }

  @autobind
  handlePastedText(
    text: string,
    html?: string,
    editorState?: Draft.EditorState
  ): Draft.DraftHandleValue {
    if (!editorState || !html) {
      return 'not-handled';
    }

    // fix broken relm link shortcode
    if (html.includes('title="{relmlink}"')) {
      // eslint-disable-next-line no-param-reassign
      html = html.replace(brokenRelmLinkPattern, (match, href1, href2) =>
        match.replace(href1 || href2, '{relmlink}')
      );
    }
    // fix broken consumer key shortcode
    if (html.includes('%7Bconsumer_relm_key%7D')) {
      // eslint-disable-next-line no-param-reassign
      html = html.replace(brokenConsumerKeyPattern, (match, href) =>
        match.replace(href, '{consumer_relm_key}')
      );
    }
    const newState = Draft.Modifier.replaceWithFragment(
      this.props.editorState.getCurrentContent(),
      this.props.editorState.getSelection(),
      contentStateFromHtml(html).getBlockMap()
    );

    this.props.onChange(
      Draft.EditorState.push(
        this.props.editorState,
        newState,
        'insert-characters'
      )
    );

    return 'handled';
  }

  @autobind
  handleReturn(event: React.KeyboardEvent): DraftHandleValue {
    if (event.shiftKey) {
      this.props.onChange(RichUtils.insertSoftNewline(this.props.editorState));

      return 'handled';
    }

    return 'not-handled';
  }

  @autobind
  handleShortcodeSearchChange(event: MentionSearchChangeEvent) {
    this.setState({
      shortcodeSuggestions: defaultSuggestionsFilter(
        event.value,
        this.props.shortcodes
      )
    });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const { SideToolbar } = this._sideToolbarPlugin;
    const { MentionSuggestions } = this._mentionPlugin;

    return (
      <>
        <RootRef rootRef={this._rootRef}>
          <Typography
            className={cx(this.props.classes.root, this.props.className)}
            component="div"
            onClick={this.handleRootClick}
          >
            <Editor
              ref={this._editorRef}
              editorState={this.props.editorState}
              plugins={this._draftPlugins}
              spellCheck
              handlePastedText={this.handlePastedText}
              onChange={this.props.onChange}
              handleReturn={this.handleReturn}
            />
          </Typography>
        </RootRef>
        <SideToolbar scrollOffsetRef={this._rootRef}>
          {externalProps => (
            <Grid container>
              <Grid item md={6}>
                <ItalicButton {...externalProps} />
                <BoldButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <AddRelmLinkButton
                  editorState={this.props.editorState}
                  stories={this.props.stories}
                  steps={this.props.currentPersona?.steps}
                  onChange={this.props.onChange}
                  {...externalProps}
                />
                <AddLinkButton
                  editorState={this.props.editorState}
                  onChange={this.props.onChange}
                  {...externalProps}
                />
              </Grid>
              <Grid item md={6}>
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <AddImageButton
                  addImage={this._imagePlugin.addImage}
                  editorState={this.props.editorState}
                  maxImageWidth={maxImageWidth}
                  onChange={this.props.onChange}
                  {...externalProps}
                />
              </Grid>
            </Grid>
          )}
        </SideToolbar>
        <MentionSuggestions
          suggestions={this.state.shortcodeSuggestions}
          onSearchChange={this.handleShortcodeSearchChange}
        />
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(EngageEditor);
