import { Modal } from '@material-ui/core';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { CenteredCircularProgress } from 'src/components';
import Loader, {
  LoaderEvent,
  LoadingStartEvent,
  LoadingStopEvent
} from 'src/services/Loader';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  loading: boolean;
}

// endregion

/**
 *
 */
class LoadingOverlay extends React.Component<Props, State> {
  static readonly defaultProps = {};

  readonly state: State = {
    loading: false
  };

  // region component lifecycle methods
  /**
   * @inheritDoc
   */
  componentDidMount() {
    Loader.on(LoaderEvent.E_LOADING_START, this.handleLoadingStartEvent);
    Loader.on(LoaderEvent.E_LOADING_STOP, this.handleLoadingStopEvent);
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    Loader.removeListener(
      LoaderEvent.E_LOADING_START,
      this.handleLoadingStartEvent
    );
    Loader.removeListener(
      LoaderEvent.E_LOADING_STOP,
      this.handleLoadingStopEvent
    );
  }

  // endregion
  // region autobound methods
  /**
   * Handles when a 'start loading' event is fired.
   *
   * @param {Toaster#event:E_TOAST_SHOW} event
   */
  @autobind
  handleLoadingStartEvent(event: LoadingStartEvent) {
    this.setState({ loading: true });
  }

  /**
   * Handles when a 'stop loading' event is fired.
   *
   * @param {Toaster#event:E_TOAST_SHOW} event
   */
  @autobind
  handleLoadingStopEvent(event: LoadingStopEvent) {
    this.setState({ loading: false });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <Modal open={this.state.loading}>
        <div>
          <CenteredCircularProgress disableOutline />
        </div>
      </Modal>
    );
  }

  // endregion
}

export default LoadingOverlay;
