import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Sellers.SignUpSeller.Request>(
  {
    firstName: {
      required: true,
      message: {
        required: 'First name is required'
      }
    },
    lastName: {
      required: true,
      message: {
        required: 'Last name is required'
      }
    },
    email: {
      required: true,
      type: 'string',
      match: regexps.email,
      message: {
        required: 'A valid email is required',
        match: 'A valid email is required'
      }
    },
    password: {
      required: true,
      type: 'string',
      length: {
        min: 6
      },
      message: {
        required: 'Password must be at least 6 characters long',
        length: 'Password must be at least 6 characters long'
      }
    },
    passwordConfirmation: {
      required: true,
      type: 'string',
      use: { equals: (val: any, obj: any) => val === obj.password },
      message: {
        required: 'Passwords must match',
        equals: 'Passwords must match'
      }
    },
    terms: {
      required: true,
      type: 'boolean',
      enum: [true],
      message: {
        enum: 'Please agree to the terms and conditions'
      }
    },
    company: {
      required: true,
      type: 'string'
    }
  },
  { strip: false }
);
