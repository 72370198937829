import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import cx from 'classnames';
import * as React from 'react';
import { API } from 'src/definitions';

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    },
    header: {
      textAlign: 'center'
    },
    content: {
      textAlign: 'center'
    },
    actions: {
      textAlign: 'center'
    },
    current: {
      textAlign: 'center',
      fontSize: '2.5em'
    },
    progress: {
      'textAlign': 'center',
      '&.negative': {
        color: 'red'
      },
      '&.positive': {
        color: 'green'
      }
    }
  }),
  { name: 'StatisticsCompareCard' }
);

interface Props {
  value: API.Entities.Statistics.LastVisitsCompare;
  description: string;
}

export const StatisticsCompareCard = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const progress = props.value.current - props.value.previous;

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography variant="h6" className={classes.header}>
          {props.description}
        </Typography>
        <Typography className={classes.current}>
          {props.value.current}
        </Typography>
        <Typography
          className={cx(
            classes.progress,
            progress > 0 ? 'positive' : '',
            progress < 0 ? 'negative' : ''
          )}
        >
          {progress >= 0 ? '+' : ''}
          {progress} From previous
        </Typography>
      </CardContent>
    </Card>
  );
};
