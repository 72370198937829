import {
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import * as React from 'react';
import { CardWithMargin } from 'src/components';
import { ShortcodeSuggestion } from 'src/components/editors/EngageEditor';

interface Props {
  title: React.ReactNode;
  shortcodes: ShortcodeSuggestion[];
}

export const ShortcodesListCard = (props: Props) => (
  <CardWithMargin>
    <CardHeader subheader={props.title} />
    <CardContent>
      <List>
        {props.shortcodes.map(shortcode => (
          <ListItem key={shortcode.name} dense divider>
            <ListItemText primary={shortcode.name} />
          </ListItem>
        ))}
      </List>
    </CardContent>
  </CardWithMargin>
);
