import autobind from 'autobind-decorator';
import KeenAnalysis, {
  AnalysisType,
  FilterOperator,
  KeenFilter,
  KeenQueryResult,
  TimeframeRel,
  TimeframeUnit
} from 'keen-analysis';
import KeenTracking from 'keen-tracking';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import * as React from 'react';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import RelmApi from 'src/services/RelmApi';
import StatsApi from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';
import {
  asISODate,
  getFullName,
  groupObjectsByStringProperty,
  secondsToHms,
  sellerCleanStream
} from 'src/utilities';
import { isNumber } from 'util';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentSellerValue;

interface State {
  initStream: boolean;
  cleaningStep: API.Nullable<string>;
  lastSeenConsumersList: Array<{
    'consumer.relmKey': string;
    'result': string;
  }>;
  currentlyOnTheSiteList: API.Nullable<LastConsumersRawRecords[]>;
  lastEvent: API.Nullable<API.DateTime>;
  lastRefresh: API.DateTime;
  lastRecordedEvent: LastRecordedEvent;
  refreshEnable: boolean;
  rawEvents: API.Nullable<RawEvent[]>;
  splittedRawEvents: API.Nullable<Map<string, RawEvent[]>>;
  unparsedCleanEvents: API.Nullable<RawEvent[]>;
  cleanEvents: API.Nullable<CleanEvent[]>;
}

interface RawEvent {
  consumer: {
    anonymous?: boolean | string;
    doNotTrack: boolean | string;
    isTargeted: boolean | string;
    relmKey: string;
    sharedKey: string;
    step: string;
  };
  consuming: {
    order: number;
    page: {
      article?: {
        bj: string;
        order: string;
        slug: string;
        private?: boolean | string;
      };
      title: API.Nullable<string>;
      type: string;
    };
    session: API.Nullable<string>;
    timeSpent: number;
    isVisible?: API.Nullable<boolean>;
  };
  context?: Context;
  geo?: Geo;
  ipAddress: string;
  keen: {
    timestamp: API.DateTime;
  };
  tech: {
    device: string;
    isBot: boolean | string;
  };
  urlParsed: {
    domain: string;
    path: string;
  };
  userAgent: API.Nullable<string>;
  time: {
    start: API.DateTime;
    end: API.DateTime;
    timeSpent: number;
    timeSpentDisplay: string;
  };
}

interface SplittedRawEvent {
  relmKey: string;
  targeted: boolean;
  session: API.Nullable<string>;
  slug: string;
  order: number;
}

interface AllTimeConsuming {
  visit: number;
  timeSpent: number;
  timeSpentDisplay: string;
  firstSeen: API.DateTime;
  lastSeen: API.DateTime;
}

interface LastConsumersRawRecords {
  relmKey: string;
  lastSeen: API.DateTime;
  untilNow: number;
  untilNowDisplay: string;
}

interface LastCleanRecords {
  consumer: {
    relmKey: string;
  };
  first_seen: API.Nullable<API.DateTime>;
  last_seen: API.Nullable<API.DateTime>;
  timeSpent: API.Nullable<number>;
  visit: API.Nullable<number>;
}

interface ConsumerAllTimeConsuming {
  relmKey: string;
  isTargeted: boolean | string;
  isAnonymous: boolean | string;
  session: API.Nullable<string>;
  isBot: boolean | string;
  allTimeConsuming: AllTimeConsuming;
}

interface ConsumerSessionList {
  relmKey: string;
  sessions: string[];
}

interface Consuming {
  sessionId: API.Nullable<string>;

  start: API.DateTime;
  end: API.DateTime;
  timeSpent: number;
  timeSpentDisplay: string;

  pageType?: string;
  title?: API.Nullable<string>;
  slug?: API.Nullable<string>;
  private?: API.Nullable<boolean | string>;
  buyerJourney?: {
    id?: API.Nullable<string>;
    step: number;
    order: number;
    consumerAlignment?: number;
  };
}

interface Context {
  documentWidth: number;
  documentHeight: number;
  viewportWidth: number;
  viewportHeight: number;
  scrollTopY: number;
  scrollBottomY: number;
  scrollTopYMax: number;
  scrollBottomYMax: number;
  consumed: number;
}

interface Geo {
  city?: API.Nullable<string>;
  continent?: API.Nullable<string>;
  coordinates?: API.Nullable<string>;
  country?: API.Nullable<string>;
  country_code?: API.Nullable<string>;
  postal_code?: API.Nullable<string>;
  province?: API.Nullable<string>;
}

interface Seller {
  id: string;
  domain: API.Nullable<string>;
  firstName: string;
  lastName: string;
  fullName: string;
  company: API.Nullable<string>;
  email: string;
  createdAt: API.DateTime;
}

interface FullConsumer {
  relmKey: API.Nullable<string>;
  sharedKey: API.Nullable<string>;
  relmLink?: API.Nullable<string>;
  sharedLink?: API.Nullable<string>;
  relmId?: API.Nullable<string>;
  doNotTrack: boolean | string;
  isTargeted: boolean | string;
  isAnonymous: boolean | string;
  firstName?: API.Nullable<string>;
  lastName?: API.Nullable<string>;
  fullName: string;
  organisation?: API.Nullable<string>;
  email?: API.Nullable<string>;
  persona?: {
    id?: API.Nullable<string>;
  };
  buyerJourney?: {
    id?: API.Nullable<string>;
    step?: API.Nullable<number>;
    order?: API.Nullable<number>;
    consumerAlignment?: API.Nullable<number>;
  };
  hasBeenEngaged?: API.Nullable<boolean | string>;
  createdAt: API.Nullable<API.DateTime>;
}

interface CleanEvent {
  keen: {
    timestamp: API.DateTime;
  };
  seller: Seller;
  consumer: FullConsumer;
  tech: {
    device: string;
    isBot: boolean | string;
    ipAddress?: API.Nullable<string>;
    userAgent?: API.Nullable<string>;
  };
  geo: API.Nullable<Geo>;
  context: API.Nullable<Context>;
  consuming: Consuming;
  allTimeConsuming: AllTimeConsuming;
}

interface LastRecordedEvent {
  timestamp: API.Nullable<Date>;
  events: Array<API.Nullable<CleanEvent>>;
}

interface ConsumerCompareEvents {
  raw: {
    firstSeen: API.Nullable<API.DateTime>;
    lastSeen: API.Nullable<API.DateTime>;
  };
  clean: {
    firstSeen: API.Nullable<API.DateTime>;
    lastSeen: API.Nullable<API.DateTime>;
  };
}

const rawPropertiesNames = [
  'consumer.anonymous',
  'consumer.doNotTrack',
  'consumer.isTargeted',
  'consumer.relmKey',
  'consumer.sharedKey',
  'consumer.step',
  'consuming.order',
  'consuming.page.article.bj',
  'consuming.page.article.order',
  'consuming.page.article.slug',
  'consuming.page.article.private',
  'consuming.page.title',
  'consuming.page.type',
  'consuming.session',
  'consuming.timeSpent',
  'consuming.isVisible',
  'context.documentWidth',
  'context.documentHeight',
  'context.viewportWidth',
  'context.viewportHeight',
  'context.scrollTopY',
  'context.scrollBottomY',
  'context.scrollTopYMax',
  'context.scrollBottomYMax',
  'context.consumed',
  'geo.city',
  'geo.continent',
  'geo.coordinates',
  'geo.country',
  'geo.country_code',
  'geo.postal_code',
  'geo.province',
  'ipAddress',
  'keen.timestamp',
  'tech.device',
  'tech.isBot',
  'urlParsed.domain',
  'urlParsed.path',
  'userAgent'
];

// endregion

enum CleaningSteps {
  UPDATE_BOT_LIST = 'Bots list update',
  GET_LAST_CLEAN_RECORDS = 'Initialising',
  GET_LAST_RAW_RECORDS = 'Loading',
  CHECK_AVAILABILITY = 'Stand by',
  HOLD_ON = 'Cooldown',
  RECORDS_PROCESSING_LEFT = 'Processing: ',
  RECORDS_UPDATE_LEFT = 'Upgrading: ',
  RECORDS_CLEAN_EVENTS = 'Recording: '
}

@withCurrentSeller<Props>()
class KeenCleaner extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: undefined,
    onCurrentSellerChange: undefined
  };

  /**
   * @type {string}
   * @private
   */
  private readonly _projectId = String(process.env.KEEN_PROJECT_ID);
  private readonly _readKey = String(process.env.KEEN_READ_KEY);
  private readonly _writeKey = String(process.env.KEEN_WRITE_KEY);
  private readonly _rawStream = String(process.env.KEEN_RAW_STREAM);
  private readonly _cleanStream: string = sellerCleanStream(
    this.props.currentSeller?.id ?? ''
  );

  private readonly _storageKey: string = `Relm_KeenCleaner_${this.props.currentSeller?.id}`;

  private _analysisClient: API.Nullable<KeenAnalysis> = null;
  private _trackingClient: API.Nullable<KeenTracking> = null;

  private readonly _noRecordsBefore: Date = new Date(
    '2018-12-25T00:00:00.000Z'
  ); // YYYY-MM-DDTHH:MM:SS.000Z

  private readonly _noBotsUpdateBefore: Date = new Date(
    '2020-01-01T00:00:00.000Z'
  ); // YYYY-MM-DDTHH:MM:SS.000Z

  private readonly _rawQueryLimit = 80000; // keen limit is 100 000
  private readonly _timeSpentLimit = 3600; // Don't record event longer than this
  private readonly _cooldownDelay = 20; // Delay after recording before allowing an update
  private _cooldownTimeout = 0;
  private readonly _recordingStats = true;
  private readonly _allowCleanProcess = !!(
    process.env.ALLOW_KEEN_CLEAN_PROCESSING &&
    !!process.env.ALLOW_KEEN_CLEAN_PROCESSING
  );

  private _isMounted = false;

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = KeenCleaner.name;

  readonly state: State = {
    initStream: true,
    cleaningStep: null,
    lastSeenConsumersList: [],
    lastEvent: null,
    lastRefresh: new Date().toISOString(),
    lastRecordedEvent: {
      timestamp: null,
      events: []
    },
    refreshEnable: true,
    rawEvents: null,
    splittedRawEvents: null,
    unparsedCleanEvents: null,
    cleanEvents: null,
    currentlyOnTheSiteList: null
  };

  // region component lifecycle methods

  // endregion
  // region LocalStorage
  loadLastRecords(): LastRecordedEvent {
    try {
      return JSON.parse(
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        decompressFromUTF16(localStorage.getItem(this._storageKey) ?? '') ||
          'null'
      ).error(this.state.lastRecordedEvent);
    } catch {
      return this.state.lastRecordedEvent;
    }
  }

  saveLastRecords(lastRecordedEvent: LastRecordedEvent): LastRecordedEvent {
    localStorage.setItem(
      this._storageKey,
      compressToUTF16(JSON.stringify(lastRecordedEvent))
    );

    return lastRecordedEvent;
  }

  /**
   * Clear Last record of the current seller from local storage
   */
  deleteLastRecords() {
    localStorage.removeItem(this._storageKey);
  }
  // endregion

  /**
   * Define the cooldown for refresh
   */
  refreshAvailability() {
    this.setState({ lastRecordedEvent: this.loadLastRecords() });

    // No events recorded
    if (this.state.lastRecordedEvent.timestamp === null) {
      this.setState({ refreshEnable: true });

      return;
    }

    const delayToWait =
      this._cooldownDelay * 1000 +
      this.state.lastRecordedEvent.events.length * 100 -
      Math.round(
        new Date().getTime() -
          new Date(this.state.lastRecordedEvent.timestamp).getTime()
      );

    // The cooldown delay is passed
    if (delayToWait < 0) {
      this.setState({ refreshEnable: true });

      return;
    }

    // Cooldown
    this.setState({ refreshEnable: false });

    // Auto cooldown shut off
    this._cooldownTimeout = window.setTimeout(() => {
      this.setState({ refreshEnable: true });
      clearTimeout(this._cooldownTimeout);
    }, delayToWait);
  }

  /**
   * Delete Global Stats caches
   */
  deleteGlobalCache() {
    if (!this.props.currentSeller) {
      return;
    }
    StatsApi.deleteLocalStorage(this.props.currentSeller.id);
  }

  /**
   * Start an update process
   * @returns {Promise<void>}
   */
  async updateCleanStream() {
    if (!this._allowCleanProcess) {
      console.log('KeenCleaner is locked on this environment');

      return;
    }
    if (!this.props.currentSeller) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    await this.getLastCleanRecords(this.props.currentSeller);
  }

  currentSellerFilterBuilder(
    seller: API.Entities.CurrentSeller
  ): API.Nullable<KeenFilter> {
    const sellerDefaultHostingDomain = `${seller.id}.${String(
      process.env.CLP_DOMAIN
    )}`;
    const defaultHostDomainFilter: KeenFilter = {
      operator: FilterOperator.EQ,
      property_name: 'urlParsed.domain',
      property_value: sellerDefaultHostingDomain
    };

    if (seller.styles.hostingUrl === null) {
      return defaultHostDomainFilter;
    }

    const hostingUrl = document.createElement('a');

    hostingUrl.href = seller.styles.hostingUrl;

    return {
      operator: FilterOperator.OR,
      operands: [
        defaultHostDomainFilter,
        {
          operator: FilterOperator.EQ,
          property_name: 'urlParsed.domain',
          property_value: hostingUrl.hostname
        }
      ]
    };
  }

  /**
   * Processing consumer
   * @returns {Promise<API.Nullable<ConsumerCompareEvents>>}
   */
  async checkConsumerEvents(
    consumer: API.Entities.Consumer
  ): Promise<ConsumerCompareEvents> {
    if (this._analysisClient === null) {
      this._analysisClient = this.initAnalysisClient();
    } // Initialize Client if needed

    const queryFilters: KeenFilter[] = [
      {
        operator: FilterOperator.EQ,
        property_name: 'consumer.relmKey',
        property_value: consumer.relmKey
      }
    ];
    const afterLastEvent: Date =
      new Date(asISODate(consumer.createdAt)) > this._noRecordsBefore
        ? new Date(asISODate(consumer.createdAt))
        : this._noRecordsBefore;

    const rawConsumer: Promise<KeenQueryResult<{
      firstSeen: number;
      lastSeen: number;
    }>> = this._analysisClient.query(AnalysisType.MULTI_ANALYSIS, {
      analyses: {
        firstSeen: {
          analysis_type: AnalysisType.MINIMUM,
          target_property: 'keen.timestamp'
        },
        lastSeen: {
          analysis_type: AnalysisType.MAXIMUM,
          target_property: 'keen.timestamp'
        }
      },
      event_collection: this._rawStream,
      timeframe: {
        start: afterLastEvent.toISOString(),
        end: new Date().toISOString()
      },
      filters: queryFilters
    });
    const cleanConsumer: Promise<KeenQueryResult<{
      firstSeen: number;
      lastSeen: number;
    }>> = this._analysisClient.query(AnalysisType.MULTI_ANALYSIS, {
      analyses: {
        firstSeen: {
          analysis_type: AnalysisType.MINIMUM,
          target_property: 'allTimeConsuming.firstSeen'
        },
        lastSeen: {
          analysis_type: AnalysisType.MAXIMUM,
          target_property: 'allTimeConsuming.lastSeen'
        }
      },
      event_collection: this._cleanStream,
      timeframe: {
        start: afterLastEvent.toISOString(),
        end: new Date().toISOString()
      },
      filters: queryFilters
    });

    return Promise.all([rawConsumer, cleanConsumer]).then(values => {
      const raw: { firstSeen: number; lastSeen: number } = values[0].result;
      const clean: { firstSeen: number; lastSeen: number } = values[1].result;

      return {
        raw: {
          firstSeen:
            raw.firstSeen !== null
              ? new Date(raw.firstSeen).toISOString()
              : null,
          lastSeen:
            raw.lastSeen !== null ? new Date(raw.lastSeen).toISOString() : null
        },
        clean: {
          firstSeen:
            clean.firstSeen !== null
              ? new Date(clean.firstSeen).toISOString()
              : null,
          lastSeen:
            clean.lastSeen !== null
              ? new Date(clean.lastSeen).toISOString()
              : null
        }
      };
    });
  }

  async getLastConsumers(): Promise<void> {
    const cooldownDelay = 1; // in Minutes

    if (!this.props.currentSeller) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    this.setState({ cleaningStep: CleaningSteps.CHECK_AVAILABILITY });
    if (this._analysisClient === null) {
      this._analysisClient = this.initAnalysisClient();
    } // Initialize Client if needed

    const queryFilters: KeenFilter[] = [];
    const sellerFilter: API.Nullable<KeenFilter> = this.currentSellerFilterBuilder(
      this.props.currentSeller
    );

    if (sellerFilter !== null) {
      queryFilters.push(sellerFilter);
    }

    const queryResult: Promise<KeenQueryResult> = this._analysisClient.query(
      AnalysisType.MAXIMUM,
      {
        target_property: 'keen.timestamp',
        event_collection: this._rawStream,
        timeframe: `${TimeframeRel.THIS}_${cooldownDelay}_${TimeframeUnit.MINUTES}`,
        filters: queryFilters,
        group_by: 'consumer.relmKey'
      }
    );

    await queryResult.then(({ result }) => {
      this.setState({
        currentlyOnTheSiteList:
          result.length === 0
            ? null
            : result.map(
                (event: { 'consumer.relmKey': string; 'result': string }) => {
                  const untilNow = parseInt(
                    (
                      Math.abs(
                        new Date().getTime() - new Date(event.result).getTime()
                      ) * 0.001
                    ).toString(),
                    10
                  );

                  return {
                    relmKey: event['consumer.relmKey'],
                    lastSeen: event.result,
                    untilNow: untilNow,
                    untilNowDisplay: secondsToHms(untilNow)
                  };
                }
              )
      });
    });
  }

  /**
   * Get some information from the last record in the clean steam to prevent overlap and duplicate
   *
   * @param {API.Entities.CurrentSeller} seller
   * @returns {Promise<void>}
   */
  async getLastCleanRecords(seller: API.Entities.CurrentSeller): Promise<void> {
    if (StatsApi.findCachedStats(seller.id)) {
      return;
    } // Don't refresh until keen cache isn't valid

    this.refreshAvailability();
    if (!this.state.refreshEnable) {
      return;
    }

    this.setState({ cleaningStep: CleaningSteps.GET_LAST_CLEAN_RECORDS });
    if (this._analysisClient === null) {
      this._analysisClient = this.initAnalysisClient();
    } // Initialize Client if needed

    const afterLastEvent: Date =
      new Date(asISODate(seller.createdAt)) > this._noRecordsBefore
        ? new Date(asISODate(seller.createdAt))
        : this._noRecordsBefore;
    const queryResult: Promise<KeenQueryResult> = this._analysisClient.query(
      AnalysisType.MULTI_ANALYSIS,
      {
        analyses: {
          sessions: {
            analysis_type: AnalysisType.SELECT_UNIQUE,
            target_property: 'consuming.sessionId'
          },
          visit: {
            analysis_type: AnalysisType.MAXIMUM,
            target_property: 'allTimeConsuming.visit'
          },
          timeSpent: {
            analysis_type: AnalysisType.MAXIMUM,
            target_property: 'allTimeConsuming.timeSpent'
          },
          firstSeen: {
            analysis_type: AnalysisType.MAXIMUM,
            target_property: 'allTimeConsuming.firstSeen'
          },
          lastSeen: {
            analysis_type: AnalysisType.MAXIMUM,
            target_property: 'allTimeConsuming.lastSeen'
          }
        },
        event_collection: this._cleanStream,
        timeframe: {
          start: afterLastEvent.toISOString(),
          end: new Date().toISOString()
        },
        filters: [
          {
            operator: FilterOperator.NE,
            property_name: 'consumer.isTargered',
            property_value: 'false'
          }
        ],
        group_by: ['consumer.relmKey']
      }
    );

    queryResult.then(({ result }) => {
      if (result.length === 0) {
        this.setState({ initStream: true });
        this.getSellerLastRawRecords(seller);

        return;
      }
      this.setState({ initStream: false });
      this.setState({ lastSeenConsumersList: result });
      this.setState({
        lastEvent: new Date(
          result
            .map((e: LastCleanRecords) => e.last_seen)
            .sort()
            .reverse()[0]
        ).toISOString()
      });
      this.endRefreshFeedback();
      this.getSellerLastRawRecords(seller);
    });
  }

  /**
   * Get the last bunch of raw date from where le last record was done
   *
   * @param {API.Entities.CurrentSeller} seller
   * @returns {Promise<void>}
   */
  async getSellerLastRawRecords(
    seller: API.Entities.CurrentSeller
  ): Promise<void> {
    this.setState({ cleaningStep: CleaningSteps.GET_LAST_RAW_RECORDS });

    if (this._analysisClient === null) {
      this._analysisClient = this.initAnalysisClient();
    } // Initialize Client if needed

    // Build the timeframe
    let afterLastEvent: Date =
      new Date(asISODate(seller.createdAt)) > this._noRecordsBefore
        ? new Date(asISODate(seller.createdAt))
        : this._noRecordsBefore;

    if (
      this._recordingStats &&
      !this.state.initStream &&
      this.state.lastEvent !== null
    ) {
      afterLastEvent =
        new Date(this.state.lastEvent) > this._noRecordsBefore
          ? new Date(this.state.lastEvent)
          : this._noRecordsBefore;
      afterLastEvent.setSeconds(afterLastEvent.getSeconds() + 1);
    }

    const queryFilters: KeenFilter[] = [
      {
        operator: FilterOperator.LTE,
        property_name: 'consuming.timeSpent',
        property_value: this._timeSpentLimit
      },
      {
        operator: FilterOperator.NE,
        property_name: 'consuming.isVisible',
        property_value: 'false'
      }
    ];
    const sellerFilter: API.Nullable<KeenFilter> = this.currentSellerFilterBuilder(
      seller
    );

    if (sellerFilter !== null) {
      queryFilters.push(sellerFilter);
    }

    this.rawResultProcessing(
      seller,
      this._analysisClient.query(AnalysisType.EXTRACTION, {
        event_collection: this._rawStream,
        timeframe: {
          start: afterLastEvent.toISOString(),
          end: new Date().toISOString()
        },
        property_names: rawPropertiesNames,
        filters: queryFilters,
        latest: this._rawQueryLimit
      })
    );
  }

  async rawResultProcessing(
    seller: API.Entities.CurrentSeller,
    queryResult: Promise<KeenQueryResult>
  ): Promise<void> {
    await queryResult.then(({ result }) => {
      if (result.length === 0) {
        // Refresh infos
        this.setState({ lastRefresh: new Date().toISOString() });
        this.endRefreshFeedback();

        return;
      }

      if (this.state.initStream) {
        this.endRefreshFeedback(
          "Statistics are initializing. Please don't refresh the page."
        );
      }

      this.setState({ rawEvents: result.slice(0) });
      if (this.state.rawEvents === null) {
        return;
      } // this'll never be null, but typescript don't know that

      // Remove known bots
      this.botsChecker(this.state.rawEvents);

      // Split Raw Events
      this.splitRaw(this.state.rawEvents);

      // Convert to unparsed Clean Event
      if (!this.state.splittedRawEvents) {
        return;
      }
      const initCleanEvents: RawEvent[] = [];
      let lastTimeSpent = 0;

      this.state.splittedRawEvents.forEach(splittedRawEvent => {
        splittedRawEvent
          .slice(0)
          .sort(
            (a, b) =>
              parseInt(b.keen.timestamp, 10) - parseInt(a.keen.timestamp, 10)
          );

        // Map of timeSpent Occurencies
        const timeSpentOccurenciesList: Map<number, number> = splittedRawEvent
          .slice(0)
          .sort((a, b) => b.consuming.timeSpent - a.consuming.timeSpent)
          .map(a => a.consuming.timeSpent)
          .reduce(
            (acc, e) => acc.set(e, (acc.get(e) ?? 0) + 1),
            new Map<number, number>()
          );
        // Array by occurrences
        const occurrencesList = new Map<number, number>();

        for (const [key, value] of timeSpentOccurenciesList) {
          const occurrences = occurrencesList.get(value) ?? 0;

          if (value >= occurrences) {
            occurrencesList.set(value, key);
          }
        }
        const occurrencesArray = Array.from(occurrencesList.values());
        // Object.entries(timeSpentOccurenciesList).reduce((obj, [key, value]) => ({ ...obj, [value]: key }), {});

        const similarEvent: RawEvent[] = [];

        splittedRawEvent.forEach(event => {
          if (
            event.consuming.timeSpent > lastTimeSpent &&
            Math.abs(event.consuming.timeSpent) - lastTimeSpent >= 2 &&
            occurrencesArray.includes(event.consuming.timeSpent)
          ) {
            similarEvent.push(event);
          }
          lastTimeSpent = event.consuming.timeSpent;
        });
        similarEvent.forEach((event, index) => {
          // Update Time data
          const timeEnd: Date = new Date(
            new Date(event.keen.timestamp).setMilliseconds(0)
          );
          const startIndex: number =
            similarEvent.length === 1
              ? splittedRawEvent.length
              : similarEvent.slice(0, index).reduce((sum, val) => {
                  return sum + val.consuming.timeSpent;
                }, event.consuming.timeSpent);

          event.time = {
            start:
              splittedRawEvent[startIndex - 1] !== undefined
                ? splittedRawEvent[startIndex - 1].keen.timestamp
                : new Date(
                    new Date(
                      timeEnd.getTime() - event.consuming.timeSpent * 1000
                    )
                  ).toISOString(),
            timeSpent: event.consuming.timeSpent,
            timeSpentDisplay: secondsToHms(event.consuming.timeSpent),
            end: event.keen.timestamp
          };
          event.keen.timestamp = event.time.start;
          initCleanEvents.push(event);
        });
      });
      this.setState({ unparsedCleanEvents: initCleanEvents.reverse() });
      if (this.state.unparsedCleanEvents === null) {
        return;
      }

      // Record processing
      this.rawRecordsProcessing(this.state.unparsedCleanEvents, seller);
    });
  }

  /**
   * Split Raw event to prevent duplicate and improve parsing speed
   *
   * @param {RawEvent[]} rawEvents
   */
  splitRaw(rawEvents: RawEvent[]) {
    const splittedRawEvents = new Map<string, RawEvent[]>();

    // Merge empty session if possible
    let lastRelmKey: API.Nullable<string> = null;
    let lastSession: API.Nullable<string> = null;

    rawEvents.forEach(rawEventRelmKey => {
      if (lastRelmKey !== rawEventRelmKey.consumer.relmKey) {
        lastRelmKey = rawEventRelmKey.consumer.relmKey;
        lastSession = null;
      }
      if (rawEventRelmKey.consuming.session !== null) {
        lastSession = rawEventRelmKey.consuming.session;
      }
      const splittedRawEvent: SplittedRawEvent = {
        relmKey: rawEventRelmKey.consumer.relmKey,
        targeted: rawEventRelmKey.consumer.isTargeted === true,
        session:
          rawEventRelmKey.consuming.session !== null
            ? rawEventRelmKey.consuming.session
            : lastSession,
        slug:
          rawEventRelmKey.consuming.page.article !== undefined
            ? rawEventRelmKey.consuming.page.article.slug
            : '',
        order: rawEventRelmKey.consuming.order
      };
      const key = JSON.stringify(splittedRawEvent);
      const collection = splittedRawEvents.get(key);

      collection
        ? collection.push(rawEventRelmKey)
        : splittedRawEvents.set(key, [rawEventRelmKey]);
    });

    this.setState({ splittedRawEvents });
  }

  /**
   * Track and Remove Bots from RawRecords
   *
   * @param {RawEvent[]} rawRecords
   */
  botsChecker(rawRecords: RawEvent[]) {
    const AmazonBotsBanishedIP = [
      {
        city: 'Boardman',
        ip: ['52.34.76.65', '54.70.53.60', '54.71.187.124']
      },
      {
        city: 'Ashburn',
        ip: ['52.44.93.197']
      }
    ];
    const microsoftBotsIpMask = /^40\.94(?:\.\d{1,3}){2}$/u;

    this.setState({
      rawEvents: rawRecords
        .map((rawEvent: RawEvent) => {
          // Identify Gmail bots
          if (rawEvent.geo && rawEvent.geo.city === 'New York') {
            const eventsCount = rawRecords
              .slice(0)
              .filter(
                event =>
                  event.consumer.relmKey === rawEvent.consumer.relmKey &&
                  event.geo &&
                  event.geo.city === 'New York'
              );

            // If events duration are a multiple of 59 seconds, assume it's a bot
            if (eventsCount.length % 59 === 0) {
              rawEvent.tech.isBot = true;

              return rawEvent;
            }
          }

          // Don't remove more bots before the given date
          if (new Date(rawEvent.keen.timestamp) < this._noBotsUpdateBefore) {
            return rawEvent;
          }

          // Identity Microsoft bots
          if (microsoftBotsIpMask.test(rawEvent.ipAddress)) {
            const eventsWithSameIp = rawRecords
              .slice(0)
              .filter(
                event => event.consuming.session === rawEvent.consuming.session
              );

            // If events duration is between to 20 and 35, assume it's a bot
            if (
              eventsWithSameIp.length >= 20 &&
              eventsWithSameIp.length <= 35
            ) {
              rawEvent.tech.isBot = true;

              return rawEvent;
            }
          }

          // Identify Amazon bots
          AmazonBotsBanishedIP.forEach(banished => {
            if (
              banished.ip.includes(rawEvent.ipAddress) &&
              rawEvent.geo &&
              rawEvent.geo.city === banished.city
            ) {
              rawEvent.tech.isBot = true;
            }
          });

          return rawEvent;
        })
        .filter(rawEvent => rawEvent.tech.isBot === false)
    });
  }

  static groupRAWByRelmkey(list: RawEvent[]): Map<string, RawEvent[]> {
    const map = new Map();

    list.forEach(item => {
      const key = item.consumer.relmKey;
      const collection = map.get(key);

      collection ? collection.push(item) : map.set(key, [item]);
    });

    return map;
  }

  static geoProcessing(rawEvent: RawEvent): API.Nullable<Geo> {
    return rawEvent.geo !== undefined ? rawEvent.geo : null;
  }

  static contextProcessing(rawEvent: RawEvent): API.Nullable<Context> {
    return rawEvent.context !== undefined
      ? {
          ...rawEvent.context,
          consumed: parseInt(rawEvent.context.consumed.toString(), 10)
        }
      : null;
  }

  static consumingProcessing(rawEvent: RawEvent): Consuming {
    // Session or DriveBy?
    const consuming: Consuming = {
      sessionId: rawEvent.consuming.session ? rawEvent.consuming.session : null,
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      timeSpent: 0,
      timeSpentDisplay: '00s'
    };

    // Time
    if (rawEvent.time !== undefined) {
      consuming.start = rawEvent.time.start;
      consuming.end = rawEvent.time.end;
      consuming.timeSpent = rawEvent.time.timeSpent;
      consuming.timeSpentDisplay = rawEvent.time.timeSpentDisplay;
    }

    // Consuming
    consuming.pageType = rawEvent.consuming.page.type;
    consuming.title = rawEvent.consuming.page.title;
    if (
      consuming.pageType === 'article' &&
      typeof rawEvent.consuming.page.article !== 'undefined' &&
      rawEvent.consuming.page.article !== null
    ) {
      const rawArticle = rawEvent.consuming.page.article;
      const consumerBj = isNumber(rawEvent.consumer.step)
        ? parseInt(rawEvent.consumer.step, 10)
        : 0;
      const articleBj = isNumber(rawArticle.bj)
        ? parseInt(rawArticle.bj, 10)
        : 0;

      consuming.slug = rawArticle.slug;
      consuming.private =
        rawArticle.private !== undefined ? rawArticle.private : null;
      consuming.buyerJourney = {
        step: articleBj,
        order: isNumber(rawArticle.order) ? parseInt(rawArticle.order, 10) : 0,
        consumerAlignment: articleBj - consumerBj
      };
    }

    return consuming;
  }

  /**
   * Raw records processing to merge add upgrade results
   * @returns {Promise<void>}
   */
  async rawRecordsProcessing(
    rawRecords: RawEvent[],
    seller: API.Entities.CurrentSeller
  ): Promise<void> {
    const allCleanEvents: CleanEvent[] = [];
    let allCleanSessionsList: ConsumerSessionList[] = [];

    if (rawRecords.length !== 0) {
      const currentSeller: Seller = {
        id: seller.id,
        domain: null,
        firstName: seller.firstName,
        lastName: seller.lastName,
        fullName: getFullName(seller),
        company: seller.company,
        email: seller.email,
        createdAt: new Date(asISODate(seller.createdAt)).toISOString()
      };
      const consumersList: API.Consumers.ListConsumersOfSeller.Response = await RelmApi.listConsumers(
        seller.id
      );
      const consumersByRelmKey = groupObjectsByStringProperty(
        consumersList.data,
        'relmKey',
        ''
      );

      allCleanSessionsList = this.state.lastSeenConsumersList.map(
        consumerEvent => {
          const consumerEventObj = JSON.parse(
            JSON.stringify(Object.assign({}, consumerEvent))
          );

          return {
            relmKey: consumerEventObj['consumer.relmKey'],
            sessions: consumerEventObj.sessions.length
              ? consumerEventObj.sessions
              : []
          };
        }
      );

      this.setState({
        cleaningStep: `${CleaningSteps.RECORDS_PROCESSING_LEFT} ${
          rawRecords.length
        } event${rawRecords.length > 1 ? 's' : ''}`
      });
      rawRecords.forEach(rawEvent => {
        let consumer: FullConsumer = {
          relmKey: null,
          sharedKey: null,
          doNotTrack: rawEvent.consumer.doNotTrack
            ? rawEvent.consumer.doNotTrack
            : false,
          isTargeted: false,
          isAnonymous: true,
          fullName: 'Anonymous',
          hasBeenEngaged: false,
          createdAt: new Date(rawEvent.keen.timestamp).toISOString()
        };

        if (rawEvent.consumer.relmKey !== undefined) {
          consumer = {
            relmKey: rawEvent.consumer.relmKey,
            sharedKey: rawEvent.consumer.sharedKey,
            relmLink: `https://${rawEvent.urlParsed.domain}/rk/${rawEvent.consumer.relmKey}.html`,
            sharedLink: `https://${rawEvent.urlParsed.domain}/rk/${rawEvent.consumer.sharedKey}.html`,
            doNotTrack: rawEvent.consumer.doNotTrack,
            isTargeted: rawEvent.consumer.isTargeted,
            isAnonymous: false,
            fullName:
              rawEvent.consumer.isTargeted !== undefined &&
              rawEvent.consumer.isTargeted === true
                ? rawEvent.consumer.relmKey
                : rawEvent.consumer.sharedKey,
            buyerJourney: {
              step: isNumber(rawEvent.consumer.step)
                ? parseInt(rawEvent.consumer.step, 10)
                : 0
            },
            hasBeenEngaged: null,
            createdAt: null
          };
          if (
            Object.keys(consumersByRelmKey).includes(rawEvent.consumer.relmKey)
          ) {
            const fullConsumer =
              consumersByRelmKey[rawEvent.consumer.relmKey][0];

            consumer.relmId = fullConsumer.id;
            consumer.firstName = fullConsumer.firstName;
            consumer.lastName = fullConsumer.lastName;
            consumer.fullName = getFullName(fullConsumer);
            consumer.organisation = fullConsumer.organisation;
            consumer.email = fullConsumer.email;
            consumer.persona = {
              id: fullConsumer.personaId
            };
            consumer.buyerJourney = {
              id: fullConsumer.stepId,
              step: isNumber(rawEvent.consumer.step)
                ? parseInt(rawEvent.consumer.step, 10)
                : 0
            };
            consumer.hasBeenEngaged = fullConsumer.hasBeenEngaged;
            consumer.createdAt = new Date(
              asISODate(fullConsumer.createdAt)
            ).toISOString();
          }
        }

        const allTimeConsumingIndex: number = this.state.lastSeenConsumersList.findIndex(
          obj => obj['consumer.relmKey'] === consumer.relmKey
        );
        const currentLastSeenConsumersList =
          allTimeConsumingIndex !== -1
            ? JSON.parse(
                JSON.stringify(
                  Object.assign(
                    {},
                    this.state.lastSeenConsumersList[allTimeConsumingIndex]
                  )
                )
              )
            : null;

        const currentAllTimeConsuming: AllTimeConsuming =
          currentLastSeenConsumersList === null || rawEvent.tech.isBot
            ? {
                visit: 0,
                timeSpent: 0,
                timeSpentDisplay: '00s',
                firstSeen: new Date(rawEvent.time.start).toISOString(),
                lastSeen: new Date(rawEvent.time.end).toISOString()
              }
            : {
                visit: currentLastSeenConsumersList.visit,
                timeSpent: currentLastSeenConsumersList.timeSpent,
                timeSpentDisplay: secondsToHms(
                  currentLastSeenConsumersList.timeSpent
                ),
                firstSeen: new Date(
                  currentLastSeenConsumersList.first_seen
                ).toISOString(),
                lastSeen: new Date(rawEvent.time.end).toISOString()
              };

        const cleanEvent: CleanEvent = {
          keen: {
            timestamp: rawEvent.keen.timestamp
          },
          seller: Object.assign(currentSeller, {
            domain: rawEvent.urlParsed.domain
          }),
          consumer: consumer,
          tech: {
            device: rawEvent.tech.device,
            isBot: rawEvent.tech.isBot,
            ipAddress: rawEvent.ipAddress,
            userAgent: rawEvent.userAgent
          },
          geo: KeenCleaner.geoProcessing(rawEvent),
          context: KeenCleaner.contextProcessing(rawEvent),
          consuming: KeenCleaner.consumingProcessing(rawEvent),
          allTimeConsuming: currentAllTimeConsuming
        };

        allCleanEvents.push(cleanEvent);
      });

      // ATC by relmKey
      const allTimeConsumingList: ConsumerAllTimeConsuming[] = [];

      // Update the ATC
      allCleanEvents.forEach(cleanEvent => {
        if (
          cleanEvent.consumer.relmKey !== null &&
          cleanEvent.consumer.isTargeted
        ) {
          let allTimeConsumingIndex: number = allTimeConsumingList.findIndex(
            obj => obj.relmKey === cleanEvent.consumer.relmKey
          );

          if (allTimeConsumingIndex === -1) {
            allTimeConsumingList.push({
              isBot: cleanEvent.tech.isBot,
              relmKey: cleanEvent.consumer.relmKey,
              isTargeted: cleanEvent.consumer.isTargeted,
              isAnonymous: cleanEvent.consumer.isAnonymous,
              session: cleanEvent.consuming.sessionId,
              allTimeConsuming: cleanEvent.allTimeConsuming
            });
            allTimeConsumingIndex = allTimeConsumingList.length - 1;
          }
          const consumerAllTimeConsuming: AllTimeConsuming = JSON.parse(
            JSON.stringify(
              Object.assign(
                {},
                allTimeConsumingList[allTimeConsumingIndex].allTimeConsuming
              )
            )
          );

          consumerAllTimeConsuming.timeSpent += cleanEvent.consuming.timeSpent;
          consumerAllTimeConsuming.timeSpentDisplay = secondsToHms(
            consumerAllTimeConsuming.timeSpent
          );
          consumerAllTimeConsuming.lastSeen = cleanEvent.consuming.end;

          // Count Session as visit
          if (cleanEvent.consuming.sessionId !== null) {
            let sessionsList: string[] = [cleanEvent.consuming.sessionId];
            let sessionsIndex: number = allCleanSessionsList.findIndex(
              obj => obj.relmKey === cleanEvent.consumer.relmKey
            );

            if (sessionsIndex !== -1) {
              sessionsList = [
                ...new Set([
                  ...allCleanSessionsList[sessionsIndex].sessions,
                  ...[cleanEvent.consuming.sessionId]
                ])
              ];
            }
            // update session list
            if (sessionsIndex === -1) {
              allCleanSessionsList.push({
                relmKey: cleanEvent.consumer.relmKey,
                sessions: sessionsList
              });
              sessionsIndex = allCleanSessionsList.length - 1;
            }
            allCleanSessionsList[sessionsIndex].sessions = sessionsList;
            consumerAllTimeConsuming.visit =
              allCleanSessionsList[sessionsIndex].sessions.length;
          }

          allTimeConsumingList[
            allTimeConsumingIndex
          ].allTimeConsuming = consumerAllTimeConsuming;
          cleanEvent.allTimeConsuming =
            allTimeConsumingList[allTimeConsumingIndex].allTimeConsuming;
        }
      });
    }
    this.setState({ cleanEvents: allCleanEvents });

    this.recordCleanEvents(allCleanEvents);
  }

  async recordCleanEvents(cleanEventsList: CleanEvent[]): Promise<void> {
    this.refreshAvailability();
    if (!this.state.refreshEnable) {
      this.setState({ cleaningStep: CleaningSteps.HOLD_ON });

      return;
    }
    let cleanEvents = cleanEventsList;

    if (
      cleanEvents === null ||
      cleanEvents.length === 0 ||
      !this._recordingStats
    ) {
      this.setState({ lastRefresh: new Date().toISOString() });
      this.endRefreshFeedback();

      return;
    }

    await this.getLastConsumers();

    if (this._trackingClient === null) {
      this._trackingClient = this.initTrackingClient();
    }

    // Get if current consumer, waiting for the currently online consumer list
    if (this.state.currentlyOnTheSiteList !== null) {
      // Remove clean events who overlap with the last consumer currently on the site including cooldown delay
      this.state.currentlyOnTheSiteList.forEach(
        (lastConsumer: LastConsumersRawRecords) => {
          cleanEvents = cleanEvents
            .filter(event => event.consumer.relmKey === lastConsumer.relmKey)
            .filter(
              event =>
                this._cooldownDelay +
                  (new Date(event.consuming.end).getTime() -
                    new Date(lastConsumer.lastSeen).getTime()) *
                    0.001 <=
                0
            );
        }
      );
      if (cleanEvents.length === 0) {
        this.setState({ cleaningStep: CleaningSteps.HOLD_ON });
        this.endRefreshFeedback();

        return;
      }
    }
    this.setState({
      cleaningStep: `${CleaningSteps.RECORDS_UPDATE_LEFT} ${
        cleanEvents.length
      } event${cleanEvents.length > 1 ? 's' : ''}`
    });
    if (!this._isMounted) {
      this.endRefreshFeedback();

      return;
    }
    this._trackingClient
      .recordEvents({ [this._cleanStream]: cleanEvents })
      .then(() => {
        const lastRecordedEvent: LastRecordedEvent = {
          timestamp: new Date(),
          events: cleanEvents
        };

        if (lastRecordedEvent.timestamp === null) {
          throw new Error('Recorded timestamp error');
        }
        this.setState({
          lastEvent: lastRecordedEvent.timestamp.toISOString(),
          lastRecordedEvent,
          cleaningStep: `${CleaningSteps.RECORDS_CLEAN_EVENTS} ${
            cleanEvents.length
          } event${cleanEvents.length > 1 ? 's' : ''}`
        });
        this.saveLastRecords(lastRecordedEvent);
        this.deleteGlobalCache();
        this.refreshAvailability();
        setTimeout(
          () =>
            this.endRefreshFeedback(
              this.state.initStream
                ? 'Statistics are ready'
                : 'New Statistics are available!',
              SnackbarVariant.SUCCESS
            ),
          5000
        );
      });
  }

  // endregion

  // region autobound methods

  @autobind
  handleRefreshButtonClick() {
    this.updateCleanStream();
  }

  componentDidMount() {
    this._analysisClient = this.initAnalysisClient();
    this._trackingClient = this.initTrackingClient();
    this._isMounted = true;
    this.updateCleanStream();
  }

  componentWillUnmount() {
    this._analysisClient = null;
    this._trackingClient = null;
    this._isMounted = false;
    clearTimeout(this._cooldownTimeout);
  }

  initAnalysisClient(): KeenAnalysis {
    return new KeenAnalysis({
      projectId: this._projectId,
      readKey: this._readKey
    });
  }

  initTrackingClient(): KeenTracking {
    return new KeenTracking({
      projectId: this._projectId,
      writeKey: this._writeKey
    });
  }

  /**
   * Switch of the processing state and display a feedback if needed
   *
   * @param {string} toastMessage
   * @param {string} toastVariant
   */
  endRefreshFeedback(
    toastMessage?: string,
    toastVariant: SnackbarVariant = SnackbarVariant.INFO
  ) {
    if (toastMessage) {
      toast(toastVariant, toastMessage);
    }
    this.setState({ lastRefresh: new Date().toISOString() });
    this.setState({ cleaningStep: null });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    if (process.env.ALLOW_KEEN_CLEAN_CONSOLE_LOG === 'true') {
      console.log(
        'lastRefresh',
        this.state.lastRefresh,
        'cleaningStep',
        this.state.cleaningStep,
        'cleanEvents',
        this.state.cleanEvents
      );
    }

    return null;
  }

  // endregion
}

export default KeenCleaner;
