import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  MenuItem,
  TextField,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { PersonAdd } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import ReactTrello from 'react-trello';
import {
  AddCardLink,
  AreYouSureDialog,
  ListItemCheckbox,
  ListItemRadio
} from 'src/components';
import AddConsumerCardTemplate from 'src/components/AddConsumerCardTemplate';
import AddStepLaneTemplate from 'src/components/AddStepLaneTemplate';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import ConsumerProfileDialog from 'src/components/ConsumerProfileDialog';
import ConsumerTrelloCard, {
  ConsumerCardEvent
} from 'src/components/ConsumerTrelloCard';
import EditStepDialog from 'src/components/EditStepDialog';
import ListItemButton from 'src/components/ListItemButton';
import ListItemCollapse, {
  ListItemCollapseEvent
} from 'src/components/ListItemCollapse';
import SelectStepDialog from 'src/components/SelectStepDialog';
import TrelloLaneHeader, {
  TrelloLaneHeaderEvent,
  TrelloLaneHeaderProps
} from 'src/components/TrelloLaneHeader';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API, ObjectMap } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import ValidationException from 'src/exceptions/ValidationException';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import StatsApi from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import {
  assertIsDefined,
  assertIsString,
  buildTrelloCardFromConsumer,
  buildTrelloLaneFromStep,
  calculateObjectPositions,
  extractCampaignsFromConsumers,
  getFullName,
  groupConsumersByStep,
  trelloClasses
} from 'src/utilities';
import Engagements from 'src/views/Persona/Engagements';

type Consumer = API.Entities.Consumer;
type ConsumerAchievementValues = API.Entities.Statistics.ConsumerAchievementValues;

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0
    },
    ...trelloClasses(theme),
    modalConsumerProfile: {
      width: '50%'
    },
    actionsCard: {
      maxHeight: `calc(100vh - ${theme.spacing(24)}px)`,
      overflowY: 'auto'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentStatisticsValue &
  CurrentSellerValue &
  CurrentPersonaValue &
  WithDataFromApiProps<
    'consumers',
    API.Consumers.ListConsumersOfSeller.Response
  > &
  WithStyles<typeof styles> &
  RouteComponentProps<{ personaId: string }>;

interface State {
  boardData: ReactTrello.BoardData<API.Entities.Consumer>;
  idOfConsumerBeingViewed: API.Nullable<string>;
  idOfLaneWithMenuOpen: API.Nullable<ReactTrello.Lane['id']>;
  idOfLaneLoadingFor: API.Nullable<ReactTrello.Lane['id']>;
  idOfStepBeingEdited: API.Nullable<API.Entities.Step['id']>;
  idOfStepToDelete: API.Nullable<API.Entities.Step['id']>;
  isDeletingStep: boolean;
  consumersSelectedForDeletion: Array<API.Entities.Consumer['id']>;
  isDeletingConsumers: boolean;
  selectStepDialogOpen: boolean;
  selectedConsumerIds: Array<API.Entities.Consumer['id']>;
  expandedList: ExpandKey;
  sortKeys: ObjectMap<ConsumerSortKey>;
  campaignFilters: Record<string, string>;
  duplicateConsumersFilters: Record<string, boolean>;
  campaignSelectOpen: boolean;

  consumersToEngageWith: API.Entities.Consumer[];

  consumerAchievementValues: ObjectMap<ConsumerAchievementValues>;
}

// endregion

enum ExpandKey {
  NONE = 'none',
  SORT = 'sort',
  FILTER = 'filter'
}

enum ConsumerSortKey {
  NONE = 'None',
  CREATED_AT = 'Created at',
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  ORGANISATION = 'Organisation'
}

const defaultStep: API.Entities.Step = {
  personaId: 'ignore',
  id: '$available-consumers',
  name: 'Available Consumers',
  position: 0
};

interface LaneActionClickEvent {
  laneId: string;
}

@withCurrentStatistics<Props>(true)
@withCurrentSeller<Props>()
@withCurrentPersona<Props>()
@withDataFromApi<Props>(
  props => RelmApi.listConsumers(props.currentPersona!.sellerId),
  'consumers'
)
class ConsumersBoard extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: undefined,
    onCurrentStatisticsChange: undefined,
    currentSeller: undefined,
    onCurrentSellerChange: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = ConsumersBoard.name;

  readonly state: State = {
    boardData: { lanes: this.buildLanes() },
    idOfConsumerBeingViewed: null,
    idOfLaneWithMenuOpen: null,
    idOfLaneLoadingFor: null,
    idOfStepBeingEdited: null,
    idOfStepToDelete: null,
    isDeletingStep: false,
    consumersSelectedForDeletion: [],
    isDeletingConsumers: false,
    selectStepDialogOpen: false,
    selectedConsumerIds: [],
    expandedList: ExpandKey.NONE,
    sortKeys: {},
    campaignFilters: {},
    duplicateConsumersFilters: {},
    campaignSelectOpen: false,

    consumersToEngageWith: [],
    consumerAchievementValues: {}
  };

  /**
   * @inheritDoc
   *
   * @param {Readonly<Props>} prevProps
   * @param {Readonly<State>} prevState
   * @param [snapshot]
   */
  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.classes !== this.props.classes) {
      this.setState({ boardData: { lanes: this.buildLanes() } });
    } // rebuild the board whenever classes change, b/c new class names
  }

  /**
   * Load data from the api, marking the lane with the given id as being 'in progress'.
   *
   * @param {string} forLane
   *
   * @return {Promise<void>}
   */
  async loadFromApi(forLane: API.Nullable<string> = null) {
    if (forLane) {
      this.setState({ idOfLaneLoadingFor: forLane });
    }

    await this.props.loadFromApi();

    this.setState({ idOfLaneLoadingFor: null });
  }

  /**
   * Tries to move the `Step` with the given `id` to the given `position`.
   *
   * @param {string} stepId
   * @param {number} position
   */
  async tryMoveStep(stepId: string, position: number) {
    try {
      await RelmApi.updateStep(this.props.currentPersona!.id, stepId, {
        position
      });
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to update step'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    this.props.onCurrentPersonaChange();
  }

  /**
   * Tries to delete the `Step` with the given `id`.
   *
   * @param {string} stepId
   *
   * @return {Promise<void>}
   */
  async tryDeleteStep(stepId: string) {
    try {
      await RelmApi.deleteStep(this.props.currentPersona!.id, stepId);
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong while trying to delete step'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    toast(SnackbarVariant.SUCCESS, 'Successfully deleted step');

    await Promise.all([
      this.props.onCurrentPersonaChange(),
      this.props.loadFromApi()
    ]);
  }

  /**
   * Tries to move the `Consumer` with the given `id` into the `Step` with the given `id`.
   *
   * @param {string} consumerId
   * @param {API.Nullable<string>} stepId
   */
  async tryMoveConsumerToNewStep(
    consumerId: string,
    stepId: API.Nullable<string>
  ) {
    try {
      await RelmApi.updateConsumer(
        this.props.currentPersona!.sellerId,
        consumerId,
        {
          stepId: stepId,
          personaId: stepId ? undefined : null
        }
      );
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to updating consumer'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    await load(this.loadFromApi(stepId ?? defaultStep.id));

    this.setState({ boardData: { lanes: this.buildLanes() } });
  }

  async tryMoveConsumersToNewStep(
    consumers: Array<API.Entities.Consumer['id']>,
    stepId: API.Nullable<string>
  ) {
    const personaId = this.props.currentPersona!.id || undefined;

    try {
      await RelmApi.bulkUpdateConsumers(
        this.props.currentPersona!.sellerId,
        consumers.map(id => ({
          id,
          stepId,
          personaId: stepId ? personaId : null
        }))
      );
    } catch (error) {
      if (
        !(error instanceof AxiosException) &&
        !(error instanceof ValidationException)
      ) {
        throw error;
      }

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to updating consumer'
      );

      // save an unneeded api request
      return this.forceUpdate();
    }

    await load(this.loadFromApi(stepId ?? defaultStep.id));

    this.setState({ boardData: { lanes: this.buildLanes() } });
  }

  /**
   *
   * @param {string} stepId
   *
   * @return {Promise<void>}
   */
  @autobind
  async updateAchievementValuesForConsumersInStep(
    stepId: string
  ): Promise<void> {
    const groupedConsumers = groupConsumersByStep(
      this.props.consumers,
      defaultStep.id
    );

    const consumerKeenValues = await Promise.all(
      groupedConsumers[stepId].map(async consumer => ({
        id: consumer.id,
        v: await StatsApi.getConsumerAchievementValues(
          consumer,
          this.props.currentStatistics
        )
      }))
    );

    const consumerAchievementValues: ObjectMap<ConsumerAchievementValues> = {};

    consumerKeenValues.forEach(
      consumerKeenValue =>
        (consumerAchievementValues[consumerKeenValue.id] = consumerKeenValue.v)
    );

    await this.setState({ consumerAchievementValues });
  }

  /**
   * Pushes the `App` to the `Engage` view,
   * by pushing the proper url to `history`.
   */
  pushAppToEngageView() {
    this.props.history.push(`${this.props.match.url}/engage`);
  }

  /**
   * Pushes the `App` to the `Outbox` view,
   * by pushing the proper url to `history`.
   */
  pushAppToOutboxView() {
    assertIsDefined(this.props.currentPersona);

    this.props.history.push(`/p/${this.props.currentPersona.id}/outbox`);
  }

  /**
   * Gets all the `Consumer`s for the given `step`,
   * based on the active filters.
   *
   * @param {string} stepId
   *
   * @return {API.Entities.Consumer[]}
   */
  getFilteredConsumers(stepId: string): API.Entities.Consumer[] {
    const groupedConsumersForStep: API.Entities.Consumer[] =
      groupConsumersByStep(this.props.consumers, defaultStep.id)[stepId] || [];

    return groupedConsumersForStep
      .filter(consumer => {
        if (!this.state || !this.state.campaignFilters[stepId]) {
          return true;
        }

        return (
          consumer.salesforceCampaignName === this.state.campaignFilters[stepId]
        );
      })
      .filter(consumer => {
        if (!this.state || !this.state.duplicateConsumersFilters[stepId]) {
          return true;
        }

        return consumer.isEmailDuplicatedWithinSeller;
      });
  }

  // region autobound methods
  @autobind
  handleLaneDragEnd(
    oldPosition: number,
    newPosition: number,
    lane: ReactTrello.Lane
  ) {
    if (lane.id.startsWith('$') || newPosition === 0) {
      this.forceUpdate();

      return;
    } // force an update to restore lane positions

    const positionedLanes = this.state.boardData.lanes.map(
      (lane, position) => ({ lane, position })
    );

    this.setState({
      boardData: {
        lanes: calculateObjectPositions(
          positionedLanes,
          positionedLanes[oldPosition],
          newPosition + 1
        ).map(({ lane }) => lane)
      }
    });

    load(this.tryMoveStep(lane.id, newPosition));
  }

  @autobind
  handleConsumerCardDragEnd(
    cardId: string,
    sourceLaneId: string,
    targetLaneId: string,
    position: number,
    cardDetails: ReactTrello.Card<API.Entities.Consumer>
  ) {
    if (sourceLaneId === targetLaneId) {
      return;
    } // ignore, as they're the same

    if (!cardDetails.metadata) {
      throw new Error('A card without metadata was dragged');
    }

    this.tryMoveConsumerToNewStep(
      cardDetails.metadata.id,
      targetLaneId.startsWith('$') ? null : targetLaneId
    );
  }

  /**
   * Handles when a card for a `Consumer` is clicked.
   *
   * @param {ConsumerCardEvent} event
   */
  @autobind
  handleConsumerCardClick(event: ConsumerCardEvent) {
    this.setState({
      idOfConsumerBeingViewed: event.consumer.id,
      boardData: { lanes: this.buildLanes(false) }
    });
  }

  /**
   * Handles when a `Consumer` `Card` `Checkbox` is changed.
   *
   * @param {ConsumerCardEvent} event
   */
  @autobind
  handleConsumerCardCheckboxClick(event: ConsumerCardEvent) {
    const selectedConsumerIds = [...this.state.selectedConsumerIds];

    const index = selectedConsumerIds.indexOf(event.consumer.id);

    index === -1
      ? selectedConsumerIds.push(event.consumer.id)
      : selectedConsumerIds.splice(index, 1);

    this.setState({ selectedConsumerIds });
  }

  /**
   * Handles when the `ConsumerProfile` is called to close.
   */
  @autobind
  handleConsumerProfileCallToClose() {
    this.setState({
      idOfConsumerBeingViewed: null,
      boardData: { lanes: this.buildLanes(true) }
    });
  }

  /**
   * Handles when a `Consumer` has been updated.
   */
  @autobind
  handleConsumerProfileUpdated() {
    load(this.props.loadFromApi()).then(() =>
      this.setState({ boardData: { lanes: this.buildLanes() } })
    );
  }

  @autobind
  async handleStepEditDialogClose(wasUpdated: boolean) {
    if (wasUpdated) {
      await load(this.props.onCurrentPersonaChange());

      this.setState({ boardData: { lanes: this.buildLanes() } });
    }

    this.setState({ idOfStepBeingEdited: null });
  }

  @autobind
  handleCancelDeleteStep() {
    this.setState({ idOfStepToDelete: null });
  }

  @autobind
  async handleActionDeleteStep() {
    if (this.state.idOfStepToDelete) {
      this.setState({ isDeletingStep: true });

      await load(this.tryDeleteStep(this.state.idOfStepToDelete));

      this.setState({
        boardData: { lanes: this.buildLanes() },
        idOfStepToDelete: null,
        isDeletingStep: false
      });
    }
  }

  @autobind
  handleEngageSelectedClick() {
    const consumersWithEngagements: string[] = [];

    const consumers = this.props.consumers
      .filter(consumer => this.state.selectedConsumerIds.includes(consumer.id))
      .filter((consumer: Consumer) =>
        consumer.hasOutstandingEngagement
          ? !consumersWithEngagements.push(getFullName(consumer))
          : true
      );

    if (consumersWithEngagements.length) {
      toast(
        SnackbarVariant.WARNING,
        `Selected consumers already have pending engagements: ${consumersWithEngagements.join(
          ', '
        )}`
      );
    }

    this.setState(
      {
        idOfLaneWithMenuOpen: null,
        selectedConsumerIds: [],
        consumersToEngageWith: consumers
      },
      () =>
        this.state.consumersToEngageWith.length && this.pushAppToEngageView()
    );
  }

  @autobind
  handleDeleteSelectedClick() {
    this.setState({
      consumersSelectedForDeletion: this.state.selectedConsumerIds
    });
  }

  @autobind
  handleCancelDeleteConsumers() {
    this.setState({ consumersSelectedForDeletion: [] });
  }

  @autobind
  async handleSelectStepDialogClose(stepId?: string) {
    if (!stepId) {
      this.setState({ selectStepDialogOpen: false });

      return;
    }

    const actualStepId = stepId === defaultStep.id ? null : stepId;

    await this.tryMoveConsumersToNewStep(
      this.state.selectedConsumerIds,
      actualStepId
    );

    this.setState({ selectStepDialogOpen: false, selectedConsumerIds: [] });
  }

  @autobind
  async handleActionDeleteConsumers() {
    this.setState({ isDeletingConsumers: true });
    const isMultiple: boolean =
      this.state.consumersSelectedForDeletion.length > 1;

    try {
      await RelmApi.bulkArchiveConsumers(
        this.props.currentPersona!.sellerId,
        this.state.consumersSelectedForDeletion
      );
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      this.setState({ isDeletingConsumers: false });
      toast(
        SnackbarVariant.ERROR,
        `Something went wrong while trying to delete the selected consumer${
          isMultiple ? 's' : ''
        }`
      );

      return;
    }

    this.setState({
      isDeletingConsumers: false,
      consumersSelectedForDeletion: [],
      selectedConsumerIds: []
    });

    load(this.props.loadFromApi()).then(() =>
      this.setState({
        boardData: { lanes: this.buildLanes() }
      })
    );

    toast(
      SnackbarVariant.SUCCESS,
      `Successfully deleted consumer${isMultiple ? 's' : ''}`
    );
  }

  @autobind
  handleEngageAllClick(event: LaneActionClickEvent) {
    this.setState(
      {
        idOfLaneWithMenuOpen: null,
        selectedConsumerIds: [],
        consumersToEngageWith: this.props.consumers.filter(
          consumer => (consumer.stepId ?? defaultStep.id) === event.laneId
        )
      },
      () =>
        this.state.consumersToEngageWith.length && this.pushAppToEngageView()
    );
  }

  @autobind
  handleDeleteAllClick(event: LaneActionClickEvent) {
    this.setState({
      idOfLaneWithMenuOpen: null,
      selectedConsumerIds: [],
      consumersSelectedForDeletion: this.props.consumers
        .filter(
          consumer => (consumer.stepId ?? defaultStep.id) === event.laneId
        )
        .map(consumer => consumer.id)
    });
  }

  @autobind
  handleSelectAllClick(event: LaneActionClickEvent) {
    this.setState({
      idOfLaneWithMenuOpen: null,
      selectedConsumerIds: this.getFilteredConsumers(event.laneId).map(
        ({ id }) => id
      )
    });
  }

  @autobind
  handleMoveSelectedToNextStep() {
    this.setState({
      idOfLaneWithMenuOpen: null,
      selectStepDialogOpen: true
    });
  }

  @autobind
  handleLaneSort(
    card1: ReactTrello.Card<API.Entities.Consumer>,
    card2: ReactTrello.Card<API.Entities.Consumer>
  ) {
    if (!card1.metadata || !card2.metadata) {
      throw new Error('A card has no metadata!');
    }

    const sortKey =
      this.state.sortKeys[card1.metadata.stepId ?? defaultStep.id] ||
      ConsumerSortKey.NONE;

    switch (sortKey) {
      case ConsumerSortKey.NONE:
      default:
        return 0;
      case ConsumerSortKey.CREATED_AT:
        return (
          new Date(card1.metadata.createdAt).getTime() -
          new Date(card2.metadata.createdAt).getTime()
        );
      case ConsumerSortKey.FIRST_NAME:
        return card1.metadata.firstName.localeCompare(card2.metadata.firstName);
      case ConsumerSortKey.LAST_NAME:
        return card1.metadata.lastName.localeCompare(card2.metadata.lastName);
      case ConsumerSortKey.ORGANISATION:
        return (card1.metadata.organisation ?? '').localeCompare(
          card2.metadata.organisation ?? ''
        );
    }
  }

  /**
   * Handles when the menu icon of a `TrelloLaneHeader` is clicked.
   *
   * @param {TrelloLaneHeaderEvent} event
   */
  @autobind
  handleLaneHeaderMenuIconClick(event: TrelloLaneHeaderEvent) {
    const idOfLaneWithMenuOpen =
      this.state.idOfLaneWithMenuOpen === event.laneId ? null : event.laneId;

    this.setState({ idOfLaneWithMenuOpen });
  }

  /**
   * Handles when the menu of a `TrelloLaneHeader` is closed.
   */
  @autobind
  handleLaneHeaderMenuClickAway() {
    if (this.state.campaignSelectOpen) {
      this.setState({ campaignSelectOpen: false });

      return;
    }

    this.setState({
      idOfLaneWithMenuOpen: null,
      expandedList: ExpandKey.NONE
    });
  }

  @autobind
  handleDeleteStepClick(event: { laneId: string }) {
    const targetLaneId = event.laneId;

    if (targetLaneId === defaultStep.id) {
      console.warn('ignoring request to delete ghost step!');

      return;
    } // just in case...

    this.setState({
      idOfLaneWithMenuOpen: null,
      idOfStepToDelete: targetLaneId
    });
  }

  @autobind
  handleRenameStepClick(event: { laneId: string }) {
    const targetLaneId = event.laneId;

    if (targetLaneId === defaultStep.id) {
      console.warn('ignoring request to rename ghost step!');

      return;
    } // just in case...

    this.setState({
      idOfLaneWithMenuOpen: null,
      idOfStepBeingEdited: targetLaneId
    });
  }

  /**
   *
   * @param {ListItemCollapseEvent} event
   */
  @autobind
  handleExpandListClick(event: ListItemCollapseEvent) {
    const expandedList = event.expandKey as ExpandKey;

    this.setState({ expandedList });
  }

  @autobind
  handleDuplicatedConsumersFilterItemClick(event: { laneId: string }) {
    this.setState(
      {
        duplicateConsumersFilters: {
          ...this.state.duplicateConsumersFilters,
          [event.laneId]: !(
            this.state.duplicateConsumersFilters[event.laneId] || false
          )
        }
      },
      () => this.setState({ boardData: { lanes: this.buildLanes() } })
    );
  }

  @autobind
  handleClearFiltersClick(event: { laneId: string }) {
    this.setState(
      {
        campaignFilters: {
          ...this.state.campaignFilters,
          [event.laneId]: ''
        },
        duplicateConsumersFilters: {
          ...this.state.duplicateConsumersFilters,
          [event.laneId]: false
        }
      },
      () => this.setState({ boardData: { lanes: this.buildLanes() } })
    );
  }

  /**
   *
   * @param {React.SyntheticEvent<HTMLElement>} event
   */
  @autobind
  handleSortItemClick(event: { laneId: string; sortKey: ConsumerSortKey }) {
    this.setState(
      {
        sortKeys: {
          ...this.state.sortKeys,
          [event.laneId]: event.sortKey
        }
      },
      () => this.setState({ boardData: { lanes: this.buildLanes() } })
    );
  }

  @autobind
  handleBoardDataChange(newData: ReactTrello.BoardData<API.Entities.Consumer>) {
    this.setState({ boardData: newData });
  }

  @autobind
  handleConsumerAdded(stepId: API.Entities.Step['id']) {
    load(this.loadFromApi(stepId));
  }

  /**
   * Handles when `Engagement`s are sent to the `Outbox` for `Consumer`s.
   */
  @autobind
  handleEngagementsSentToOutbox() {
    this.pushAppToOutboxView();
  }

  @autobind
  handleCampaignFilterChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const { name, value } = event.target;

    assertIsDefined(name);
    assertIsString(value);

    this.setState(
      {
        campaignFilters: {
          ...this.state.campaignFilters,
          [name]: value
        }
      },
      () => this.setState({ boardData: { lanes: this.buildLanes() } })
    );
  }

  @autobind
  async handleStepAdded() {
    await load(this.props.onCurrentPersonaChange());

    this.setState({ boardData: { lanes: this.buildLanes() } });
  }

  // endregion
  // region render & get-render-content methods
  /**
   *
   * @param {boolean} [droppable=true]
   *
   * @return {Array<ReactTrello.Lane>}
   */
  buildLanes(droppable = true): Array<ReactTrello.Lane<API.Entities.Consumer>> {
    const classes = this.props.classes;

    const sortedSteps = [defaultStep, ...this.props.currentPersona!.steps].sort(
      (a, b) => a.position - b.position
    );

    return sortedSteps.map(step => {
      const consumers = this.getFilteredConsumers(step.id).map(card =>
        buildTrelloCardFromConsumer(card, classes.trelloCard)
      );

      if (this.state && Object.keys(this.state.sortKeys).length) {
        consumers.sort(this.handleLaneSort);
      }

      return buildTrelloLaneFromStep(
        step,
        classes.trelloLane,
        consumers,
        droppable
      );
    });
  }

  getActionsModalBuilderRenderContent(props: TrelloLaneHeaderProps) {
    return (
      <Card className={this.props.classes.actionsCard} tabIndex={-1}>
        <CardContent>
          <Typography variant="subtitle2">Actions</Typography>
        </CardContent>
        <Divider />
        <List dense disablePadding>
          {props.id !== defaultStep.id && (
            <>
              <ListItemButton<{ laneId: string }>
                label="Rename step"
                clickData={{ laneId: props.id }}
                onClick={this.handleRenameStepClick}
              />
              <ListItemButton<{ laneId: string }>
                label="Delete step"
                divider
                clickData={{ laneId: props.id }}
                onClick={this.handleDeleteStepClick}
              />
              <ListItemButton<{ laneId: string }>
                label="Engage selected"
                disabled={this.state.selectedConsumerIds.length === 0}
                clickData={{ laneId: props.id }}
                onClick={this.handleEngageSelectedClick}
              />
              <ListItemButton<LaneActionClickEvent>
                label="Engage all"
                disabled={props.cards.length === 0}
                divider
                clickData={{ laneId: props.id }}
                onClick={this.handleEngageAllClick}
              />
              <ListItemButton<{ laneId: string }>
                label="Delete selected"
                disabled={this.state.selectedConsumerIds.length === 0}
                clickData={{ laneId: props.id }}
                onClick={this.handleDeleteSelectedClick}
              />
              <ListItemButton<LaneActionClickEvent>
                label="Delete all"
                disabled={props.cards.length === 0}
                divider
                clickData={{ laneId: props.id }}
                onClick={this.handleDeleteAllClick}
              />
            </>
          )}
          <ListItemButton<LaneActionClickEvent>
            label="Select all"
            disabled={props.cards.length === 0}
            divider
            clickData={{ laneId: props.id }}
            onClick={this.handleSelectAllClick}
          />
          <ListItemButton<LaneActionClickEvent>
            label="Move selected"
            disabled={this.state.selectedConsumerIds.length === 0}
            divider
            clickData={{ laneId: props.id }}
            onClick={this.handleMoveSelectedToNextStep}
          />
          <ListItemCollapse
            in={this.state.expandedList === ExpandKey.SORT}
            expandKey={ExpandKey.SORT}
            header="Sort by..."
            divider
            onClick={this.handleExpandListClick}
          >
            <List dense disablePadding>
              {this.getSortOptionListRenderContent(props.id)}
            </List>
          </ListItemCollapse>
          <ListItemCollapse
            in={this.state.expandedList === ExpandKey.FILTER}
            expandKey={ExpandKey.FILTER}
            header="Filter by..."
            onClick={this.handleExpandListClick}
          >
            <List dense disablePadding>
              {this.getFilterOptionListRenderContent(props.id)}
            </List>
          </ListItemCollapse>
        </List>
      </Card>
    );
  }

  /**
   *
   * @param {string} laneId
   */
  getSortOptionListRenderContent(laneId: string) {
    return Object.values(ConsumerSortKey).map(sortKey => (
      <ListItemRadio<{ laneId: string; sortKey: ConsumerSortKey }>
        key={sortKey}
        checked={
          (this.state.sortKeys[laneId] || ConsumerSortKey.NONE) === sortKey
        }
        label={sortKey}
        clickData={{ laneId, sortKey }}
        onClick={this.handleSortItemClick}
      />
    ));
  }

  @autobind
  handleCampaignSelectOpen() {
    this.setState({ campaignSelectOpen: true });
  }

  @autobind
  handleCampaignSelectClose() {
    this.setState({ campaignSelectOpen: false });
  }

  /**
   *
   * @param {string} laneId
   */
  getFilterOptionListRenderContent(laneId: string) {
    return (
      <>
        <ListItem>
          <TextField
            name={laneId}
            fullWidth
            label="Campaign"
            margin="dense"
            value={this.state.campaignFilters[laneId] || ''}
            InputLabelProps={{
              shrink: true
            }}
            SelectProps={{
              onOpen: this.handleCampaignSelectOpen,
              onClose: this.handleCampaignSelectClose,
              onChange: this.handleCampaignFilterChange,
              open: this.state.campaignSelectOpen
            }}
            select
          >
            {extractCampaignsFromConsumers(this.props.consumers).map(name => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        <ListItemCheckbox<{ laneId: string }>
          checked={this.state.duplicateConsumersFilters[laneId] || false}
          label="Duplicated"
          clickData={{ laneId }}
          onClick={this.handleDuplicatedConsumersFilterItemClick}
        />
        <ListItemButton<{ laneId: string }>
          label="Clear Filters"
          clickData={{ laneId }}
          onClick={this.handleClearFiltersClick}
        />
      </>
    );
  }

  render() {
    if (!this.props.currentPersona) {
      throw new Error('currentPersona not loaded');
    } // shouldn't happen, but TypeScript doesn't realise that

    if (this.props.location.pathname.endsWith('/engage')) {
      if (this.state.consumersToEngageWith.length === 0) {
        return <Redirect to={this.props.match.url} />;
      }

      return (
        <Engagements
          consumersToEngageWith={this.state.consumersToEngageWith}
          currentPersona={this.props.currentPersona}
          onSentToOutbox={this.handleEngagementsSentToOutbox}
        />
      );
    }

    return (
      <>
        <div className={this.props.classes.root}>
          <ReactTrello<API.Entities.Consumer>
            className={this.props.classes.trelloBoard}
            editable
            draggable
            cardDraggable
            customCardLayout
            hideCardDeleteIcon
            addLaneTitle="Add a step..."
            addCardLink={
              <AddCardLink text="Add a consumer">
                <PersonAdd />
              </AddCardLink>
            }
            newLaneTemplate={
              <AddStepLaneTemplate onStepAdded={this.handleStepAdded} />
            }
            newCardTemplate={
              <AddConsumerCardTemplate
                defaultStep={defaultStep}
                onConsumerAdded={this.handleConsumerAdded}
              />
            }
            customLaneHeader={
              <TrelloLaneHeader
                idOfLaneWithMenuOpen={this.state.idOfLaneWithMenuOpen}
                idOfLaneBeingLoadedFor={this.state.idOfLaneLoadingFor}
                actionsModalBuilder={props =>
                  this.getActionsModalBuilderRenderContent(props)
                }
                onMenuIconClick={this.handleLaneHeaderMenuIconClick}
                onMenuClickAway={this.handleLaneHeaderMenuClickAway}
              />
            }
            data={this.state.boardData}
            handleLaneDragEnd={this.handleLaneDragEnd}
            handleDragEnd={this.handleConsumerCardDragEnd}
            onDataChange={this.handleBoardDataChange}
          >
            <ConsumerTrelloCard
              rootClassName={this.props.classes.trelloCardInner}
              selectedConsumerIds={this.state.selectedConsumerIds}
              onCardClick={this.handleConsumerCardClick}
              onCheckClick={this.handleConsumerCardCheckboxClick}
            />
          </ReactTrello>
        </div>
        {this.state.idOfConsumerBeingViewed !== null && (
          <ConsumerProfileDialog
            open
            consumer={
              this.props.consumers.find(
                consumer => consumer.id === this.state.idOfConsumerBeingViewed
              )!
            }
            onCallToClose={this.handleConsumerProfileCallToClose}
            onConsumerUpdated={this.handleConsumerProfileUpdated}
          />
        )}
        {this.state.idOfStepBeingEdited !== null && (
          <EditStepDialog
            open
            step={
              this.props.currentPersona.steps.find(
                step => step.id === this.state.idOfStepBeingEdited
              )!
            }
            onClose={this.handleStepEditDialogClose}
          />
        )}
        {this.state.idOfStepToDelete !== null && (
          <AreYouSureDialog
            open
            loading={this.state.isDeletingStep}
            onCancel={this.handleCancelDeleteStep}
            onAction={this.handleActionDeleteStep}
            actionText="Delete step"
          >
            Are you sure you want to delete this step?
            <br />
            All stories & consumers currently in this step will be moved back to
            'available'.
            <br />
            <br />
            <strong>This action cannot be undone.</strong>
          </AreYouSureDialog>
        )}
        {this.state.consumersSelectedForDeletion.length > 0 && (
          <AreYouSureDialog
            open
            loading={this.state.isDeletingConsumers}
            onCancel={this.handleCancelDeleteConsumers}
            onAction={this.handleActionDeleteConsumers}
            actionText={`Delete Consumer${
              this.state.consumersSelectedForDeletion.length > 1 ? 's' : ''
            }`}
          >
            {this.state.consumersSelectedForDeletion.length > 1 ? (
              <>Are you sure you want to delete these consumers?</>
            ) : (
              <>Are you sure you want to delete this consumer?</>
            )}
            <br />
            <strong>This action cannot be undone.</strong>
          </AreYouSureDialog>
        )}
        <SelectStepDialog
          open={this.state.selectStepDialogOpen}
          steps={[defaultStep, ...this.props.currentPersona.steps]}
          onClose={this.handleSelectStepDialogClose}
        />
      </>
    );
  }

  // endregion
}

export default withStyles(styles, { name: ConsumersBoard.jssName })(
  ConsumersBoard
);
