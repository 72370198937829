import * as CSS from 'csstype';
import {
  AlignBlockCenterButton,
  AlignBlockLeftButton,
  AlignBlockRightButton
} from 'draft-js-buttons';
import * as React from 'react';
import {
  Alignment,
  AlignmentPluginTheme,
  AlignmentToolState
} from 'src/draft-plugins/draft-js-alignment-plugin/index';
import { StateStore } from 'src/draft-plugins/draft-js-alignment-plugin/utils/createStore';
import getRelativeParent from 'src/draft-plugins/draft-js-alignment-plugin/utils/getRelativeParent';

// region component props
interface ExternalProps {
  store: StateStore<AlignmentToolState>;

  theme: AlignmentPluginTheme;

  // scrollOffsetRef: React.RefObject<HTMLElement>;
}

export type AlignmentToolProps = ExternalProps;

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  position: {
    [k: string]: CSS.Properties<number | string>[keyof CSS.Properties];
  };
  alignment: Alignment | null;
}

// endregion

export default class AlignmentTool extends React.Component<Props, State> {
  private readonly _toolbarRef: React.RefObject<
    HTMLDivElement
  > = React.createRef<HTMLDivElement>();

  state = {
    position: {},
    alignment: null
  };

  // eslint-disable-next-line @typescript-eslint/camelcase
  UNSAFE_componentWillMount() {
    this.props.store.subscribeToItem('visibleBlock', this.onVisibilityChanged);
    this.props.store.subscribeToItem('alignment', this.onAlignmentChange);
  }

  componentWillUnmount() {
    this.props.store.unsubscribeFromItem(
      'visibleBlock',
      this.onVisibilityChanged
    );
    this.props.store.unsubscribeFromItem('alignment', this.onAlignmentChange);
  }

  onVisibilityChanged = (visibleBlock: unknown | null) => {
    setTimeout(() => {
      let position;
      const boundingRect = this.props.store.getItem('boundingRect');

      if (visibleBlock && this._toolbarRef.current) {
        const relativeParent = getRelativeParent(
          this._toolbarRef.current.parentElement
        );
        const toolbarHeight = this._toolbarRef.current.clientHeight;
        const relativeRect = relativeParent
          ? relativeParent.getBoundingClientRect()
          : document.body.getBoundingClientRect();

        position = {
          top: boundingRect.top - relativeRect.top - toolbarHeight,
          left: boundingRect.left - relativeRect.left + boundingRect.width / 2,
          transform: 'translate(-50%) scale(1)',
          transition: 'transform 0.15s cubic-bezier(.3,1.2,.2,1)'
        };
      } else {
        position = { transform: 'translate(-50%) scale(0)' };
      }
      const alignment =
        this.props.store.getItem('alignment') ?? Alignment.CENTER;

      this.setState({
        alignment,
        position
      });
    }, 0);
  };

  onAlignmentChange = (alignment: Alignment | null) => {
    this.setState({
      alignment
    });
  };

  render() {
    const defaultButtons = [
      AlignBlockLeftButton,
      AlignBlockCenterButton,
      AlignBlockRightButton
    ];

    const { theme } = this.props;

    return (
      <div
        className={theme.alignmentToolStyles.alignmentTool}
        style={this.state.position}
        ref={this._toolbarRef}
      >
        {defaultButtons.map((Button, index) => (
          <Button
            /* the index can be used here as the buttons list won't change */
            key={index}
            alignment={this.state.alignment}
            setAlignment={this.props.store.getItem('setAlignment')}
            theme={theme.buttonStyles}
          />
        ))}
      </div>
    );
  }
}
