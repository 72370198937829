import {
  Card,
  CardHeader,
  CircularProgress,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import hoistNonReactStatics from 'hoist-non-react-statics';
import * as React from 'react';
import { CenteredCircularProgress } from 'src/components';
import AuthTokenFailureException from 'src/exceptions/AuthTokenFailureException';
import RelmApi from 'src/services/RelmApi';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const styles = createStyles({
  root: {
    margin: 'auto',
    width: 'fit-content',
    textAlign: 'center'
  }
});

/* there exists no way to move this to another file apparently? */
const RenderWithStyles = withStyles(styles)(
  class RenderWithStylesComponent extends React.Component<
    {
      classes?: Partial<ClassNameMap<keyof typeof styles>>;
      children: (
        classes: Partial<ClassNameMap<keyof typeof styles>>
      ) => React.ReactNode;
    } & WithStyles<typeof styles>
  > {
    render() {
      return this.props.children(this.props.classes);
    }
  }
);

export type LoadDataFromApiFunc<P> = (props: P) => Promise<{ data: any }>;

export type WithDataFromApiProps<TPropName extends string, TPropType> = {
  loadFromApi: (
    resetLoading?: boolean
  ) => Promise<
    State<TPropType extends { data: unknown } ? TPropType['data'] : TPropType>
  >;
} & {
  [K in TPropName]: TPropType extends { data: unknown }
    ? TPropType['data']
    : TPropType;
};

interface State<ResultType = any> {
  result: ResultType;
  hasLoaded: boolean;
  hasErrored: boolean;
}

/**
 * Decorator that makes an api call, and maps the results to a prop
 */
const withDataFromApi = <P extends WithDataFromApiProps<string, any>>(
  apiCall: LoadDataFromApiFunc<
    Omit<P & WithDataFromApiProps<string, any>, 'loadFromApi'>
  >,
  propName: keyof P & string,
  useAbsoluteCenteredProgress = true,
  loadOnMount = true
) => <C extends React.ComponentClass<P>>(Component: C): C => {
  class WithDataFromApi extends React.Component<
    Omit<P & WithDataFromApiProps<string, any>, 'loadFromApi'>
  > {
    readonly state: State = {
      result: null,
      hasLoaded: false,
      hasErrored: false
    };

    componentDidMount() {
      loadOnMount && this.makeApiCall();
    }

    @autobind
    async makeApiCall(resetLoading = false): Promise<State> {
      if (resetLoading) {
        this.setState({ hasLoaded: false });
      }

      const newState: State = { ...this.state, hasLoaded: true };

      try {
        newState.result = (await apiCall(this.props)).data;
      } catch (error) {
        if (error instanceof AuthTokenFailureException) {
          RelmApi.unauthenticate();

          return newState;
        } // handle unauthorized errors

        console.warn('error happened');

        newState.hasErrored = true;
      }

      this.setState(newState);

      return newState;
    }

    render() {
      if (!this.state.hasLoaded) {
        return (
          <RenderWithStyles>
            {classes => (
              <div className={classes.root}>
                {useAbsoluteCenteredProgress ? (
                  <CenteredCircularProgress />
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </RenderWithStyles>
        ); // show a loading circle until we have the api data
      } // otherwise we'll cause bad setState calls in other components

      if (this.state.hasErrored) {
        return (
          <RenderWithStyles>
            {classes => (
              <Card className={classes.root}>
                <CardHeader
                  title="Oh no, looks like something went wrong!"
                  subheader="Please refresh the page to try again"
                />
              </Card>
            )}
          </RenderWithStyles>
        );
      }

      const TypedComponent = Component as React.ComponentClass<P>;

      /*
                be careful making changes - typescript has 'issues',
                so it won't typecheck this components props.
             */
      return (
        <TypedComponent
          {...((this.props as unknown) as P)}
          loadFromApi={this.makeApiCall}
          {...{ [propName]: this.state.result }}
        />
      );
    }
  }

  hoistNonReactStatics(WithDataFromApi, Component);

  return (WithDataFromApi as unknown) as C;
};

export default withDataFromApi;
