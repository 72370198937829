import {
  Button,
  Card,
  CardContent,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { GenericLink } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import ResetPasswordRequest from 'src/schemas/ResetPasswordRequest';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import { RelmIcon } from 'src/theme/icons';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    authCard: {
      margin: `0 ${theme.spacing()}px`,
      width: `${theme.spacing(50)}px`,
      height: `${theme.spacing(55)}px`,
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between'
    },
    toolbarBrand: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.primary.main,
      paddingTop: `${theme.spacing(1.5)}px`,
      paddingBottom: `${theme.spacing(1.5)}px`
    },
    toolbarBrandName: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      fontSize: `${theme.spacing(3.5)}px`
    },
    logo: {
      height: '46px',
      width: 'auto'
    },
    cardActionsRoot: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    resetPasswordButton: {
      marginBottom: `${theme.spacing()}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  RouteComponentProps<{ token: string }> &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<API.Authorization.ResetPassword.Request> {
  disableSubmit: boolean;
}

// endregion

class ResetPassword extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _validator = new ValidationTrait(this, ResetPasswordRequest);

  readonly state: State = {
    disableSubmit: false,
    inputValues: {
      email: '',
      token: this.props.match.params.token,
      password: '',
      passwordConfirmation: ''
    },
    inputErrors: {
      email: '',
      token: '',
      password: '',
      passwordConfirmation: ''
    }
  };

  async tryUpdateUserPassword() {
    try {
      await RelmApi.updateUserPassword(
        this.state.inputValues.email,
        this.state.inputValues.password,
        this.state.inputValues.passwordConfirmation,
        this.state.inputValues.token
      );

      toast(
        SnackbarVariant.SUCCESS,
        `Password reset, going to login screen...`
      );
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          error.response!.data.message ||
            'Something went wrong trying to reset your password.'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);
      this.setState({ disableSubmit: false });

      return;
    }

    /*
     * slight pause before navigating to signin,
     * might not be necessary if API is a bit sluggish
     */
    window.setTimeout(() => {
      this.props.history.push('/signin');
    }, 1000);
  }

  // region autobound methods
  @autobind
  handleResetPasswordButtonClick() {
    this.setState({ disableSubmit: true });
    this.tryUpdateUserPassword();
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <div className={this.props.classes.root}>
        <Card className={this.props.classes.authCard}>
          <CardContent className={this.props.classes.toolbarBrand}>
            <RelmIcon className={this.props.classes.logo} />
            <Typography
              variant="h4"
              className={this.props.classes.toolbarBrandName}
            >
              Reset Password
            </Typography>
          </CardContent>
          <CardContent>
            {this._validator.buildTextFieldForProperty('email', 'Email', {
              required: true
            })}
            {this._validator.buildTextFieldForProperty(
              'password',
              'New password',
              { required: true, type: 'password' }
            )}
            {this._validator.buildTextFieldForProperty(
              'passwordConfirmation',
              'Confirm password',
              { required: true, type: 'password' }
            )}
          </CardContent>
          <CardContent className={this.props.classes.cardActionsRoot}>
            <Button
              className={this.props.classes.resetPasswordButton}
              disabled={this.state.disableSubmit}
              fullWidth
              variant="outlined"
              onClick={this.handleResetPasswordButtonClick}
            >
              Reset password
            </Button>
            <Typography align="center" variant="body2">
              <GenericLink to="/signin">Remembered password?</GenericLink>
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }

  // endregion
}

export default withStyles(styles)(ResetPassword);
