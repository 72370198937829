import {
  CardHeader,
  DialogActions,
  DialogContent,
  IconButton,
  RootRef,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, Edit, RecentActors } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as Draft from 'draft-js';
import * as React from 'react';
import { FormButton, FormDialog, Glue } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import StoryMedia, { MediaType } from 'src/components/StoryMedia';
import StoryEditor, {
  contentStateToHtml
} from 'src/components/editors/StoryEditor';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import AddStoryRequest from 'src/schemas/AddStoryRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import withDataFromApi, {
  Omit,
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import {
  buildTrelloCardFromStory,
  createLandingPageStyles
} from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      height: '100%',
      overflowY: 'unset',
      maxWidth: '1400px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: 0,
        margin: 0
      }
    },
    typographyTitle: {
      textAlign: 'center'
    },
    dialogHeader: {
      paddingTop: `${theme.spacing()}px`,
      paddingBottom: `${theme.spacing(0.5)}px`,
      flex: '0 0 auto'
    },
    dialogContent: {
      paddingTop: `${theme.spacing(3)}px`,
      paddingBottom: `${theme.spacing(2)}px`,
      paddingLeft: 0,
      paddingRight: 0,
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      width: '100%'
    },
    storyContainer: {
      'width': '100%',
      'maxWidth': `1000px`,
      'margin': `0 auto`,
      'overflow': 'hidden',
      '& > div': {
        margin: 0
      }
    },
    storyTitle: {
      'fontSize': '1.5rem',
      'fontWeight': 700,
      'letterSpacing': '-1px',
      '& > input': {
        margin: 0,
        height: '1.5em',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`
      }
    },
    storyEditorContainer: {
      padding: `${theme.spacing(2.4)}px`,
      clear: 'both',
      overflow: 'hidden'
    },
    storySummary: {
      '& > textarea': {
        margin: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
        lineHeight: '1.8em'
      }
    },
    storySummaryLabel: {
      paddingTop: `${theme.spacing(2)}px`,
      left: `${theme.spacing(2)}px`
    },
    storySummaryWarning: {
      textAlign: 'right',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
      color: theme.palette.text.secondary
    },
    storySummaryBad: {
      fontWeight: 'bold',
      color: theme.palette.text.primary
    },
    dialogActions: {
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    },
    buttonProfileActionLabel: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    iconProfileAction: {
      [theme.breakpoints.up('md')]: {
        paddingRight: `${theme.spacing(0.5)}px`
      }
    },
    dialogActionsProcess: {
      marginRight: `${theme.spacing()}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`,
      minWidth: 'auto'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  consumerId?: string;

  onStoryAdded: (stepId: API.Entities.Step['id']) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentPersonaValue &
  ReactTrello.NewCardTemplateProps &
  WithDataFromApiProps<
    'sellerStyles',
    API.Sellers.ShowSingleSellerStyles.Response
  > &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<
    Omit<API.Stories.AddStory.Request, 'consumerId' | 'position'>
  > {
  editorState: Draft.EditorState;
  imageFile: API.Nullable<File>;
}

// endregion

/**
 *
 */
@withCurrentPersona<Props>()
@withDataFromApi<Props>(
  props => RelmApi.getSellerStyles(props.currentPersona.sellerId),
  'sellerStyles'
)
class AddStoryCardTemplate extends React.Component<Props, State> {
  static readonly defaultProps = {
    consumerId: undefined,
    currentPersona: undefined,
    onCurrentPersonaChange: undefined,
    laneId: '',
    sellerStyles: undefined,
    landingPageStyles: undefined,
    loadFromApi: undefined,

    onCurrentSellerChange: () => {},
    onCancel: () => {},
    onAdd: () => {}
  };

  private readonly _validator = new ValidationTrait(this, AddStoryRequest);

  readonly state: State = {
    editorState: Draft.EditorState.createEmpty(),
    imageFile: null,
    inputValues: {
      title: '',
      summary: '',
      imageUrl: '',
      videoUrl: '',
      body: '',
      stepId: this.props.laneId
    },
    inputErrors: {
      title: '',
      summary: '',
      imageUrl: '',
      videoUrl: '',
      body: '',
      stepId: ''
    }
  };

  private readonly _scrollOffsetRef = React.createRef<HTMLElement>();

  private readonly _classesLPStyles = createLandingPageStyles(
    this.props.sellerStyles
  );

  /**
   * Attempts to upload an image file from the state.
   *
   * If the `imageFile` is `null`, or an error occurs while uploading the file,
   * `null` is returned. Otherwise, the `url` of the file is returned.
   *
   * @return {Promise<API.Nullable<API.Entities.File['url']>>}
   */
  async tryUploadImageFromState(): Promise<
    API.Nullable<API.Entities.File['url']>
  > {
    if (!this.state.imageFile) {
      return null;
    }

    try {
      return (await RelmApi.uploadFile(this.state.imageFile)).data.url;
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to upload your image.'
      );

      return null;
    }
  }

  async tryCreateNewStory() {
    // lane Ids starting with `$` are invalid, and should be ignored ;)
    const stepId = this.props.laneId.startsWith('$')
      ? undefined
      : this.props.laneId;

    const imageUrl = (await this.tryUploadImageFromState()) ?? undefined;

    try {
      const newStory = (
        await RelmApi.createStory(this.props.currentPersona!.id, {
          ...this.state.inputValues,
          imageUrl,
          stepId,
          consumerId: this.props.consumerId
        })
      ).data;

      toast(SnackbarVariant.SUCCESS, 'Successfully created story');

      this.props.onAdd(buildTrelloCardFromStory(newStory, 'ignore'));
      this.props.onStoryAdded(stepId ?? '');
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to create story'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);
    }
  }

  // region autobound methods
  @autobind
  handleSaveStoryButtonClick() {
    load(this.tryCreateNewStory());
  }

  @autobind
  handleCloseClick() {
    this.props.onCancel();
  }

  @autobind
  handleBodyEditorChange(editorState: Draft.EditorState) {
    this.setState({
      editorState,
      inputValues: {
        ...this.state.inputValues,
        body: contentStateToHtml(editorState.getCurrentContent())
      }
    });
  }

  /**
   * Handles when a file is dropped onto the `imageUrl` dropzone.
   *
   * @param {Array<File>} accepted
   */
  @autobind
  handleImageUrlFileDrop(accepted: File[]) {
    this.setState({ imageFile: accepted[0] });
  }

  @autobind
  handleClearMediaClick(mediaType: MediaType) {
    const imageFile =
      mediaType === MediaType.IMAGE ? null : this.state.imageFile;

    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [mediaType]: null
      },
      imageFile
    });
  }

  // endregion
  // region methods
  summaryWarn() {
    if (
      !this.state.inputValues.summary ||
      this.state.inputValues.summary === ''
    ) {
      return;
    }
    const charactersLeft: number = 255 - this.state.inputValues.summary.length;

    if (charactersLeft > 10) {
      return;
    }

    return (
      <Typography
        className={cx({
          [this.props.classes.storySummaryWarning]: charactersLeft <= 10,
          [this.props.classes.storySummaryBad]: charactersLeft < 0
        })}
      >
        Characters left in the summary: {charactersLeft}
      </Typography>
    );
  }

  // endregion
  // region render & get-render-content methods
  render() {
    if (!this.props.currentPersona) {
      throw new Error('currentPersona not loaded');
    } // shouldn't happen, but TypeScript doesn't realise that
    const classes = this.props.classes;

    return (
      <FormDialog classes={{ paper: classes.paper }} open>
        <CardHeader
          className={classes.dialogHeader}
          style={this._classesLPStyles.storyHeader}
          avatar={<RecentActors />}
          action={
            <IconButton onClick={this.handleCloseClick}>
              <Close />
            </IconButton>
          }
          titleTypographyProps={{
            className: classes.typographyTitle
          }}
          title="Add a Story"
        />
        <RootRef rootRef={this._scrollOffsetRef}>
          <DialogContent
            className={classes.dialogContent}
            style={this._classesLPStyles.storyBg}
          >
            <div
              className={classes.storyContainer}
              style={this._classesLPStyles.storyContainer}
            >
              <StoryMedia
                imageFile={this.state.imageFile}
                imageUrl={this.state.inputValues.imageUrl}
                videoUrl={this.state.inputValues.videoUrl}
                videoError={this.state.inputErrors.videoUrl}
                onFileDropped={this.handleImageUrlFileDrop}
                onClearMediaClick={this.handleClearMediaClick}
              >
                {this._validator.buildTextFieldForProperty(
                  'videoUrl',
                  'Video URL'
                )}
              </StoryMedia>
              {this._validator.buildTextFieldForProperty('title', '', {
                required: true,
                placeholder: 'Title',
                InputProps: {
                  classes: {
                    root: classes.storyTitle
                  },
                  style: this._classesLPStyles.storyTitle
                }
              })}
              <StoryEditor
                className={classes.storyEditorContainer}
                editorState={this.state.editorState}
                scrollOffsetRef={this._scrollOffsetRef}
                onChange={this.handleBodyEditorChange}
                onCtrlEnter={this.handleSaveStoryButtonClick}
              />
              {this._validator.buildTextFieldForProperty('summary', 'Summary', {
                multiline: true,
                rows: 3,
                InputLabelProps: {
                  classes: {
                    root: classes.storySummaryLabel
                  }
                },
                InputProps: {
                  classes: {
                    root: classes.storySummary
                  },
                  style: this._classesLPStyles.storySummary
                }
              })}
            </div>
          </DialogContent>
        </RootRef>
        <DialogActions className={classes.dialogActions}>
          <Glue />
          {this.summaryWarn()}
          <FormButton
            className={classes.dialogActionsProcess}
            variant="outlined"
            size="large"
            color="primary"
            disabled={
              this.state.inputValues.title === '' ||
              (this.state.inputValues.summary !== null &&
                this.state.inputValues.summary!.length > 255)
            }
            onClick={this.handleSaveStoryButtonClick}
          >
            <Edit className={classes.iconProfileAction} />
            <span className={classes.buttonProfileActionLabel}>Add Story</span>
          </FormButton>
        </DialogActions>
      </FormDialog>
    );
  }

  // endregion
}

export default withStyles(styles)(AddStoryCardTemplate);

// interface HasValidationImplementation {
//     state: {
//         inputValues: { [k: string]: any },
//         inputErrors: Record<keyof HasValidationImplementation['state']['inputValues'], string>,
//     }
//     handleInputValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
//     handleInputValueBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
// }
//
// const buildTextFieldForProperty = <T extends HasValidationImplementation>(comp: T, propertyName: keyof T['state']['inputValues'], label: string,
// extraProps: TextFieldProps = {}) => { return ( <TextField name={`${propertyName}`} label={label} value={comp.state.inputValues[propertyName] ||
// ''} helperText={comp.state.inputErrors[propertyName] || ' '} error={!!comp.state.inputErrors[propertyName]}  margin={'dense'}  fullWidth
// onChange={comp.handleInputValueChange} onBlur={comp.handleInputValueBlur}  {...extraProps} /> ); };
