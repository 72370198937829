import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { formatDistance } from 'date-fns';
import * as Draft from 'draft-js';
import { API, ExtractPropertiesOfType, ObjectMap } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import { OutreachEvent, OutreachStream } from 'src/services/StatsApi';

// todo: utils/ should be renamed to utilities/, and all functions here barrelled
export * from 'src/utils';

/**
 * Gets the current contents of the editor in plain text.
 *
 * @param {Draft.Model.ImmutableData.EditorState} editorState
 *
 * @return {string}
 */
export const getEditorContent = (editorState: Draft.EditorState): string =>
  editorState.getCurrentContent().getPlainText();

/**
 * Gets the `fullname` of an object that has a name.
 *
 * This joins the `firstName` & `lastName` properties,
 * with a space in the middle.
 *
 * @param {API.Entities.HasName} objWithName
 *
 * @return {string}
 */
export const getFullName = ({
  firstName,
  lastName
}: API.Entities.HasName): string => `${firstName} ${lastName}`;

/**
 * Gets the default url to use for redirecting to a persona
 * with the given `personaId`.
 *
 * @param {string} personaId
 *
 * @return {string}
 * @instance
 */
export const getDefaultUrlForPersona = (personaId: string): string =>
  `/p/${personaId}/consumers`;

/**
 * Groups an array of objects based on the value of a shared property of type `string`.
 *
 * @param {Array<T>} objects
 * @param {V} property
 * @param {string} defaultValue
 *
 * @return {ObjectMap<Array<T>>}
 *
 * @template T, V
 */
export const groupObjectsByStringProperty = <
  T extends { [K: string]: any },
  V extends keyof ExtractPropertiesOfType<T, string | API.Nullable<string>>
>(
  objects: T[],
  property: V,
  defaultValue: string
): ObjectMap<T[]> => {
  const map: ObjectMap<T[]> = {};

  objects.forEach(obj => {
    const stepSlug = obj[property] || defaultValue;

    if (!map[stepSlug]) {
      map[stepSlug] = [];
    }

    map[stepSlug].push(obj);
  });

  return map;
};

// region react-trello related stuff
/**
 * Generates the rules used for styling `ReactTrello` things.
 *
 * @param {Theme} theme
 *
 * @return {StyleRules<'trelloBoard' | 'trelloLane' | 'trelloCard'>}
 */
export const trelloClasses = (
  theme: Theme
): StyleRules<
  'trelloBoard' | 'trelloLane' | 'trelloCard' | 'trelloCardInner'
> => ({
  trelloBoard: {
    '& .smooth-dnd-container .vertical': {
      'minHeight': `${theme.spacing(12)}px`,
      'whiteSpace': 'normal',
      '& > .smooth-dnd-draggable-wrapper article': {
        boxSizing: 'border-box',
        margin: theme.spacing(0.5)
      }
    }, // makes it easier to drag cards
    'boxSizing': 'border-box',
    'height': `calc(100vh - ${theme.spacing(18)}px)`,
    'backgroundColor': 'transparent',
    'margin': `${theme.spacing()}px`,
    'padding': `${theme.spacing()}px`,
    '& > .smooth-dnd-container:nth-child(1)': {
      height: '100%'
    }
  },
  trelloLane: {
    'backgroundColor': theme.palette.background.paper,
    'borderRadius': `${theme.shape.borderRadius}px`,
    'boxShadow': theme.shadows[2],
    'maxHeight': `calc(100% - ${theme.spacing()}px)`,
    'width': 265,
    '& > div': {
      marginBottom: `${theme.spacing(6)}px`,
      maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
      paddingBottom: 0
    }
  },
  trelloCard: {
    'margin': theme.spacing(0.5),
    'marginBottom': theme.spacing(),
    '&:hover': {
      backgroundColor: 'transparent',
      borderRadius: `${theme.shape.borderRadius}px`
    }
  },
  trelloCardInner: {
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.default,
    borderRadius: `${theme.shape.borderRadius}px`,
    borderTop: `1px solid ${fade(theme.palette.text.disabled, 0.1)}`
  }
});

/**
 * Builds a `ReactTrello` `Lane` based off a `Step` entity.
 *
 * @param {API.Entities.Step} step
 * @param {string} className
 * @param {Array<ReactTrello.Card<T>>} [cards=[]]
 * @param {boolean} [droppable=true]
 *
 * @return {ReactTrello.Lane<T>}
 *
 * @template T
 */
export const buildTrelloLaneFromStep = <TCardMetaData>(
  step: API.Entities.Step,
  className: string,
  cards: Array<ReactTrello.Card<TCardMetaData>> = [],
  droppable = true
): ReactTrello.Lane<TCardMetaData> => ({
  className,
  id: step.id,
  title: step.name,
  cards,
  droppable
});

/**
 * Builds a `ReactTrello` `Card` based off a `Consumer` entity.
 *
 * @param {API.Entities.Consumer} consumer
 * @param {string} className
 *
 * @return {ReactTrello.Card}
 */
export const buildTrelloCardFromConsumer = (
  consumer: API.Entities.Consumer,
  className: string
): ReactTrello.Card<API.Entities.Consumer> => ({
  className,
  id: consumer.id,
  title: getFullName(consumer),
  metadata: { ...consumer }
});

/**
 * Builds a `ReactTrello` `Card` based off a `Story` entity.
 *
 * @param {API.Entities.Story} story
 * @param {string} className
 *
 * @return {ReactTrello.Card}
 */
export const buildTrelloCardFromStory = (
  story: API.Entities.Story,
  className: string
): ReactTrello.Card<API.Entities.Story> => ({
  className,
  id: story.id,
  title: story.title,
  metadata: { ...story }
});
// endregion

/**
 * Calculates the positions of objects with a `position` property.
 *
 * @todo clean this function up
 *
 * @param objectsToPosition
 * @param object
 * @param position
 */
export const calculateObjectPositions = <T extends { position: number }>(
  objectsToPosition: T[],
  object: T,
  position: number
) => {
  const objects = [...objectsToPosition].sort(
    (a, b) => a.position - b.position
  );

  objects.splice(objects.indexOf(object), 1);
  objects.splice(position - 1, 0, object);

  return objects.map((obj, index) =>
    Object.assign({}, obj, { position: index + 1 })
  );
};

/**
 * Converts a `camelCase` string into a `label` string,
 * adding a space after every uppercase letter.
 *
 * @param {string} camelCaseString
 *
 * @return {string}
 */
export const camelCaseToLabel = (camelCaseString: string): string =>
  camelCaseString.replace(/^[a-z]|[A-Z]/g, (v, i) =>
    i === 0 ? v.toUpperCase() : ` ${v.toLowerCase()}`
  );
// region Date and Time
/**
 * Converts the given date string into an ISO-8601-compliant string
 *
 * @param {API.DateTime} dateTime
 *
 * @return {API.DateTime}
 */
export const asISODate = (dateTime: API.DateTime): API.DateTime =>
  `${dateTime.replace(/ /g, 'T')}.0000Z`;

/**
 * Convert Seconds into Readable time
 * @param {number} second
 * @param {boolean} shortVersion
 *
 * @return {string}
 */
export const secondsToHms = (second: number, shortVersion = true): string => {
  const d = Math.floor(second / 86400);
  const h = Math.floor((second % 86400) / 3600);
  const m = Math.floor(((second % 86400) % 3600) / 60);
  const s = Math.floor(((second % 86400) % 3600) % 60);

  if (!shortVersion) {
    if (second < 60) {
      return `${`0${s}`.slice(-2)}s`;
    }
    if (second < 3600) {
      return `${`0${m}`.slice(-2)}m${`0${s}`.slice(-2)}s`;
    }
    if (second < 86400) {
      return `${`0${h}`.slice(-2)}h${`0${m}`.slice(-2)}m`;
    }

    return `${`0${d}`.slice(-2)}d${`0${h}`.slice(-2)}h`;
  }

  if (second < 60) {
    return `${s}s`;
  }
  if (second < 3600) {
    return `${m}m` + (s !== 0 ? `${`0${s}`.slice(-2)}s` : '');
  }
  if (second < 86400) {
    return `${h}h` + (m !== 0 ? `${`0${m}`.slice(-2)}m` : '');
  }

  return `${d}d` + (h !== 0 ? `${`0${h}`.slice(-2)}h` : '');
};

/**
 * Display time spent until a date
 *
 * @param {API.Nullable<string | Date>} startDate
 *
 * @return {string}
 */
export const fromTimeDisplay = (
  startDate: API.Nullable<string | Date> = null
): string => {
  const theStartDate =
    typeof startDate === 'string' ? new Date(startDate) : startDate;

  return theStartDate === null
    ? 'Never'
    : `${formatDistance(theStartDate, new Date()).toString()} ago`;
};

/**
 * Return the day for a given date (or for the current day by default)
 *
 * @param {string | Date} date
 *
 * @return {Date}
 */
export const getDay = (date: string | Date = new Date()): Date => {
  const theDate = typeof date === 'string' ? new Date(date) : date;

  return new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate());
};
// endregion
// region Videos
/**
 * Return the Video Provider.
 *
 * @param {string} videoUrl
 * @returns {string | null}
 */
export const getVideoProvider = (videoUrl: string): string | null => {
  const regexp = videoUrl.match(regexps.videoProvider);

  if (regexp === null) {
    return null;
  }
  if (regexp.toString() === 'vimeo') {
    return 'vimeo';
  }

  return 'youtube';
};

/**
 * Extracts video ID from youtube URL.
 *
 * @param {string} videoUrl
 *
 * @return {string|null} Video ID
 */
export const getYoutubeVideoId = (videoUrl: string): string | null =>
  (videoUrl.match(regexps.youtubeLink) ?? [])[1] || null;

/**
 * Extracts video ID from vimeo URL.
 *
 * @param {string} videoUrl
 *
 * @return {string|null} Video ID
 */
export const getVimeoVideoId = (videoUrl: string): string | null =>
  (videoUrl.match(regexps.vimeoLink) ?? [])[1] || null;

/**
 * Extract Video Id from any provider.
 *
 * @param {string} videoUrl
 * @returns {string | null}
 */
export const getVideoId = (videoUrl: string): string | null => {
  switch (getVideoProvider(videoUrl)) {
    case 'youtube':
      return getYoutubeVideoId(videoUrl);
    case 'vimeo':
      return getVimeoVideoId(videoUrl);
    default:
      return null;
  }
};

/**
 * Extract Youtube Thumbnails from a YouTube Url
 *
 * @param {string} videoId
 * @returns {string} Thumbnail url
 */
export const getYoutubeThumbUrl = (videoId: string): string =>
  `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

/**
 * Return the Vimeo thumbnail URL.
 *
 * @param {string} videoId
 * @returns {Promise<string | null>}
 */
export const getVimeoThumbURL = async (
  videoId: string
): Promise<string | null> => {
  return fetch(`http://vimeo.com/api/v2/video/${videoId}.json`)
    .then(data => data.json())
    .then(response => response[0].thumbnail_large);
};
// endregion
// region Keen
/**
 * Make match Outreach StreamName with Keen Collection
 *
 * @param {string} streamName
 *
 * @return {Stream}
 */
export const getKeenCollectionFromStreamname = (
  streamName: string
): OutreachStream => {
  switch (streamName) {
    case OutreachEvent.UNSENT:
      return OutreachStream.UNSENT;
    case OutreachEvent.RECEIVED:
      return OutreachStream.RECEIVED;
    case OutreachEvent.UNRECEIVED:
      return OutreachStream.UNRECEIVED;
    case OutreachEvent.OPENED:
      return OutreachStream.OPENED;
    default:
      return OutreachStream.SENT;
  }
};

/**
 * Build the CLEAN collection name for a seller
 *
 * @param {string} sellerId
 * @return {string}
 */
export const sellerCleanStream = (sellerId: string): string => {
  return `${String(process.env.KEEN_CLEAN_STREAM)}_${sellerId}`;
};

/**
 * Return the ordinal value for a number
 *
 * @param {number} position
 *
 * @return {string}
 */
export const ordinalSuffixOf = (position: number): string =>
  ['st', 'nd', 'rd'][
    (((((position < 0 ? -position : position) + 90) % 100) - 10) % 10) - 1
  ] || 'th';
// endregion

/**
 * Builds the hosting url for Relm for the given seller.
 *
 * @param {API.Entities.CurrentSeller} currentSeller
 *
 * @return {string}
 */
export const buildRelmHostingUrlForSeller = (
  currentSeller: API.Entities.CurrentSeller
): string =>
  currentSeller.styles.hostingUrl ??
  `https://${currentSeller.id}.${process.env.CLP_DOMAIN}`;

/**
 * Available fonts in style editor.
 * Can't use copyrighted ones such as Calibri, Cambria, Proxima Nova
 *
 * @type {string[]}
 */
export const fonts = [
  'Arimo',
  'Lato',
  'Montserrat',
  'Nunito Sans',
  'Open Sans',
  'Oswald',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Slab',
  'Source Sans Pro'
] as const;
