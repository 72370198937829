import {
  CardHeader,
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, PersonOutline } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormButton, FormDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import AddPersonaRequest from 'src/schemas/AddPersonaRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '20%',
      maxWidth: 'unset',
      overflowY: 'unset'
    },
    typographyTitle: {
      textAlign: 'center'
    },
    textField: {
      display: 'block'
    },
    dialogHeader: {
      paddingTop: '10px',
      paddingBottom: '0'
    },
    dialogContent: {
      padding: `0 ${theme.spacing(2)}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  /**
   * Controls the open state of the `Dialog`.
   */
  open: boolean;

  /**
   * Called when the Dialog is closed.
   *
   * @param {string} [newPersonaId] the id of the newly added `Persona` (if one was added).
   */
  onClose: (event: AddPersonaDialogCloseEvent) => void;
}

export interface AddPersonaDialogCloseEvent {
  /**
   * The added `Persona`, if one was added.
   */
  addedPersona?: API.Entities.Persona;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State extends ValidationTraitState<API.Personas.AddPersona.Request> {}

// endregion

/**
 *
 */
class AddPersonaDialog extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _validator = new ValidationTrait(this, AddPersonaRequest);

  readonly state: State = {
    inputValues: {
      name: ''
    },
    inputErrors: {
      name: ''
    }
  };

  async tryAddPersona() {
    try {
      const addedPersona = (await RelmApi.createPersona(this.state.inputValues))
        .data;

      toast(SnackbarVariant.SUCCESS, 'Successfully created persona');

      this.props.onClose({ addedPersona });
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to create persona'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);
    }
  }

  // region autobound methods
  @autobind
  handleAddPersonaButtonClick() {
    load(this.tryAddPersona());
  }

  @autobind
  handleCloseClick() {
    this.props.onClose({});
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <FormDialog
        classes={{ paper: this.props.classes.paper }}
        open={this.props.open}
        onClose={this.handleCloseClick}
      >
        <CardHeader
          className={this.props.classes.dialogHeader}
          avatar={<PersonOutline />}
          action={
            <IconButton onClick={this.handleCloseClick}>
              <Close />
            </IconButton>
          }
          titleTypographyProps={{
            className: this.props.classes.typographyTitle
          }}
          title="New Persona"
        />
        <DialogContent className={this.props.classes.dialogContent}>
          {this._validator.buildTextFieldForProperty('name', 'Name', {
            required: true
          })}
        </DialogContent>
        <DialogActions>
          <FormButton
            variant="outlined"
            fullWidth
            onClick={this.handleAddPersonaButtonClick}
          >
            Create
          </FormButton>
        </DialogActions>
      </FormDialog>
    );
  }

  // endregion
}

export default withStyles(styles)(AddPersonaDialog);
