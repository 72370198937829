import {
  Card,
  CardHeader,
  CardMedia,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Videocam, Warning } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import { API } from 'src/definitions';
import { getYoutubeThumbUrl, getYoutubeVideoId } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    cardHeader: {
      padding: `${theme.spacing()}px`
    },
    imageWrapper: {
      position: 'relative'
    },
    imageCardMedia: {
      paddingTop: '75%' // '56.25%'
    },
    videoCover: {
      position: 'absolute',
      margin: `${theme.spacing(0.5)}px`,
      padding: `${theme.spacing(0.5)}px`,
      paddingBottom: 0,
      right: 0,
      borderRadius: `${theme.shape.borderRadius}px`,
      backgroundColor: fade(theme.palette.text.disabled, 0.5)
    },
    videoCoverIcon: {
      color: '#fff'
    },
    typographyTitle: {}
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  /**
   * `className` for the root of this component.
   *
   * Since `react-trello` passes all the lane props,
   * we can't use `className` as it'll be overridden.
   */
  rootClassName?: string;
  currentPersonaId?: string;

  onCardClick: (event: StoryCardEvent) => void;
}

export interface StoryCardEvent {
  story: API.Entities.Story;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentPersonaValue &
  ReactTrello.CardTemplateProps<API.Entities.Story> &
  WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
@withCurrentPersona<Props>()
class StoryTrelloCard extends React.Component<Props, State> {
  static readonly defaultProps = {
    rootClassName: '',

    currentPersonaId: undefined,
    id: '',
    laneId: '',
    lineId: '',
    index: -1,
    title: '',
    editable: false,
    customCardLayout: false,
    hideCardDeleteIcon: false,
    tagStyle: {},
    cardStyle: {},
    dragStyle: {},
    metadata: {} as API.Entities.Story,
    currentPersona: undefined,
    onCurrentPersonaChange: undefined,
    onClick: () => {},
    onDelete: () => {},
    removeCard: () => {}
  };

  readonly state: State = {};

  getThumbnail() {
    if (!this.props.metadata) {
      return;
    }
    const youtubeId = getYoutubeVideoId(this.props.metadata.videoUrl ?? '');
    const videoUrl = youtubeId && getYoutubeThumbUrl(youtubeId);
    const thumbnailUrl = videoUrl ?? this.props.metadata.imageUrl;

    if (!thumbnailUrl) {
      return;
    }

    return (
      <div className={this.props.classes.imageWrapper}>
        {videoUrl && (
          <div className={this.props.classes.videoCover}>
            <Videocam className={this.props.classes.videoCoverIcon} />
          </div>
        )}
        <CardMedia
          className={this.props.classes.imageCardMedia}
          image={thumbnailUrl}
        />
      </div>
    );
  }

  // region autobound methods
  @autobind
  handleListItemClick() {
    this.props.onCardClick({ story: this.props.metadata! });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    if (!this.props.metadata) {
      throw new Error('missing metadata');
    }

    const innerTitle = `${this.props.metadata.title}${
      this.props.metadata.consumerId ? '*' : ''
    }`;

    const title =
      this.props.currentPersonaId === this.props.metadata.personaId ? (
        innerTitle
      ) : (
        <strong>{innerTitle}</strong>
      );

    return (
      <Card
        className={this.props.rootClassName}
        onClick={this.handleListItemClick}
      >
        <CardHeader
          className={this.props.classes.cardHeader}
          avatar={
            this.props.metadata.personaId !== this.props.currentPersona!.id && (
              <Warning />
            )
          }
          title={title}
          titleTypographyProps={{
            className: this.props.classes.typographyTitle,
            variant: 'body2'
          }}
        />
        {this.getThumbnail()}
      </Card>
    );
  }

  // endregion
}

export default withStyles(styles)(StoryTrelloCard);
