import { API } from 'src/definitions';
import Schema from 'validate';

export default new Schema<API.Steps.AddStep.Request>(
  {
    name: {
      required: true,
      type: 'string',
      message: {
        required: 'A name is required'
      }
    }
  },
  { strip: false }
);
