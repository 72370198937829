import {
  Checkbox,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';

const useStyles = makeStyles({
  checkboxButton: { padding: 0 }
});

interface Props<TClickData extends object> {
  checked: boolean;
  label: string;

  clickData: TClickData;
  onClick: (event: TClickData) => void;
}

export const ListItemCheckbox = <TClickData extends object>({
  onClick,
  clickData,
  checked,
  label
}: Props<TClickData>) => {
  const classes = useStyles();
  const handleListItemClick = useCallback(
    () => onClick(clickData), //
    [onClick, clickData]
  );

  return (
    <ListItem button dense onClick={handleListItemClick}>
      <Checkbox
        className={classes.checkboxButton}
        checked={checked}
        disableRipple
      />
      <ListItemText>{label}</ListItemText>
    </ListItem>
  );
};
