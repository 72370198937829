import {
  AppBar,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, Help } from '@material-ui/icons';
import * as React from 'react';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      marginRight: `${theme.spacing(2)}px`
    },
    grow: {
      flexGrow: 1
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  onClose: () => void;
  onOpenStylesHelp: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class SellerStylesDrawerTopBar extends React.Component<Props, State> {
  static readonly defaultProps = {};

  readonly state: State = {};

  // region render & get-render-content methods
  render() {
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            className={this.props.classes.closeButton}
            color="inherit"
            onClick={this.props.onClose}
          >
            <Close />
          </IconButton>
          <Typography className={this.props.classes.grow} color="inherit">
            Styles Editor
          </Typography>
          <IconButton color="inherit" onClick={this.props.onOpenStylesHelp}>
            <Help />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }

  // endregion
}

export default withStyles(styles)(SellerStylesDrawerTopBar);
