const getRelativeParent = (element: HTMLElement | null): HTMLElement | null => {
  if (!element) {
    return null;
  }

  const position = window
    .getComputedStyle(element)
    .getPropertyValue('position');

  if (position !== 'static') {
    return element;
  }

  return getRelativeParent(element.parentElement);
};

export default getRelativeParent;
