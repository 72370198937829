import { CardContent, CardHeader, List } from '@material-ui/core';
import * as React from 'react';
import { CardWithMargin } from 'src/components';
import ConsumerListItem, {
  ConsumerListItemEvent
} from 'src/components/ConsumerListItem';
import { API } from 'src/definitions';

interface Props {
  title: React.ReactNode;
  consumers: API.Entities.Consumer[];

  idOfSelectedConsumer?: string;

  onConsumerClick?: (event: ConsumerListItemEvent) => void;
}

export const ConsumersListCard = (props: Props) => (
  <CardWithMargin>
    <CardHeader subheader={props.title} />
    <CardContent>
      <List>
        {props.consumers.map(consumer => (
          <ConsumerListItem
            key={consumer.id}
            consumer={consumer}
            selected={consumer.id === props.idOfSelectedConsumer}
            onClick={props.onConsumerClick}
          />
        ))}
      </List>
    </CardContent>
  </CardWithMargin>
);
