import * as React from 'react';
import CurrentPersonaContext, {
  CurrentPersonaValue
} from 'src/contexts/CurrentPersonaContext';
import RelmApi from 'src/services/RelmApi';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';

// region component props
interface ExternalProps {
  personaId: string;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  WithDataFromApiProps<'currentPersona', CurrentPersonaValue['currentPersona']>;

interface State {}

// endregion

@withDataFromApi<Props>(
  props => RelmApi.getPersona(props.personaId),
  'currentPersona'
)
class CurrentPersonaProvider extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentPersona: null,
    loadFromApi: () => Promise.resolve() as any
  };

  private _currentPersonaValue: CurrentPersonaValue = {
    currentPersona: null,
    onCurrentPersonaChange: () => this.props.loadFromApi()
  };

  readonly state: State = {};

  /**
   * @inheritDoc
   *
   * @param {Readonly<Props>} prevProps
   * @param {Readonly<State>} prevState
   * @param [snapshot]
   */
  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.personaId !== this.props.personaId) {
      this.props.loadFromApi(true);
    }
  }

  render() {
    if (
      this._currentPersonaValue.currentPersona !== this.props.currentPersona
    ) {
      this._currentPersonaValue = {
        ...this._currentPersonaValue,
        currentPersona: this.props.currentPersona
      }; // save re-renders by not passing a new object every render.
    }

    return (
      <CurrentPersonaContext.Provider value={this._currentPersonaValue}>
        {this.props.children}
      </CurrentPersonaContext.Provider>
    );
  }
}

export default CurrentPersonaProvider;
