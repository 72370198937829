import * as React from 'react';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import CurrentStatisticsContext, {
  CurrentStatisticsValue
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi, { OutreachStream } from 'src/services/StatsApi';
import { groupObjectsByStringProperty } from 'src/utilities';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentSellerValue & CurrentStatisticsValue;

interface State {
  currentStatisticsValue: CurrentStatisticsValue;
}

@withCurrentSeller<Props>(true)
class CurrentStatisticsProvider extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    onCurrentSellerChange: () => {},
    currentStatistics: null,
    onCurrentStatisticsChange: () => {}
  };

  private _currentStatisticsValue: CurrentStatisticsValue = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {}
  };

  readonly state: State = {
    currentStatisticsValue: {
      currentStatistics: this.props.currentStatistics,
      onCurrentStatisticsChange: this.props.onCurrentStatisticsChange
    }
  };

  setValue(newValue: CurrentStatisticsValue = this._currentStatisticsValue) {
    this._currentStatisticsValue = newValue;
    this.setState({ currentStatisticsValue: newValue });
  }

  initStats() {
    this.setValue({
      currentStatistics: null,
      onCurrentStatisticsChange: () => this.updateStats()
    });
  }

  async getSellerStatsResult(): Promise<
    API.Entities.Statistics.CachedGlobalSellerStats
  > {
    if (this.props.currentSeller === null) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    // Get Current Global Stats
    const currentCachedStats = StatsApi.findCachedStats(
      this.props.currentSeller.id
    );

    if (currentCachedStats !== null) {
      return currentCachedStats;
    }

    // Get Stats from Keen
    const [
      timestamp,
      landingPageEvents,
      sent,
      unsent,
      received,
      unreceived,
      opened
    ] = await Promise.all([
      new Date().toISOString(),
      StatsApi.getSellerLandingPageStats(this.props.currentSeller),
      StatsApi.getSellerOutreach(this.props.currentSeller, OutreachStream.SENT),
      StatsApi.getSellerOutreach(
        this.props.currentSeller,
        OutreachStream.UNSENT
      ),
      StatsApi.getSellerOutreach(
        this.props.currentSeller,
        OutreachStream.RECEIVED
      ),
      StatsApi.getSellerOutreach(
        this.props.currentSeller,
        OutreachStream.UNRECEIVED
      ),
      StatsApi.getSellerOutreach(
        this.props.currentSeller,
        OutreachStream.OPENED
      )
    ]);

    // Save result in local cache
    StatsApi.saveToLocalStorage(
      this.props.currentSeller.id,
      landingPageEvents,
      sent,
      unsent,
      received,
      unreceived,
      opened
    );

    return {
      timestamp,
      landingPageEvents,
      sent,
      unsent,
      received,
      unreceived,
      opened
    };
  }

  async updateStats() {
    if (this.props.currentSeller === null) {
      this.initStats();

      return;
    }

    this._currentStatisticsValue.currentStatistics = {
      sellerId: this.props.currentSeller.id,
      consumersById: {},
      consumers: [],
      stories: [],
      events: [],
      outreach: [],
      lastRefresh: new Date()
    };
    // Get Seller data from Relm API
    const [consumers, stories] = await Promise.all([
      StatsApi.getSellerConsumers(this.props.currentSeller),
      StatsApi.getSellerStories(this.props.currentSeller)
    ]);
    const consumersById = groupObjectsByStringProperty(consumers, 'id', '');

    // Get Seller Stats from Keen API or Cache
    const {
      timestamp,
      landingPageEvents,
      sent,
      unsent,
      received,
      unreceived,
      opened
    } = await this.getSellerStatsResult();
    const events = landingPageEvents.filter(
      event => event.consumer.relmId && event.consumer.relmId in consumersById
    );

    this._currentStatisticsValue.currentStatistics = {
      ...this._currentStatisticsValue.currentStatistics,
      lastRefresh: new Date(timestamp),
      consumersById,
      consumers,
      stories,
      events
    };
    await StatsApi.assignEventsToConsumers(consumers, events);
    await StatsApi.assignConsumersToStories(stories, consumers, events);
    this.setValue();

    const outreach = StatsApi.mergeSellerOutreach(
      this._currentStatisticsValue.currentStatistics.consumers,
      sent,
      unsent,
      received,
      unreceived,
      opened
    );

    this._currentStatisticsValue.currentStatistics = {
      ...this._currentStatisticsValue.currentStatistics,
      outreach
    };
    await StatsApi.assignOutreachToConsumers(consumers, outreach);
    this.setValue();
  }

  // region component lifecycle methods
  async componentDidMount() {
    await this.updateStats();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.currentSeller !== this.props.currentSeller) {
      await this.updateStats();
    }
  }

  // endregion

  render() {
    if (
      this._currentStatisticsValue.currentStatistics !==
      this.props.currentStatistics
    ) {
      this._currentStatisticsValue = {
        ...this._currentStatisticsValue,
        currentStatistics: this._currentStatisticsValue.currentStatistics
      }; // save re-renders by not passing a new object every render.
    }

    return (
      <CurrentStatisticsContext.Provider value={this._currentStatisticsValue}>
        {this.props.children}
      </CurrentStatisticsContext.Provider>
    );
  }
}

export default CurrentStatisticsProvider;
