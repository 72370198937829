import { subDays } from 'date-fns';
import KeenAnalysis, {
  AnalysisType,
  FilterOperator,
  KeenQuery,
  KeenQueryResult
} from 'keen-analysis';
import { compressToUTF16, decompressFromUTF16 } from 'lz-string';
import { API } from 'src/definitions';
import RelmApi from 'src/services/RelmApi';
import {
  asISODate,
  fromTimeDisplay,
  getDay,
  getFullName,
  groupObjectsByStringProperty,
  ordinalSuffixOf,
  secondsToHms,
  sellerCleanStream
} from 'src/utilities';

export enum OutreachStream {
  SENT = 'email_processed',
  UNSENT = 'email_dropped', // The message was suppressed to protect your sender reputation by avoiding duplicate spam or bounces.
  RECEIVED = 'email_delivered',
  UNRECEIVED = 'email_bounce', // A bounce is a message that is returned to the server that sent it.
  OPENED = 'email_open'
}

export enum OutreachEvent {
  SENT = 'sent',
  UNSENT = 'unsent',
  RECEIVED = 'received',
  UNRECEIVED = 'unreceived',
  OPENED = 'opened'
}

export enum OutreachEventLabel {
  SENT = 'Sent',
  UNSENT = 'Unsent',
  RECEIVED = 'Received',
  UNRECEIVED = 'Unreceived',
  OPENED = 'Opened'
}

class StatsApi {
  private readonly _client: KeenAnalysis;
  private readonly _projectId = String(process.env.KEEN_PROJECT_ID);
  private readonly _readKey = String(process.env.KEEN_READ_KEY);
  private readonly _allowRefresh =
    process.env.ALLOW_KEEN_REFRESH_STATISTICS &&
    !!process.env.ALLOW_KEEN_REFRESH_STATISTICS;

  private readonly _useCache =
    process.env.ALLOW_KEEN_USE_CACHE && !!process.env.ALLOW_KEEN_USE_CACHE;

  constructor() {
    this._client = new KeenAnalysis({
      projectId: this._projectId,
      readKey: this._readKey
    });
    if (!this._allowRefresh) {
      console.log('No Keen Refreshing on this environment');
    }
  }

  // region Stats filters
  /**
   * Filter events base on a days duration from now or the previous period
   *
   * @param {Array<API.Entities.Statistics.CleanEvent>} events
   * @param {number} days
   * @param {boolean} [previous]
   *
   * @return {Array<API.Entities.Statistics.CleanEvent>}
   */
  filterForEventsWithinTimeFrame(
    events: API.Entities.Statistics.CleanEvent[],
    days: number,
    previous?: boolean
  ): API.Entities.Statistics.CleanEvent[] {
    const day = getDay();
    const from = subDays(day, days * (previous ? 2 : 1)).getTime();
    const to = subDays(day, previous ? days : 0).getTime();

    return events.filter(event => {
      const eventDate = getDay(event.consuming.start).getTime();

      return from < eventDate && eventDate <= to;
    });
  }

  /**
   * Filter events base on properties used in a global view (Not in Consumer Profile)
   *
   * @param {Array<API.Entities.Statistics.CleanEvent>} events
   *
   * @return {Array<API.Entities.Statistics.CleanEvent>}
   */
  filterForGlobalViewEvents(
    events: API.Entities.Statistics.CleanEvent[]
  ): API.Entities.Statistics.CleanEvent[] {
    return events
      .filter(event => event.consumer.isTargeted)
      .filter(event => event.consuming.sessionId)
      .filter(event => !event.consumer.doNotTrack)
      .filter(event => !event.consumer.isAnonymous);
  }

  // endregion
  // region Stats Caches
  /**
   * Build the name of the local storage key for a seller
   *
   * @param {string} sellerId
   *
   * @return {string}
   */
  buildSellerCacheKey(sellerId: string) {
    return `Relm_${String(process.env.KEEN_CLEAN_STREAM)}_${sellerId}`;
  }

  /**
   * Clear cached keen records of a seller from local storage
   *
   * @param {string} sellerId
   */
  deleteLocalStorage(sellerId: string) {
    localStorage.removeItem(this.buildSellerCacheKey(sellerId));
  }

  /**
   * Cache validation
   *
   * @param {API.Entities.Statistics.CachedGlobalSellerStats | null} currentCachedStats
   *
   * @return {boolean}
   */
  isCacheStillValid(
    currentCachedStats: API.Entities.Statistics.CachedGlobalSellerStats
  ) {
    if (getDay(currentCachedStats.timestamp).getTime() !== getDay().getTime()) {
      return false;
    }

    // Refresh rate limit
    const cacheDelay = Math.round(
      (new Date().getTime() -
        new Date(currentCachedStats.timestamp).getTime()) /
        1000
    );
    const refreshRateInSecond = parseInt(
      process.env.KEEN_REFRESH_RATE ?? '14400',
      10
    ); // 4 hours by default

    return cacheDelay <= refreshRateInSecond;
  }

  /**
   * Returns stats from the cache has possible
   *
   * @param {string} sellerId
   *
   * @return {API.Entities.Statistics.CachedGlobalSellerStats | null}
   */
  findCachedStats(sellerId: string) {
    const currentCachedStats = this.loadFromLocalStorage(sellerId);

    if (
      currentCachedStats === null ||
      !this.isCacheStillValid(currentCachedStats)
    ) {
      this.deleteLocalStorage(sellerId);

      return null;
    }

    return currentCachedStats;
  }

  /**
   * Save keen records onto seller local storage
   *
   * @param {string} sellerId
   * @param {API.Entities.Statistics.CleanEvent[]} landingPageEvents
   * @param {API.Entities.Statistics.Outreach[]} sent
   * @param {API.Entities.Statistics.Outreach[]} unsent
   * @param {API.Entities.Statistics.Outreach[]} received
   * @param {API.Entities.Statistics.Outreach[]} unreceived
   * @param {API.Entities.Statistics.Outreach[]} opened
   */
  saveToLocalStorage(
    sellerId: string,
    landingPageEvents: API.Entities.Statistics.CleanEvent[],
    sent: API.Entities.Statistics.Outreach[],
    unsent: API.Entities.Statistics.Outreach[],
    received: API.Entities.Statistics.Outreach[],
    unreceived: API.Entities.Statistics.Outreach[],
    opened: API.Entities.Statistics.Outreach[]
  ) {
    const globalStats = {
      timestamp: new Date().toISOString(),
      landingPageEvents,
      sent,
      unsent,
      received,
      unreceived,
      opened
    };

    localStorage.setItem(
      this.buildSellerCacheKey(sellerId),
      compressToUTF16(JSON.stringify(globalStats))
    );
  }

  /**
   * Load keen records from the local storage of the seller
   *
   * @param {string} sellerId
   *
   * @return {API.Nullable<API.Entities.Statistics.GlobalSellerStats>}
   */
  loadFromLocalStorage(
    sellerId: string
  ): API.Nullable<API.Entities.Statistics.CachedGlobalSellerStats> {
    if (!this._useCache) {
      return null;
    }
    try {
      return JSON.parse(
        decompressFromUTF16(
          localStorage.getItem(this.buildSellerCacheKey(sellerId)) ?? ''
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        ) || 'null'
      );
    } catch {
      return null;
    }
  }

  // endregion
  // region Dashboard Site Visits
  /**
   * Return visits (sessions) of the week and the week before
   *
   * @param {Array<API.Entities.Statistics.CleanEvent>} events
   * @param {number} [days=7]
   *
   * @return {API.Entities.Statistics.LastVisitsCompare}
   */
  consumersVisitCompare(
    events: API.Entities.Statistics.CleanEvent[],
    days = 7
  ): API.Entities.Statistics.LastVisitsCompare {
    const clearEvents = this.filterForGlobalViewEvents(events);

    return {
      days,
      previous: new Set(
        this.filterForEventsWithinTimeFrame(clearEvents, days, true).map(
          event => event.consuming.sessionId
        )
      ).size,
      current: new Set(
        this.filterForEventsWithinTimeFrame(clearEvents, days).map(
          event => event.consuming.sessionId
        )
      ).size
    };
  }

  /**
   * Consumers Visits (sessions) during a period of time
   *
   * @param {Array<API.Entities.Statistics.CleanEvent>} events
   * @param {number} [days=7]
   *
   * @return {number}
   */
  consumersVisitLastDays(
    events: API.Entities.Statistics.CleanEvent[],
    days = 7
  ): number {
    const clearEvents = this.filterForGlobalViewEvents(events);

    return new Set(
      this.filterForEventsWithinTimeFrame(clearEvents, days).map(
        event => event.consumer.relmKey
      )
    ).size;
  }

  /**
   * Return the list of all the consumer with the timeSpent for the last [X] days
   *
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumers
   * @param {number} [days=7]
   *
   * @return {Array<API.Entities.Statistics.TopConsumer>}
   */
  topConsumers(
    consumers: API.Entities.Statistics.ConsumerWithStats[],
    days = 7
  ): API.Entities.Statistics.TopConsumer[] {
    const oldestDate = subDays(getDay(), days).getTime();

    return consumers
      .map(consumer => {
        const timeSpent = consumer.events
          .filter(event => getDay(event.consuming.start).getTime() > oldestDate)
          .reduce((sum, val) => sum + val.consuming.timeSpent, 0);

        return {
          consumer,
          timeSpent,
          timeSpentDisplay: secondsToHms(timeSpent)
        };
      })
      .sort((a, b) => (a.timeSpent > b.timeSpent ? -1 : 1));
  }

  /**
   * Return the list of all the stories with the timeSpent for the last [X] days
   *
   * @param {Array<API.Entities.Statistics.StoryWithStats>} stories
   * @param {number} [days=7]
   *
   * @return {Array<API.Entities.Statistics.TopStoryDisplay>}
   */
  topStories(
    stories: API.Entities.Statistics.StoryWithStats[],
    days = 7
  ): API.Entities.Statistics.TopStoryDisplay[] {
    const oldestDate = subDays(getDay(), days).getTime();
    const storyList = stories
      .map(story => {
        const timeSpent = story.consumers
          .reduce<API.Entities.Statistics.ConsumerEvent[]>(
            (acc, { events }) => acc.concat(events),
            []
          )
          .filter(
            storyFilter =>
              getDay(storyFilter.consuming.start).getTime() > oldestDate
          )
          .reduce((sum, val) => sum + val.consuming.timeSpent, 0);

        return {
          story,
          timeSpent,
          timeSpentDisplay: secondsToHms(timeSpent)
        };
      })
      .filter(topStory => topStory.timeSpent > 0);

    // Merge by Title
    const topStoriesByTitles: API.Entities.Statistics.TopStoryDisplay[] = [];

    const topStories = storyList.map(story => ({
      id: story.story.id,
      title: story.story.title,
      private: !!story.story.consumerId,
      imageUrl: story.story.imageUrl,
      videoUrl: story.story.videoUrl,
      timeSpent: story.timeSpent,
      timeSpentDisplay: story.timeSpentDisplay
    }));
    const topStoriesGroup = groupObjectsByStringProperty(
      topStories,
      'title',
      ''
    );

    Object.values(topStoriesGroup).map(([story]) => {
      const timeSpent = topStoriesGroup[story.title].reduce(
        (sum, val) => sum + val.timeSpent,
        0
      );

      topStoriesByTitles.push({
        ...story,
        timeSpent,
        timeSpentDisplay: secondsToHms(timeSpent)
      });
    });

    // Sort consumer in desc timeSpend order
    return topStoriesByTitles.sort((a, b) =>
      a.timeSpent > b.timeSpent ? -1 : 1
    );
  }

  // endregion
  // region Daily AmCharts Formatter
  /**
   * Build an array of Daily Visits for an AmCharts Chart in the given period of time
   *
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumers
   * @param {number} [days=7]
   *
   * @return {Array<API.Entities.Statistics.ConsumerTimeSpentByDate>}
   */
  dailyVisitsAmChartsFormated(
    consumers: API.Entities.Statistics.ConsumerWithStats[],
    days = 7
  ): API.Entities.Statistics.ConsumerTimeSpentByDate[] {
    const today = getDay();
    const daysRange = [...Array(days).keys()].reverse().map(dayPosition => ({
      date: subDays(today, dayPosition).toISOString()
    }));
    const daysRangeByDate = groupObjectsByStringProperty(daysRange, 'date', '');

    consumers.forEach(consumer => {
      if (consumer.events.length === 0) {
        return;
      }
      consumer.events.forEach(event => {
        const eventDay = getDay(event.consuming.start).toISOString();

        if (!(eventDay in daysRangeByDate)) {
          return;
        } // This event is out of range

        const dayEvents: { [k: string]: string } = {
          ...daysRangeByDate[eventDay][0]
        };
        const timeSpent =
          consumer.id in dayEvents ? Number(dayEvents[consumer.id]) : 0;

        dayEvents[consumer.id] = (
          timeSpent + event.consuming.timeSpent
        ).toString();

        daysRangeByDate[eventDay][0] = {
          ...dayEvents,
          date: eventDay
        };
      });
    });

    return Object.values(daysRangeByDate).map(event => event[0]);
  }

  /**
   * Build an array of Daily Outreach for an AmCharts Chart in the given period of time
   *
   * @param {Array<API.Entities.Statistics.ConsumerOutreach>} outreach
   * @param {number} [days=7]
   *
   * @return {Array<API.Entities.Statistics.ConsumerOutreachByDate>}
   */
  dailyOutreachAmChartsFormated(
    outreach: API.Entities.Statistics.ConsumerOutreach[],
    days = 7
  ): API.Entities.Statistics.ConsumerOutreachByDate[] {
    const outreachDays = outreach.map(sent => ({
      sent: sent.sentDate && getDay(sent.sentDate.toString()).toISOString(),
      unsent:
        sent.unsentDate && getDay(sent.unsentDate.toString()).toISOString(),
      unreceived:
        sent.unreceivedDate && getDay(sent.unreceivedDate[0]).toISOString(),
      opened: sent.openedDate && getDay(sent.openedDate[0]).toISOString()
    }));
    const today = getDay();

    return [...Array(days).keys()].reverse().map(dayPosition => {
      const date = subDays(today, dayPosition).toISOString();

      return {
        date,
        sent: outreachDays.reduce(
          (emailCount, email) => emailCount + +(email.sent === date),
          0
        ),
        unsent: outreachDays.reduce(
          (emailCount, email) => emailCount + +(email.unsent === date),
          0
        ),
        unreceived: outreachDays.reduce(
          (emailCount, email) => emailCount + +(email.unreceived === date),
          0
        ),
        opened: outreachDays.reduce(
          (emailCount, email) => emailCount + +(email.opened === date),
          0
        )
      };
    });
  }

  // endregion
  // region Consumer Statistic Panel
  /**
   * Returns the number of articles read
   *
   * @param {API.Entities.Statistics.ConsumerWithStats} consumer
   *
   * @return {number}
   */
  consumerPanelNumberOfStoriesRead(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): number {
    return new Set(
      consumer.events
        .filter(event => event.consuming.pageType === 'article')
        .map(event => event.consuming.title)
    ).size;
  }

  /**
   * For a give consumer returns stories with a total time spent and the detail by sessions
   *
   * @param {API.Entities.Statistics.ConsumerWithStats} consumer
   *
   * @return {Array<API.Entities.Statistics.StoryTimeSpentBySession>}
   */
  consumerTimeSpentBySessions(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.StoryTimeSpentBySession[] {
    const storiesConsuming = consumer.events
      .map(event => event.consuming)
      .filter(
        (
          event: API.Entities.Statistics.Consuming
        ): event is API.Entities.Statistics.Consuming & {
          pageType: 'article';
          sessionId: string;
          title: string;
          slug: string;
        } =>
          event.pageType === 'article' &&
          !!event.sessionId &&
          !!event.title &&
          !!event.slug
      );

    const sessionIdOrderedList = Array.from(
      new Set(
        storiesConsuming
          .sort((a, b) => (a.start < b.start ? -1 : 1))
          .map(event => event.sessionId)
      )
    );
    const sessionsGroup = groupObjectsByStringProperty(
      storiesConsuming,
      'sessionId',
      ''
    );

    return storiesConsuming
      .filter(
        (stories, index) =>
          index ===
          storiesConsuming.findIndex(
            currentStory => currentStory.slug === stories.slug
          )
      )
      .map(event => {
        const sessions = sessionIdOrderedList.map(sessionId => {
          const timeSpent = sessionsGroup[sessionId]
            .filter(story => story.slug === event.slug)
            .reduce((sum, val) => sum + val.timeSpent, 0);

          return {
            id: sessionId,
            timeSpent,
            timeSpentDisplay: secondsToHms(timeSpent)
          };
        });
        const totalTimeSpent = storiesConsuming
          .filter(story => story.slug === event.slug)
          .reduce((sum, val) => sum + val.timeSpent, 0);

        return {
          slug: event.slug,
          title: event.title,
          totalTimeSpent,
          totalTimeSpentDisplay: secondsToHms(totalTimeSpent),
          sessions
        };
      })
      .sort((a, b) => (a.totalTimeSpent > b.totalTimeSpent ? -1 : 1));
  }

  getSessionIdList(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerSession[] {
    const sessionIdOrderedList = Array.from(
      new Set(
        consumer.events
          .map(event => event.consuming)
          .filter(
            (
              event: API.Entities.Statistics.Consuming
            ): event is API.Entities.Statistics.Consuming & {
              sessionId: string;
            } => !!event.sessionId
          )
          .sort((a, b) => (a.start < b.start ? -1 : 1))
          .map(event => event.sessionId)
      )
    );

    return sessionIdOrderedList.map((id, index) => {
      let label = `${index + 1}${ordinalSuffixOf(index + 1)} Visit`;

      if (index + 1 === sessionIdOrderedList.length) {
        label = 'Last Visit';
      }
      if (index === 0) {
        label = 'First Visit';
      }

      return {
        label,
        id
      };
    });
  }

  /**
   * Return AmCharts formated data from a stories list
   *
   * @param {Array<API.Entities.Statistics.StoryTimeSpentBySession>} storiesListWithStats
   *
   * @returns {Array<API.Entities.Statistics.ConsumerTimeSpentByStoryAndSession>}
   */
  consumerPanelTimeSpentChart(
    storiesListWithStats: API.Entities.Statistics.StoryTimeSpentBySession[]
  ): API.Entities.Statistics.ConsumerTimeSpentByStoryAndSession[] {
    return storiesListWithStats
      .map(event => ({
        story: event.title,
        total: event.totalTimeSpentDisplay,
        sessions: event.sessions
      }))
      .reverse();
  }

  /**
   * Returns Events history
   *
   * @param {API.Entities.Statistics.ConsumerWithStats} consumer
   *
   * @return {Array<API.Entities.Statistics.ConsumerEvent>}
   */
  getChronologicalConsumerHistory(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerEvent[] {
    return [...consumer.events].sort((a, b) =>
      a.consuming.start > b.consuming.start ? -1 : 1
    );
  }
  // endregion
  // region Consumer Achievements Panel

  getConsumerAchievementValues(
    consumer: API.Entities.Consumer,
    currentStatistics: API.Nullable<API.Entities.Statistics.Statistics>
  ): API.Entities.Statistics.ConsumerAchievementValues {
    if (
      !currentStatistics?.consumersById ||
      !(consumer.id in currentStatistics.consumersById) ||
      !this._allowRefresh
    ) {
      return {
        outreachEmailSent: 0,
        outreachEmailReceived: 0,
        outreachEmailOpened: 0,
        totalTimeSpentOnWebsite: 0,
        longerTimeSpentOnArticle: 0,
        numberOfSession: 0,
        doNotTrack: 0,
        sharedVisits: 0,
        outreachReactivity: 0
      };
    }
    const [consumerStats] = currentStatistics.consumersById[consumer.id];

    return {
      outreachEmailSent: this.getOutreachEmailSent(consumerStats),
      outreachEmailReceived: this.getOutreachEmailReceived(consumerStats),
      outreachEmailOpened: this.getOutreachEmailOpened(consumerStats),
      doNotTrack: this.getDoNotTrack(consumerStats),
      sharedVisits: this.getSharedVisits(consumerStats),
      longerTimeSpentOnArticle: this.getLongerTimeSpentOnArticle(consumerStats),
      totalTimeSpentOnWebsite: this.getTotalTimeSpentOnWebsite(consumerStats),
      numberOfSession: this.getNumberOfSession(consumerStats),
      outreachReactivity: this.getOutreachReactivity(consumerStats)
    };
  }

  getOutreachEmailSent(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['outreachEmailSent'] {
    const [lastOutreach] = consumer.outreach
      .filter(outreach => outreach.sentDate)
      .slice(-1);

    return +!!lastOutreach?.sentDate;
  }

  getOutreachEmailReceived(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['outreachEmailReceived'] {
    const [lastOutreach] = consumer.outreach
      .filter(outreach => outreach.sentDate)
      .slice(-1);

    return +!!lastOutreach?.receivedDate;
  }

  getOutreachEmailOpened(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['outreachEmailOpened'] {
    const [lastOutreach] = consumer.outreach
      .filter(outreach => outreach.sentDate)
      .slice(-1);

    return +!!lastOutreach?.firstOpenedDate;
  }

  getOutreachReactivity(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['outreachReactivity'] {
    const [lastOutreach] = consumer.outreach
      .filter(outreach => outreach.sentDate)
      .slice(-1);

    const startingDate =
      lastOutreach?.firstOpenedDate ??
      lastOutreach?.receivedDate ??
      lastOutreach?.sentDate;

    if (!startingDate) {
      return 0;
    }

    const consuming = consumer.events
      .map(event => event.consuming.start)
      .filter(
        event => new Date(event).getTime() > new Date(startingDate).getTime()
      )
      .sort(
        (lastEvent, event) =>
          new Date(lastEvent).getTime() - new Date(event).getTime()
      );

    if (consuming.length === 0) {
      return 0;
    }

    return Math.floor(
      (new Date(consuming[0]).getTime() - new Date(startingDate).getTime()) /
        1000
    );
  }

  getDoNotTrack(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['doNotTrack'] {
    return +consumer.doNotTrack;
  }

  getSharedVisits(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['sharedVisits'] {
    return +consumer.hasShared;
  }

  getLongerTimeSpentOnArticle(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['longerTimeSpentOnArticle'] {
    if (consumer.events.length === 0) {
      return 0;
    } // No Events

    const storiesConsuming = consumer.events
      .map(event => event.consuming)
      .filter(
        (
          event: API.Entities.Statistics.Consuming
        ): event is API.Entities.Statistics.Consuming & {
          pageType: 'article';
          sessionId: string;
          title: string;
          slug: string;
        } =>
          event.pageType === 'article' &&
          !!event.sessionId &&
          !!event.title &&
          !!event.slug
      )
      .filter(
        (stories, index, self) =>
          index ===
          self.findIndex(currentStory => currentStory.slug === stories.slug)
      );

    return storiesConsuming
      .map(event => {
        return storiesConsuming
          .filter(story => story.slug === event.slug)
          .reduce((sum, val) => sum + val.timeSpent, 0);
      })
      .reduce((max, n) => (n > max ? n : max), 0);
  }

  getTotalTimeSpentOnWebsite(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['totalTimeSpentOnWebsite'] {
    return consumer.timeSpent;
  }

  getNumberOfSession(
    consumer: API.Entities.Statistics.ConsumerWithStats
  ): API.Entities.Statistics.ConsumerAchievementValues['numberOfSession'] {
    return consumer.visits;
  }

  // endregion
  // region Get Seller list and initiate the stats properties
  /**
   * Build the ConsumerWithStats list of a seller
   *
   * @param {API.Entities.CurrentSeller} seller
   *
   * @return {Promise<Array<API.Entities.Statistics.ConsumerWithStats>>}
   */
  async getSellerConsumers(
    seller: API.Entities.CurrentSeller
  ): Promise<API.Entities.Statistics.ConsumerWithStats[]> {
    const { data } = await RelmApi.listConsumers(seller.id);

    return data.map(consumer => ({
      ...consumer,
      fullName: getFullName({
        firstName: consumer.firstName,
        lastName: consumer.lastName
      }),
      // AllTimeConsuming init
      firstSeen: null,
      firstSeenDisplay: fromTimeDisplay(),
      lastSeen: null,
      lastSeenDisplay: fromTimeDisplay(),
      timeSpent: 0,
      timeSpentDisplay: '0s',
      visits: 0,
      hasShared: false,
      doNotTrack: false,
      // Events init
      events: [],
      // Outreachs init
      outreach: []
    }));
  }

  /**
   * Get Stories list of a seller
   *
   * @param {API.Entities.CurrentSeller} seller
   *
   * @return {Promise<Array<API.Entities.Statistics.StoryWithStats>>}
   */
  async getSellerStories(
    seller: API.Entities.CurrentSeller
  ): Promise<API.Entities.Statistics.StoryWithStats[]> {
    const { data } = await RelmApi.listSellerStories(seller.id);

    return data.map(story => ({
      ...story,
      timeSpent: 0,
      timeSpentDisplay: '0s',
      consumers: []
    }));
  }

  // endregion
  // region get Seller LandingPage stats from Keen (Clean Stream)
  /**
   * Get all clean events of the landing page
   *
   * @param {API.Entities.CurrentSeller} seller
   *
   * @return {Promise<Array<API.Entities.Statistics.CleanEvent>>}
   */
  async getSellerLandingPageStats(
    seller: API.Entities.CurrentSeller
  ): Promise<API.Entities.Statistics.CleanEvent[]> {
    if (!this._allowRefresh) {
      return [];
    }
    if (!this._client) {
      throw new Error('client is null');
    } // this'll never be null, but typescript don't know that

    const query: KeenQuery = {
      event_collection: sellerCleanStream(seller.id),
      property_names: [
        'consumer.isTargeted',
        'consumer.doNotTrack',
        'consumer.isAnonymous',
        'consumer.firstName',
        'consumer.lastName',
        'consumer.fullName',
        'consumer.email',
        'consumer.organisation',
        'consumer.relmKey',
        'consumer.relmId',
        'allTimeConsuming.firstSeen',
        'allTimeConsuming.lastSeen',
        'allTimeConsuming.timeSpent',
        'allTimeConsuming.timeSpentDisplay',
        'allTimeConsuming.visit',
        'consuming.sessionId',
        'consuming.start',
        'consuming.end',
        'consuming.pageType',
        'consuming.title',
        'consuming.slug',
        'consuming.private',
        // 'consuming.isVisible',
        'consuming.timeSpent',
        'consuming.timeSpentDisplay',
        // 'context.documentWidth',
        // 'context.documentHeight',
        // 'context.viewportWidth',
        // 'context.viewportHeight',
        // 'context.scrollTopY',
        // 'context.scrollBottomY',
        // 'context.scrollTopYMax',
        // 'context.scrollBottomYMax',
        'context.consumed',
        'tech.device',
        'geo.city',
        // 'geo.continent',
        // 'geo.coordinates',
        'geo.country',
        'geo.country_code'
        // 'geo.postal_code',
        // 'geo.province',
      ],
      timeframe: {
        start: new Date(asISODate(seller.createdAt)).toISOString(),
        end: new Date().toISOString()
      }
    };
    const data: KeenQueryResult = await this._client.query(
      AnalysisType.EXTRACTION,
      query
    );

    return data.result;
  }

  // endregion
  // region getSellerOutreach for each stream related
  /**
   * Get Outreach for a given stream
   *
   * @param {API.Entities.CurrentSeller} seller
   * @param {string} stream
   *
   * @return {Promise<Array<API.Entities.Statistics.Outreach>>}
   */
  async getSellerOutreach(
    seller: API.Entities.CurrentSeller,
    stream: string
  ): Promise<API.Entities.Statistics.Outreach[]> {
    if (!this._allowRefresh) {
      return [];
    }
    if (!this._client) {
      throw new Error('client is null');
    } // this'll never be null, but typescript don't know that

    const query: KeenQuery = {
      event_collection: stream,
      property_names: [
        'keen.timestamp',
        'email',
        'custom_attributes.relmkey',
        'message_id'
      ],
      timeframe: {
        start: new Date(asISODate(seller.createdAt)).toISOString(),
        end: new Date().toISOString()
      },
      filters: [
        {
          operator: FilterOperator.EQ,
          property_name: 'custom_attributes.seller_id',
          property_value: seller.id
        }
      ]
    };
    const data: KeenQueryResult = await this._client.query(
      AnalysisType.EXTRACTION,
      query
    );

    return data.result.map(
      (outreach: API.Entities.Statistics.KeenOutreach) => ({
        timestamp: outreach.keen.timestamp,
        email: outreach.email,
        relmKey: outreach.custom_attributes.relmkey,
        messageId: outreach.message_id
      })
    );
  }

  /**
   * Get all the ConsumerWithStats by outreach stream
   *
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumersList
   * @param {Array<API.Entities.Statistics.ConsumerOutreach>} outreachList
   * @param {Date} date
   *
   * @return {API.Entities.Statistics.DailyOutreachByStream}
   */
  getOutreachByDay(
    consumersList: API.Entities.Statistics.ConsumerWithStats[],
    outreachList: API.Entities.Statistics.ConsumerOutreach[],
    date: Date
  ): API.Entities.Statistics.DailyOutreachByStream {
    const dailyConsumerList: API.Entities.Statistics.DailyOutreachByStream = {
      sent: [],
      unsent: [],
      unreceived: [],
      opened: []
    };
    const currentDay = getDay(date).getTime();

    consumersList
      .filter(consumer => consumer.outreach.length > 0)
      .forEach(consumer =>
        consumer.outreach.forEach(
          (outreach: API.Entities.Statistics.ConsumerOutreach) => {
            if (
              outreach.sentDate !== null &&
              getDay(outreach.sentDate).getTime() === currentDay
            ) {
              dailyConsumerList.sent.push(consumer);
            }
            if (
              outreach.unsentDate !== null &&
              getDay(outreach.unsentDate).getTime() === currentDay
            ) {
              dailyConsumerList.unsent.push(consumer);
            }
            if (
              outreach.firstUnreceivedDate !== null &&
              getDay(outreach.firstUnreceivedDate).getTime() === currentDay
            ) {
              dailyConsumerList.unreceived.push(consumer);
            }
            if (
              outreach.firstOpenedDate !== null &&
              getDay(outreach.firstOpenedDate).getTime() === currentDay
            ) {
              dailyConsumerList.opened.push(consumer);
            }
          }
        )
      );

    return dailyConsumerList;
  }

  /**
   * Merge Outreach from all available stream to make them easier to sort by relmKey
   *
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumersList
   * @param {Array<API.Entities.Statistics.Outreach>} sentOutreachList
   * @param {Array<API.Entities.Statistics.Outreach>} unsentOutreachList
   * @param {Array<API.Entities.Statistics.Outreach>} receivedOutreachList
   * @param {Array<API.Entities.Statistics.Outreach>} openedOutreachList
   * @param {Array<API.Entities.Statistics.Outreach>} unreceivedOutreachList
   *
   * @return {Array<API.Entities.Statistics.ConsumerOutreachByRelmKey>}
   */
  mergeSellerOutreach(
    consumersList: API.Entities.Statistics.ConsumerWithStats[],
    sentOutreachList: API.Entities.Statistics.Outreach[],
    unsentOutreachList: API.Entities.Statistics.Outreach[],
    receivedOutreachList: API.Entities.Statistics.Outreach[],
    unreceivedOutreachList: API.Entities.Statistics.Outreach[],
    openedOutreachList: API.Entities.Statistics.Outreach[]
  ): API.Entities.Statistics.ConsumerOutreachByRelmKey[] {
    const consumersListByRelmKey = groupObjectsByStringProperty(
      consumersList,
      'relmKey',
      ''
    );
    const receivedOutreachByMessageId = groupObjectsByStringProperty(
      receivedOutreachList,
      'messageId',
      ''
    );
    const unreceivedOutreachByMessageId = groupObjectsByStringProperty(
      unreceivedOutreachList,
      'messageId',
      ''
    );
    const openedOutreachByMessageId = groupObjectsByStringProperty(
      openedOutreachList,
      'messageId',
      ''
    );

    const outreachList: API.Entities.Statistics.ConsumerOutreachByRelmKey[] = [];

    // region Sent outreach
    sentOutreachList.forEach(sent => {
      if (!(sent.relmKey in consumersListByRelmKey)) {
        return;
      }
      const receivedOutreach = receivedOutreachByMessageId?.[sent.messageId];
      const unreceivedOutreach =
        unreceivedOutreachByMessageId?.[sent.messageId];
      const openedOutreach = openedOutreachByMessageId?.[sent.messageId];

      outreachList.push({
        relmKey: sent.relmKey,
        messageId: sent.messageId,
        sentDate: sent.timestamp,
        unsentDate: null,
        receivedDate: receivedOutreach?.[0].timestamp ?? null,
        unreceivedDate:
          unreceivedOutreach?.map(outreach => outreach.timestamp) ?? null,
        openedDate: openedOutreach?.map(outreach => outreach.timestamp) ?? null,
        firstUnreceivedDate: unreceivedOutreach?.[0].timestamp ?? null,
        firstOpenedDate: openedOutreach?.[0].timestamp ?? null
      });
    });

    // endregion
    // region Dropped outreach
    unsentOutreachList.forEach(dropped => {
      if (!(dropped.relmKey in consumersListByRelmKey)) {
        return;
      }

      outreachList.push({
        relmKey: dropped.relmKey,
        messageId: dropped.messageId,
        sentDate: null,
        unsentDate: dropped.timestamp,
        receivedDate: null,
        unreceivedDate: null,
        openedDate: null,
        firstUnreceivedDate: null,
        firstOpenedDate: null
      });
    });

    // endregion

    return outreachList;
  }

  // endregion
  // region Assign stats's relative information as needed
  /**
   * Update consumer by adding outreach stats
   *
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumersList
   * @param {Array<API.Entities.Statistics.ConsumerOutreachByRelmKey>} outreachList
   */
  assignOutreachToConsumers(
    consumersList: API.Entities.Statistics.ConsumerWithStats[],
    outreachList: API.Entities.Statistics.ConsumerOutreachByRelmKey[]
  ) {
    const consumersListByRelmKey = groupObjectsByStringProperty(
      consumersList,
      'relmKey',
      ''
    );

    outreachList.forEach(outreach => {
      if (!(outreach.relmKey in consumersListByRelmKey)) {
        return;
      }
      consumersListByRelmKey[outreach.relmKey][0].outreach.push(outreach);
    });
  }

  /**
   * Update consumer and adding event to consumers
   *
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumersList
   * @param {Array<API.Entities.Statistics.CleanEvent>} cleanEvents
   */
  assignEventsToConsumers(
    consumersList: API.Entities.Statistics.ConsumerWithStats[],
    cleanEvents: API.Entities.Statistics.CleanEvent[]
  ) {
    const consumersListByRelmKey = groupObjectsByStringProperty(
      consumersList,
      'relmKey',
      ''
    );

    cleanEvents.forEach(event => {
      if (
        event.consumer.relmKey === null ||
        !(event.consumer.relmKey in consumersListByRelmKey)
      ) {
        return;
      } // Don't care about anonymous or deleted consumers

      const [consumer] = consumersListByRelmKey[event.consumer.relmKey];

      if (!event.consumer.isTargeted) {
        consumer.hasShared = true;

        return;
      } // Don't keep shared event, just log it for achievements

      if (event.consumer.doNotTrack) {
        consumer.doNotTrack = true;

        return;
      } // Don't keep events with a doNotTrack request, just log it for achievements

      const extendEvent: API.Entities.Statistics.ConsumerEvent = {
        ...event,
        consuming: {
          ...event.consuming,
          start: event.consuming.start,
          end: event.consuming.end
        }
      };

      consumer.firstSeen = event.allTimeConsuming.firstSeen;
      consumer.firstSeenDisplay = fromTimeDisplay(consumer.firstSeen);
      if (
        consumer.lastSeen === null ||
        new Date(event.allTimeConsuming.lastSeen).getTime() >
          new Date(consumer.lastSeen).getTime()
      ) {
        consumer.lastSeen = event.allTimeConsuming.lastSeen;
        consumer.lastSeenDisplay = fromTimeDisplay(consumer.lastSeen);
      }
      if (event.allTimeConsuming.timeSpent > consumer.timeSpent) {
        consumer.timeSpent = event.allTimeConsuming.timeSpent;
        consumer.timeSpentDisplay = event.allTimeConsuming.timeSpentDisplay;
      }
      consumer.visits =
        event.allTimeConsuming.visit > consumer.visits
          ? event.allTimeConsuming.visit
          : consumer.visits;
      consumer.events.push(extendEvent);
    });
  }

  /**
   * Update stories by adding consumers events
   *
   * @param {Array<API.Entities.Statistics.StoryWithStats>} storiesList
   * @param {Array<API.Entities.Statistics.ConsumerWithStats>} consumersList
   * @param {Array<API.Entities.Statistics.CleanEvent>} cleanEvents
   */
  assignConsumersToStories(
    storiesList: API.Entities.Statistics.StoryWithStats[],
    consumersList: API.Entities.Statistics.ConsumerWithStats[],
    cleanEvents: API.Entities.Statistics.CleanEvent[]
  ) {
    const storiesListById = groupObjectsByStringProperty(storiesList, 'id', '');
    const consumersListById = groupObjectsByStringProperty(
      consumersList,
      'id',
      ''
    );

    this.filterForGlobalViewEvents(cleanEvents)
      .filter(
        event =>
          event.consuming.pageType === 'article' &&
          event.consuming.slug &&
          event.consuming.slug in storiesListById &&
          event.consumer.relmId &&
          event.consumer.relmId in consumersListById
      )
      .forEach(event => {
        if (!event.consuming.slug || !event.consumer.relmId) {
          return;
        }

        const [story] = storiesListById[event.consuming.slug];
        const [consumer] = consumersListById[event.consumer.relmId];
        const storyConsumersById = groupObjectsByStringProperty(
          story.consumers,
          'id',
          ''
        );

        const eventStory = {
          id: consumer.id,
          fullName: consumer.fullName,
          events: [event],
          timeSpent: event.consuming.timeSpent,
          timeSpentDisplay: secondsToHms(event.consuming.timeSpent)
        };

        if (!(consumer.id in storyConsumersById)) {
          // Adding the consumer
          story.consumers.push(eventStory);
          story.timeSpent += event.consuming.timeSpent;
          story.timeSpentDisplay = secondsToHms(story.timeSpent);

          return;
        }
        const [currentConsumer] = storyConsumersById[consumer.id];

        currentConsumer.events.push(event);
        currentConsumer.timeSpent += event.consuming.timeSpent;
        currentConsumer.timeSpentDisplay = secondsToHms(
          currentConsumer.timeSpent
        );
        story.timeSpent += event.consuming.timeSpent;
        story.timeSpentDisplay = secondsToHms(story.timeSpent);
      });
  }

  // endregion
}

export default new StatsApi();
