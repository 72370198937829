import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Stories.AddStory.Request>(
  {
    title: {
      required: true,
      type: 'string',
      message: {
        required: 'A title is required'
      }
    },
    body: {
      required: true,
      type: 'string',
      message: {
        required: 'A body is required'
      }
    },
    summary: {
      required: false,
      type: 'string',
      message: {
        required: 'A summary is required'
      }
    },
    imageUrl: {
      required: false,
      type: 'string',
      match: regexps.urlOrEmpty,
      message: {
        required: 'An image url is required',
        match: 'A valid url is required'
      }
    },
    videoUrl: {
      required: false,
      type: 'string',
      match: regexps.youtubeLinkOrEmpty,
      message: {
        required: 'A video url is required',
        match: 'A video url is required'
      }
    },
    stepId: {
      required: false,
      type: 'string',
      message: {
        required: 'A step id is required'
      }
    },
    consumerId: {
      required: false,
      type: 'string',
      message: {
        required: 'A consumer id is required'
      }
    },
    position: {
      required: false,
      type: 'number',
      message: {
        required: 'A position is required'
      }
    }
  },
  { strip: false }
);
