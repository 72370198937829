import autobind from 'autobind-decorator';
import debug from 'debug';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import {
  AuthTokenChangeEvent,
  AuthTokenManagerEvent
} from 'src/services/AuthTokenManager';
import RelmApi from 'src/services/RelmApi';

// region component props
interface ExternalProps {
  /**
   * The component to render when authenticated.
   */
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  /**
   * The route to redirect to when not authenticated.
   */
  redirectTo: string;
}

type InternalProps = Required<ExternalProps> & RouteProps;

type Props = InternalProps;

interface State {
  isAuthenticated: boolean;
}

// endregion

/**
 * PrivateRoute Component.
 *
 * Renders a `Route` component if the user is authenticated.
 * Otherwise, redirects to an provided alternative route.
 */
class PrivateRoute extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   * @type {debug.IDebugger}
   * @private
   */
  private readonly _logger: debug.IDebugger = debug(this.constructor.name);

  /**
   *
   */
  public readonly state: State = {
    isAuthenticated: RelmApi.isAuthenticated()
  };

  // region component lifecycle methods
  /**
   * @inheritDoc
   */
  componentDidMount() {
    RelmApi.authManager.on(
      AuthTokenManagerEvent.E_TOKEN_CHANGE,
      this.handleAuthTokenChanged
    );
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    RelmApi.authManager.removeListener(
      AuthTokenManagerEvent.E_TOKEN_CHANGE,
      this.handleAuthTokenChanged
    );
  }

  // endregion
  // region autobound methods
  /**
   * Handles when the stored access token changes for some reason.
   *
   * @param {AuthTokenManager#event:E_TOKEN_CHANGE} event
   */
  @autobind
  handleAuthTokenChanged(event: AuthTokenChangeEvent) {
    this._logger('access token changed', event);
    const isAuthenticated = RelmApi.isAuthenticated();

    if (isAuthenticated !== this.state.isAuthenticated) {
      this.setState({ isAuthenticated });
    }
  }

  /**
   * Handles rendering the contents of the `Route` component.
   *
   * @param {RouteComponentProps<any>} props
   *
   * @return {*}
   */
  @autobind
  handleRouteRender(props: RouteComponentProps<any>) {
    const { component: Component, render, redirectTo, location } = this.props;

    if (!this.state.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: location }
          }}
        />
      );
    }

    this._logger('you may pass');

    return render ? render(props) : <Component {...props} />;
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const {
      component: ignore, // we don't want to pass on component
      ...rest
    } = this.props;

    return <Route {...rest} render={this.handleRouteRender} />;
  }

  // endregion
}

export default PrivateRoute;
