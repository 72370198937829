import { createBrowserHistory } from 'history';
import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import AppTopBar from 'src/components/AppTopBar';
import CurrentSellerProvider from 'src/components/CurrentSellerProvider';
import CurrentStatisticsProvider from 'src/components/CurrentStatisticsProvider';
import KeenCleaner from 'src/components/KeenCleaner';
import LoadingOverlay from 'src/components/LoadingOverlay';
import PrivateRoute from 'src/components/PrivateRoute';
import PublicRoute from 'src/components/PublicRoute';
import Statistics from 'src/components/Statistics';
import Toastbar from 'src/components/Toastbar';
import ForgotPassword from 'src/views/ForgotPassword';
import Persona from 'src/views/Persona';
import ResetPassword from 'src/views/ResetPassword';
import SignIn from 'src/views/SignIn';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {}

// endregion

class App extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _history = createBrowserHistory();

  readonly state: State = {};

  render() {
    return (
      <CurrentSellerProvider>
        <CurrentStatisticsProvider>
          <Toastbar />
          <LoadingOverlay />
          <Router history={this._history}>
            <>
              {/* eslint-disable-next-line react/no-children-prop */}
              <Route path="/p/:personaId" children={AppTopBar} />
              <Switch>
                <PublicRoute path="/signin" component={SignIn} redirectTo="/" />
                <PublicRoute
                  path="/password/forgot"
                  component={ForgotPassword}
                  redirectTo="/"
                />
                <PublicRoute
                  path="/password/reset/:token"
                  component={ResetPassword}
                  redirectTo="/"
                />

                <PrivateRoute
                  path="/p/:personaId"
                  component={Persona}
                  redirectTo="/"
                />
                <PrivateRoute
                  path="/"
                  component={Statistics}
                  redirectTo="/signin"
                />

                <Redirect to="/" />
              </Switch>
            </>
          </Router>
          <KeenCleaner />
        </CurrentStatisticsProvider>
      </CurrentSellerProvider>
    );
  }
}

export default App;
