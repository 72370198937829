/**
 * Asserts that the given `value` is of type `string`
 *
 * @param {unknown} value
 * @param {string} [message] message to throw in error if assertion fails
 *
 * @return {asserts value is string}
 */
export function assertIsString(
  value: unknown,
  message?: string
): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error(
      message ?? `Expected value to be string, but got ${typeof value}`
    );
  }
}
