import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Delete } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { AreYouSureDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderTop: `1px solid ${theme.palette.grey.A100}`,
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      marginTop: '-1px'
    },
    isDragging: {
      border: `1px solid ${theme.palette.background.paper}`,
      boxShadow:
        '0px 8px 10px -5px rgba(0,0,0,0.2),' +
        '0px 16px 24px 2px rgba(0,0,0,0.14),' +
        '0px 6px 30px 5px rgba(0,0,0,0.12)'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  persona: API.Entities.Persona;
  index: number;
  selected?: boolean;

  onPersonaArchived: (event: PersonaListItemEvent) => void;
  onClick: (event: PersonaListItemEvent) => void;
}

export interface PersonaListItemEvent {
  persona: API.Entities.Persona;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {
  isArchivePersonaDialogOpen: boolean;
}

// endregion

/**
 *
 */
class PersonaListItem extends React.Component<Props, State> {
  static readonly defaultProps = {
    selected: false
  };

  readonly state: State = {
    isArchivePersonaDialogOpen: false
  };

  async tryArchivePersona() {
    try {
      await RelmApi.removePersona(this.props.persona.id);
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to delete persona'
      );

      return;
    }

    toast(SnackbarVariant.SUCCESS, 'Successfully deleted persona');

    this.props.onPersonaArchived({ persona: this.props.persona });
    this.setState({ isArchivePersonaDialogOpen: false });
  }

  // region autobound methods
  @autobind
  handleListItemClick() {
    this.props.onClick({ persona: this.props.persona });
  }

  @autobind
  handleArchiveButtonClick() {
    this.setState({ isArchivePersonaDialogOpen: true });
  }

  @autobind
  handleArchiveConsumerCancel() {
    this.setState({ isArchivePersonaDialogOpen: false });
  }

  @autobind
  handleArchiveConsumerAction() {
    load(this.tryArchivePersona());
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <Draggable
        key={this.props.persona.id}
        draggableId={this.props.persona.id}
        index={this.props.index}
      >
        {(provided, snapshot) => (
          <div
            className={cx(this.props.classes.root, {
              [this.props.classes.isDragging]: snapshot.isDragging
            })}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <ListItem
              selected={this.props.selected}
              button
              onClick={this.handleListItemClick}
            >
              <ListItemText primary={this.props.persona.name} />
              <ListItemSecondaryAction>
                <IconButton onClick={this.handleArchiveButtonClick}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <AreYouSureDialog
              open={this.state.isArchivePersonaDialogOpen}
              actionText={`Delete ${this.props.persona.name}`}
              onCancel={this.handleArchiveConsumerCancel}
              onAction={this.handleArchiveConsumerAction}
            >
              Are you sure you want to delete this persona?
              <br />
              <strong>This action cannot be undone.</strong>
            </AreYouSureDialog>
          </div>
        )}
      </Draggable>
    );
  }

  // endregion
}

export default withStyles(styles)(PersonaListItem);
