import autobind from 'autobind-decorator';
import debug from 'debug';
import { EventEmitter } from 'events';
import { OAuth2Token } from 'src/definitions';

// region namespace: AuthTokenManager
export enum AuthTokenManagerEvent {
  E_TOKEN_CHANGE = 'e:token.change'
}

export interface AuthTokenChangeEvent {
  newToken: StoredAuthToken | null;
  oldToken: StoredAuthToken | null;
}

export interface StoredAuthToken extends OAuth2Token {
  /**
   * The unix timestamp of when the token was put into storage.
   */
  storedAt: number;
}

// endregion

/**
 * Manager class that's responsible for managing a single `OAuth2` token stored in LocalStorage.
 *
 * This provides an interface for accessing & updating `OAuth2` tokens nicely,
 * as well as a means of tracking when such events occur, allowing both
 * Components & Services to play nice with persistent `OAuth2` tokens.
 */
class AuthTokenManager extends EventEmitter {
  /**
   * @type {debug.IDebugger}
   * @private
   */
  private readonly _logger: debug.IDebugger = debug(this.constructor.name);

  /**
   * The namespace of the `OAuthToken` being managed.
   *
   * This is the name used to store & access the token.
   *
   * @type {string}
   * @private
   */
  private readonly _tokenKey: string;

  /**
   * Writes the given data as a JSON string under the given `key` on `localStorage`.
   *
   * @param {string} key
   * @param {?Object} data
   * @private
   */
  private static writeToLocalStorage(key: string, data: object | null) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  /**
   * Removes all data stored under the given `key` from `localStorage`.
   *
   * @param {string} key
   * @private
   */
  private static removeFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Reads and parses a JSON string that's stored under the given `key` on `localStorage`.
   *
   * If the key doesn't exist in `localStorage`, or the data isn't valid JSON, `null` is returned.
   *
   * @param {string} key
   *
   * @return {?Object}
   * @private
   */
  private static readFromLocalStorage<TExpectedReturn = object>(
    key: string
  ): TExpectedReturn | null {
    try {
      return AuthTokenManager.tryParseAsJSON(localStorage.getItem(key));
    } catch (error) {
      return null;
    }
  }

  /**
   * Tries to parse the given `str` as `JSON`.
   *
   * If successful, the resulting `Object` is returned;
   * otherwise, `null` is returned.
   *
   * @param {?string} str
   *
   * @return {?Object}
   */
  public static tryParseAsJSON<TExpectedReturn = object>(
    str: string | null
  ): TExpectedReturn | null {
    try {
      return JSON.parse(str!);
    } catch (error) {
      return null;
    }
  }

  /**
   *
   * @param {string} tokenKey
   */
  constructor(tokenKey: string) {
    super();

    this._tokenKey = tokenKey;

    window.addEventListener('storage', this._handleLocalStorageEvent);
  }

  // region getters & setters
  /**
   * Gets the key that the auth token this instance is managing is stored under in `localStorage`.
   *
   * @return {string}
   */
  get key(): string {
    return this._tokenKey;
  }

  // endregion
  // region event emitting
  /**
   * Emits an event signaling that the stored `OAuth2` auth token was changed.
   *
   * This event includes both the old and new tokens that were and are stored in `localStorage`.
   *
   * The old token value is not guaranteed to always be correct.
   *
   * @param {?StoredAuthToken} newToken
   * @param {?StoredAuthToken} oldToken
   *
   * @fires AuthTokenManager#E_TOKEN_CHANGE
   * @private
   */
  _emitTokenChanged(
    newToken: StoredAuthToken | null,
    oldToken: StoredAuthToken | null
  ): void {
    this._logger(
      `_emitTokenChanged: emitting "${AuthTokenManagerEvent.E_TOKEN_CHANGE}" event`,
      { newToken, oldToken }
    );
    /**
     * @event AuthTokenManager#E_TOKEN_CHANGE
     * @type {Object}
     *
     * @property {?StoredAuthToken} E_TOKEN_CHANGE:newToken
     * @property {?StoredAuthToken} E_TOKEN_CHANGE:oldToken
     */
    this.emit(AuthTokenManagerEvent.E_TOKEN_CHANGE, { newToken, oldToken });
  }

  // endregion
  // region event handling
  /**
   * Handles `localStorage` events, that get fired when *another* document changes `localStorage`.
   *
   * @param {StorageEvent} event
   * @private
   *
   * @fires AuthTokenManager#E_TOKEN_CHANGE when the key of the event matches this managers key.
   */
  @autobind
  _handleLocalStorageEvent(event: StorageEvent): void {
    if (event.key !== this.key) {
      return;
    } // don't care about other peoples storage

    this._logger(
      '_handleLocalStorageEvent: token was changed by another document'
    );

    this._emitTokenChanged(
      AuthTokenManager.tryParseAsJSON<StoredAuthToken>(event.newValue),
      AuthTokenManager.tryParseAsJSON<StoredAuthToken>(event.oldValue)
    );
  }

  // endregion

  /**
   * Saves the given `oauth2Token` to `localStorage`.
   *
   * @param {OAuth2Token} oauth2Token
   *
   * @return {StoredAuthToken} the token that was saved to `localStorage`.
   *
   * @fires AuthTokenManager#E_TOKEN_CHANGE after the token has been saved.
   */
  save(oauth2Token: OAuth2Token | null): StoredAuthToken | null {
    const oldToken: StoredAuthToken | null = this.load(); // could be null, but eh
    const newToken: StoredAuthToken | null = oauth2Token
      ? { ...oauth2Token, storedAt: Date.now() / 1000 }
      : oauth2Token;

    this._logger('save: saving auth token', newToken);

    if (newToken === oldToken) {
      this._logger(
        'save: rejected request to save oauth2Token, as its already in localStorage',
        newToken,
        oldToken
      );

      return null;
    }

    AuthTokenManager.writeToLocalStorage(this.key, newToken);
    this._logger('save: saved auth token');

    this._emitTokenChanged(newToken, oldToken);

    return newToken;
  }

  /**
   * Loads the `OAuth2` auth token from `localStorage`.
   *
   * @return {?StoredAuthToken}
   */
  load(): StoredAuthToken | null {
    return AuthTokenManager.readFromLocalStorage<StoredAuthToken>(this.key);
  }

  /**
   * Clears the `OAuth2` token from `localStorage`.
   *
   * This is the same as calling the {@link #save AuthTokenManager.save}
   * method with an argument of `null`.
   *
   * @fires AuthTokenManager#E_TOKEN_CHANGE after the token has been saved.
   */
  clear(): void {
    this.save(null);
  }

  /**
   * Checks if the auth token being managed by this instance has expired.
   *
   * @return {boolean}
   */
  isExpired(): boolean {
    const token = this.load();

    if (!token) {
      return true;
    }

    return token.storedAt + (token.expires_in - 32) < Date.now() / 1000;
  }
}

declare interface AuthTokenManager {
  on(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    listener: (event: AuthTokenChangeEvent) => void
  ): this;

  once(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    listener: (event: AuthTokenChangeEvent) => void
  ): this;

  prependListener(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    listener: (event: AuthTokenChangeEvent) => void
  ): this;

  prependOnceListener(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    listener: (event: AuthTokenChangeEvent) => void
  ): this;

  addListener(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    listener: (event: AuthTokenChangeEvent) => void
  ): this;

  removeListener(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    listener: (event: AuthTokenChangeEvent) => void
  ): this;

  removeAllListeners(event: AuthTokenManagerEvent): this;

  emit(
    event: AuthTokenManagerEvent.E_TOKEN_CHANGE,
    eventObj: AuthTokenChangeEvent
  ): boolean;
}

export default AuthTokenManager;
