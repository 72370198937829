import { Dialog } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';

export const suppressFormSubmission = (event: React.SyntheticEvent) =>
  event.preventDefault();

export const FormDialog = (props: DialogProps) => (
  <Dialog
    PaperProps={{ component: 'form' }}
    onSubmit={suppressFormSubmission}
    {...props}
  />
);
