import {
  Collapse,
  Divider,
  ListItem,
  ListItemText,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';

// region component styles
const styles = createStyles({
  root: {}
});

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  expandKey: string;
  header: string;
  in?: boolean;
  divider?: boolean;

  onClick: (event: ListItemCollapseEvent) => void;
}

export interface ListItemCollapseEvent {
  expandKey: string;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
class ListItemCollapse extends React.Component<Props, State> {
  static readonly defaultProps = {
    in: false,
    divider: false
  };

  readonly state: State = {};

  // region autobound methods
  @autobind
  handleListItemClick() {
    this.props.onClick({ expandKey: this.props.expandKey });
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <>
        <ListItem
          button
          data-expanded-list="sort"
          onClick={this.handleListItemClick}
        >
          <ListItemText>{this.props.header}</ListItemText>
        </ListItem>
        <Collapse in={this.props.in} unmountOnExit>
          {this.props.children}
        </Collapse>
        {this.props.divider && <Divider />}
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(ListItemCollapse);
