import * as Draft from 'draft-js';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createLinkAtSelection(
    editorState: Draft.EditorState,
    url: string
  ): Draft.EditorState {
    const contentState = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { url });
    const entityKey = contentState.getLastCreatedEntityKey();
    const withLink = Draft.RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey
    );

    return Draft.EditorState.forceSelection(
      withLink,
      editorState.getSelection()
    );
  },

  removeLinkAtSelection(editorState: Draft.EditorState): Draft.EditorState {
    const selection = editorState.getSelection();

    return Draft.RichUtils.toggleLink(editorState, selection, null);
  },

  collapseToEnd(editorState: Draft.EditorState): Draft.EditorState {
    const selection = editorState.getSelection();

    return Draft.EditorState.forceSelection(
      editorState,
      selection.merge({
        anchorKey: selection.getEndKey(),
        focusKey: selection.getEndKey(),
        anchorOffset: selection.getEndOffset(),
        focusOffset: selection.getEndOffset()
      })
    );
  },

  getCurrentEntityKey(editorState: Draft.EditorState): string {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    const anchorBlock = contentState.getBlockForKey(anchorKey);
    const offset = selection.getAnchorOffset();
    const index = selection.getIsBackward() ? offset - 1 : offset;

    return anchorBlock.getEntityAt(index);
  },

  getCurrentEntity(
    editorState: Draft.EditorState
  ): Draft.EntityInstance | null {
    const contentState = editorState.getCurrentContent();
    const entityKey = this.getCurrentEntityKey(editorState);

    return entityKey ? contentState.getEntity(entityKey) : null;
  },

  hasEntity(
    editorState: Draft.EditorState,
    entityType: string
  ): boolean | null {
    const entity = this.getCurrentEntity(editorState);

    return entity && entity.getType() === entityType;
  }
};
