import { API } from 'src/definitions';
import Schema from 'validate';

export default new Schema<API.Personas.UpdatePersona.Request>(
  {
    name: {
      required: true,
      type: 'string',
      message: {
        required: 'This cannot be blank'
      }
    }
  },
  { strip: false }
);
