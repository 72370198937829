import cx from 'classnames';
import * as Draft from 'draft-js';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  AlignmentToolState,
  BlockProps
} from 'src/draft-plugins/draft-js-alignment-plugin/index';
import { StateStore } from 'src/draft-plugins/draft-js-alignment-plugin/utils/createStore';

const getDisplayName = (
  WrappedComponent: WrappedComponent & { WrappedComponent?: WrappedComponent }
): string => {
  const component = WrappedComponent.WrappedComponent ?? WrappedComponent;

  return component.displayName ?? (component.name || 'Component');
};

// region component props
interface ExternalProps {
  className?: string;

  blockProps: BlockProps;
  block: Draft.ContentBlock;
  style: any;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {}

// endregion

interface WrappedComponentProps {
  className?: string;
  blockProps: BlockProps;
  style: any;
}

type WrappedComponent = React.ComponentClass<WrappedComponentProps>;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  store
}: {
  config: any;
  store: StateStore<AlignmentToolState>;
}) => (
  WrappedComponent: WrappedComponent & { WrappedComponent?: WrappedComponent }
) =>
  class BlockAlignmentDecorator extends React.Component<Props> {
    static displayName = `BlockDraggable(${getDisplayName(WrappedComponent)})`;
    static WrappedComponent =
      WrappedComponent.WrappedComponent ?? WrappedComponent;

    componentDidUpdate = () => {
      if (
        this.props.blockProps.isFocused &&
        this.props.blockProps.isCollapsedSelection
      ) {
        // TODO figure out if and how to achieve this without fetching the DOM node
        const blockNode = ReactDOM.findDOMNode(this);

        if (blockNode === null) {
          throw new Error('blockNode is null');
        }

        if (blockNode instanceof Text) {
          throw new Error('blockNode is instanceof Text');
        }
        const boundingRect = blockNode.getBoundingClientRect();

        store.updateItem('setAlignment', this.props.blockProps.setAlignment);
        store.updateItem('alignment', this.props.blockProps.alignment);
        store.updateItem('boundingRect', boundingRect);
        store.updateItem('visibleBlock', this.props.block.getKey());
        // Only set visibleBlock to null in case it's the current one. This is important
        // in case the focus directly switches from one block to the other. Then the
        // Alignment tool should not be hidden, but just moved.
      } else if (store.getItem('visibleBlock') === this.props.block.getKey()) {
        store.updateItem('visibleBlock', null);
      }
    };

    componentWillUnmount() {
      // Set visibleBlock to null if the block is deleted
      store.updateItem('visibleBlock', null);
    }

    render() {
      const {
        blockProps,
        style,
        // using destructuring to make sure unused props are not passed down to the block
        ...elementProps
      } = this.props;

      return (
        <WrappedComponent
          {...elementProps}
          className={cx(
            elementProps.className,
            `align-${blockProps.alignment}`
          )}
          blockProps={blockProps}
          style={style}
        />
      );
    }
  };
