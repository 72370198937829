import { API } from 'src/definitions';

export enum Achievement {
  OUTREACH_EMAIL_SENT = 'outreach-email-sent',
  OUTREACH_EMAIL_RECEIVED = 'outreach-email-received',
  OUTREACH_EMAIL_OPENED = 'outreach-email-opened',

  TIMES_VIEWED_WEBSITE_LVL_1 = 'times-viewed-website-lvl-1',
  TIMES_VIEWED_WEBSITE_LVL_2 = 'times-viewed-website-lvl-2',
  TIMES_VIEWED_WEBSITE_LVL_3 = 'times-viewed-website-lvl-3',

  OUTREACH_REACTIVITY_LVL_1 = 'outreach-reactivity-lvl-1',
  OUTREACH_REACTIVITY_LVL_2 = 'outreach-reactivity-lvl-2',
  OUTREACH_REACTIVITY_LVL_3 = 'outreach-reactivity-lvl-3',
  OUTREACH_REACTIVITY_LVL_4 = 'outreach-reactivity-lvl-4',

  TOTAL_TIME_SPENT_ON_WEBSITE_LVL_1 = 'total-time-spent-on-website-lvl-1',
  TOTAL_TIME_SPENT_ON_WEBSITE_LVL_2 = 'total-time-spent-on-website-lvl-2',
  TOTAL_TIME_SPENT_ON_WEBSITE_LVL_3 = 'total-time-spent-on-website-lvl-3',
  TOTAL_TIME_SPENT_ON_WEBSITE_LVL_4 = 'total-time-spent-on-website-lvl-4',

  LONGER_TIME_SPENT_ON_ARTICLE_LVL_1 = 'longer-time-spent-on-article-lvl-1',
  LONGER_TIME_SPENT_ON_ARTICLE_LVL_2 = 'longer-time-spent-on-article-lvl-2',
  LONGER_TIME_SPENT_ON_ARTICLE_LVL_3 = 'longer-time-spent-on-article-lvl-3',
  LONGER_TIME_SPENT_ON_ARTICLE_LVL_4 = 'longer-time-spent-on-article-lvl-4',

  SHARED_VISITS = 'shared-visits',

  DO_NOT_TRACK = 'do-not-track'
}

interface AchievementValueMap {
  [Achievement.OUTREACH_EMAIL_SENT]: number;
  [Achievement.OUTREACH_EMAIL_RECEIVED]: number;
  [Achievement.OUTREACH_EMAIL_OPENED]: number;

  [Achievement.TIMES_VIEWED_WEBSITE_LVL_1]: number;
  [Achievement.TIMES_VIEWED_WEBSITE_LVL_2]: number;
  [Achievement.TIMES_VIEWED_WEBSITE_LVL_3]: number;

  [Achievement.OUTREACH_REACTIVITY_LVL_1]: number;
  [Achievement.OUTREACH_REACTIVITY_LVL_2]: number;
  [Achievement.OUTREACH_REACTIVITY_LVL_3]: number;
  [Achievement.OUTREACH_REACTIVITY_LVL_4]: number;

  [Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_1]: number;
  [Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_2]: number;
  [Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_3]: number;
  [Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_4]: number;

  [Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_1]: number;
  [Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_2]: number;
  [Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_3]: number;
  [Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_4]: number;

  [Achievement.SHARED_VISITS]: number;

  [Achievement.DO_NOT_TRACK]: number;
}

interface AchievementSettings {
  achievement: Achievement;
  calcValue: keyof API.Entities.Statistics.ConsumerAchievementValues;
  label: string;
}

interface AchievementGroup {
  title: string;
  achievements: AchievementSettings[];
}

export const getAchievementSettings = (
  achievement: Achievement
): AchievementSettings => {
  switch (achievement) {
    // region 'Outreach'
    case Achievement.OUTREACH_EMAIL_SENT:
      return {
        achievement,
        calcValue: 'outreachEmailSent',
        label: 'Sent'
      };
    case Achievement.OUTREACH_EMAIL_RECEIVED:
      return {
        achievement,
        calcValue: 'outreachEmailReceived',
        label: 'Received'
      };
    case Achievement.OUTREACH_EMAIL_OPENED:
      return {
        achievement,
        calcValue: 'outreachEmailOpened',
        label: 'Opened'
      };
    // endregion
    // region 'Times viewed the website'
    case Achievement.TIMES_VIEWED_WEBSITE_LVL_1:
      return {
        achievement,
        calcValue: 'numberOfSession',
        label: 'Once'
      };
    case Achievement.TIMES_VIEWED_WEBSITE_LVL_2:
      return {
        achievement,
        calcValue: 'numberOfSession',
        label: '5 times or less'
      };
    case Achievement.TIMES_VIEWED_WEBSITE_LVL_3:
      return {
        achievement,
        calcValue: 'numberOfSession',
        label: 'More than 5 times'
      };
    // endregion
    // region 'Last Outreach reactivity'
    case Achievement.OUTREACH_REACTIVITY_LVL_1:
      return {
        achievement,
        calcValue: 'outreachReactivity',
        label: 'More than a day'
      };
    case Achievement.OUTREACH_REACTIVITY_LVL_2:
      return {
        achievement,
        calcValue: 'outreachReactivity',
        label: 'Less than a day'
      };
    case Achievement.OUTREACH_REACTIVITY_LVL_3:
      return {
        achievement,
        calcValue: 'outreachReactivity',
        label: 'Less than an hour'
      };
    case Achievement.OUTREACH_REACTIVITY_LVL_4:
      return {
        achievement,
        calcValue: 'outreachReactivity',
        label: 'Less than a minute'
      };
    // endregion
    // region 'Total time spent on the website'
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_1:
      return {
        achievement,
        calcValue: 'totalTimeSpentOnWebsite',
        label: 'Less than a minute'
      };
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_2:
      return {
        achievement,
        calcValue: 'totalTimeSpentOnWebsite',
        label: 'From 1 to 3 minutes'
      };
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_3:
      return {
        achievement,
        calcValue: 'totalTimeSpentOnWebsite',
        label: 'From 3 to 5 minutes'
      };
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_4:
      return {
        achievement,
        calcValue: 'totalTimeSpentOnWebsite',
        label: 'More than 5 minutes'
      };
    // endregion
    // region 'Longer time spent on an article'
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_1:
      return {
        achievement,
        calcValue: 'longerTimeSpentOnArticle',
        label: 'Less than a minute'
      };
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_2:
      return {
        achievement,
        calcValue: 'longerTimeSpentOnArticle',
        label: 'From 1 to 3 minutes'
      };
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_3:
      return {
        achievement,
        calcValue: 'longerTimeSpentOnArticle',
        label: 'From 3 to 5 minutes'
      };
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_4:
      return {
        achievement,
        calcValue: 'longerTimeSpentOnArticle',
        label: 'More than 5 minutes'
      };
    // endregion
    // region 'Secondary visit'
    case Achievement.SHARED_VISITS:
      return {
        achievement,
        calcValue: 'sharedVisits',
        label: 'Consumer has shared the site with someone else'
      };
    // endregion
    // region 'Do not track'
    case Achievement.DO_NOT_TRACK:
      return {
        achievement,
        calcValue: 'doNotTrack',
        label: 'No "Do Not Track" request has been received'
      };
    // endregion
    default:
      throw new Error(`${achievement} is not yet supported`);
  }
};

export const getGroupedAchievements = (): AchievementGroup[] => [
  {
    title: 'Last Outreach',
    achievements: [
      Achievement.OUTREACH_EMAIL_SENT,
      Achievement.OUTREACH_EMAIL_RECEIVED,
      Achievement.OUTREACH_EMAIL_OPENED
    ].map(getAchievementSettings)
  },
  {
    title: 'Reactivity to Last Outreach',
    achievements: [
      Achievement.OUTREACH_REACTIVITY_LVL_1,
      Achievement.OUTREACH_REACTIVITY_LVL_2,
      Achievement.OUTREACH_REACTIVITY_LVL_3,
      Achievement.OUTREACH_REACTIVITY_LVL_4
    ].map(getAchievementSettings)
  },
  {
    title: 'Site Visits',
    achievements: [
      Achievement.TIMES_VIEWED_WEBSITE_LVL_1,
      Achievement.TIMES_VIEWED_WEBSITE_LVL_2,
      Achievement.TIMES_VIEWED_WEBSITE_LVL_3
    ].map(getAchievementSettings)
  },
  {
    title: 'Total time spent on site',
    achievements: [
      Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_1,
      Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_2,
      Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_3,
      Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_4
    ].map(getAchievementSettings)
  },
  {
    title: 'Time spent on a single article',
    achievements: [
      Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_1,
      Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_2,
      Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_3,
      Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_4
    ].map(getAchievementSettings)
  },
  {
    title: 'Has shared link',
    achievements: [Achievement.SHARED_VISITS].map(getAchievementSettings)
  },
  {
    title: 'Allowed to track activity',
    achievements: [Achievement.DO_NOT_TRACK].map(getAchievementSettings)
  }
];

/**
 * Checks if the given achievement is unlocked, given the given value.
 *
 * @param {Achievement} achievement
 * @param {AchievementValueMap[A]} value
 *
 * @return {boolean}
 */
export const isAchievementUnlocked = <A extends Achievement>(
  achievement: A,
  value: AchievementValueMap[A]
): boolean => {
  switch (achievement as Achievement) {
    case Achievement.OUTREACH_EMAIL_SENT:
      // Outreach Email Sent
      return value === 1;
    case Achievement.OUTREACH_EMAIL_RECEIVED:
      // Outreach Email Read
      return value === 1;
    case Achievement.OUTREACH_EMAIL_OPENED:
      // Outreach Email Read
      return value === 1;

    case Achievement.TIMES_VIEWED_WEBSITE_LVL_1:
      // Has visited the Website
      return value >= 1;
    case Achievement.TIMES_VIEWED_WEBSITE_LVL_2:
      // From 2 to 5 visits
      return value >= 2;
    case Achievement.TIMES_VIEWED_WEBSITE_LVL_3:
      // More than 5 visits
      return value > 5;

    case Achievement.OUTREACH_REACTIVITY_LVL_1:
      // More than a day
      return value > 0;
    case Achievement.OUTREACH_REACTIVITY_LVL_2:
      // Less than a day
      return value > 0 && value <= 86400;
    case Achievement.OUTREACH_REACTIVITY_LVL_3:
      // Less than an hour
      return value > 0 && value <= 3600;
    case Achievement.OUTREACH_REACTIVITY_LVL_4:
      // Less than a minute
      return value > 0 && value <= 60;

    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_1:
      // Less than a minute
      return value > 0;
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_2:
      // From 1 to 3 minutes
      return value > 60;
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_3:
      // From 3 to 5 minutes
      return value > 180;
    case Achievement.TOTAL_TIME_SPENT_ON_WEBSITE_LVL_4:
      // More than 5 minutes
      return value > 300;

    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_1:
      // Less than a minute
      return value > 0;
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_2:
      // From 1 to 3 minutes
      return value > 60;
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_3:
      // From 3 to 5 minutes
      return value > 180;
    case Achievement.LONGER_TIME_SPENT_ON_ARTICLE_LVL_4:
      // More than 5 minutes
      return value > 300;

    case Achievement.SHARED_VISITS:
      return value >= 1;

    case Achievement.DO_NOT_TRACK:
      return value === 0;

    default:
      throw new Error(`${achievement} is not yet supported`);
  }
};

// hasAchievementUnlocked(Achievement.TIME_SPENT_ON_PAGE_LVL_1, 1);
