import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormButton, FormDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import UpdateSellerRequest from 'src/schemas/UpdateSellerRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';

// region component props
interface ExternalProps {
  open: boolean;

  onClose: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentSellerValue;

interface State
  extends ValidationTraitState<
    Pick<
      API.Sellers.UpdateSeller.Request,
      'password' | 'passwordConfirmation' | 'passwordCurrent'
    >
  > {
  disableSubmit: boolean;
}

// endregion
/**
 *
 */
@withCurrentSeller<Props>()
class ChangePasswordDialog extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    onCurrentSellerChange: () => {}
  };

  private readonly _validator = new ValidationTrait(this, UpdateSellerRequest);

  readonly state: State = {
    inputValues: {
      password: '',
      passwordConfirmation: '',
      passwordCurrent: ''
    },
    inputErrors: {
      password: '',
      passwordConfirmation: '',
      passwordCurrent: ''
    },
    disableSubmit: false
  };

  /**
   *
   * @param {API.ErrorResponse} data
   */
  static getErrorMessage(data: API.ErrorResponse) {
    for (const prop in data.errors) {
      if (data.errors.hasOwnProperty(prop)) {
        return data.errors[prop][0];
      }
    }

    return 'Something went wrong trying to reset your password.';
  }

  async tryUpdateUserPassword() {
    try {
      await RelmApi.updateSeller(this.props.currentSeller!.id, {
        ...this.state.inputValues
      });

      toast(SnackbarVariant.SUCCESS, `Password successfully updated`);
    } catch (error) {
      if (error instanceof AxiosException) {
        const message = ChangePasswordDialog.getErrorMessage(
          error.response!.data
        );

        toast(SnackbarVariant.ERROR, message);

        this.setState({ disableSubmit: false });

        return;
      }

      this._validator.handlePossibleValidationError(error);
      this.setState({ disableSubmit: false });

      return;
    }

    this.handleCloseDialog();
    this.props.onCurrentSellerChange();
  }

  // region autobound methods
  @autobind
  handleUpdatePasswordClick() {
    this.setState({ disableSubmit: true });
    load(this.tryUpdateUserPassword());
  }

  @autobind
  handleCloseDialog() {
    this.setState({
      disableSubmit: false,
      inputValues: {
        password: '',
        passwordConfirmation: '',
        passwordCurrent: ''
      },
      inputErrors: {
        password: '',
        passwordConfirmation: '',
        passwordCurrent: ''
      }
    });

    this.props.onClose();
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <FormDialog
        open={this.props.open}
        fullWidth
        maxWidth="sm"
        onClose={this.handleCloseDialog}
      >
        <DialogTitle>Change password</DialogTitle>
        <DialogContent>
          {this._validator.buildTextFieldForProperty(
            'passwordCurrent',
            'Current password',
            { required: true, type: 'password' }
          )}
          {this._validator.buildTextFieldForProperty(
            'password',
            'New password',
            { required: true, type: 'password' }
          )}
          {this._validator.buildTextFieldForProperty(
            'passwordConfirmation',
            'Confirm new password',
            { required: true, type: 'password' }
          )}
        </DialogContent>
        <DialogActions>
          <FormButton
            disabled={this.state.disableSubmit}
            variant="outlined"
            onClick={this.handleUpdatePasswordClick}
          >
            Change password
          </FormButton>
          <Button variant="outlined" onClick={this.handleCloseDialog}>
            Cancel
          </Button>
        </DialogActions>
      </FormDialog>
    );
  }

  // endregion
}

export default ChangePasswordDialog;
