import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4LangEn from '@amcharts/amcharts4/lang/en';
import am4ThemesAnimated from '@amcharts/amcharts4/themes/animated';
import {
  CardContent,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  CenteredCircularProgress,
  StatisticsNoDataWarning
} from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import ConsumerProfileDialog from 'src/components/ConsumerProfileDialog';
import ConsumersListDialog from 'src/components/ConsumersListDialog';
import CurrentPersonaProvider from 'src/components/CurrentPersonaProvider';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi, {
  OutreachEvent,
  OutreachEventLabel
} from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';
import { groupObjectsByStringProperty } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'relative',
      minHeight: '450px',
      [`& .amcharts-XYSeries-group.amcharts-ColumnSeries-group,
        & .amcharts-amexport-menu,
        & .amcharts-ZoomOutButton-group`]: {
        cursor: 'pointer'
      }
    },
    header: {
      textAlign: 'center'
    },
    chart: {
      width: '100%',
      height: '515px',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      margin: '1em 0',
      overflowX: 'visible',
      overflowY: 'visible'
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  id: string;
  days: number;
  value: API.Nullable<API.Entities.Statistics.ConsumerOutreach[]>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

interface State {
  showNoDataWarning: boolean;
  dailyOutreach: API.Nullable<API.Entities.Statistics.DailyOutreach>;
  consumer: API.Nullable<API.Entities.Consumer>;
}

// region Am4core
am4core.useTheme(am4ThemesAnimated);
am4core.options.autoSetClassName = true;
am4core.options.minPolylineStep = 5;
// endregion Am4core

// endregion

/**
 * Build to display statistics
 */
@withCurrentStatistics<Props>()
class StatisticsOutreachsChart extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {},
    id: 'amCharts-outreach',
    value: null,
    days: 7
  };

  static readonly jssName: string = StatisticsOutreachsChart.name;

  private _amChartsOutreach: API.Nullable<am4charts.XYChart> = null;
  private readonly _chartId: string = this.props.id;
  private readonly _chartStyle: API.Entities.Statistics.ChartStyle = {
    tensionX: 0.77,
    tensionY: 1,
    strokeWidth: 1,
    fillOpacity: 0.6,
    sequencedInterpolation: true
  };

  private _consumersListById: API.Nullable<
    Record<string, API.Entities.Statistics.ConsumerWithStats[]>
  > = null;

  readonly state: State = {
    showNoDataWarning: false,
    dailyOutreach: null,
    consumer: null
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.days !== this.props.days
    ) {
      this.updateStats();
    }
  }

  componentDidMount() {
    this.initGraph();
    this.updateStats();
  }

  componentWillUnmount() {
    this._amChartsOutreach && this._amChartsOutreach.dispose();
  }

  /**
   * Handles when the `ConsumerProfile` is called to close.
   */
  @autobind
  handleConsumerProfileCallToClose() {
    this.setState({
      consumer: null
    });
  }

  /**
   * Handles when a `Consumer` has been updated.
   */
  @autobind
  handleConsumerProfileUpdated() {
    this.setState({
      consumer: null
    });
  }

  @autobind
  handleOpenConsumerProfile(relmId: string): void {
    if (
      this.props.currentStatistics === null ||
      this.props.currentStatistics.consumers === null
    ) {
      return;
    }

    this._consumersListById = groupObjectsByStringProperty(
      this.props.currentStatistics.consumers,
      'relmKey',
      ''
    );

    if (!(relmId in this._consumersListById)) {
      this.setState({
        consumer: null
      });
      toast(SnackbarVariant.WARNING, `This consumer doesn't exist anymore`);

      return;
    }
    this.setState({
      consumer: this._consumersListById[relmId][0]
    });
  }

  @autobind
  handleConsumersListCallToClose() {
    this.setState({
      dailyOutreach: null
    });
  }

  @autobind
  handleConsumersListCallToOpenProfile(consumer: API.Entities.Consumer) {
    this.setState({
      consumer: consumer
    });
  }

  @autobind
  async handleOpenOutreachList(
    date: Date,
    streamName: string,
    streamLabel: string
  ): Promise<void> {
    if (!this.props.currentStatistics) {
      throw new Error('currentStatistics not loaded');
    }

    this.setState({
      dailyOutreach: {
        event: streamName,
        label: streamLabel,
        consumers: StatsApi.getOutreachByDay(
          this.props.currentStatistics.consumers,
          this.props.currentStatistics.outreach,
          date
        ),
        date: date
      }
    });
  }

  private static _setTooltip(
    series: am4charts.XYSeries,
    field: string,
    label: string
  ): void {
    series.tooltipText = `[#000]${
      label ? label : field
    }: [bold #000]{${field}}`;
    if (series.tooltip) {
      series.tooltip.getStrokeFromObject = true;
      series.tooltip.background.strokeWidth = 2;
      series.tooltip.getFillFromObject = false;
    }
  }

  private static _createBullet(
    field: string,
    color: am4core.Color
  ): am4charts.CircleBullet {
    const bullet = new am4charts.CircleBullet();

    bullet.fill = am4core.color('white');
    bullet.strokeWidth = 2;
    bullet.circle.radius = 5;
    bullet.circle.propertyFields.opacity = field;
    bullet.clickable = false;
    bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    bullet.stroke = color;

    return bullet;
  }

  private static _setMarker(
    markerTemplate: am4core.Container,
    isBig: boolean
  ): am4core.Container {
    if (isBig) {
      markerTemplate.width = 25;
      markerTemplate.height = 15;

      return markerTemplate;
    }
    markerTemplate.width = 15;
    markerTemplate.height = 10;
    markerTemplate.fillOpacity = 0.8;

    return markerTemplate;
  }

  private static _createLegend(): am4charts.Legend {
    const legend = new am4charts.Legend();

    legend.position = 'bottom';
    legend.labels.template.text = '{name}';

    return legend;
  }

  private _createCursor(dateAxis: am4charts.DateAxis): am4charts.XYCursor {
    const cursor = new am4charts.XYCursor();

    cursor.xAxis = dateAxis;
    cursor.fullWidthLineX = true;
    cursor.lineX.strokeWidth = this._chartStyle.strokeWidth;
    cursor.lineX.strokeDasharray = '';
    cursor.tooltipText = '{valueY}';
    cursor.adapter.add('tooltipText', label => label);
    cursor.lineX.fill = am4core.color('#cccccc');
    cursor.lineX.fillOpacity = 0.1;
    cursor.lineX.stroke = am4core.color('#777777');
    cursor.lineY.disabled = true;

    return cursor;
  }

  private static _createScrollbar(): am4core.Scrollbar {
    const scrollbar = new am4core.Scrollbar();

    scrollbar.showOnInit = false;
    scrollbar.hideGrips = true;
    scrollbar.startGrip.disabled = true;
    scrollbar.endGrip.disabled = true;
    scrollbar.showSystemTooltip = false;
    scrollbar.hide();

    return scrollbar;
  }

  /**
   * Draw the column's gradient
   *
   * @returns {LinearGradientModifier}
   */
  private static _fillModifier() {
    const fillModifier = new am4core.LinearGradientModifier();

    fillModifier.opacities = [1, 0.7];
    fillModifier.offsets = [0, 1];
    fillModifier.gradient.rotation = 90;

    return fillModifier;
  }

  // Create series
  private _createSeriesLine(
    chart: am4charts.XYChart,
    field: string,
    label: string,
    valueAxis: am4charts.Axis,
    color: am4core.Color
  ) {
    if (this._amChartsOutreach === null) {
      return;
    }

    // Series
    const series: am4charts.LineSeries = chart.series.push(
      new am4charts.LineSeries()
    );

    series.showOnInit = false;
    series.name = field;
    series.dataFields.valueY = field;
    series.dataFields.dateX = 'date';
    series.tensionX = this._chartStyle.tensionX;
    series.tensionY = this._chartStyle.tensionY;
    series.strokeWidth = this._chartStyle.strokeWidth * 2;
    series.fillOpacity = this._chartStyle.fillOpacity * 0.5;
    series.sequencedInterpolation = this._chartStyle.sequencedInterpolation;
    series.yAxis = valueAxis;
    series.legendSettings.labelText = label;
    StatisticsOutreachsChart._setTooltip(series, field, label);
    StatisticsOutreachsChart._setMarker(chart.legend.markers.template, true);
    series.bullets
      .push(StatisticsOutreachsChart._createBullet(field, color))
      .circle.events.on('hit', event => this.hitHandler(event, field, label));
    series.segments.template.events.on('hit', event =>
      this.hitHandler(event, field, label)
    );
    series.segments.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.segments.template.interactionsEnabled = true;

    return series;
  }

  private _createSeriesColumn(
    chart: am4charts.XYChart,
    field: string,
    label: string,
    valueAxis: am4charts.Axis,
    color: am4core.Color
  ) {
    if (this._amChartsOutreach === null) {
      return;
    }

    const series: am4charts.ColumnSeries = chart.series.push(
      new am4charts.ColumnSeries()
    );

    series.showOnInit = false;
    series.name = field;
    series.dataFields.valueY = field;
    series.dataFields.dateX = 'date';
    series.stroke = color;
    series.fill = color;
    series.stacked = true;
    series.strokeWidth = this._chartStyle.strokeWidth;
    series.fillOpacity = this._chartStyle.fillOpacity;
    series.sequencedInterpolation = this._chartStyle.sequencedInterpolation;
    series.yAxis = valueAxis;
    series.legendSettings.labelText = label;
    StatisticsOutreachsChart._setTooltip(series, field, label);
    StatisticsOutreachsChart._setMarker(chart.legend.markers.template, true);
    series.columns.template.fillModifier = StatisticsOutreachsChart._fillModifier();
    series.columns.template.events.on('hit', event =>
      this.hitHandler(event, field, label)
    );

    return series;
  }

  /**
   * Auto toggle scrollBar visibility on Zoom change
   *
   * @param {am4charts.XYChart} chart
   */
  private _scrollBarAutoToggle(chart: am4charts.XYChart) {
    chart.zoomOutButton.events.on('visibilitychanged', event => {
      chart.scrollbarX[event.visible ? 'show' : 'hide']();
      if (chart.scrollbarY) {
        chart.scrollbarY[event.visible ? 'show' : 'hide']();
      }
    });
  }

  hitHandler(
    event: {
      type: 'hit';
      target: am4core.Circle | am4charts.LineSeriesSegment | am4charts.Column;
    },
    streamName: string,
    streamLabel: string
  ) {
    if (
      event.target.dataItem === undefined ||
      event.target.dataItem.component === undefined
    ) {
      return;
    }

    return this.handleOpenOutreachList(
      new Date(event.target.dataItem.component.tooltipDataItem.dates.dateX),
      streamName,
      streamLabel
    );
  }

  @autobind
  initGraph() {
    this._amChartsOutreach = am4core.create(this._chartId, am4charts.XYChart);
    this._amChartsOutreach.language.locale = am4LangEn;
    this._amChartsOutreach.numberFormatter.numberFormat = '#.';

    // X Axis
    const dateAxis = this._amChartsOutreach.xAxes.push(
      new am4charts.DateAxis()
    );

    dateAxis.dateFormatter.inputDateFormat = 'i';
    dateAxis.renderer.cellStartLocation = 0.01;
    dateAxis.renderer.cellEndLocation = 0.99;
    dateAxis.renderer.grid.template.opacity = 0;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.renderer.labels.template.verticalCenter = 'middle';
    dateAxis.renderer.labels.template.horizontalCenter = 'middle';
    dateAxis.renderer.axisFills.template.disabled = true;

    // Y Axis Sent
    const valueAxisSent = this._amChartsOutreach.yAxes.push(
      new am4charts.ValueAxis()
    );

    valueAxisSent.renderer.grid.template.opacity = 0.8;
    valueAxisSent.renderer.ticks.template.length = 10;
    valueAxisSent.cursorTooltipEnabled = false;
    valueAxisSent.title.text = 'Sent emails';
    valueAxisSent.marginTop = 0;
    valueAxisSent.marginBottom = 20;
    valueAxisSent.align = 'right';

    // Y Axis Undelivered
    const valueAxisFeedback = this._amChartsOutreach.yAxes.push(
      new am4charts.ValueAxis()
    );

    valueAxisFeedback.renderer.grid.template.opacity = 0.8;
    valueAxisFeedback.renderer.ticks.template.length = 10;
    valueAxisFeedback.cursorTooltipEnabled = false;
    valueAxisFeedback.title.text = 'Feedbacks';
    valueAxisFeedback.marginTop = 20;
    valueAxisFeedback.marginBottom = 0;
    valueAxisFeedback.align = 'right';

    this._amChartsOutreach.scrollbarX = StatisticsOutreachsChart._createScrollbar();
    this._scrollBarAutoToggle(this._amChartsOutreach);
    this._amChartsOutreach.cursor = this._createCursor(dateAxis);
    this._amChartsOutreach.cursor.behavior = 'zoomXY';
    this._amChartsOutreach.legend = StatisticsOutreachsChart._createLegend();
    this._amChartsOutreach.leftAxesContainer.layout = 'vertical';
    this._amChartsOutreach.events.on('ready', this.updateStats);

    this._createSeriesLine(
      this._amChartsOutreach,
      OutreachEvent.SENT,
      OutreachEventLabel.SENT,
      valueAxisSent,
      am4core.color('#67b7dc')
    );
    this._createSeriesLine(
      this._amChartsOutreach,
      OutreachEvent.UNSENT,
      OutreachEventLabel.UNSENT,
      valueAxisSent,
      am4core.color('#253269')
    );
    this._createSeriesColumn(
      this._amChartsOutreach,
      OutreachEvent.UNRECEIVED,
      OutreachEventLabel.UNRECEIVED,
      valueAxisFeedback,
      am4core.color('#ff0000')
    );
    this._createSeriesColumn(
      this._amChartsOutreach,
      OutreachEvent.OPENED,
      OutreachEventLabel.OPENED,
      valueAxisFeedback,
      am4core.color('#00aa00')
    );
  }

  @autobind
  updateStats() {
    if (this.props.value === null) {
      return;
    }
    const consumerOutreachByDays = StatsApi.dailyOutreachAmChartsFormated(
      this.props.value,
      this.props.days
    ).map(day => ({
      ...day,
      sent: day.sent + day.unsent,
      unreceived: day.unreceived !== 0 ? day.unreceived : undefined,
      opened: day.opened !== 0 ? day.opened : undefined
    }));

    this.setState({
      showNoDataWarning:
        consumerOutreachByDays.filter(
          email =>
            (email.sent || email.unsent || email.unreceived) ?? email.opened
        ).length === 0
    });
    if (this._amChartsOutreach === null || !this._amChartsOutreach.isReady()) {
      return;
    }
    this._amChartsOutreach.data = consumerOutreachByDays;
    this._amChartsOutreach.yAxes.each(e => e.dataChangeUpdate());
  }

  // region render & get-render-content methods
  render() {
    if (!this.props.currentStatistics) {
      throw new Error('currentStatistics not loaded');
    }

    return (
      <>
        <CardContent className={this.props.classes.root}>
          {this.props.value === null && (
            <div className={this.props.classes.progress}>
              <CenteredCircularProgress disableOutline />
            </div>
          )}
          <StatisticsNoDataWarning
            show={this.state.showNoDataWarning}
            warningMessage={`There's been no activity during this timeframe.`}
          />
          <div
            id={this._chartId}
            className={this.props.classes.chart}
            hidden={!this.props.currentStatistics.outreach.length}
          />
        </CardContent>
        {this.state.dailyOutreach !== null && (
          <ConsumersListDialog
            open
            dailyOutreach={this.state.dailyOutreach}
            onCallToClose={this.handleConsumersListCallToClose}
            onCallToOpenProfile={this.handleConsumersListCallToOpenProfile}
          />
        )}
        {this.state.consumer !== null && (
          <>
            {this.state.consumer.personaId === null && (
              <ConsumerProfileDialog
                open
                consumer={this.state.consumer}
                onCallToClose={this.handleConsumerProfileCallToClose}
                onConsumerUpdated={this.handleConsumerProfileUpdated}
              />
            )}
            {this.state.consumer.personaId !== null && (
              <CurrentPersonaProvider personaId={this.state.consumer.personaId}>
                <ConsumerProfileDialog
                  open
                  consumer={this.state.consumer}
                  onCallToClose={this.handleConsumerProfileCallToClose}
                  onConsumerUpdated={this.handleConsumerProfileUpdated}
                />
              </CurrentPersonaProvider>
            )}
          </>
        )}
      </>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StatisticsOutreachsChart.jssName })(
  StatisticsOutreachsChart
);
