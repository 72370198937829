import {
  CircularProgress,
  IconButton,
  Paper,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Cancel, Check, Edit } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { Glue } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import {
  CurrentPersonaValue,
  withCurrentPersona
} from 'src/contexts/CurrentPersonaContext';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import UpdatePersonaRequest from 'src/schemas/UpdatePersonaRequest';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import { assertIsDefined } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    circularProgress: {
      marginRight: `${theme.spacing(6)}px`
    },
    personaName: {
      alignItems: 'flex-start',
      display: 'flex',
      paddingTop: `${theme.spacing(2)}px`
    },
    personaNameEdit: {
      alignItems: 'center',
      display: 'flex'
    },
    personaNameEditButton: {
      marginLeft: `${theme.spacing()}px`
    },
    personaNameEditField: {
      flexGrow: 2,
      marginRight: `${theme.spacing(2)}px`
    },
    personaNamePaper: {
      'left': `${theme.spacing()}px`,
      'padding': `${theme.spacing()}px ${theme.spacing(2)}px`,
      'position': 'absolute',
      'top': `${theme.spacing()}px`,
      'zIndex': 2000,
      '& $personaName': {
        paddingTop: 0
      }
    },
    personaToolbar: {
      alignItems: 'flex-start'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  CurrentPersonaValue &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<API.Personas.UpdatePersona.Request> {
  isEditingPersonaName: boolean;
  isMakingApiRequest: boolean;
}

// endregion

/**
 *
 */
@withCurrentSeller<Props>()
@withCurrentPersona<Props>()
class PersonaTopbar extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentPersona: null,
    currentSeller: null,
    onCurrentSellerChange: () => {},
    onCurrentPersonaChange: () => {}
  };

  private readonly _validator = new ValidationTrait(this, UpdatePersonaRequest);

  readonly state: State = {
    isEditingPersonaName: false,
    isMakingApiRequest: false,
    inputValues: {
      name: ''
    },
    inputErrors: {
      name: ''
    }
  };

  async tryUpdatePersona() {
    assertIsDefined(this.props.currentPersona);
    this.setState({ isMakingApiRequest: true });

    try {
      await RelmApi.updatePersona(
        this.props.currentPersona.id,
        this.state.inputValues
      );

      toast(SnackbarVariant.SUCCESS, 'Successfully updated persona');
    } catch (error) {
      this.setState({ isMakingApiRequest: false });

      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong updating your persona. Please try again.'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);

      return;
    }

    this.setState({
      isEditingPersonaName: false,
      isMakingApiRequest: false
    });

    this.props.onCurrentPersonaChange();
  }

  // region autobound methods
  @autobind
  handleEditButtonClick() {
    this.setState(prevState => ({
      isEditingPersonaName: true,
      isMakingApiRequest: false,
      inputValues: {
        ...prevState.inputValues,
        name: this.props.currentPersona!.name
      }
    }));
  }

  @autobind
  handleSaveButtonClick() {
    this.tryUpdatePersona();
  }

  @autobind
  handleCancelButtonClick() {
    this.setState({
      isEditingPersonaName: false,
      isMakingApiRequest: false
    });
  }

  // endregion
  // region render & get-render-content methods
  getDisplayNameContent() {
    assertIsDefined(this.props.currentPersona);

    return (
      <div className={this.props.classes.personaName}>
        <Typography variant="h4">{this.props.currentPersona.name}</Typography>
        {!this.state.isEditingPersonaName && (
          <IconButton
            className={this.props.classes.personaNameEditButton}
            onClick={this.handleEditButtonClick}
          >
            <Edit fontSize="small" />
          </IconButton>
        )}
      </div>
    );
  }

  getEditingNameContent() {
    return (
      <div className={this.props.classes.personaNameEdit}>
        {this._validator.buildTextFieldForProperty('name', 'Name', {
          required: true,
          className: this.props.classes.personaNameEditField
        })}
        {this.state.isMakingApiRequest ? (
          <CircularProgress
            size={40}
            className={this.props.classes.circularProgress}
          />
        ) : (
          <>
            <IconButton onClick={this.handleSaveButtonClick}>
              <Check fontSize="small" />
            </IconButton>
            <IconButton onClick={this.handleCancelButtonClick}>
              <Cancel fontSize="small" />
            </IconButton>
          </>
        )}
      </div>
    );
  }

  getPersonaNameContent() {
    if (this.state.isEditingPersonaName) {
      return (
        <Paper className={this.props.classes.personaNamePaper}>
          {this.getDisplayNameContent()}
          {this.getEditingNameContent()}
        </Paper>
      );
    }

    return this.getDisplayNameContent();
  }

  render() {
    return (
      <Toolbar className={this.props.classes.personaToolbar}>
        {this.getPersonaNameContent()}
        <Glue />
      </Toolbar>
    );
  }

  // endregion
}

export default withStyles(styles)(PersonaTopbar);
