import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Image, Panorama } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import { AddImage } from 'draft-js-image-plugin';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import LoadingButton from 'src/components/LoadingButton';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      minWidth: '400px',
      maxHeight: '400px',
      paddingBottom: 0
    },
    dialogActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`
    },
    mediaImageDropper: {
      minHeight: '200px',
      border: '1px solid #666666',
      alignItems: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'space-between',
      margin: 0,
      padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
      flexFlow: 'column'
    },
    mediaImageDropperIcon: {
      color: theme.palette.grey.A100,
      fontSize: `${theme.spacing(5)}px`
    },
    mediaImageDropperActive: {
      backgroundColor: fade(theme.palette.text.disabled, 0.15),
      borderRadius: 0,
      mediaImageDropperIcon: {
        color: '#000000'
      }
    },
    mediaImagePreview: {
      maxWidth: '200px',
      margin: `${theme.spacing(2)}px 0 ${theme.spacing()}px 0`
    },
    dropzoneInput: {
      width: 0
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  addImage: AddImage;
  editorState: Draft.EditorState;
  maxImageWidth?: number;
  onChange: Draft.EditorProps['onChange'];
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  DraftJsButtons.ButtonProps &
  WithStyles<typeof styles>;

interface State {
  imageFile: API.Nullable<File>;
  isUploadImageDialogOpen: boolean;
}

// endregion

/**
 *
 */
class AddImageButton extends React.Component<Props, State> {
  static readonly defaultProps = {
    maxImageWidth: 1000
  };

  readonly state: State = {
    imageFile: null,
    isUploadImageDialogOpen: false
  };

  /**
   * Attempts to upload an image file from the state.
   *
   * If the `imageFile` is `null`, or an error occurs while uploading the file,
   * `null` is returned. Otherwise, the `url` of the file is returned.
   *
   * @return {Promise<API.Nullable<API.Entities.File['url']>>}
   */
  async tryUploadImageFromState(): Promise<
    API.Nullable<API.Entities.File['url']>
  > {
    if (!this.state.imageFile) {
      return null;
    }

    try {
      return (await RelmApi.uploadFile(this.state.imageFile)).data.url;
    } catch (error) {
      AxiosException.throwIfNotOneOfUs(error);

      toast(
        SnackbarVariant.ERROR,
        'Something went wrong when trying to upload your image.'
      );

      return null;
    }
  }

  // region autobound methods
  @autobind
  handleAddImageClick() {
    this.setState({
      imageFile: null,
      isUploadImageDialogOpen: true
    });
  }

  @autobind
  handleUploadImageDialogClose() {
    this.setState({ isUploadImageDialogOpen: false });
  }

  @autobind
  async handleUploadImageFile() {
    const url = await load(this.tryUploadImageFromState());

    if (url) {
      this.props.onChange(
        this.props.addImage(this.props.getEditorState(), url)
      );

      this.setState({ isUploadImageDialogOpen: false });
    }
  }

  /**
   * Handles when a file is dropped onto the `imageUrl` dropzone.
   *
   * @param {Array<File>} accepted
   */
  @autobind
  handleImageUrlFileDrop(accepted: File[]) {
    this.setState({ imageFile: accepted[0] });
  }

  // endregion

  getImagePreviewRender() {
    if (!this.state.imageFile) {
      return <Panorama className={this.props.classes.mediaImageDropperIcon} />;
    }

    return (
      <img
        src={URL.createObjectURL(this.state.imageFile)}
        alt="Upload Preview"
        className={this.props.classes.mediaImagePreview}
      />
    );
  }

  // region render & get-render-content methods
  render() {
    const classes = this.props.classes;

    return (
      <>
        <div className={this.props.theme.buttonWrapper}>
          <button
            className={this.props.theme.button}
            type="button"
            onClick={this.handleAddImageClick}
          >
            <Image />
          </button>
        </div>
        <Dialog
          open={this.state.isUploadImageDialogOpen}
          aria-labelledby={`${this.constructor.name}-dialog-title`}
          onClose={this.handleUploadImageDialogClose}
        >
          <DialogTitle id={`${this.constructor.name}-dialog-title`}>
            Upload Image
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl fullWidth>
              <Dropzone
                accept="image/gif, image/jpeg, image/png"
                onDrop={this.handleImageUrlFileDrop}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    className={cx(
                      classes.mediaImageDropper,
                      isDragActive && classes.mediaImageDropperActive
                    )}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <Typography variant="subtitle1">
                      Drag or click here to upload an image
                    </Typography>
                    <Typography variant="caption" align="center">
                      {`Full width is ${this.props.maxImageWidth}px, could be less or more`}
                      <br />
                      Maximum File Size: 2MB
                    </Typography>
                    {this.getImagePreviewRender()}
                  </div>
                )}
              </Dropzone>
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="outlined"
              onClick={this.handleUploadImageDialogClose}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              autoFocus
              disabled={!this.state.imageFile}
              onClick={this.handleUploadImageFile}
            >
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  // endregion
}

export default withStyles(styles)(AddImageButton);
