import autobind from 'autobind-decorator';
import * as CSS from 'csstype';
import * as Draft from 'draft-js';
import * as DraftJsButtons from 'draft-js-buttons';
import {
  BlockquoteButton,
  CodeBlockButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  OrderedListButton,
  UnorderedListButton
} from 'draft-js-buttons';
import * as React from 'react';
import DraftOffsetKey from 'src/draft-plugins/DraftOffsetKey';
import { SideToolbarStore } from 'src/draft-plugins/draft-js-side-toolbar-plugin';
import BlockTypeSelect, {
  BlockTypeSelectTheme
} from 'src/draft-plugins/draft-js-side-toolbar-plugin/components/BlockTypeSelect';
import { StateStore } from 'src/draft-plugins/draft-js-side-toolbar-plugin/utils/createStore';

// region component props
interface ExternalProps {
  children: (externalProps: DraftJsButtons.ButtonProps) => React.ReactNode;

  store: StateStore<SideToolbarStore>;
  position: 'left' | 'right';
  theme: BlockTypeSelectTheme;

  scrollOffsetRef: React.RefObject<HTMLElement>;
}

export type ToolbarProps = ExternalProps;

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  position: {
    [k: string]: CSS.Properties<number | string>[keyof CSS.Properties];
  };
}

// endregion

/**
 *
 */
class Toolbar extends React.Component<Props, State> {
  static readonly defaultProps = {
    children: (externalProps: DraftJsButtons.ButtonProps) => (
      // may be use React.Fragment instead of div to improve performance after React 16
      <div>
        <HeadlineOneButton {...externalProps} />
        <HeadlineTwoButton {...externalProps} />
        <BlockquoteButton {...externalProps} />
        <CodeBlockButton {...externalProps} />
        <UnorderedListButton {...externalProps} />
        <OrderedListButton {...externalProps} />
      </div>
    )
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = Toolbar.name;

  readonly state: State = {
    position: {
      transform: 'scale(0)'
    }
  };

  // region component lifecycle methods
  /**
   * @inheritDoc
   */
  componentDidMount() {
    this.props.store.subscribeToItem(
      'editorState',
      this.handleEditorStateChange
    );
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    this.props.store.unsubscribeFromItem(
      'editorState',
      this.handleEditorStateChange
    );
  }

  // endregion
  // region autobound methods
  @autobind
  handleEditorStateChange(editorState: Draft.EditorState) {
    const selection = editorState.getSelection();

    if (!selection.getHasFocus()) {
      this.setState({
        position: {
          transform: 'scale(0)'
        }
      });

      return;
    }

    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(selection.getStartKey());
    // TODO verify that always a key-0-0 exists
    const offsetKey = DraftOffsetKey.encode(currentBlock.getKey(), 0, 0);

    // Note: need to wait on tick to make sure the DOM node has been create by Draft.js
    setTimeout(() => {
      const node = document.querySelectorAll<HTMLElement>(
        `[data-offset-key="${offsetKey}"]`
      )[0];

      // The editor root should be two levels above the node from
      // `getEditorRef`. In case this changes in the future, we
      // attempt to find the node dynamically by traversing upwards.
      const editorRef: any | null = this.props.store.getItem('getEditorRef')();

      if (!editorRef) {
        return;
      }

      // this keeps backwards-compatibility with react 15
      let editorRoot =
        editorRef.refs && editorRef.refs.editor
          ? editorRef.refs.editor
          : editorRef.editor;

      while (editorRoot.className.indexOf('DraftEditor-root') === -1) {
        editorRoot = editorRoot.parentNode;
      }

      const scrollingOffset = this.props.scrollOffsetRef.current
        ? this.props.scrollOffsetRef.current.scrollTop
        : 0;

      const top = node.offsetTop + editorRoot.offsetTop - scrollingOffset;

      const position = {
        top,
        transform: 'scale(1)',
        transition: 'transform 0.15s cubic-bezier(.3,1.2,.2,1)',
        left: '0'
      };

      // TODO: remove the hard code(width for the hover element)
      if (this.props.position === 'right') {
        position.left = `${
          editorRoot.offsetLeft + editorRoot.offsetWidth + 80 - 36
        }px`;
      } else {
        position.left = `${editorRoot.offsetLeft - 80}px`;
      }

      this.setState({
        position
      });
    }, 0);
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const { theme, store } = this.props;

    return (
      <div className={theme.toolbarStyles.wrapper} style={this.state.position}>
        <BlockTypeSelect
          getEditorState={store.getItem('getEditorState')}
          setEditorState={store.getItem('setEditorState')}
          theme={theme}
        >
          {this.props.children}
        </BlockTypeSelect>
      </div>
    );
  }

  // endregion
}

export default Toolbar;
