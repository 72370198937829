import {
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, PersonOutline } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { FormButton, FormDialog } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import UpdateConsumerRequest from 'src/schemas/UpdateConsumerRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import { Omit } from 'src/services/withDataFromApi';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: `${theme.breakpoints.values.sm}px`
      }
    },
    typographyTitle: {
      textAlign: 'center'
    },
    cardHeader: {
      paddingTop: '10px',
      paddingBottom: '0'
    },
    cardContent: {
      paddingTop: '0',
      paddingBottom: '0'
    },
    cardActions: {
      paddingTop: '0',
      paddingBottom: '20px'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  /**
   * The `Consumer` that is being edited.
   */
  consumer: API.Entities.Consumer;
  /**
   * Controls the open state of the `Dialog`.
   */
  open: boolean;

  /**
   * Called when the `Dialog` is closed.
   *
   * @param {boolean} wasUpdated `true` if the `Consumer` was updated, otherwise `false`.
   */
  onClose: (wasUpdated: boolean) => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<
    Omit<API.Consumers.UpdateConsumer.Request, 'stepId' | 'personaId'>
  > {}

// endregion

/**
 *
 */
class EditConsumerDialog extends React.Component<Props, State> {
  static readonly defaultProps = {};

  private readonly _validator = new ValidationTrait(
    this,
    UpdateConsumerRequest
  );

  readonly state: State = {
    inputValues: { ...this.props.consumer },
    inputErrors: {
      email: '',
      firstName: '',
      lastName: '',
      organisation: ''
    }
  };

  async tryUpdateConsumer() {
    try {
      await RelmApi.updateConsumer(
        this.props.consumer.sellerId,
        this.props.consumer.id,
        this.state.inputValues
      );
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to update consumer'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);

      return;
    }

    toast(SnackbarVariant.SUCCESS, 'Successfully updated consumer');

    this.props.onClose(true);
  }

  // region autobound methods
  /**
   * Handles when the `'Update Consumer'` button is clicked.
   */
  @autobind
  handleUpdateConsumerButtonClick() {
    load(this.tryUpdateConsumer());
  }

  @autobind
  handleCloseClick() {
    this.props.onClose(false);
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return (
      <FormDialog
        classes={{ paper: this.props.classes.paper }}
        open={this.props.open}
        onClose={this.handleCloseClick}
      >
        <CardHeader
          className={this.props.classes.cardHeader}
          avatar={<PersonOutline />}
          action={
            <IconButton onClick={this.handleCloseClick}>
              <Close />
            </IconButton>
          }
          titleTypographyProps={{
            className: this.props.classes.typographyTitle
          }}
          title="Edit Consumer"
        />
        <CardContent className={this.props.classes.cardContent}>
          {this._validator.buildTextFieldForProperty('firstName', 'First Name')}
          {this._validator.buildTextFieldForProperty('lastName', 'Last Name')}
          {this._validator.buildTextFieldForProperty('email', 'Email')}
          {this._validator.buildTextFieldForProperty(
            'organisation',
            'Organisation'
          )}
        </CardContent>
        <CardActions className={this.props.classes.cardActions}>
          <FormButton
            variant="outlined"
            fullWidth
            onClick={this.handleUpdateConsumerButtonClick}
          >
            Update
          </FormButton>
        </CardActions>
      </FormDialog>
    );
  }

  // endregion
}

export default withStyles(styles)(EditConsumerDialog);
