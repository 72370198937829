import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Consumers.AddConsumer.Request>(
  {
    email: {
      required: true,
      type: 'string',
      match: regexps.email,
      message: {
        required: 'A valid email is required',
        match: 'A valid email is required'
      }
    },
    firstName: {
      required: true,
      message: {
        required: 'First name is required'
      }
    },
    lastName: {
      required: true,
      message: {
        required: 'Last name is required'
      }
    },
    organisation: {
      required: false,
      type: 'string'
    },
    stepId: {
      required: false,
      type: 'string'
    },
    personaId: {
      required: false,
      type: 'string'
    }
  },
  { strip: false }
);
