import { CircularProgress, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import * as React from 'react';

const useStyles = makeStyles(
  {
    root: {
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)'
    },
    disableOutline: {
      outline: 'none'
    }
  },
  { name: 'CenteredCircularProgress' }
);

interface Props {
  disableOutline?: boolean;
}

export const CenteredCircularProgress = ({ disableOutline }: Props) => {
  const classes = useStyles();

  return (
    <CircularProgress
      className={cx({
        [classes.root]: true,
        [classes.disableOutline]: disableOutline
      })}
    />
  );
};
