import * as Draft from 'draft-js';
import { EditorState } from 'draft-js';
import * as React from 'react';
import { Omit } from 'src/definitions';
import AlignmentTool, { AlignmentToolProps } from './components/AlignmentTool';
import createDecorator from './createDecorator';
import createStore from './utils/createStore';

require('./css/buttonStyles.css');
require('./css/alignmentToolStyles.css');

const buttonStyles = {
  buttonWrapper: 'buttonWrapper',
  button: 'button',
  active: 'active',
  separator: 'separator'
};

const alignmentToolStyles = {
  alignmentTool: 'alignmentTool'
};

export interface AlignmentPluginTheme {
  buttonStyles: typeof buttonStyles;
  alignmentToolStyles: typeof alignmentToolStyles;
}

export const enum Alignment {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left'
}

export interface BlockProps {
  isFocused: boolean;
  isCollapsedSelection: boolean;
  setAlignment: (alignment: Alignment) => void;
  alignment: Alignment;
}

export interface AlignmentToolState {
  isVisible: boolean;
  editorState: Draft.EditorState;
  getReadOnly: () => boolean;
  getEditorState: () => Draft.EditorState;
  setEditorState: (editorState: Draft.EditorState) => void;
  setAlignment: (alignment: Alignment) => void;
  alignment: Alignment | null;
  boundingRect: ClientRect;
  visibleBlock: unknown | null;
}

const createSetAlignment = (
  contentBlock: Draft.ContentBlock,
  {
    getEditorState,
    setEditorState
  }: {
    getEditorState: () => Draft.EditorState;
    setEditorState: (editorState: Draft.EditorState) => void;
  }
) => (data: object) => {
  const entityKey = contentBlock.getEntityAt(0);

  if (entityKey) {
    const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();

    contentState.mergeEntityData(entityKey, { ...data });
    setEditorState(
      EditorState.forceSelection(editorState, editorState.getSelection())
    );
  }
};

export interface AlignmentPluginConfig {
  theme?: AlignmentPluginTheme;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (config: AlignmentPluginConfig = {}) => {
  const defaultAlignmentToolTheme = {
    buttonStyles,
    alignmentToolStyles
  };

  const store = createStore<AlignmentToolState>({
    isVisible: false,
    editorState: Draft.EditorState.createEmpty(),
    alignment: Alignment.CENTER,
    boundingRect: (undefined as unknown) as ClientRect,
    getReadOnly: (): boolean => false,
    getEditorState: () => Draft.EditorState.createEmpty(),
    setEditorState: (editorState: Draft.EditorState) => {},
    setAlignment: alignment => {},
    visibleBlock: null
  });

  const { theme = defaultAlignmentToolTheme } = config;

  const alignmentToolProps = {
    store,
    theme
  };

  return {
    initialize: ({
      getReadOnly,
      setEditorState,
      getEditorState
    }: {
      getReadOnly: () => boolean;
      getEditorState: () => Draft.EditorState;
      setEditorState: (editorState: Draft.EditorState) => void;
    }) => {
      store.updateItem('getReadOnly', getReadOnly);
      store.updateItem('getEditorState', getEditorState);
      store.updateItem('setEditorState', setEditorState);
    },
    decorator: createDecorator({ config, store }),
    blockRendererFn: (
      contentBlock: Draft.ContentBlock,
      {
        getEditorState,
        setEditorState
      }: {
        getEditorState: () => Draft.EditorState;
        setEditorState: (editorState: Draft.EditorState) => void;
      }
    ) => {
      const entityKey = contentBlock.getEntityAt(0);
      const contentState = getEditorState().getCurrentContent();
      const alignmentData = entityKey
        ? contentState.getEntity(entityKey).getData()
        : {};

      return {
        props: {
          alignment: alignmentData.alignment || Alignment.CENTER,
          setAlignment: createSetAlignment(contentBlock, {
            getEditorState,
            setEditorState
          })
        }
      };
    },
    AlignmentTool: (
      props: Omit<AlignmentToolProps, keyof typeof alignmentToolProps>
    ) => <AlignmentTool {...props} {...alignmentToolProps} />
  };
};
