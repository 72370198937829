import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import autobind from 'autobind-decorator';
import * as React from 'react';
import ValidationException from 'src/exceptions/ValidationException';

export interface ValidationTraitState<TInputFields> {
  inputValues: TInputFields;
  inputErrors: Record<keyof TInputFields, string>;
}

class ValidationTrait<TInputFields extends { [k: string]: any }> {
  private readonly _component: React.Component<
    any,
    ValidationTraitState<TInputFields>
  >;

  private readonly _schema: Schema<TInputFields>;
  private readonly _getExtraInputProperties?: () => object;

  /**
   *
   * @type {number}
   */
  private _validationTimerId = 0;

  /**
   *
   * @type {number}
   */
  private readonly _validateOnChangeDelay = 500;

  constructor(
    component: React.Component<any, ValidationTraitState<TInputFields>>,
    schema: Schema<TInputFields>,
    getExtraInputPropertiesFn?: () => object
  ) {
    this._component = component;
    this._schema = schema;
    this._getExtraInputProperties = getExtraInputPropertiesFn;
  }

  handlePossibleValidationError(error: Error): void {
    const validationError = ValidationException.throwIfNotOneOfUs<
      keyof TInputFields
    >(error);

    this._component.setState({
      inputErrors: validationError.validationResultsMap
    });
  }

  /**
   *
   * @param {N} propertyName
   * @param {T} value
   * @param {number} [delay=0]
   *
   * @template N
   * @template T
   */
  tryValidatePropertyAgainstValue<
    N extends keyof TInputFields,
    T extends TInputFields[N]
  >(propertyName: N, value: T, delay = 0): void {
    if (delay) {
      clearTimeout(this._validationTimerId);

      this._validationTimerId = window.setTimeout(
        () => this.tryValidatePropertyAgainstValue(propertyName, value, 0),
        delay
      );

      return;
    }

    let errorMessage = '';

    try {
      // todo: replace with spread operator, once TS supports spread on generics
      ValidationException.assert(
        this._schema.validate(
          Object.assign(
            {},
            this._component.state.inputValues,
            { [propertyName]: value },
            this._getExtraInputProperties && this._getExtraInputProperties()
          )
        )
      );
    } catch (error) {
      const validationError = ValidationException.throwIfNotOneOfUs<
        keyof TInputFields
      >(error);

      errorMessage = validationError.messageFor(propertyName);
    }

    // todo: replace with spread operator, once TS supports spread on generics
    this._component.setState(prevState => ({
      inputErrors: Object.assign({}, prevState.inputErrors, {
        [propertyName]: errorMessage
      })
    }));
  }

  /**
   * Type guard for check if an propertyName is 'valid', and considered supported by this component.
   *
   * @param {string} propertyName
   *
   * @return {boolean}
   */
  isNameOfSupportedProperty(
    propertyName: string
  ): propertyName is Extract<keyof TInputFields, string> {
    return Object.keys(this._component.state.inputValues).includes(
      propertyName
    );
  }

  /**
   * Handles when the value of an `input` element changes.
   *
   * @param {React.ChangeEvent} event
   */
  @autobind
  public handleInputValueChange(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const { name, value } = event.target;

    if (!this.isNameOfSupportedProperty(name)) {
      throw new Error(`${name} is not a supported property`);
    }

    this.tryValidatePropertyAgainstValue(
      name,
      value as any,
      this._validateOnChangeDelay
    );

    // todo: replace with spread operator, once TS supports spread on generics
    this._component.setState({
      inputValues: Object.assign({}, this._component.state.inputValues, {
        [name]: value
      })
    });
  }

  /**
   * Handles when the value of an `input` element changes.
   *
   * @param {React.FocusEvent} event
   */
  @autobind
  public handleInputValueBlur(event: React.FocusEvent<HTMLInputElement>): void {
    const { name } = event.target;

    if (!this.isNameOfSupportedProperty(name)) {
      throw new Error(`${name} is not a supported property`);
    }

    this.tryValidatePropertyAgainstValue(
      name,
      this._component.state.inputValues[name]
    );
  }

  /**
   * Handles when something is submitted
   *
   * @param {React.SyntheticEvent} event
   */
  @autobind
  handleSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
  }

  /**
   *
   * @param {string} propertyName
   * @param {string} label
   * @param {TextFieldProps} [extraProps={}]
   */
  public buildTextFieldForProperty(
    propertyName: keyof TInputFields & string,
    label: string,
    extraProps: TextFieldProps = {}
  ) {
    return (
      <TextField
        name={propertyName}
        label={label}
        value={this._component.state.inputValues[propertyName] || ''}
        helperText={this._component.state.inputErrors[propertyName] || ' '}
        error={!!this._component.state.inputErrors[propertyName]}
        margin="dense"
        fullWidth
        onChange={this.handleInputValueChange}
        onBlur={this.handleInputValueBlur}
        {...extraProps}
      />
    );
  }
}

export default ValidationTrait;
