import {
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Videocam } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  CenteredCircularProgress,
  StatisticsNoDataWarning
} from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import CurrentPersonaProvider from 'src/components/CurrentPersonaProvider';
import EditStoryDialog from 'src/components/EditStoryDialog';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import { toast } from 'src/services/Toaster';
import { getYoutubeVideoId, groupObjectsByStringProperty } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%',
      position: 'relative',
      minHeight: '450px'
    },
    chart: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      overflowY: 'auto',
      overflowX: 'hidden',
      margin: 0,
      padding: 0,
      height: '450px'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.grey['400']}`,
      paddingTop: `${theme.spacing(2)}px`,
      paddingBottom: `${theme.spacing(2)}px`
    },
    storyHeader: {
      fontWeight: 'bold',
      flexGrow: 3
    },
    timeSpentHeader: {
      fontWeight: 'bold',
      textAlign: 'right',
      flexGrow: 1
    },
    story: {
      flexGrow: 3,
      maxWidth: `calc(80% - 100px)`,
      minWidth: 'auto'
    },
    imageWrapper: {
      position: 'relative',
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.default,
      borderRadius: `${theme.shape.borderRadius}px`
    },
    imageCardMedia: {
      borderRadius: `${theme.shape.borderRadius}px`,
      height: 0,
      width: '100px',
      paddingTop: '75%' // Story View Format: '38.4%' // Video Format:'56.25%'
    },
    videoCover: {
      position: 'absolute',
      margin: `${theme.spacing(0.5)}px`,
      padding: `${theme.spacing(0.5)}px`,
      paddingBottom: 0,
      right: 0,
      borderRadius: `${theme.shape.borderRadius}px`,
      backgroundColor: fade(theme.palette.text.disabled, 0.5)
    },
    videoCoverIcon: {
      color: '#ffffff'
    },
    timeSpend: {
      textAlign: 'right',
      flexGrow: 1,
      width: '20%',
      paddingRight: 0,
      paddingLeft: 0
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  id: string;
  days: number;
  value: API.Nullable<API.Entities.Statistics.StoryWithStats[]>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

interface State {
  showNoDataWarning: boolean;
  storiesList: API.Entities.Statistics.TopStoryDisplay[];
  story: API.Nullable<API.Entities.Story>;
}

// endregion

/**
 * Build to display statistics
 */
@withCurrentStatistics<Props>()
class StatisticsTopStories extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {},
    id: 'amCharts-lp-visit',
    value: null,
    days: 7
  };

  static readonly jssName: string = StatisticsTopStories.name;

  readonly state: State = {
    showNoDataWarning: false,
    storiesList: [],
    story: null
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.days !== this.props.days
    ) {
      this.updateStats();
    }
  }

  componentDidMount() {
    this.updateStats();
  }

  @autobind
  updateStats() {
    if (this.props.value === null) {
      return;
    }
    const topStories = StatsApi.topStories(this.props.value, this.props.days);

    this.setState({ showNoDataWarning: topStories.length === 0 });
    this.setState({ storiesList: topStories });
  }

  @autobind
  handleOpenStory(storyId: string) {
    if (
      !this.props.currentStatistics ||
      !this.props.currentStatistics.stories
    ) {
      return;
    }
    const storyById = groupObjectsByStringProperty(
      this.props.currentStatistics.stories,
      'id',
      ''
    );

    if (!(storyId in storyById)) {
      this.setState({
        story: null
      });
      toast(SnackbarVariant.WARNING, `This story doesn't exist anymore`);

      return;
    }
    this.setState({
      story: storyById[storyId][0]
    });
  }

  @autobind
  handleStoryEditDialogClose() {
    this.setState({ story: null });
  }

  // region render & get-render-content methods
  render() {
    if (!this.props.currentStatistics) {
      throw new Error('currentStatistics not loaded');
    }

    return (
      <CardContent className={this.props.classes.root}>
        {this.props.value === null && (
          <div className={this.props.classes.progress}>
            <CenteredCircularProgress disableOutline />
          </div>
        )}
        <StatisticsNoDataWarning
          show={this.state.showNoDataWarning}
          warningMessage="There have been no visits during this timeframe."
        />
        <List id={this.props.id} className={this.props.classes.chart}>
          {this.props.currentStatistics.events.length > 0 && (
            <ListSubheader className={this.props.classes.header}>
              <Typography className={this.props.classes.storyHeader}>
                Stories
              </Typography>
              <Typography className={this.props.classes.timeSpentHeader}>
                Time Spent
              </Typography>
            </ListSubheader>
          )}
          {this.state.storiesList.map(story => (
            <ListItem
              button
              key={story.title}
              divider
              onClick={event => this.handleOpenStory(story.id)}
            >
              {story.videoUrl && !story.imageUrl && (
                <div className={this.props.classes.imageWrapper}>
                  <div className={this.props.classes.videoCover}>
                    <Videocam className={this.props.classes.videoCoverIcon} />
                  </div>
                  <CardMedia
                    className={this.props.classes.imageCardMedia}
                    image={`https://img.youtube.com/vi/${getYoutubeVideoId(
                      story.videoUrl
                    )}/mqdefault.jpg`}
                  />
                </div>
              )}
              {!story.videoUrl && story.imageUrl && (
                <div className={this.props.classes.imageWrapper}>
                  <CardMedia
                    className={this.props.classes.imageCardMedia}
                    image={story.imageUrl}
                  />
                </div>
              )}
              <ListItemText
                className={this.props.classes.story}
                primary={story.title}
                secondary={story.private ? 'Private story' : ''}
              />
              <ListItemText
                className={this.props.classes.timeSpend}
                primary={story.timeSpentDisplay}
              />
            </ListItem>
          ))}
        </List>
        {this.state.story !== null && (
          <CurrentPersonaProvider personaId={this.state.story.personaId}>
            <EditStoryDialog
              open
              readOnly
              story={this.state.story}
              onClose={this.handleStoryEditDialogClose}
            />
          </CurrentPersonaProvider>
        )}
      </CardContent>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StatisticsTopStories.jssName })(
  StatisticsTopStories
);
