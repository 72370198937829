import { Card, makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(theme => ({
  margin: { margin: `${theme.spacing(2)}px` }
}));

export const CardWithMargin = (props: { children?: React.ReactNode }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return <Card className={classes.margin}>{props.children}</Card>;
};
