import { makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(
  theme => ({
    root: {
      'color': theme.palette.text.primary,
      'transition': theme.transitions.create('color'),
      '&:visited, &:hover, &:active': {
        color: theme.palette.text.secondary
      }
    }
  }),
  { name: 'GenericLink' }
);

interface Props {
  to: string;
}

export const GenericLink = ({
  children,
  to
}: React.PropsWithChildren<Props>) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Link className={classes.root} to={to}>
      {children}
    </Link>
  );
};
