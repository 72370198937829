import autobind from 'autobind-decorator';
import debug from 'debug';
import * as React from 'react';
import CurrentSellerContext, {
  CurrentSellerValue
} from 'src/contexts/CurrentSellerContext';
import AuthTokenManager, {
  AuthTokenChangeEvent,
  AuthTokenManagerEvent
} from 'src/services/AuthTokenManager';
import RelmApi from 'src/services/RelmApi';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  WithDataFromApiProps<'currentSeller', CurrentSellerValue['currentSeller']>;

interface State {}

// endregion

@withDataFromApi<Props>(
  () =>
    RelmApi.isAuthenticated()
      ? RelmApi.getCurrentSeller()
      : Promise.resolve({ data: null }),
  'currentSeller'
)
class CurrentSellerProvider extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    loadFromApi: () => Promise.resolve() as any
  };

  /**
   * @type {debug.IDebugger}
   * @private
   */
  private readonly _logger: debug.IDebugger = debug(this.constructor.name);

  private _currentSellerValue: CurrentSellerValue = {
    currentSeller: null,
    onCurrentSellerChange: () => this.updateCurrentSeller()
  };

  readonly state: State = {};

  // region component lifecycle methods
  /**
   * @inheritDoc
   */
  componentDidMount() {
    RelmApi.authManager.on(
      AuthTokenManagerEvent.E_TOKEN_CHANGE,
      this.handleAuthTokenChanged
    );
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    RelmApi.authManager.removeListener(
      AuthTokenManagerEvent.E_TOKEN_CHANGE,
      this.handleAuthTokenChanged
    );
  }

  // endregion
  /**
   *
   */
  async updateCurrentSeller() {
    this._logger(
      '',
      'current seller is going to be',
      (await this.props.loadFromApi()).result
    );
  }

  // region autobound methods
  /**
   * Handles when the stored access token changes for some reason.
   *
   * @param {AuthTokenManager#event:E_TOKEN_CHANGE} event
   */
  @autobind
  handleAuthTokenChanged(event: AuthTokenChangeEvent) {
    this._logger('access token changed', event);

    this.updateCurrentSeller();
  }

  // endregion

  render() {
    if (this._currentSellerValue.currentSeller !== this.props.currentSeller) {
      this._currentSellerValue = {
        ...this._currentSellerValue,
        currentSeller: this.props.currentSeller
      }; // save re-renders by not passing a new object every render.
    }

    return (
      <CurrentSellerContext.Provider value={this._currentSellerValue}>
        {this.props.children}
      </CurrentSellerContext.Provider>
    );
  }
}

export default CurrentSellerProvider;
