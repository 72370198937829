import hoistNonReactStatics from 'hoist-non-react-statics';
import * as React from 'react';
import { ThemeName } from 'src/theme/theme';

export interface CurrentThemeValue {
  currentThemeName: ThemeName;
  changeCurrentTheme: (themeName: ThemeName) => void;
}

const CurrentThemeContext = React.createContext<CurrentThemeValue>({
  currentThemeName: ThemeName.DEFAULT,
  changeCurrentTheme: () => {}
});

/**
 *
 */
export const withCurrentTheme = <P extends CurrentThemeValue>() => <
  C extends React.ComponentClass<P>
>(
  Component: C
): C => {
  class WithCurrentTheme extends React.Component<P & { innerRef?: any }> {
    render() {
      const TypedComponent = Component as React.ComponentClass<P>;

      return (
        <CurrentThemeContext.Consumer>
          {(contextValue: CurrentThemeValue) => (
            <TypedComponent
              ref={this.props.innerRef}
              {...this.props}
              currentThemeName={contextValue.currentThemeName}
              changeCurrentTheme={contextValue.changeCurrentTheme}
            />
          )}
        </CurrentThemeContext.Consumer>
      );
    }
  }

  hoistNonReactStatics(WithCurrentTheme, Component);

  return (WithCurrentTheme as unknown) as C;
};

export default CurrentThemeContext;
