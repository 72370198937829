import {
  Button,
  Grid,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Send, Undo } from '@material-ui/icons';
import autobind from 'autobind-decorator';
import * as Draft from 'draft-js';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { ConsumersListCard, Glue, ShortcodesListCard } from 'src/components';
import { SnackbarVariant } from 'src/components/AppSnackbar';
import EngageEditor from 'src/components/editors/EngageEditor';
import {
  contentStateFromHtml,
  contentStateToHtml
} from 'src/components/editors/StoryEditor';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import AxiosException from 'src/exceptions/AxiosException';
import AddEngagementInBulkRequest from 'src/schemas/AddEngagementInBulkRequest';
import { load } from 'src/services/Loader';
import RelmApi from 'src/services/RelmApi';
import { toast } from 'src/services/Toaster';
import ValidationTrait, {
  ValidationTraitState
} from 'src/services/ValidationTrait';
import withDataFromApi, {
  Omit,
  WithDataFromApiProps
} from 'src/services/withDataFromApi';
import { buildRelmHostingUrlForSeller } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.spacing(9)}px)`
    },
    spacing: {
      padding: `${theme.spacing(2)}px`
    },
    buttonCancel: {
      marginTop: `${theme.spacing()}px`
    },
    iconCancel: {
      [theme.breakpoints.up('md')]: {
        paddingRight: `${theme.spacing(0.5)}px`
      }
    },
    iconCancelLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    buttonOutbox: {
      marginTop: `${theme.spacing()}px`
    },
    iconOutbox: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: `${theme.spacing(0.5)}px`
      }
    },
    iconOutboxLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    relmLink: {
      marginLeft: `${theme.spacing()}px`
    },
    iframeLandingPagePreview: {
      width: '100%',
      height: '75%'
    },
    editorContainer: {
      flex: 1,
      padding: `${theme.spacing(2)}px`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: `${theme.spacing(0.5)}px`,
      border: `1px solid rgba(${
        theme.palette.type === 'light' ? '0, 0, 0, 0.25' : '255, 255, 255, 0.25'
      })`
    },
    signatureEditorContainer: {
      padding: `${theme.spacing(2)}px`,
      clear: 'both',
      overflowX: 'hidden',
      overflowY: 'auto',
      boxShadow: '0px 0px 1px inset black'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  consumersToEngageWith: API.Entities.Consumer[];
  currentPersona: API.Entities.Persona;

  onSentToOutbox?: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  WithDataFromApiProps<'stories', API.Stories.ListStoriesOfPersona.Response> &
  WithDataFromApiProps<
    'sellerStyles',
    API.Sellers.ShowSingleSellerStyles.Response
  > &
  WithStyles<typeof styles>;

interface State
  extends ValidationTraitState<
    Omit<API.Engagements.AddEngagementInBulk.Request, 'consumerIds'>
  > {
  editorState: Draft.EditorState;
}

// endregion

const shortcodes = [
  { name: '{first_name}' },
  { name: '{last_name}' },
  { name: '{organisation}' }
];

/**
 *
 */
@withCurrentSeller<Props>()
@withDataFromApi<Props>(
  props =>
    RelmApi.listStories(props.currentPersona.id).then(({ data }) => ({
      data: data.filter(story => story.consumerId === null)
    })),
  'stories'
)
@withDataFromApi<Props>(
  props => RelmApi.getSellerStyles(props.currentSeller.id),
  'sellerStyles'
)
class Engagements extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: undefined,
    onCurrentSellerChange: undefined,
    onSentToOutbox: undefined,
    sellerStyles: undefined,
    loadFromApi: undefined,
    stories: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = Engagements.name;

  private readonly _validator = new ValidationTrait(
    this,
    AddEngagementInBulkRequest
  );

  private readonly _history = createBrowserHistory();

  readonly state: State = {
    editorState: this.signature(),
    inputValues: {
      title: '',
      body: ''
    },
    // eslint-disable-next-line react/no-unused-state
    inputErrors: {
      title: '',
      body: ''
    }
  };

  /**
   * Tries to create a new `Engagement` for each `Consumer`.
   *
   * @return (Promise<void>}
   */
  async tryCreateNewEngagementsForConsumers(): Promise<void> {
    if (this.props.consumersToEngageWith.length === 0) {
      return;
    } // don't do anything

    const personaId = this.props.consumersToEngageWith[0].personaId;

    if (personaId === null) {
      throw new Error(
        "can't create engagements for consumers without a persona"
      );
    }

    try {
      await RelmApi.bulkCreateEngagement(personaId, {
        ...this.state.inputValues,
        consumerIds: this.props.consumersToEngageWith.map(
          consumer => consumer.id
        )
      });
    } catch (error) {
      if (error instanceof AxiosException) {
        toast(
          SnackbarVariant.ERROR,
          'Something went wrong when trying to create engagements'
        );

        return;
      }

      this._validator.handlePossibleValidationError(error);

      return;
    }

    this.props.onSentToOutbox();
  }

  buildRelmLink(): string {
    if (!this.props.currentSeller) {
      throw new Error('currentSeller is null');
    } // this'll never be null, but typescript don't know that

    const hostingUrl = buildRelmHostingUrlForSeller(this.props.currentSeller);

    const consumer = this.props.consumersToEngageWith[0];

    return new URL(
      `/gk/${consumer.personaId}/${consumer.stepId}.html?hidden`,
      hostingUrl
    ).toString();
  }

  signature(): Draft.EditorState {
    if (this.props.sellerStyles && this.props.sellerStyles.signatureEnabled) {
      return Draft.EditorState.createWithContent(
        contentStateFromHtml(
          `<p></br></p>${this.props.sellerStyles.signature ?? ''}`
        )
      );
    }

    return Draft.EditorState.createEmpty();
  }

  @autobind
  handleCancelClick() {
    this._history.goBack();
  }

  // region autobound methods
  /**
   * Handles when the `Sent to Outbox` button is clicked.
   */
  @autobind
  handleSendToOutboxClick() {
    load(this.tryCreateNewEngagementsForConsumers());
  }

  @autobind
  handleBodyEditorChange(editorState: Draft.EditorState) {
    this.setState(prevState => ({
      editorState,
      inputValues: {
        ...prevState.inputValues,
        body: contentStateToHtml(editorState.getCurrentContent())
      }
    }));
  }

  // endregion
  // region render & get-render-content methods
  render() {
    const { classes } = this.props;

    return (
      <Grid className={classes.root} container>
        <Grid className={classes.spacing} item xs={1} />
        <Grid className={classes.spacing} item xs={2}>
          <ConsumersListCard
            title="Engage with"
            consumers={this.props.consumersToEngageWith || []}
          />
          <ShortcodesListCard
            title="Available shortcodes"
            shortcodes={shortcodes}
          />
        </Grid>
        <Grid
          className={classes.spacing}
          container
          direction="column"
          item
          xs={5}
        >
          {this._validator.buildTextFieldForProperty('title', 'Subject')}
          <EngageEditor
            className={classes.editorContainer}
            editorState={this.state.editorState}
            stories={this.props.stories}
            shortcodes={shortcodes}
            onChange={this.handleBodyEditorChange}
          />
          <Grid container>
            <Button
              className={classes.buttonCancel}
              variant="outlined"
              size="small"
              aria-label="Cancel"
              onClick={this.handleCancelClick}
            >
              <Undo className={classes.iconCancel} />
              <span className={classes.iconCancelLabel}>Cancel</span>
            </Button>
            <Glue />
            <Button
              className={classes.buttonOutbox}
              variant="contained"
              color="primary"
              aria-label="Send to outbox"
              onClick={this.handleSendToOutboxClick}
            >
              <span className={classes.iconOutboxLabel}>Send to outbox</span>
              <Send className={classes.iconOutbox} />
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.spacing} item xs={4}>
          <iframe
            title="Landing Page Preview"
            className={classes.iframeLandingPagePreview}
            src={this.buildRelmLink()}
          />
        </Grid>
      </Grid>
    );
  }

  // endregion
}

export default withStyles(styles, { name: Engagements.jssName })(Engagements);
