import { API } from 'src/definitions';
import regexps from 'src/schemas/regexps';
import Schema from 'validate';

export default new Schema<API.Sellers.UpdateSeller.Request>(
  {
    email: {
      required: false,
      type: 'string',
      match: regexps.email,
      message: {
        required: 'A valid email is required',
        match: 'A valid email is required'
      }
    },
    lastName: {
      required: false,
      type: 'string',
      match: regexps.name,
      message: {
        match: 'Last name is required'
      }
    },
    firstName: {
      required: false,
      type: 'string',
      match: regexps.name,
      message: {
        match: 'First name is required'
      }
    },
    password: {
      required: false,
      type: 'string',
      length: {
        min: 6
      },
      message: {
        required: 'Password must be at least 6 characters long',
        length: 'Password must be at least 6 characters long'
      }
    },
    passwordConfirmation: {
      required: false,
      type: 'string',
      use: { equals: (val, obj) => val === obj.password },
      message: {
        required: 'Passwords must match',
        equals: 'Passwords must match'
      }
    },
    passwordCurrent: {
      required: false,
      type: 'string',
      use: {
        requiredWhen: (val, obj) => obj.password === '' || (obj.password && val)
      },
      message: {
        requiredWhen: 'Current password is required'
      }
    },
    company: {
      required: false,
      type: 'string'
    },
    team: {
      required: false,
      type: 'string'
    },
    hasBeenToured: {
      required: false,
      type: 'boolean'
    }
  },
  { strip: false }
);
