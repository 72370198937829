// eslint-disable-next-line import/order
import { EventEmitter } from 'events';

/** @type {debug.IDebugger} */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logger = require('debug')('loader');

export enum LoaderEvent {
  /**
   * Event that represents when loading has started.
   *
   * @type {'e:loading.start'}
   */
  E_LOADING_START = 'e:loading.start',
  /**
   * Event that represents when loading has stopped.
   *
   * @type {'e:loading.stop'}
   */
  E_LOADING_STOP = 'e:loading.stop'
}

export interface LoadingStartEvent {}

export interface LoadingStopEvent {}

export declare interface Loader extends EventEmitter {
  on(
    event: LoaderEvent.E_LOADING_START,
    listener: (event: LoadingStartEvent) => void
  ): this;

  on(
    event: LoaderEvent.E_LOADING_STOP,
    listener: (event: LoadingStopEvent) => void
  ): this;

  once(
    event: LoaderEvent.E_LOADING_START,
    listener: (event: LoadingStartEvent) => void
  ): this;

  once(
    event: LoaderEvent.E_LOADING_STOP,
    listener: (event: LoadingStopEvent) => void
  ): this;

  prependListener(
    event: LoaderEvent.E_LOADING_START,
    listener: (event: LoadingStartEvent) => void
  ): this;

  prependListener(
    event: LoaderEvent.E_LOADING_STOP,
    listener: (event: LoadingStopEvent) => void
  ): this;

  prependOnceListener(
    event: LoaderEvent.E_LOADING_START,
    listener: (event: LoadingStartEvent) => void
  ): this;

  prependOnceListener(
    event: LoaderEvent.E_LOADING_STOP,
    listener: (event: LoadingStopEvent) => void
  ): this;

  addListener(
    event: LoaderEvent.E_LOADING_START,
    listener: (event: LoadingStartEvent) => void
  ): this;

  addListener(
    event: LoaderEvent.E_LOADING_STOP,
    listener: (event: LoadingStopEvent) => void
  ): this;

  removeListener(
    event: LoaderEvent.E_LOADING_START,
    listener: (event: LoadingStartEvent) => void
  ): this;

  removeListener(
    event: LoaderEvent.E_LOADING_STOP,
    listener: (event: LoadingStopEvent) => void
  ): this;

  removeAllListeners(event: LoaderEvent): this;

  emit(
    event: LoaderEvent.E_LOADING_START,
    eventObj: LoadingStartEvent
  ): boolean;

  emit(event: LoaderEvent.E_LOADING_STOP, eventObj: LoadingStopEvent): boolean;
}

/**
 *
 * @type {module:events.internal.EventEmitter}
 */
export const Loader: Loader = new EventEmitter();
// region event emitting
/**
 * Emits an event signaling that loading has started.
 *
 * @fires Loader#E_LOADING_START
 * @private
 */
const _emitStartLoading = (): void => {
  logger(
    `${_emitStartLoading.name}: emitting "${LoaderEvent.E_LOADING_START}" event`
  );
  /**
   * @event Loader#E_LOADING_START
   * @type {Object}
   */
  Loader.emit(LoaderEvent.E_LOADING_START, {});
};

/**
 * Emits an event signaling that loading has stopped.
 *
 * @fires Loader#E_LOADING_STOP
 * @private
 */
const _emitStopLoading = (): void => {
  logger(
    `${_emitStopLoading.name}: emitting "${LoaderEvent.E_LOADING_STOP}" event`
  );
  /**
   * @event Loader#E_LOADING_STOP
   * @type {Object}
   */
  Loader.emit(LoaderEvent.E_LOADING_STOP, {});
};
// endregion

/**
 * Collection of `Promise`s that are currently loading.
 *
 * @type {Set<Promise<unknown>>}
 */
const loads = new Set<Promise<unknown>>();

/**
 * Loads the promise.
 *
 * @param {T} waitForIt
 *
 * @template T
 */
export const load = <T extends unknown>(waitForIt: T): T => {
  const promise = Promise.resolve(waitForIt);

  loads.add(promise);

  if (loads.size !== 0) {
    _emitStartLoading();
  }

  promise
    .catch(() => {})
    .then(() => {
      loads.delete(promise);

      if (loads.size === 0) {
        _emitStopLoading();
      }
    });

  return waitForIt;
};

export default Loader;
