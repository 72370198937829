import {
  Grid,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import { format } from 'date-fns';
import * as React from 'react';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { fromTimeDisplay } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing()}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

// endregion

@withCurrentStatistics<Props>(true)
class StatisticsFeedback extends React.Component<Props> {
  static readonly defaultProps = {
    currentStatistics: undefined,
    onCurrentStatisticsChange: undefined
  };

  static readonly jssName: string = StatisticsFeedback.name;

  @autobind
  feedbackMessage() {
    if (this.props.currentStatistics === null) {
      return <Typography variant="caption">Loading...</Typography>;
    }

    return (
      <>
        <Typography variant="caption">
          {`Last refresh: ${fromTimeDisplay(
            this.props.currentStatistics.lastRefresh
          )}`}
        </Typography>
        <br />
        <Typography variant="caption">
          {`Local time: ${format(new Date(), `O`)}`}
        </Typography>
      </>
    );
  }

  // region render & get-render-content methods
  render() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="stretch"
        alignContent="flex-end"
        spacing={2}
      >
        <Grid item>{this.feedbackMessage()}</Grid>
      </Grid>
    );
  }

  // endregion
}

export default withStyles(styles, { name: StatisticsFeedback.jssName })(
  StatisticsFeedback
);
