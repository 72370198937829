import autobind from 'autobind-decorator';
import debug from 'debug';
import * as React from 'react';
import {
  AuthTokenChangeEvent,
  AuthTokenManagerEvent
} from 'src/services/AuthTokenManager';
import RelmApi from 'src/services/RelmApi';

// region component props
interface ExternalProps {}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps;

interface State {
  isAuthenticated: boolean;
}

// endregion

/**
 * HideUnlessAuthed Component.
 *
 * Renders children only when user is authenticated.
 */
class HideUnlessAuthed extends React.Component<Props, State> {
  static readonly defaultProps = {};

  /**
   * @type {debug.IDebugger}
   * @private
   */
  private readonly _logger: debug.IDebugger = debug(this.constructor.name);

  /**
   *
   */
  public readonly state: State = {
    isAuthenticated: RelmApi.isAuthenticated()
  };

  // region component lifecycle methods
  /**
   * @inheritDoc
   */
  componentDidMount() {
    RelmApi.authManager.on(
      AuthTokenManagerEvent.E_TOKEN_CHANGE,
      this.handleAuthTokenChanged
    );
  }

  /**
   * @inheritDoc
   */
  componentWillUnmount() {
    RelmApi.authManager.removeListener(
      AuthTokenManagerEvent.E_TOKEN_CHANGE,
      this.handleAuthTokenChanged
    );
  }

  // endregion
  // region autobound methods
  /**
   * Handles when the stored access token changes for some reason.
   *
   * @param {AuthTokenManager#event:E_TOKEN_CHANGE} event
   */
  @autobind
  handleAuthTokenChanged(event: AuthTokenChangeEvent) {
    this._logger('access token changed', event);
    const isAuthenticated = RelmApi.isAuthenticated();

    if (isAuthenticated !== this.state.isAuthenticated) {
      this.setState({ isAuthenticated });
    }
  }

  // endregion
  // region render & get-render-content methods
  render() {
    return this.state.isAuthenticated && this.props.children;
  }

  // endregion
}

export default HideUnlessAuthed;
