import {
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import autobind from 'autobind-decorator';
import * as React from 'react';
import {
  CenteredCircularProgress,
  StatisticsCompareCard,
  StatisticsSimpleCard
} from 'src/components';
import StatisticsFeedback from 'src/components/StatisticsFeedback';
import StatisticsOutreachsChart from 'src/components/StatisticsOutreachsChart';
import StatisticsTopConsumers from 'src/components/StatisticsTopConsumers';
import StatisticsTopStories from 'src/components/StatisticsTopStories';
import StatisticsVisitsChart from 'src/components/StatisticsVisitsChart';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import { assertIsDefined } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing()}px`
    },
    card: {
      position: 'relative',
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%',
      minHeight: '450px'
    },
    statsVisitsCardHeader: {
      textAlign: 'center'
    },
    graphContainer: {
      position: 'relative',
      minHeight: '450px'
    },
    formControl: {
      minWidth: '130px'
    },
    modalConsumerProfile: {
      width: '50%'
    },
    chart: {
      height: '450px'
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

interface State {
  timeframe: API.Entities.Statistics.AllTimeFrame;
  lastVisitCompare: API.Entities.Statistics.LastVisitsCompare;
  lastVisitNumber: API.Entities.Statistics.LastVisits;
}

// endregion
// region enum
enum ChartID {
  TOP_STORIES = 'chart-top-stories',
  TOP_CONSUMERS = 'chart-top-consumers',
  OUTREACH = 'amCharts-outreach',
  LP_VISIT = 'amCharts-lp-visit'
}

// endregion

const storageKey = 'RelmDashboardSettings';
const timeframeList: API.Entities.Statistics.TimeFrameDays[] = [
  {
    label: 'Last 7 days',
    days: 7
  },
  {
    label: 'Last 30 days',
    days: 30
  },
  {
    label: 'Last 60 days',
    days: 60
  },
  {
    label: 'Last 90 days',
    days: 90
  }
];

@withCurrentStatistics<Props>(true)
class Statistics extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: undefined,
    onCurrentStatisticsChange: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = Statistics.name;

  private readonly _timeframeDefault: API.Entities.Statistics.TimeFrameDays =
    timeframeList[1];

  readonly state: State = {
    timeframe: {
      topStories: this._timeframeDefault,
      topConsumers: this._timeframeDefault,
      outreach: this._timeframeDefault,
      visits: this._timeframeDefault
    },
    lastVisitCompare: {
      days: 7,
      previous: 0,
      current: 0
    },
    lastVisitNumber: {
      weekly: 0,
      monthly: 0
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.currentStatistics !== this.props.currentStatistics) {
      this.sync();
    }
  }

  async componentDidMount() {
    await this.loadSettings();
    this.sync();
  }

  // region settings
  loadSettings(): void {
    const localStorageTimeFrame = localStorage.getItem(storageKey);

    if (localStorageTimeFrame !== null) {
      this.setState({ timeframe: JSON.parse(localStorageTimeFrame) });
    }
  }

  saveSettings(timeframe: API.Entities.Statistics.AllTimeFrame): void {
    this.setState({ timeframe: timeframe });
    localStorage.setItem(storageKey, JSON.stringify(timeframe));
  }

  /**
   * Timeframe Change
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  @autobind
  handleTimeframeChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    assertIsDefined(event.target.name);

    if (typeof event.target.value !== 'number') {
      throw new Error(
        `Expected value to be number, but got ${typeof event.target.value}`
      );
    }

    const days = event.target.value;
    const timeframeIndex = timeframeList.findIndex(obj => obj.days === days);

    if (timeframeIndex === -1) {
      console.error('unable to find timeframe for ', days);

      return;
    }

    const timeframe = {
      ...this.state.timeframe,
      [event.target.name]: {
        label: timeframeList[timeframeIndex].label,
        days: days
      }
    };

    this.saveSettings(timeframe);
  }

  getTimeframeRenderContent(chartName: string) {
    return (
      <FormControl className={this.props.classes.formControl}>
        <Select
          inputProps={{
            id: `${chartName}TimeframeSelection`,
            name: chartName
          }}
          value={this.state.timeframe[chartName].days}
          onChange={this.handleTimeframeChange}
        >
          {timeframeList.map(timeframe => (
            <MenuItem key={timeframe.days} value={timeframe.days}>
              {timeframe.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  // endregion

  statsHeader(chartName: string, label: string) {
    return (
      <CardContent>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6">{label}</Typography>
          </Grid>
          <Grid item>{this.getTimeframeRenderContent(chartName)}</Grid>
        </Grid>
      </CardContent>
    );
  }

  sync(): void {
    if (this.props.currentStatistics === null) {
      return;
    }

    this.setState({
      lastVisitCompare: StatsApi.consumersVisitCompare(
        this.props.currentStatistics.events,
        7
      ),
      lastVisitNumber: {
        weekly: StatsApi.consumersVisitLastDays(
          this.props.currentStatistics.events,
          7
        ),
        monthly: StatsApi.consumersVisitLastDays(
          this.props.currentStatistics.events,
          30
        )
      }
    });
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} lg={4}>
            <Card className={this.props.classes.card}>
              <CardContent>
                <Typography variant="h6">Site Visits</Typography>
              </CardContent>
              {!this.props.currentStatistics && (
                <div className={this.props.classes.progress}>
                  <CenteredCircularProgress disableOutline />
                </div>
              )}
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="stretch"
                  alignContent="stretch"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <StatisticsCompareCard
                      value={this.state.lastVisitCompare}
                      description="Total Visits last 7 days"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StatisticsSimpleCard
                      value={this.state.lastVisitNumber.weekly}
                      description="Consumer Visits last 7 days"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StatisticsSimpleCard
                      value={this.state.lastVisitNumber.monthly}
                      description="Consumer Visits last 30 days"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={this.props.classes.card}>
              {this.statsHeader('topStories', 'Top Stories')}
              <CardContent className={this.props.classes.graphContainer}>
                <StatisticsTopStories
                  id={ChartID.TOP_STORIES}
                  value={
                    this.props.currentStatistics &&
                    this.props.currentStatistics.stories
                  }
                  days={this.state.timeframe.topStories.days}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card className={this.props.classes.card}>
              {this.statsHeader('topConsumers', 'Top Consumers')}
              <CardContent className={this.props.classes.graphContainer}>
                <StatisticsTopConsumers
                  id={ChartID.TOP_CONSUMERS}
                  value={
                    this.props.currentStatistics &&
                    this.props.currentStatistics.consumers
                  }
                  days={this.state.timeframe.topConsumers.days}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} lg={6}>
            <Card className={this.props.classes.card}>
              {this.statsHeader('outreach', 'Outreach')}
              <StatisticsOutreachsChart
                id={ChartID.OUTREACH}
                value={
                  this.props.currentStatistics &&
                  this.props.currentStatistics.outreach
                }
                days={this.state.timeframe.outreach.days}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card className={this.props.classes.card}>
              {this.statsHeader('visits', 'Visits')}
              <StatisticsVisitsChart
                id={ChartID.LP_VISIT}
                value={
                  this.props.currentStatistics &&
                  this.props.currentStatistics.consumers
                }
                days={this.state.timeframe.visits.days}
              />
            </Card>
          </Grid>
        </Grid>
        <StatisticsFeedback />
      </div>
    );
  }
}

export default withStyles(styles, { name: Statistics.jssName })(Statistics);
