import {
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
  WithStyles,
  Zoom,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Close, PersonOutline } from '@material-ui/icons';
import { format } from 'date-fns';
import * as React from 'react';
import { API } from 'src/definitions';
import { groupObjectsByStringProperty } from 'src/utilities';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing()}px`
    },
    dialog: {
      width: '100%',
      height: '100%',
      overflowY: 'unset',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        maxHeight: '100%',
        borderRadius: 0,
        margin: 0
      }
    },
    dialogHeader: {
      paddingTop: `${theme.spacing()}px`,
      paddingBottom: `${theme.spacing(0.5)}px`
    },
    dialogTitle: {
      paddingTop: `${theme.spacing()}px`,
      paddingBottom: `${theme.spacing()}px`
    },
    dialogContent: {
      paddingTop: 0,
      paddingBottom: 0
    },
    dialogActions: {
      paddingLeft: `${theme.spacing()}px`
    },
    contextSentence: {
      'paddingTop': `${theme.spacing()}px`,
      'paddingBottom': `${theme.spacing(2)}px`,
      'color': theme.palette.text.secondary,
      '& b': {
        color: theme.palette.text.primary
      }
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  open: boolean;
  dailyOutreach: API.Nullable<API.Entities.Statistics.DailyOutreach>;
  onCallToOpenProfile: (consumer: API.Entities.Consumer) => void;
  onCallToClose: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & WithStyles<typeof styles>;

// endregion

/**
 *
 */
class ConsumersListDialog extends React.Component<Props> {
  static readonly defaultProps = {
    dailyOutreach: null
  };

  private _consumerList: API.Entities.Statistics.ConsumerWithStats[] = [];

  private _header() {
    if (this.props.dailyOutreach === null) {
      return;
    }
    const classes = this.props.classes;
    const day = (
      <Typography className={classes.contextSentence}>
        {format(this.props.dailyOutreach.date, 'EEEE, do MMMM, yyyy (O)')}
      </Typography>
    );

    const regularHeader = (
      <Typography variant="h5">
        {this._consumerList.length ? this._consumerList.length : 'No'}{' '}
        {this.props.dailyOutreach.label} outreach
      </Typography>
    );

    if (this._consumerList.length === 0) {
      return (
        <>
          <Typography variant="h5">No outreach</Typography>
          {day}
        </>
      );
    }
    switch (this.props.dailyOutreach.event) {
      case 'sent':
        if (this.props.dailyOutreach.consumers.unsent.length > 0) {
          return (
            <>
              <Typography variant="h5">
                {this._consumerList.length +
                  this.props.dailyOutreach.consumers.unsent.length}{' '}
                {this.props.dailyOutreach.label} outreach overall
              </Typography>
              <Typography>
                {this.props.dailyOutreach.consumers.sent.length
                  ? this.props.dailyOutreach.consumers.sent.length
                  : 'None'}{' '}
                has been received,{' '}
                {this.props.dailyOutreach.consumers.unsent.length} has been
                unsent to protect your sender reputation by avoiding duplicate
                bounces.
              </Typography>
              {day}
            </>
          );
        }

        return (
          <>
            {regularHeader}
            {day}
          </>
        );
      case 'unsent':
        return (
          <>
            {regularHeader}
            <Typography>
              Outreach has been unsent to protect your sender reputation by
              avoiding duplicate bounces.
            </Typography>
            {day}
          </>
        );
      case 'unreceived':
        return (
          <>
            {regularHeader}
            <Typography>
              Outreach could not be delivered successfully to recipients.
            </Typography>
            {day}
          </>
        );
      default:
        return (
          <>
            {regularHeader}
            {day}
          </>
        );
    }
  }

  // region render & get-render-content methods
  render() {
    if (this.props.dailyOutreach === null) {
      return;
    }
    const classes = this.props.classes;

    this._consumerList = this.props.dailyOutreach.consumers[
      this.props.dailyOutreach.event
    ]
      .filter(
        (consumer): consumer is API.Entities.Statistics.ConsumerWithStats =>
          !!consumer
      )
      .sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }

        return 0;
      });

    const dailyOutreachConsumersById = groupObjectsByStringProperty(
      this._consumerList,
      'id',
      ''
    );

    return (
      <Dialog
        open={this.props.open}
        classes={{ paper: classes.dialog }}
        maxWidth="sm"
        TransitionComponent={Zoom}
        onClose={this.props.onCallToClose}
      >
        <CardHeader
          className={classes.dialogHeader}
          avatar={<PersonOutline />}
          action={
            <IconButton onClick={this.props.onCallToClose}>
              <Close />
            </IconButton>
          }
        />
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid
              item
              xs
              container
              direction="row"
              justify="flex-start"
              alignContent="center"
            >
              <Grid item>{this._header()}</Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {this._consumerList.length === 0 && (
            <Typography>No outreach has been recorded on this date</Typography>
          )}
          {this._consumerList.length > 0 && (
            <List>
              {[...new Set(this._consumerList)].map(consumer => {
                const outreachCount =
                  dailyOutreachConsumersById[consumer.id].length;

                return (
                  <ListItem
                    button
                    key={consumer.id}
                    divider
                    onClick={() => this.props.onCallToOpenProfile(consumer)}
                  >
                    <ListItemText
                      primary={`
                        ${consumer.fullName}
                        ${
                          outreachCount > 1
                            ? ` (${outreachCount} Emails ${this.props.dailyOutreach?.label})`
                            : ''
                        }
                      `}
                      secondary={consumer.organisation ?? ''}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  // endregion
}

export default withStyles(styles)(ConsumersListDialog);
