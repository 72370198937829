import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { TeamDrawerTopBar } from 'src/components';
import {
  CurrentSellerValue,
  withCurrentSeller
} from 'src/contexts/CurrentSellerContext';
import { API } from 'src/definitions';
import RelmApi from 'src/services/RelmApi';
import withDataFromApi, {
  WithDataFromApiProps
} from 'src/services/withDataFromApi';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: '20%'
    },
    innerRoot: {
      padding: `${theme.spacing(2)}px`
    }
  });

// endregion
// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;

  open: boolean;

  onClose?: () => void;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps &
  CurrentSellerValue &
  WithDataFromApiProps<'team', API.Teams.ShowSingleTeam.Response> &
  WithStyles<typeof styles>;

interface State {}

// endregion

/**
 *
 */
@withCurrentSeller<Props>()
@withDataFromApi<Props>(
  props => RelmApi.getTeam(props.currentSeller.team),
  'team'
)
class TeamDrawer extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentSeller: null,
    team: undefined,

    onCurrentSellerChange: () => {},
    loadFromApi: undefined
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = TeamDrawer.name;

  readonly state: State = {};

  // region render & get-render-content methods
  render() {
    const classes = this.props.classes;

    return (
      <Drawer
        classes={{ paper: classes.root }}
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <TeamDrawerTopBar />
        <div className={classes.innerRoot}>
          <Typography variant="h5">{this.props.team.name}</Typography>
          <Typography variant="subtitle2">Team Members</Typography>
          <List>
            {this.props.team.users.map(seller => (
              <ListItem key={seller.id}>
                <ListItemAvatar>
                  <div>
                    {seller.photoUrl && <Avatar src={seller.photoUrl} />}
                  </div>
                </ListItemAvatar>
                <ListItemText>
                  {seller.firstName} {seller.lastName}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    );
  }

  // endregion
}

export default withStyles(styles, { name: TeamDrawer.jssName })(TeamDrawer);
