import {
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { AchievementChip } from 'src/components';
import {
  CurrentStatisticsValue,
  withCurrentStatistics
} from 'src/contexts/CurrentStatisticsContext';
import { API } from 'src/definitions';
import StatsApi from 'src/services/StatsApi';
import {
  getGroupedAchievements,
  isAchievementUnlocked
} from 'src/services/achievements';

// region component styles
const styles = (theme: Theme) =>
  createStyles({
    root: {},
    chipContainer: {
      marginBottom: `${theme.spacing(2)}px`,
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(2)}px`,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }
    }
  });
// endregion

// region component props
interface ExternalProps {
  classes?: Partial<ClassNameMap<keyof typeof styles>>;
  consumer: API.Entities.Consumer;
}

type InternalProps = Required<ExternalProps>;

type Props = InternalProps & CurrentStatisticsValue & WithStyles<typeof styles>;

interface State {
  groupedAchievements: API.Entities.Statistics.ConsumerAchievementValues;
}

// endregion

/**
 *
 */
@withCurrentStatistics<Props>(true)
class ConsumerAchievementsPanel extends React.Component<Props, State> {
  static readonly defaultProps = {
    currentStatistics: null,
    onCurrentStatisticsChange: () => {}
  };

  /**
   * The name to use for jss classes.
   *
   * Easiest way to get this class' original name
   * without pissing off typescript, or modifying
   * every decorator with annoying hacks.
   *
   * @type {string}
   */
  static readonly jssName: string = AchievementChip.name;

  readonly state: State = {
    groupedAchievements: StatsApi.getConsumerAchievementValues(
      this.props.consumer,
      this.props.currentStatistics
    )
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.currentStatistics !== this.props.currentStatistics) {
      this.sync();
    }
  }

  componentDidMount() {
    this.sync();
  }

  sync(): void {
    this.setState({
      groupedAchievements: StatsApi.getConsumerAchievementValues(
        this.props.consumer,
        this.props.currentStatistics
      )
    });
  }

  // region render & get-render-content methods
  render() {
    const groupedAchievements = getGroupedAchievements();

    return (
      <div className={this.props.classes.root}>
        {groupedAchievements.map(groupedAchievement => (
          <div key={groupedAchievement.title}>
            <Typography variant="h6">{groupedAchievement.title}</Typography>
            <div className={this.props.classes.chipContainer}>
              {groupedAchievement.achievements.map(achievementSettings => (
                <AchievementChip
                  key={achievementSettings.achievement}
                  label={achievementSettings.label}
                  isDone={isAchievementUnlocked(
                    achievementSettings.achievement,
                    this.state.groupedAchievements[
                      achievementSettings.calcValue
                    ]
                  )}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  // endregion
}

export default withStyles(styles, { name: ConsumerAchievementsPanel.jssName })(
  ConsumerAchievementsPanel
);
