import { API } from 'src/definitions';
import UpdateConsumerRequest from 'src/schemas/UpdateConsumerRequest';
import Schema from 'validate';

export const UpdateConsumerInBulkRequest = new Schema<
  API.Consumers.UpdateConsumerInBulk.Request
>(
  {
    consumers: {
      required: true,
      type: 'array',
      each: UpdateConsumerRequest.props
    }
  },
  { strip: false }
);
