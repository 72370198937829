import { MenuItem, SvgIcon, makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const useStyles = makeStyles(
  theme => ({
    root: {
      'margin': '5px 0',
      'position': 'relative',
      'display': 'block',
      'backgroundColor': 'transparent',
      'width': 'auto',
      'cursor': 'pointer',
      '&:visited': {
        color: 'inherit'
      },
      '&, &:hover, &:focus': {
        color: 'inherit'
      },
      [theme.breakpoints.up('md')]: {
        margin: '5px'
      }
    },
    menuItem: {
      color: 'inherit',
      borderRadius: '20px'
    },
    icon: {
      float: 'left'
    },
    text: {
      display: 'none',
      marginLeft: '5px',
      [theme.breakpoints.up('md')]: {
        display: 'inline'
      }
    },
    active: {
      '& > $menuItem': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
      }
    }
  }),
  { name: 'MenuLink' }
);

interface Props {
  NavLinkProps?: NavLinkProps;
  icon?: typeof SvgIcon;
  text?: string;

  to: string;
}

export const MenuLink = (props: React.PropsWithChildren<Props>) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <NavLink
      className={classes.root}
      activeClassName={classes.active}
      {...props.NavLinkProps}
      to={props.to}
    >
      <MenuItem className={classes.menuItem}>
        {props.icon && <props.icon className={classes.icon} />}
        {props.text && <span className={classes.text}>{props.text}</span>}
        {props.children}
      </MenuItem>
    </NavLink>
  );
};
