export type StateCallback<T> = (item: T) => void;

export interface StateStore<State> {
  subscribeToItem<K extends keyof State>(
    key: K,
    callback: StateCallback<State[K]>
  ): void;

  unsubscribeFromItem<K extends keyof State>(
    key: K,
    callback: StateCallback<State[K]>
  ): void;

  updateItem<K extends keyof State>(key: K, item: State[K]): void;

  getItem<K extends keyof State>(key: K): State[K];
}

const createStore = <State>(initialState: State): StateStore<State> => {
  let state = initialState;

  // 'as any' makes us about as type-safe as we can get...
  const listeners: {
    [K in keyof State]: Array<StateCallback<State[K]>>;
  } = {} as any;

  const subscribeToItem = <K extends keyof State>(
    key: K,
    callback: StateCallback<State[K]>
  ): void => {
    listeners[key] = listeners[key] || [];
    listeners[key].push(callback);
  };

  const unsubscribeFromItem = <K extends keyof State>(
    key: K,
    callback: StateCallback<State[K]>
  ): void => {
    listeners[key] = listeners[key].filter(listener => listener !== callback);
  };

  const updateItem = <K extends keyof State>(key: K, item: State[K]): void => {
    state = Object.assign({}, state, { [key]: item });

    if (listeners[key]) {
      listeners[key].forEach(listener => listener(state[key]));
    }
  };

  const getItem = <K extends keyof State>(key: K): State[K] => state[key];

  return {
    subscribeToItem,
    unsubscribeFromItem,
    updateItem,
    getItem
  };
};

// const testStore = createStore<StaticToolbarStore>({
//     isVisible: false,
//     editorState: Draft.EditorState.createEmpty(),
//     getEditorRef: () => {},
//     getEditorState: () => Draft.EditorState.createEmpty(),
//     setEditorState: () => {}
// });
//
// testStore.subscribeToItem('editorState', item => item.);

export default createStore;
