import { Card, CardHeader, makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: 0,
      flexGrow: 1,
      flex: '1 0 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    },
    header: {
      textAlign: 'center'
    },
    progress: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      height: '100%'
    }
  }),
  { name: 'StatisticsSimpleCard' }
);

interface Props {
  value: number | string;
  description: string;
}

export const StatisticsSimpleCard = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={props.value.toString()}
        subheader={props.description ?? 'Loading...'}
      />
    </Card>
  );
};
